import Vue from 'vue';
import Component from 'vue-class-component';

import bbox from '@turf/bbox';
import {
  Feature,
  feature as geojsonFeature,
  FeatureCollection,
  featureCollection,
} from '@turf/helpers';

import {
  GeographyableObject,
  GoogleMap,
  LatLngLiteral,
} from '@/types/components';
import centerOfMass from '@turf/center-of-mass';

type MapBounds = {
  east: number;
  north: number;
  south: number;
  west: number;
}

@Component
export default class MapMixin extends Vue {
  map: GoogleMap | null = null;

  mapCenter: LatLngLiteral | null = {
    lat: 45.5176439027329,
    lng: -73.56517745241965,
  };

  mapStyles: any[] = [
    {
      featureType: 'administrative',
      stylers: [{ visibility: 'on' }],
    }, {
      featureType: 'landscape',
      stylers: [{ color: '#fdfdfd' }],
    }, {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    }, {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    }, {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    }, {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#fbddb5' }],
    }, {
      featureType: 'administrative.locality',
      elementType: 'labels.text',
      stylers: [{ visibility: 'on', color: '#404040' }],
    }, {
      featureType: 'water',
      stylers: [{ color: '#E6F9F8' }],
    },
  ];

  mapZoom = 16;

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.mapRef) {
        (this.$refs.mapRef as any).$mapPromise.then((map: GoogleMap) => {
          this.map = map;
        });
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getBoundsFor(geometry: GeographyableObject | GeographyableObject[]): MapBounds {
    const [west, south, east, north] = bbox(this.getFeatureCollection(geometry));

    return {
      south,
      east,
      north,
      west,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getAsArray(itemOrCollection: any | any[]): any[] {
    return Array.isArray(itemOrCollection)
      ? itemOrCollection
      : [itemOrCollection];
  }

  // eslint-disable-next-line class-methods-use-this
  getFeatureFrom(object: GeographyableObject): Feature {
    const { geometry: { properties, geometry }, ...rest } = object;
    return geojsonFeature(geometry, { ...properties, ...rest });
  }

  // eslint-disable-next-line class-methods-use-this
  getFeatureCollection(geometry: GeographyableObject | GeographyableObject[]): FeatureCollection {
    return featureCollection(
      this.getAsArray(geometry).map((g) => this.getFeatureFrom(g)),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFeatureCollectionAsPoints(collection: FeatureCollection) {
    const { features } = collection;

    return featureCollection(
      features.map((f: Feature) => geojsonFeature(centerOfMass(f).geometry, f.properties)),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  isGeographyableObject(object: Record<string, any>) {
    return object
      && 'geometry' in object
      && 'geometry' in object.geometry
      && 'properties' in object.geometry;
  }

  mapAddFeatures(geometry: GeographyableObject | GeographyableObject[]): void {
    const features = this.getFeatureCollection(geometry);

    this.$nextTick(() => {
      (this.$refs.mapRef as any).$mapObject.data.addGeoJson(features);
    });
  }

  mapAddFeaturesAsPoints(geometry: GeographyableObject | GeographyableObject[]): void {
    const features = this.getFeatureCollection(geometry);
    const centroids = this.getFeatureCollectionAsPoints(features);

    this.$nextTick(() => {
      (this.$refs.mapRef as any).$mapObject.data.addGeoJson(centroids);
    });
  }

  mapFitBounds(geometry: GeographyableObject | GeographyableObject[]): void {
    this.$nextTick(() => {
      (this.$refs.mapRef as any).$mapObject.fitBounds(this.getBoundsFor(geometry));
    });
  }

  mapRemoveAllFeatures(): void {
    const mapRef = (this.$refs.mapRef as any);

    this.$nextTick(() => {
      mapRef.$mapObject.data.forEach((f: any) => mapRef.$mapObject.data.remove(f));
    });
  }
}
