































































































































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCallCampaignProgress
  from 'qs_vuetify/src/components/CallCampaign/QsCallCampaignProgress.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsFormEditor from 'qs_vuetify/src/components/QsFormEditor.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';
import QsUserChip from 'qs_vuetify/src/components/QsUserChip.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import {
  AppNotification,
  ButtonProps,
  ExportMimeType,
  Form,
} from 'qs_vuetify/src/types/components';
import {
  CallCampaignStats,
  PersistedCallCampaign,
  PersistedContact,
  PersistedContactExchange,
  PersistedFormDefinitionTemplate,
  PersistedUser,
  User,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import AddSingleContactModal from '@/components/Dialog/AddSingleContactModal.vue';
import CallCampaignAnswers from '@/components/CallCampaign/CallCampaignAnswers.vue';
import CallCampaignLeaderboard from '@/components/CallCampaign/CallCampaignLeaderboard.vue';
import CallCampaignUsersModal from '@/components/Dialog/CallCampaignUsersModal.vue';
import ItemNavigation from '@/components/ItemNavigation.vue';

const callCampaigns: any = namespace('call_campaigns');
const contactExchanges: any = namespace('contact_exchanges');
const contacts: any = namespace('contacts');
const global: any = namespace('global');
const templates: any = namespace('form_definition_templates');
const users: any = namespace('users');
const view: any = namespace('callCampaignsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contacts/data', []);
    this.$store.commit('contacts/lastLoadedAt', null);
    this.$store.commit('contacts/loaded', false);
    this.$store.commit('contact_exchanges/data', []);
    this.$store.commit('contact_exchanges/lastLoadedAt', null);
    this.$store.commit('contact_exchanges/loaded', false);
    next();
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
  components: {
    AddSingleContactModal,
    CallCampaignAnswers,
    CallCampaignLeaderboard,
    CallCampaignUsersModal,
    ItemNavigation,
    QsBooleanIndicator,
    QsButton,
    QsCallCampaignProgress,
    QsCard,
    QsConfirmationModal,
    QsDataTable,
    QsFilters,
    QsFormBuilder,
    QsFormEditor,
    QsHtmlEditor,
    QsUserChip,
  },
})
export default class CallCampaignForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
  NavigationMixin,
) {
  @global.Mutation addNotification!: (arg: AppNotification) => void;
  @global.Mutation removeNotification!: Function;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @callCampaigns.Getter data!: Array<PersistedCallCampaign>;
  @callCampaigns.Getter error!: ErrorResponse;
  @callCampaigns.Getter form!: Form;
  @callCampaigns.Getter initialItem!: string;
  @callCampaigns.Getter item!: PersistedCallCampaign;
  @callCampaigns.Getter loading!: boolean;
  @callCampaigns.Getter loaded!: boolean;
  @callCampaigns.Getter slug!: string;
  @callCampaigns.Getter stats!: CallCampaignStats;
  @callCampaigns.Getter total!: number;
  @callCampaigns.Mutation('item') syncItem!: any;

  @contacts.Getter('data') contacts!: Array<PersistedContact>;
  @contacts.Getter('exportUrl') contactsExportUrl!: string;
  @contacts.Getter('filtersDefinition') contactsFiltersDefinition!: FiltersDefinition | null;
  @contacts.Getter('filtersLoaded') contactsFiltersLoaded!: boolean;
  @contacts.Getter('loaded') contactsLoaded!: boolean;
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;

  @contactExchanges.Getter('data') contactExchanges!: Array<PersistedContactExchange>;
  @contactExchanges.Getter('exportUrl') contactExchangesExportUrl!: string;
  @contactExchanges.Getter('filtersDefinition') contactExchangesFiltersDefinition!: FiltersDefinition | null;
  @contactExchanges.Getter('filtersLoaded') contactExchangesFiltersLoaded!: boolean;
  @contactExchanges.Getter('loading') contactExchangesLoading!: boolean;
  @contactExchanges.Getter('total') contactExchangesTotal!: number;

  @templates.Getter('data') templates!: Array<PersistedFormDefinitionTemplate>;

  @users.Getter('data') users!: PersistedUser[];
  @users.Mutation('data') setUsers!: (arg: PersistedUser[]) => void;

  @view.Mutation clearSeenContactIds!: () => void;
  @view.Getter contactExchangesOptions!: any;
  @view.Getter contactExchangesParams!: RestParams;
  @view.Getter contactsOptions!: any;
  @view.Getter contactsParams!: RestParams;
  @view.Getter params!: RestParams;
  @view.Mutation setContactExchangesOptions!: any;
  @view.Mutation setContactExchangesParams!: any;
  @view.Mutation setContactsOptions!: any;
  @view.Mutation setContactsParams!: any;
  @view.Mutation setParams!: any;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  contactExchangesHeaders = [
    { text: 'No. de membre', value: 'contact.v1_contact_id', sortable: false },
    { text: 'Contact', value: 'contact.full_name', sortable: false },
    { text: 'Appelé par', value: 'called_by_user.contact_name', sortable: false },
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Laissé un message', value: 'left_message', sortable: false },
    { text: 'Réponses', value: 'answers', sortable: false },
    { text: 'Commentaires', value: 'comments', sortable: false },
    { text: 'Réservé par', value: 'reserved_by_user.contact_name', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  contactsFiltersOpen = false;

  contactsHeaders = [
    { text: 'No. de membre', value: 'v1_contact_id', sortable: false },
    { text: 'Contact', value: 'full_name', sortable: false },
    { text: 'Téléphone', value: 'home_phone', sortable: false },
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Appels', value: 'contact_exchanges', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  deleteContactModal: { contact: PersistedContact | null; loading: boolean; visible: boolean } = {
    contact: null,
    loading: false,
    visible: false,
  };

  deleteUserModal: { user: PersistedUser | null; loading: boolean; visible: boolean } = {
    loading: false,
    user: null,
    visible: false,
  };

  loadingPart: string | null = null;
  addSingleContactModalLoading = false;
  selfAssignCallCampaignUserLoading = false;
  showAddSingleContactModal = false;
  showCallCampaignUsersModal = false;
  showFormDefinitionWarningModal = false;
  showLeaderboard = false;
  userWarnedAboutFormDefinition = false;

  user!: User;

  get contactsFiltersSubset(): FiltersDefinition {
    if (!this.contactsFiltersDefinition) {
      return {};
    }

    return {
      status: this.contactsFiltersDefinition.status,
      q: this.contactsFiltersDefinition.q,
    };
  }

  get currentUserIsCaller() {
    return this.users.map((u) => u.id).includes(this.user?.id || 0);
  }

  get formOrder(): string[] {
    if (this.user?.superadmin) {
      return [
        'instance.id',
        'status',
        'name',
        'description',
        'start_at',
        'end_at',
        'public',
        'leave_message',
      ];
    }

    return [
      'status',
      'name',
      'description',
      'start_at',
      'end_at',
      'leave_message',
    ];
  }

  get contactExchangesExportFields(): string[] {
    return [
      'id',
      'status',
      'left_message',
      'comments',
      'contact.id',
      'contact.v1_contact_id',
      'contact.full_name',
      'called_by_user.id',
      'called_by_user.contact_id',
      'called_by_user.contact_name',
      ...Object.keys(this.item.form_definition).map((k) => `answers.${k}`),
    ];
  }

  get contactsExportFields(): string[] {
    return [
      'id',
      'status',
      'first_name',
      'last_name',
      'v1_contact_id',
      'district.name',
      'gender',
      'birthdate',
      'address',
      'apartment',
      'city',
      'postal_code',
      'home_phone',
      'email',
      'contact_exchanges.called_by_user.contact_name',
      ...Object.keys(this.item.form_definition)
        .map((k) => `contact_exchanges.answers.${k}`),
    ];
  }

  get viewParams() {
    return {
      call_campaigns: {
        fields: [
          '*',
          'instance.name',
        ].join(','),
      },
      contact_exchanges: {
        ...ListMixin.buildListState(this.contactExchangesOptions, this.contactExchangesParams),
        fields: [
          'answers',
          'called_by_user_id',
          'called_by_user.contact_name',
          'comments',
          'contact.full_name',
          'contact.v1_contact_id',
          'left_message',
          'recall_at',
          'reserved_by_user_id',
          'reserved_by_user.contact_name',
          'status',
        ].join(','),
      },
      contacts: {
        ...ListMixin.buildListState(this.contactsOptions, this.contactsParams),
        fields: [
          'v1_contact_id',
          'full_name',
          'home_phone',
          'status',
          'contact_exchanges.status',
        ].join(','),
      },
    };
  }

  mounted() {
    this.setActions();
    this.setGlobalSubtitle();
  }

  afterSave() {
    this.setGlobalSubtitle();
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Modifications enregistrées.',
      timeout: 1000,
    });
    this.setActions();
  }

  @Watch('contactExchanges.length')
  onContactExchangesLengthChanged(length: number) {
    if (length === 0 && this.contactExchangesOptions.page !== 1) {
      this.setContactExchangesOptions({ page: 1 });
    }
  }

  @Watch('contacts.length')
  onContactsLengthChanged(length: number) {
    if (length === 0 && this.contactsOptions.page !== 1) {
      this.setContactsOptions({ page: 1 });
    }
  }

  @Watch('hasChanged')
  onHasChangedChanged() {
    this.setActions();
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(loaded: boolean) {
    if (loaded) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
    this.setActions();
    this.$store.commit('call_campaigns/item', null);
  }

  async addSingleContactToCallCampaign(contact: PersistedContact) {
    this.addSingleContactModalLoading = true;

    try {
      await axios.put(`/call_campaigns/${this.item.id}/contacts/${contact.id}`);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été ajouté·e aux destinataires`,
      });

      this.reloadDataRoutesData(['contacts.index', 'call_campaigns.stats']);
      this.showAddSingleContactModal = false;
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: `Erreur lors de l'ajout de ${contact.full_name} aux destinataires`,
      });
    } finally {
      this.addSingleContactModalLoading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  canBeDeleted(contact: PersistedContact): boolean {
    return !contact.contact_exchanges || contact.contact_exchanges.length === 0;
  }

  async deleteCallCampaignContact() {
    const {
      contact,
    } = this.deleteContactModal;

    if (!contact) {
      return;
    }

    this.deleteContactModal.loading = true;

    try {
      await axios.delete(`/call_campaigns/${this.id}/contacts/${contact.id}`);

      this.reloadDataRoutesData(['contacts.index', 'call_campaigns.stats']);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été retiré des destinataires.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors du retrait du destinataire.',
      });
    } finally {
      this.deleteContactModal.contact = null;
      this.deleteContactModal.loading = false;
      this.deleteContactModal.visible = false;
    }
  }

  async deleteCallCampaignUser(): Promise<void> {
    const {
      user,
    } = this.deleteUserModal;

    if (!user) {
      return;
    }

    this.deleteUserModal.loading = true;

    const newUsers = [...this.users];
    const index = newUsers.indexOf(user);
    newUsers[index].loading = true;

    try {
      await axios.delete(`/call_campaigns/${this.id}/users/${user.id}`, {});
      newUsers.splice(index, 1);

      this.setUsers(newUsers);

      this.addNotification({
        color: 'success',
        message: `${user.contact_name || user.email} a été retiré des appelant·es.`,
      });
    } catch (e) {
      newUsers[index].loading = false;

      this.addNotification({
        color: 'error',
        message: "Erreur lors du retrait du·de l'appelant·e.",
      });
    } finally {
      this.deleteUserModal.user = null;
      this.deleteUserModal.loading = false;
      this.deleteUserModal.visible = false;
    }
  }

  async exportContactExchangesCsv() {
    await this.exportContactExchanges('text/csv');
  }

  async exportContactExchangesXls() {
    await this.exportContactExchanges('application/vnd.ms-excel');
  }

  async exportContactsCsv() {
    await this.exportContacts('text/csv');
  }

  async exportContactsXls() {
    await this.exportContacts('application/vnd.ms-excel');
  }

  filterExchangesByV1ContactId(id: number) {
    this.updateContactExchangesFilters('contact.v1_contact_id', id);
  }

  // eslint-disable-next-line class-methods-use-this
  getDataTableItemClass(item: PersistedContact): string[] {
    if (['RET', 'DEC', 'DBL'].includes(item.status)) {
      return ['call-campaign-form__contacts__item--inactive'];
    }

    return [];
  }

  // eslint-disable-next-line class-methods-use-this
  groupContactExchangesByStatus(
    exchanges: Array<PersistedContactExchange>,
  ): Record<string, Array<PersistedContactExchange>> {
    return exchanges.reduce((acc, ce) => {
      if (!acc[ce.status]) {
        acc[ce.status] = [] as Array<PersistedContactExchange>;
      }

      acc[ce.status].push(ce);

      return acc;
    }, {} as Record<string, Array<PersistedContactExchange>>);
  }

  async loadNextPage() {
    if (typeof this.params.page === 'number') {
      this.setParams({
        ...this.params,
        page: this.params.page + 1,
      });
      this.$store.commit('global/subtitle', 'Chargement...');
      this.$emit('updateHead');
      await this.$store.dispatch('call_campaigns/loadPage', this.params);
    }
  }

  async removeExchangeReservation(exchange: PersistedContactExchange) {
    await this.$store.dispatch('contact_exchanges/update', {
      id: exchange.id,
      data: {
        reserved_by_user_id: null,
      },
      params: {},
    });
    this.reloadDataRoutesData(['contact_exchanges.index'], true);
  }

  resetViewParams() {
    this.setContactsParams({});
  }

  async selfAssignCallCampaignUser(): Promise<void> {
    this.selfAssignCallCampaignUserLoading = true;

    try {
      await axios.put(`/call_campaigns/${this.id}/users/${this.user.id}`, {}, {
        params: {
          fields: 'description,instance.name,name',
        },
      });

      this.addNotification({
        color: 'success',
        message: `${this.user.contact_name} a été ajouté·e aux appelant·es.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: `Erreur lors de l'ajout de ${this.user.contact_name} aux appelant·es.`,
      });
    }

    this.selfAssignCallCampaignUserLoading = false;
    this.reloadDataRoutesData(['users.index']);
  }

  setActions() {
    const actions: Array<ButtonProps> = [
      {
        onClick: this.submitForm,
        color: 'primary',
        disabled: !this.hasChanged || this.loading || !!this.item?.deleted_at,
        icon: '$qs-save',
        tooltip: 'Enregistrer',
      },
    ];

    if (this.item && this.userHas('CALL_CAMPAIGNS_DELETE')) {
      if (this.item.deleted_at) {
        actions.push({
          onClick: async () => {
            await this.$store.dispatch('call_campaigns/restore', { id: this.item.id });
            await this.reloadDataRoutesData();
            this.setActions();
          },
          color: 'success',
          icon: 'mdi-archive-refresh',
          tooltip: 'Restaurer',
        });

        if (this.userIsSuperadmin) {
          actions.push({
            onClick: async () => {
              await this.confirmThenForceDeleteItem(
                "Supprimer une campagne d'appels",
                `Êtes-vous certain·e de vouloir <strong>supprimer cette campagne d'appels</strong>?
                Cette opération ne peut pas être annulée. Toutes les données associées
                seraont perdues, y compris l'historique des appels.`,
                this.item.id,
              );
              await this.reloadDataRoutesData();
              this.setActions();
            },
            color: 'error',
            icon: 'mdi-archive-remove',
            tooltip: 'Supprimer définitivement',
          });
        }
      } else {
        actions.push({
          onClick: async () => {
            await this.confirmThenDeleteItem(
              "Archiver une campagne d'appels",
              `Êtes-vous certain·e de vouloir <strong>archiver cette campagne d'appels</strong>?
              Vous ne pourrez plus faire d'appels, mais les données seront conservées.`,
            );
            await this.reloadDataRoutesData();
            this.setActions();
          },
          color: 'error',
          icon: 'mdi-archive',
          tooltip: 'Archiver',
        });
      }
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    if (this.itemReady) {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  setFormDefinition(formDefinition: any) {
    this.syncItem({
      ...this.item,
      form_definition: formDefinition,
    });
  }

  async startCalls() {
    const location: RawLocation = `/call_campaigns/${this.item.id}/contact_exchanges/next`;

    if (this.item.status === 'draft') {
      this.confirm(
        '',
        'La campagne est en mode brouillon. Voulez-vous la rendre active et commencer les'
          + ' appels maintenant?',
        async () => {
          await this.clearSeenContactIds();
          this.$store.commit('contact_exchanges/item', null);

          this.syncItem({
            ...this.item,
            status: 'active',
          });

          await this.submitForm();
          this.$router.push(location);
        },
        'warning',
        'mdi-alert',
      );
    } else if (this.item.status === 'completed') {
      this.addNotification({
        color: 'warning',
        message: 'La campagne est terminée',
        timeout: 2500,
      });
    } else {
      await this.clearSeenContactIds();
      this.$store.commit('contact_exchanges/item', null);

      this.$router.push(location);
    }
  }

  storeContactAndShowConfirmDeleteContactModal(contact: PersistedContact) {
    this.deleteContactModal.contact = contact;
    this.deleteContactModal.visible = true;
  }

  storeContactAndShowConfirmDeleteUserModal(user: PersistedUser) {
    this.deleteUserModal.user = user;
    this.deleteUserModal.visible = true;
  }

  async submitForm() {
    try {
      await this.submit();
    } catch (e) {
      this.addNotification({
        color: 'error',
        timeout: 5000,
        message: `Une erreur s'est produite lors de la sauvegarde de la campagne d'appels.${
          e?.status === 422 ? ' Veuillez corriger les erreurs de validation et réessayer.' : ''
        }`,
      });
    }
  }

  updateContactExchangesFilters(name: string, value: any) {
    const newParams: RestParams = {
      ...this.contactExchangesParams,
    };

    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }

    newParams.page = 1;

    this.setContactExchangesParams(newParams);
  }

  updateContactsFilters(name: string, value: any) {
    const newParams: RestParams = {
      ...this.contactsParams,
    };

    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }

    newParams.page = 1;

    this.setContactsParams(newParams);
  }

  warnAboutFormDefinition(event: MouseEvent) {
    if (!this.userWarnedAboutFormDefinition && this.contactExchangesTotal > 0) {
      event.preventDefault();
      event.stopPropagation();
      this.showFormDefinitionWarningModal = true;
    }
  }

  private async exportContacts(mimeType: ExportMimeType) {
    const params = {
      ...this.contactsParams,
      prefix: `/call_campaigns/${this.item.id}`,
      fields: this.contactsExportFields.join(','),
    };

    const generationNotification: AppNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contacts/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.contactsExportUrl;
      },
    });
  }

  private async exportContactExchanges(mimeType: ExportMimeType) {
    const params = {
      ...this.contactExchangesParams,
      prefix: `/call_campaigns/${this.item.id}`,
      fields: this.contactExchangesExportFields.join(','),
    };

    const generationNotification: AppNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contact_exchanges/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.contactExchangesExportUrl;
      },
    });
  }
}
