













import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { RouteRecord } from 'vue-router';

@Component
export default class NavigationButton extends Vue {
  @Prop({ required: true, type: [Object, String] }) to!: RouteRecord
}
