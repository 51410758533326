








import Component from 'vue-class-component';

import QsContactStatusSection from 'qs_vuetify/src/components/Contacts/QsContactStatusSection.vue';
import QsContactContributions from 'qs_vuetify/src/components/Contacts/QsContactContributions.vue';
import QsContactMemberships from 'qs_vuetify/src/components/Contacts/QsContactMemberships.vue';
import QsContactSources from 'qs_vuetify/src/components/Contacts/QsContactSources.vue';

@Component({
  components: {
    QsContactContributions,
    QsContactMemberships,
    QsContactSources,
  },
})
export default class ExchangeContactStatusSection extends QsContactStatusSection {
}
