var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color-scale" }, [
    _c(
      "div",
      {
        class: ["color-scale__gradient", _vm.minColor, _vm.maxColor],
        style: {
          background:
            "linear-gradient(to right, " +
            _vm.minColor +
            ", " +
            _vm.maxColor +
            ")"
        }
      },
      [
        _vm.value
          ? _c("div", {
              staticClass: "color-scale__dial",
              style: { "margin-left": _vm.value * 100 + "%" }
            })
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "color-scale__labels" }, [
      _c("span", { staticClass: "min" }, [
        _vm._v(_vm._s(_vm.formatNumber(_vm.min)))
      ]),
      _c("span", { staticClass: "max" }, [
        _vm._v(_vm._s(_vm.formatNumber(_vm.max)))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }