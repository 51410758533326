









































































































































import Component from 'vue-class-component';

import QsBaseItemCard from 'qs_vuetify/src/components/QsBaseItemCard.vue';
import QsChangedByIndicator from 'qs_vuetify/src/components/Indicators/QsChangedByIndicator.vue';
import QsProgress from 'qs_vuetify/src/components/QsProgress.vue';

import { PersistedEvent } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsBaseItemCard,
    QsChangedByIndicator,
    QsProgress,
  },
})
export default class EventCard extends QsBaseItemCard<PersistedEvent> {}
