var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: [
        "qs-base-item-card",
        "mail-campaign-card",
        { "mail-campaign-card--archived": !!_vm.item.deleted_at }
      ],
      attrs: { flat: "", href: _vm.href, tile: "" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex pb-1 justify-end" },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected, "hide-details": "" },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex-grow text-truncate align-self-start mt-1" },
            [
              _c("h2", { staticClass: "text-h5 font-weight-bold mb-0" }, [
                _vm._v(" " + _vm._s(_vm.item.name) + " ")
              ]),
              _c("p", { staticClass: "body-1 my-0" }, [
                _vm._v(_vm._s(_vm.item.instance.name))
              ])
            ]
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "flex-shrink align-self-start mt-1 text-right" },
            [
              _c("p", { staticClass: "text-h6 mb-0" }, [
                _vm._v(
                  _vm._s(_vm._f("status")(_vm.item.status, "mail_campaigns"))
                )
              ]),
              _vm.item.created_at === _vm.item.updated_at
                ? _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Créée par "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.item.created_by_user.contact_name))
                    ]),
                    _c("br"),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.created_at)) +
                        " "
                    )
                  ])
                : _vm.item.deleted_at
                ? _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Archivée "),
                    _vm.item.deleted_by_user
                      ? _c("span", [
                          _vm._v("par "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.item.deleted_by_user.contact_name)
                            )
                          ]),
                          _c("br")
                        ])
                      : _vm._e(),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.deleted_at)) +
                        " "
                    )
                  ])
                : _vm.item.sent_at
                ? _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Envoyée "),
                    _vm.item.sent_by_user
                      ? _c("span", [
                          _vm._v(" par "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.item.sent_by_user.contact_name))
                          ]),
                          _c("br")
                        ])
                      : _vm._e(),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.sent_at)) +
                        " "
                    )
                  ])
                : _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Dernière modification par "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.item.updated_by_user.contact_name))
                    ]),
                    _c("br"),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.updated_at)) +
                        " "
                    )
                  ])
            ]
          )
        ],
        1
      ),
      _vm.showText
        ? _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mail-campaign-card__stats mt-3" },
              [
                _c("qs-mail-campaigns-progress", {
                  attrs: { data: _vm.item.stats }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }