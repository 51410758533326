var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "qs-base-item-card event-card",
      attrs: { flat: "", tile: "", to: _vm.to }
    },
    [
      _c(
        "v-card-title",
        {
          class: {
            "d-flex": true,
            "pb-1": _vm.showText
          }
        },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected, "hide-details": "" },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                "flex-grow": true,
                "text-decoration-line-through": _vm.item.status === "deleted",
                "text-truncate": true,
                "align-self-start": true,
                "mt-1": true
              }
            },
            [
              _c("h2", { staticClass: "text-h5 font-weight-bold mb-0" }, [
                _vm._v(_vm._s(_vm.item.title))
              ]),
              _vm._l(_vm.item.instances, function(ref) {
                var id = ref.id
                var name = ref.name
                return _c("span", { key: id, staticClass: "body-1 my-0" }, [
                  _vm._v(" " + _vm._s(name) + " ")
                ])
              })
            ],
            2
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "flex-shrink align-self-start mt-1 text-right" },
            [
              _vm.item.deleted_at
                ? _c("span", [_vm._v(" Archivé ")])
                : _vm.item.status === "past"
                ? _c("span", [_vm._v(" Passé ")])
                : _vm.item.status === "current"
                ? _c("span", [_vm._v(" En cours ")])
                : _vm.item.status === "upcoming"
                ? _c("span", [_vm._v(" En préparation ")])
                : _vm._e(),
              _c("qs-changed-by-indicator", {
                attrs: { item: _vm.item, "model-name": "events" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.showText
        ? _c(
            "v-card-text",
            { staticClass: "d-flex pb-1" },
            [
              _c(
                "div",
                { staticClass: "flex-shrink align-self-start mt-1" },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _vm.item.organized_by_user
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "info", small: "" } },
                                    [_vm._v("mdi-account")]
                                  )
                                ],
                                1
                              ),
                              _c("v-list-item-content", [
                                _vm._v(
                                  "Organisé par " +
                                    _vm._s(
                                      _vm.item.organized_by_user.contact_name
                                    )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "info", small: "" } },
                                [_vm._v("mdi-calendar")]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-content", [
                            _vm._v(
                              _vm._s(_vm._f("datetime")(_vm.item.start_at))
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _c("div", { staticClass: "align-self-start mt-1 text-right" }, [
                _vm._v(" " + _vm._s(_vm.item.location_name) + " ")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.item.contacts_count
        ? _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _vm.item.status === "upcoming"
                    ? _c(
                        "v-col",
                        [
                          _c(
                            "qs-progress",
                            {
                              attrs: {
                                "model-name": "events",
                                values:
                                  _vm.item &&
                                  _vm.item.stats &&
                                  _vm.item.stats.presences
                                    ? [
                                        {
                                          color: "green",
                                          key: "intentions.present",
                                          value:
                                            _vm.item.stats.intentions.present
                                        },
                                        {
                                          color: "yellow",
                                          key: "intentions.maybe",
                                          value: _vm.item.stats.intentions.maybe
                                        },
                                        {
                                          color: "red",
                                          key: "intentions.absent",
                                          value:
                                            _vm.item.stats.intentions.absent
                                        },
                                        {
                                          value: _vm.item.stats.intentions.null
                                        }
                                      ]
                                    : []
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption font-weight-bold text-uppercase"
                                },
                                [_vm._v(" Intentions de présence ")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    [
                      _c(
                        "qs-progress",
                        {
                          attrs: {
                            "model-name": "events",
                            values:
                              _vm.item &&
                              _vm.item.stats &&
                              _vm.item.stats.presences
                                ? [
                                    {
                                      color: "blue",
                                      key: "confirmations.confirmed",
                                      value: _vm.item.stats.confirmed
                                    },
                                    { value: _vm.item.stats.not_confirmed }
                                  ]
                                : []
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase"
                            },
                            [_vm._v(" Confirmations d'inscription ")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.item.status !== "upcoming"
                    ? _c(
                        "v-col",
                        [
                          _c(
                            "qs-progress",
                            {
                              attrs: {
                                "model-name": "events",
                                values:
                                  _vm.item &&
                                  _vm.item.stats &&
                                  _vm.item.stats.presences
                                    ? [
                                        {
                                          color: "green",
                                          key: "presences.present",
                                          value:
                                            _vm.item.stats.presences.present
                                        },
                                        {
                                          color: "red",
                                          key: "presences.absent",
                                          value: _vm.item.stats.presences.absent
                                        },
                                        { value: _vm.item.stats.presences.null }
                                      ]
                                    : []
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption font-weight-bold text-uppercase"
                                },
                                [_vm._v(" Présences à l'événement ")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showAlerts
        ? _c(
            "div",
            { staticClass: "qs-base-item-card__alerts" },
            [
              !_vm.item.contacts_count
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "error" } },
                    [_vm._v(" Aucune inscription à l'événement ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }