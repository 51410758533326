



































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, PropSync, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { AppNotification } from 'qs_vuetify/src/types/components';
import { PersistedTag } from 'qs_vuetify/src/types/models';
import { RestParams, StoreQueryDefinition } from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');
const global: any = namespace('global');
const store: any = namespace('contacts');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsRelationField,
  },
})
export default class AddTagsToContactsModal extends Vue {
  @auth.Getter instanceId!: number | null;

  @global.Mutation addNotification!: (arg: AppNotification) => void;
  @store.Getter('addToTagsContactsLoading') loading!: boolean;

  @Prop({ type: Object, required: true }) params!: RestParams;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  tag: PersistedTag | null = null;
  dummyItem = {};

  queryDefinition: StoreQueryDefinition = {
    slug: 'globalView',
    key: 'tags',
    text: 'name',
    value: 'id',
  }

  async submit() {
    if (this.tag?.id) {
      try {
        await this.$store.dispatch('contacts/addToTagsContacts', {
          data: { tags: [this.tag.id] },
          params: {
            ...this.params,
            fields: 'id',
            per_page: '*',
            page: 1,
          },
        });

        this.addNotification({
          color: 'success',
          message: 'Les étiquettes ont été affectées aux contacts.',
        });

        this.syncedValue = false;
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: "Une erreur est survenue lors de l'affectation des étiquettes.",
        });
      }
    }
  }

  @Watch('syncedValue')
  onSyncedValueChanged(val: boolean) {
    if (!val) {
      (this.$refs.relation as any).clear();
    } else {
      this.$nextTick(() => {
        (this.$refs.relation as any).focus();
      });
    }
  }
}
