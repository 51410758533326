



















































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import LocationField from '@/components/LocationField.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { Event, EventStats, PersistedEvent } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { Prop, Watch } from 'vue-property-decorator';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';
import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';

const events: any = namespace('events');

@Component({
  components: {
    LocationField,
    QsButton,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class EventSideForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ErrorMixin,
  FormMixin,
  NavigationMixin,
) {
  @Prop({ type: [String, Number], required: true }) id!: string | number;

  @events.Getter data!: Array<Event>;
  @events.Getter error!: ErrorResponse;
  @events.Getter form!: Form;
  @events.Getter initialItem!: string;
  @events.Getter item!: PersistedEvent;
  @events.Getter loading!: boolean;
  @events.Getter loaded!: boolean;
  @events.Getter slug!: string;
  @events.Getter stats!: EventStats;
  @events.Getter statsLoaded!: EventStats;
  @events.Getter total!: number;
  @events.Mutation('item') syncItem!: any;

  expansionPanel = 0;

  get eventColor() {
    if (!this.itemReady || this.id === 'new') {
      return 'qs-green';
    }

    const { visibility } = this.item;
    switch (visibility) {
      case 'draft':
        return '#168268';

      case 'private':
        return 'qs-blue';

      case 'public':
        return 'qs-orange';

      default:
        return 'qs-blue';
    }
  }

  get formSections() {
    const sections = [
      {
        section: 'Titre et description',
        fields: ['title', 'description'],
        condition: true,
      },
      {
        section: `Visibilité (${this.visibility})`,
        fields: ['visibility'],
        condition: true,
      },
      {
        section: 'Lieu',
        fields: ['location_name', 'location'],
        condition: true,
      },
      {
        section: 'Accessibilité',
        fields: ['accessibility', 'accessibility_notes'],
        condition: true,
      },
      {
        section: 'Date et heure',
        fields: ['start_at', 'duration'],
        condition: true,
      },
      {
        section: 'Organisé par',
        fields: ['instance_id', 'organized_by_user_id'],
        condition: this.userIsSuperadmin,
      },
    ];

    return sections.filter((s) => !!s.condition);
  }

  get visibility() {
    if (this.item && this.item.visibility) {
      return this.$t(`models.events.enums.visibility.${this.item.visibility}`);
    }

    return '--';
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams() {
    return {
      events: {
        fields: [
          'id',
          'accessibility',
          'accessibility_notes',
          'contacts_count',
          'description',
          'duration',
          'instance_id',
          'location_name',
          'location.google_place_address',
          'location.google_place_id',
          'location.google_place_name',
          'location.point',
          'start_at',
          'title',
          'visibility',
        ].join(','),
        with_deleted: true,
      },
    };
  }

  @Watch('id')
  onIdValueChanged() {
    this.reloadDataRoutesData();
  }

  afterSave() {
    this.reloadDataRoutesData();
  }

  cancelCreate() {
    this.syncItem(null);
    this.goTo(null, { name: 'EventsCalendar' });
  }

  hasErrorsForFields(fields: string[]) {
    if (!this.error || !this.error.errors) {
      return false;
    }

    return Object.keys(this.error.errors).filter((e) => fields.includes(e))
      .length > 0;
  }

  updateItem(item: Event) {
    const index = this.data.map((e) => e.id).indexOf(item.id);
    const data = [...this.data];

    if (index > -1) {
      data.splice(index, 1);
    }

    this.$store.commit('events/data', [
      ...data,
      item,
    ]);

    this.syncItem(item);
  }
}
