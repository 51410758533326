





































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import { PersistedCallCampaign } from 'qs_vuetify/src/types/models';

import TagsMixin from '@/mixins/TagsMixin';

const auth: any = namespace('auth');
const callCampaignsView: any = namespace('callCampaignsView');
const contactsView: any = namespace('contactsView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFilters,
    QsRelationField,
  },
})
export default class ContactExchangeModal extends mixins(
  DataRouteGuards,
  TagsMixin,
  NavigationMixin,
) {
  @auth.Getter instanceId!: number;
  @callCampaignsView.Mutation removeSeenContactId!: (arg: number) => void;
  @contactsView.Getter contactExchangeModal!: boolean;
  @contactsView.Mutation setContactExchangeModal: any;

  @Prop({ required: true, type: [Number, String] }) contactId!: number | string;
  @Prop({ required: false, type: Array, default: () => [] }) excludedCampaignIds!: number[];

  callCampaign: PersistedCallCampaign | null = null;
  dummyItem = {};
  loading = false;

  closeModal(): void {
    this.setContactExchangeModal(false);
  }

  get queryDefinition() {
    return {
      key: 'data',
      slug: 'call_campaigns',
      text: 'name',
      value: 'id',
      params: {
        '!id': this.excludedCampaignIds.join(','),
        fields: 'id,name,searchable_text',
        'instance.id': this.instanceId,
        status: 'active,draft',
        order: 'name',
        per_page: '-1',
        instance_id: this.instanceId,
      },
    };
  }

  async submitForm(): Promise<void> {
    if (!this.callCampaign?.id) {
      return;
    }

    this.loading = true;

    try {
      await axios.put(`/call_campaigns/${this.callCampaign.id}/contacts/${this.contactId}`);
      const { data } = await axios.get(`/call_campaigns/${this.callCampaign.id}/contact_exchanges/next?contact_id=${this.contactId}`);

      if (data) {
        this.removeSeenContactId(Number.parseInt(String(this.contactId), 10));
        this.goTo(null, {
          name: 'CallCampaign',
          params: {
            call_campaign_id: `${this.callCampaign.id}`,
            id: data.id,
          },
        });
      }

      this.$store.commit('global/addNotification', {
        color: 'success',
        message: `Le contact a été ajouté à la campagne d'appel "${this.callCampaign.name}".`,
      });

      this.closeModal();
      this.$emit('click:close');
    } catch (e) {
      this.$store.commit('global/addNotification', {
        color: 'error',
        message: "Une erreur s'est produite lors de l'ajout à la campagne d'appels.",
      });
    } finally {
      this.loading = false;
    }
  }
}
