import axios from 'axios';
import { Module } from 'vuex';

import { RootState } from 'qs_vuetify/src/types/states';

import { GlobalViewState } from '@/types/states';

export const globalViewState: Module<GlobalViewState, RootState> = {
  namespaced: true,
  actions: {
    async callCampaigns({ commit }) {
      commit('callCampaignsLoaded', false);

      const ajax = axios.get('/call_campaigns', {
        params: {
          fields: [
            'name',
            'start_at',
            'end_at',
            'description',
            'public',
            'stats.*',
            'instance.id',
            'instance.name',
            'users.id',
          ].join(','),
          for: 'calls',
          order: 'end_at',
          page: 1,
          per_page: -1,
        },
      });

      const {
        data: {
          data: callCampaigns,
        },
      } = await ajax;

      commit('callCampaigns', callCampaigns);
      commit('callCampaignsLoaded', true);
    },
    async instanceStats({ commit }, params) {
      const ajax = axios.get(`/instances/${params.instanceId}/stats`, {
        params,
      });

      const {
        data: instanceStats,
      } = await ajax;

      commit('instanceStats', instanceStats);
    },
    async tags({ commit }, params) {
      const ajax = axios.get('/tags', {
        params,
      });

      const {
        data: {
          data: tags,
        },
      } = await ajax;

      commit('tags', tags);
    },
  },
  state: {
    callCampaigns: [],
    callCampaignsLoaded: false,
    instanceStats: [],
    loaded: false,
    tags: [],
  },
  getters: {
    callCampaigns(state) {
      return state.callCampaigns;
    },
    callCampaignsLoaded(state) {
      return state.callCampaignsLoaded;
    },
    instanceStats(state) {
      return state.instanceStats;
    },
    loaded(state) {
      return state.loaded;
    },
    tags(state) {
      return state.tags;
    },
  },
  mutations: {
    callCampaigns(state, callCampaigns) {
      state.callCampaigns = callCampaigns;
    },
    callCampaignsLoaded(state, callCampaignsLoaded) {
      state.callCampaignsLoaded = callCampaignsLoaded;
    },
    instanceStats(state, instanceStats) {
      state.instanceStats = instanceStats;
    },
    loaded(state, loaded) {
      state.loaded = loaded;
    },
    tags(state, tags) {
      state.tags = tags;
    },
  },
};

export default globalViewState;
