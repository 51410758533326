import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import {
  Filter,
  PersistedModel,
} from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

const contacts: any = namespace('contacts');
const contactsView: any = namespace('contactsView');
const volunteers: any = namespace('volunteers');
const volunteersView: any = namespace('volunteersView');

@Component
export default class FilterSelectMixin extends Vue {
  instanceId!: number | null;
  type!: string;

  @contacts.Getter('filters') contactsFilters!: Filter[];

  @contactsView.Getter('selectedItems') contactsSelectedItems!: PersistedModel[];
  @contactsView.Getter('draftFilter') contactsDraftFilter!: Filter | null;
  @contactsView.Getter('selectedFilterIndex') contactsSelectedFilterIndex!: number;

  @volunteers.Getter('filters') volunteersFilters!: Filter[];

  @volunteersView.Getter('selectedItems') volunteersSelectedItems!: PersistedModel[];
  @volunteersView.Getter('draftFilter') volunteersDraftFilter!: Filter | null;
  @volunteersView.Getter('selectedFilterIndex') volunteersSelectedFilterIndex!: number;

  get allItemsFilter(): RestParams {
    switch (this.type) {
      case 'volunteers':
        return {
          '!id': 0,
          has_roles: `${this.instanceId};6;;true`,
        };
      case 'contacts':
      default:
        return { '!id': 0 };
    }
  }

  get allItemsLabel(): string {
    switch (this.type) {
      case 'volunteers':
        return 'Tous les militant·es';
      case 'contacts':
      default:
        return 'Tous les contacts';
    }
  }

  get draftFilter(): Filter | null {
    switch (this.type) {
      case 'volunteers':
        return this.volunteersDraftFilter;
      case 'contacts':
      default:
        return this.contactsDraftFilter;
    }
  }

  get filters(): Filter[] {
    switch (this.type) {
      case 'volunteers':
        return this.volunteersFilters;
      case 'contacts':
      default:
        return this.contactsFilters;
    }
  }

  get selectedFilterIndex(): number {
    switch (this.type) {
      case 'volunteers':
        return this.volunteersSelectedFilterIndex;
      case 'contacts':
      default:
        return this.contactsSelectedFilterIndex;
    }
  }

  get selectedItems(): PersistedModel[] {
    switch (this.type) {
      case 'volunteers':
        return this.volunteersSelectedItems;
      case 'contacts':
      default:
        return this.contactsSelectedItems;
    }
  }
}
