var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { color: "white", dark: false, value: _vm.contactExchangeModal },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Échanger dans une campagne d'appels ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.callCampaign,
                    loading: _vm.loading,
                    theme: "labase"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Échanger ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.contactExchangeModal
        ? _c("qs-relation-field", {
            ref: "relation",
            attrs: {
              autofocus: "",
              item: _vm.dummyItem,
              label: "Campagne d'appels",
              name: "call_campaign",
              query: _vm.queryDefinition,
              value: _vm.callCampaign
            },
            on: {
              input: function($event) {
                _vm.callCampaign = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }