










































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFilterModal from 'qs_vuetify/src/components/Dialog/QsFilterModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsIconButton from 'qs_vuetify/src/components/QsIconButton.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsListFilters from 'qs_vuetify/src/components/QsListFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import FiltersMixin from 'qs_vuetify/src/mixins/FiltersMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import { AppNotification, ListAction } from 'qs_vuetify/src/types/components';
import {
  Filter,
  InstanceRole,
  PersistedContact,
  VolunteerStats,
} from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import AddToEventModal from '@/components/Event/AddToEventModal.vue';
import AddTagsToContactsModal from '@/components/Dialog/AddTagsToContactsModal.vue';
import AddToCallCampaignModal from '@/components/Dialog/AddToCallCampaignModal.vue';
import AddToMailCampaignModal from '@/components/Dialog/AddToMailCampaignModal.vue';
import AddUsersToCallCampaignModal from '@/components/Dialog/AddUsersToCallCampaignModal.vue';
import VolunteerCard from '@/components/Volunteer/VolunteerCard.vue';

import { userFields } from '@/App.vue';

const auth: any = namespace('auth');
const contactsView: any = namespace('contactsView');
const global: any = namespace('global');
const volunteers: any = namespace('volunteers');
const view: any = namespace('volunteersView');

type SidebarStatsConfig = Record<string, any> & {
  total?: number;
  label: string;
  key: string;
};

@Component({
  components: {
    AddTagsToContactsModal,
    AddToEventModal,
    AddToCallCampaignModal,
    AddToMailCampaignModal,
    AddUsersToCallCampaignModal,
    QsBaseLayout,
    QsButton,
    QsConfirmationModal,
    QsFilterModal,
    QsFilters,
    QsIconButton,
    QsList,
    QsListFilters,
    VolunteerCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Volunteers extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  FiltersMixin,
  LazyListMixin,
  ListMixin,
  NavigationMixin,
) {
  @auth.Getter instanceId!: number | null;

  @contactsView.Mutation('setParams') setContactsParams!: any;
  @contactsView.Mutation('setSelectedFilterIndex') setContactsSelectedFilterIndex!: any;
  @contactsView.Mutation('setDraftFilter') setContactsDraftFilter!: (filter: Filter | null) => void;

  @volunteers.Getter items!: InstanceRole[];
  @volunteers.Getter error!: ErrorResponse;
  @volunteers.Getter endInstanceRoleVolunteersLoading!: boolean;
  @volunteers.Getter exportUrl!: string;
  @volunteers.Getter loading!: boolean;
  @volunteers.Mutation('filters') setFilters!: (arg: Filter[]) => void;
  @volunteers.Getter slug!: string;
  @volunteers.Getter stats!: VolunteerStats;
  @volunteers.Getter statsLoaded!: boolean;
  @volunteers.Getter total!: number;

  @global.Mutation addNotification!: (arg: AppNotification) => void;

  // 0 => Always draftFilter , 1 => Always "All contacts"
  @view.Getter selectedFilterIndex!: number;
  @view.Mutation setSelectedFilterIndex!: (index: number) => void;

  @view.Mutation addSelectedItem!: any;
  @view.Mutation clearSelectedItems!: () => void;
  @view.Getter draftFilter!: Filter;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: any;
  @view.Getter selectedItems!: Array<PersistedContact>;
  @view.Mutation setDraftFilter!: (filter: Filter | null) => void;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    fields: [
      'adopted_instances.name',
      'contribution_current_year',
      'contribution_last_year',
      'district.name',
      'dpa',
      'email',
      'first_name',
      'full_name',
      ...[
        'contact_event_id',
        'intention',
        'instance_id',
        'location_name',
        'start_at',
        'title',
      ].map((f) => `future_events.${f}`),
      'gender',
      'home_phone',
      'last_contact_exchange.called_at',
      'last_contribution.date',
      'last_name',
      'postal_code',
      'status',
      'v1_contact_id',
      'instance_role_id',
    ].join(','),
    per_page: 20,
    order: 'id',
  };

  showFilterModal = false;

  get exportParams(): RestParams {
    return this.actionParamsWithHasRoles;
  }

  get extraSidebarStats(): SidebarStatsConfig[] {
    return [
      {
        color: 'red',
        filter: this.stats?.volunteer_tag,
        key: 'volunteer_tag',
        label: 'Personnes intéressé·es à <br>être militant·es',
        main_action: {
          action: (event: MouseEvent) => {
            if (this.stats?.volunteer_tag_filter) {
              this.applyContactsDraftFilter(
                this.stats.volunteer_tag_filter,
                'Personnes intéressé·es à être militant·es',
              );
              this.toNewCampaign(event, 'call', 'contacts');
            }
          },
          icon: 'mdi-phone-plus',
          label: 'Appeler',
        },
        secondary_actions: [
          {
            action: () => {
              if (this.stats?.volunteer_tag_filter) {
                this.applyContactParams(this.stats.volunteer_tag_filter);
                this.applyContactsDraftFilter(
                  this.stats.volunteer_tag_filter,
                  'Personnes intéressé·es à être militant·es',
                );
                this.$store.commit('contacts/data', []);
                this.$store.commit('contacts/loaded', false);
                this.$store.commit('contacts/lastLoadedAt', null);
                this.$store.commit('contacts/total', null);
                this.$router.push('/contacts');
              }
            },
            icon: 'mdi-eye',
            label: 'Voir la liste',
          },
          {
            action: (event: MouseEvent) => {
              if (this.stats?.volunteer_tag_filter) {
                this.applyContactsDraftFilter(
                  this.stats.volunteer_tag_filter,
                  'Personnes intéressé·es à être militant·es',
                );
                this.toNewCampaign(event, 'mail', 'contacts');
              }
            },
            icon: 'mdi-email-plus',
            label: 'Envoyer un courriel',
          },
        ],
        text: `Ces personnes ont manifesté leur intérêt à s’impliquer en remplissant
          un formulaire d’implication national (étiquette bénévole2022).
          Appelez-les rapidement pour les mettre en action!`,
        total: this.stats?.volunteer_tag,
      },
      {
        color: 'purple',
        filter: this.stats?.potential_volunteers,
        key: 'potential_volunteers',
        label: 'Bassin de militant·es <br>potentiel·les',
        main_action: {
          action: (event: MouseEvent) => {
            if (this.stats.potential_volunteers_filter) {
              this.applyContactsDraftFilter(
                this.stats?.potential_volunteers_filter,
                'Bassin de militant·es potentiel·les',
              );
              this.toNewCampaign(event, 'call', 'contacts');
            }
          },
          icon: 'mdi-phone-plus',
          label: 'Appeler',
        },
        secondary_actions: [
          {
            action: () => {
              if (this.stats.potential_volunteers_filter) {
                this.applyContactParams(this.stats.potential_volunteers_filter);
                this.applyContactsDraftFilter(
                  this.stats?.potential_volunteers_filter,
                  'Bassin de militant·es potentiel·les',
                );
                this.$store.commit('contacts/data', []);
                this.$store.commit('contacts/loaded', false);
                this.$store.commit('contacts/lastLoadedAt', null);
                this.$store.commit('contacts/total', null);
                this.$router.push('/contacts');
              }
            },
            icon: 'mdi-eye',
            label: 'Voir la liste',
          },
          {
            action: (event: MouseEvent) => {
              if (this.stats.potential_volunteers_filter) {
                this.applyContactsDraftFilter(
                  this.stats.potential_volunteers_filter,
                  'Bassin de militant·es potentiel·les',
                );
                this.toNewCampaign(event, 'mail', 'contacts');
              }
            },
            icon: 'mdi-email-plus',
            label: 'Envoyer un courriel',
          },
        ],
        text: `Ces personnes ont démontré leur appui à QS (étiquette appui2022), ou sont membres en règle
          de votre association. Ce sont des personnes susceptibles de vouloir s'impliquer. Appelez-les
          pour grossir votre liste de militant·es.`,
        total: this.stats?.potential_volunteers,
      },
    ];
  }

  get filterIds(): number[] {
    const filterIds = this.filters.map((stat) => stat.id);
    filterIds.push(0);
    return (filterIds as number[]);
  }

  get filtersLoaded(): boolean {
    return this.statsLoaded;
  }

  get filters(): Filter[] {
    return this.sidebarStats.slice(1).map((stat: SidebarStatsConfig, index) => ({
      id: -(index + 1),
      name: stat.label,
      filter: { ...stat.filter },
    }));
  }

  get sidebarStats(): SidebarStatsConfig[] {
    const sidebarStats: SidebarStatsConfig[] = [];

    sidebarStats.push({
      color: 'qs-blue',
      icon: 'mdi-hand-left',
      filter: this.stats?.instance_volunteers_filter,
      label: 'Tous·tes les militant·es',
      key: 'instance_volunteers',
      text: `Il s'agit de votre liste de bénévoles complète (affectés ou non affectés à un événement).
            Vous devez la construire soit en ajoutant la «main bleue» à un contact ou en cliquant sur l'icône
            «+» en haut à droite.`,
      total: this.stats?.instance_volunteers,
    });

    const withFutureEventButNoConfirmationFilter = this.stats?.with_future_event_but_no_confirmation_filter || { id: '0' };
    const withFutureEventButNoConfirmationLabel = 'Présences à confirmer';
    sidebarStats.push({
      color: 'success',
      filter: this.stats?.with_future_event_but_no_confirmation_filter,
      key: 'volunteers_with_future_event_but_no_confirmation',
      label: withFutureEventButNoConfirmationLabel,
      main_action: {
        action: (event: MouseEvent) => {
          this.toNewCampaign(event, 'call', 'volunteers');
        },
        icon: 'mdi-phone-plus',
        label: 'Appeler',
      },
      secondary_actions: [
        {
          action: () => {
            this.resetFilters();

            this.$nextTick(() => {
              this.applyFilter({
                filter: {
                  ...this.params,
                  ...withFutureEventButNoConfirmationFilter,
                },
                id: null,
                name: 'Copie de Présences à confirmer',
              });

              this.loadFirstPage();
            });
          },
          icon: 'mdi-content-copy',
          label: 'Dupliquer le filtre',
        },
        {
          action: (event: MouseEvent) => {
            this.toNewCampaign(event, 'mail', 'volunteers');
          },
          icon: 'mdi-email-plus',
          label: 'Envoyer un courriel',
        },
      ],
      text: `Ces militant·es sont inscrit·es à une activité dans les 5 prochains jours, mais n'ont
            toujours pas confirmé leur présence. La confirmation de présence est une étape
            essentielle de toute bonne mobilisation. Pour vous assurer d'une participation optimale,
            confirmez leur présence à votre activité!`,
      total: this.stats?.with_future_event_but_no_confirmation,
    });

    const withRecentParticipationsFilter = this.stats?.with_recent_participation_filter || { id: '0' };
    const withRecentParticipationLabel = 'Militant·es récemment actives';
    sidebarStats.push({
      color: 'warning',
      filter: this.stats?.with_recent_participation_filter,
      label: withRecentParticipationLabel,
      main_action: {
        action: (event: MouseEvent) => {
          this.toNewCampaign(event, 'call', 'volunteers');
        },
        icon: 'mdi-phone-plus',
        label: 'Appeler',
      },
      key: 'volunteers_with_recent_participation',
      secondary_actions: [
        {
          action: () => {
            this.resetFilters();

            this.$nextTick(() => {
              this.applyFilter({
                filter: {
                  ...withRecentParticipationsFilter,
                  page: 1,
                },
                id: null,
                name: 'Militant·es récemment actives',
              });

              this.loadFirstPage();
            });
          },
          icon: 'mdi-content-copy',
          label: 'Dupliquer le filtre',
        },
        {
          action: (event: MouseEvent) => {
            this.toNewCampaign(event, 'mail', 'volunteers');
          },
          icon: 'mdi-email-plus',
          label: 'Envoyer un courriel',
        },
      ],
      text: `Ces militant·es ont participé à une activité dans les 5 derniers jours, mais ne sont pas
            inscrit·es à une prochaine activité! Elles et ils sont les plus mobilisables de votre base
            militante. Invitez-les à votre prochaine activité!`,
      total: this.stats?.with_recent_participation,
    });

    const withoutFutureEventsFilter = this.stats?.without_future_event_filter || { id: '0' };
    const withoutFutureEventsLabel = 'Militant·es sans événement';
    sidebarStats.push({
      color: 'primary',
      filter: this.stats?.without_future_event_filter,
      label: withoutFutureEventsLabel,
      main_action: {
        action: (event: MouseEvent) => {
          this.toNewCampaign(event, 'call', 'volunteers');
        },
        icon: 'mdi-phone-plus',
        label: 'Appeler',
      },
      key: 'without_future_event',
      secondary_actions: [
        {
          action: () => {
            this.resetFilters();

            this.$nextTick(() => {
              this.applyFilter({
                filter: {
                  ...withoutFutureEventsFilter,
                  page: 1,
                },
                id: null,
                name: 'Militant·es sans événement',
              });

              this.loadFirstPage();
            });
          },
          icon: 'mdi-content-copy',
          label: 'Dupliquer le filtre',
        },
        {
          action: (event: MouseEvent) => {
            this.toNewCampaign(event, 'mail', 'volunteers');
          },
          icon: 'mdi-email-plus',
          label: 'Envoyer un courriel',
        },
      ],
      text: `Ce sont des personnes de votre liste de militant·es qui ne sont inscrites à aucun
            événement à venir. Invitez-les à votre prochaine activité!`,
      total: this.stats?.without_future_event,
    });

    return sidebarStats.map((s, index) => ({
      ...s,
      value: index + 1,
    }));
  }

  showAddToEventModal = false;
  showAddToCallCampaignModal = false;
  showAddToMailCampaignModal = false;
  showAddUsersToCallCampaignModal = false;
  showAddTagsToContactsModal = false;
  showEndInstanceRoleConfirmationModal = false;
  viewStoreName = 'volunteersView';

  get actionParamsWithHasRoles(): RestParams {
    return {
      ...this.actionParams,
      hasRoles: `${this.instanceId};6;;true`,
    };
  }

  get canDeleteVolunteers(): boolean {
    return !!this.user && this.userHas('VOLUNTEERS_DELETE');
  }

  get canExportContacts(): boolean {
    return !!this.user && this.userHas('CONTACTS_EXPORT');
  }

  get filtersWithRights(): Array<Filter> {
    return this.filters.map((f: Filter) => ({
      ...f,
      _can_delete: this.userHas('FILTERS_DELETE') || f.created_by_user_id === this.user?.id,
    }));
  }

  get showAlerts() {
    return this.$store.state.volunteersView.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('volunteersView/showAlerts', val);
  }

  get showExtraInformation() {
    return this.$store.state.volunteersView.showExtraInformation;
  }

  set showExtraInformation(val: boolean) {
    this.$store.commit('volunteersView/showExtraInformation', val);
  }

  get viewParams(): Record<string, RestParams> {
    return {
      'volunteers.index': {
        ...this.defaultParams,
        ...this.params,
      },
    };
  }

  get listActions(): ListAction[] {
    const n = this.selectedItems.length;

    const exportActions = this.canExportContacts ? [{
      callback: () => { this.exportCsv(); },
      disabled: !this.canExportContacts,
      icon: 'mdi-file-export',
      label: `${this.$tc('models.contacts.actions.export', n)} (CSV)`,
    }] : [];

    const volunteerActions = this.canDeleteVolunteers ? [{
      callback: () => { this.showEndInstanceRoleConfirmationModal = true; },
      disabled: false,
      icon: 'mdi-hand-left',
      label: this.$tc('models.volunteers.actions.end_instance_role', n),
    }] : [];

    const actions: ListAction[] = [
      ...exportActions,
      ...volunteerActions,
      {
        callback: () => {
          this.goTo(null, {
            name: 'NewCallCampaignDialog',
            params: {
              type: 'volunteers',
            },
          });
        },
        disabled: false,
        icon: 'mdi-phone',
        label: this.$tc('models.contacts.actions.new_call_campaign', n),
      },
      {
        callback: () => { this.showAddToCallCampaignModal = true; },
        disabled: false,
        icon: 'mdi-phone-plus',
        label: this.$tc('models.contacts.actions.add_contacts_to_call_campaign', n),
      },
      {
        callback: () => { this.showAddUsersToCallCampaignModal = true; },
        disabled: false,
        icon: 'mdi-phone-forward',
        label: this.$tc('models.contacts.actions.add_users_to_call_campaign', n),
      },
      {
        callback: () => {
          this.goTo(null, {
            name: 'NewMailCampaignDialog',
            params: {
              type: 'volunteers',
            },
          });
        },
        disabled: false,
        icon: 'mdi-email',
        label: this.$tc('models.contacts.actions.new_mail_campaign', n),
      },
      {
        callback: () => { this.showAddToMailCampaignModal = true; },
        disabled: false,
        icon: 'mdi-email-plus',
        label: this.$tc('models.contacts.actions.add_contacts_to_mail_campaign', n),
      },
      {
        callback: () => { this.showAddTagsToContactsModal = true; },
        disabled: false,
        icon: 'mdi-tag-plus',
        label: this.$tc('models.contacts.actions.add_tags_to_contacts', n),
      },
    ];

    if (this.userHas('EVENTS_CREATE')) {
      actions.push({
        callback: () => {
          this.goTo(null, {
            name: 'NewEventDialog',
            params: {
              type: 'volunteers',
            },
          });
        },
        disabled: false,
        icon: 'mdi-calendar',
        label: this.$tc('models.contacts.actions.new_event', n),
      });
    }

    if (this.userHas('CONTACT_EVENTS_CREATE')) {
      actions.push({
        callback: () => { this.showAddToEventModal = true; },
        disabled: false,
        icon: 'mdi-calendar-plus',
        label: this.$tc('models.contacts.actions.add_contacts_to_event', n),
      });
    }

    return actions;
  }

  mounted() {
    this.setAction();

    this.$store.commit('volunteers/exportFields', [
      'id',
      'status',
      'first_name',
      'last_name',
      'v1_contact_id',
      'district.name',
      'gender',
      'birthdate',
      'address',
      'apartment',
      'city',
      'postal_code',
      'home_phone',
      'email',
      'first_membership.period_start',
      'last_membership.period_start',
      'normalized_full_name',
    ]);

    if (this.items.length === 0) {
      this.setParams({
        ...this.viewParams.volunteers,
        page: 1,
      });
    }
  }

  async endInstanceRole() {
    try {
      const prefixedParams = Object.keys(this.actionParams)
        .reduce((acc, k) => {
          if (['fields', 'order', 'page', 'per_page'].includes(k)) {
            return acc;
          }

          acc[`contact.${k}`] = this.actionParams[k];
          return acc;
        }, ({} as RestParams));
      await this.$store.dispatch('volunteers/endInstanceRoleVolunteers', {
        params: {
          ...prefixedParams,
          instance_id: this.instanceId,
          per_page: '*',
          page: 1,
        },
      });

      this.addNotification({
        color: 'success',
        message: 'Le statut de militant·e a été retiré avec succès.',
      });

      this.$store.commit('volunteers/lastLoadedAt', null);
      this.reloadDataRoutesData();
      this.onSelectedFilterIndexChanged(this.selectedFilterIndex);
      this.$store.dispatch('auth/loadUser', userFields);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Une erreur est survenue lors du retrait du statut de militant·e.',
      });
    } finally {
      this.showEndInstanceRoleConfirmationModal = false;
    }
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('VOLUNTEERS_UPDATE') ? [
        {
          onClick: () => { this.$router.push('/volunteers/new'); },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Ajouter un·e militant·e',
        },
      ] : [],
    );
  }

  private applyContactParams(params: RestParams) {
    const {
      fields,
      order,
      per_page,
    } = this.$store.getters['contactsView/params'];
    this.setContactsParams({
      fields,
      order,
      page: 1,
      per_page,
      ...params,
    });
  }

  private applyContactsDraftFilter(params: RestParams, filterName: string) {
    this.setContactsDraftFilter({
      id: null,
      filter: {
        ...params,
      },
      name: `Filtre temporaire (${filterName})`,
      repository: 'Contact',
    });

    this.setContactsSelectedFilterIndex(0);
  }

  private toNewCampaign(event: MouseEvent, campaignType: 'call' | 'mail', type: 'contacts' | 'volunteers') {
    switch (campaignType) {
      case 'call':
        this.goTo(event, {
          name: 'NewCallCampaignDialog',
          params: {
            type,
          },
        });
        break;
      case 'mail':
        this.goTo(event, {
          name: 'NewMailCampaignDialog',
          params: {
            type,
          },
        });
        break;
      default:
        break;
    }
  }

  @Watch('instanceId')
  onVolunteersInstanceIdChanged() {
    this.reloadDataRoutesData(['volunteers.stats']);
  }

  @Watch('$route', { deep: true })
  onVolunteersPathNameChanged() {
    this.setAction();
  }

  @Watch('statsLoaded')
  onVolunteersStatsLoadedChanged(loaded: boolean) {
    if (loaded) {
      this.setFilters(this.filtersWithRights);
    }
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setAction();
    }
  }
}
