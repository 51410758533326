




































































import Component from 'vue-class-component';

import QsBaseItemCard from 'qs_vuetify/src/components/QsBaseItemCard.vue';
import QsCallCampaignProgress from 'qs_vuetify/src/components/CallCampaign/QsCallCampaignProgress.vue';

import { PersistedCallCampaign } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsCallCampaignProgress,
  },
})
export default class CallCampaignCard extends QsBaseItemCard<PersistedCallCampaign> {}
