



























































import Component from 'vue-class-component';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactEventForm from 'qs_vuetify/src/components/Dialog/Events/QsContactEventForm.vue';
import QsContactEventsSection from 'qs_vuetify/src/components/Contacts/QsContactEventsSection.vue';
import QsEventsListItem from 'qs_vuetify/src/components/Events/QsEventsListItem.vue';

@Component({
  components: {
    QsButton,
    QsContactEventForm,
    QsEventsListItem,
  },
})
export default class ExchangeContactEventsSection extends QsContactEventsSection {
}
