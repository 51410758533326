var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { contentClass: "add-to-event-modal", value: _vm.syncedValue },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Ajouter des participant·es ")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [_vm._v(" À un événement ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    disabled: !_vm.event,
                    loading: _vm.loading,
                    theme: "labase"
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.syncedValue
        ? _c("qs-relation-field", {
            ref: "relation",
            attrs: {
              item: _vm.dummyItem,
              label: "Recherche",
              name: "add_to_event",
              query: _vm.queryDefinition,
              value: _vm.event
            },
            on: {
              input: function($event) {
                _vm.event = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-events-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-events-list-item", { attrs: { item: item } })
                    ]
                  }
                }
              ],
              null,
              false,
              599105244
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }