










































import { PollingSubdivision } from 'qs_vuetify/src/types/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SegmentResults extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) closeable!: boolean;
  @Prop({ required: true, type: Object }) item!: PollingSubdivision;
  @Prop({ required: false, type: Boolean, default: false }) withTitle!: boolean;
}
