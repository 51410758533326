import { Module } from 'vuex';

import { Transaction } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { TransactionsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: TransactionsRestState = buildRestState<Transaction>('transactions', {});

export const getters = buildRestGetters<Transaction, TransactionsRestState>();

export const actions = buildRestActions<Transaction, TransactionsRestState>();

export const mutations = buildRestMutations<Transaction, TransactionsRestState>();

export const transactions: Module<TransactionsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
