import Vue from 'vue';
import Component from 'vue-class-component';

import { PersistedTag as Tag } from 'qs_vuetify/src/types/models';
import { namespace } from 'vuex-class';

const tagsStore: any = namespace('tags');

@Component
export default class WokeMixin extends Vue {
  @tagsStore.Getter('data') allTags!: Tag[];

  handicappedFalse = 'A une ou des limitations fonctionnelles: Non';
  handicappedTrue = 'A une ou des limitations fonctionnelles: Oui';
  indigenousFalse = 'Partie d\'une communauté autochtone: Non';
  indigenousTrue = 'Partie d\'une communauté autochtone: Oui';
  racializedFalse = 'Est une personne racisée: Non';
  acializedTrue = 'Est une personne racisée: Oui';

  created() {
    this.$store.dispatch('tags/index', {
      instance_id: null,
    });
  }

  get handicappedFalseId(): number | null {
    const tag = this.allTags.find((t: Tag) => t.name === this.handicappedFalse);
    return tag ? tag.id : null;
  }

  get handicappedTrueId(): number | null {
    const tag = this.allTags.find((t: Tag) => t.name === this.handicappedTrue);
    return tag ? tag.id : null;
  }

  get indigenousFalseId(): number | null {
    const tag = this.allTags.find((t: Tag) => t.name === this.indigenousFalse);
    return tag ? tag.id : null;
  }

  get indigenousTrueId(): number | null {
    const tag = this.allTags.find((t: Tag) => t.name === this.indigenousTrue);
    return tag ? tag.id : null;
  }

  get racializedFalseId(): number | null {
    const tag = this.allTags.find((t: Tag) => t.name === this.racializedFalse);
    return tag ? tag.id : null;
  }

  get racializedTrueId(): number | null {
    const tag = this.allTags.find((t: Tag) => t.name === this.acializedTrue);
    return tag ? tag.id : null;
  }

  // eslint-disable-next-line class-methods-use-this
  contactIsHandicapped(tags: Tag[]): boolean | null {
    if (tags.map((t) => t.name).includes(this.handicappedTrue)) {
      return true;
    }

    if (tags.map((t) => t.name).includes(this.handicappedFalse)) {
      return false;
    }

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  contactIsIndigenous(tags: Tag[]): boolean | null {
    if (tags.map((t) => t.name).includes(this.indigenousTrue)) {
      return true;
    }

    if (tags.map((t) => t.name).includes(this.indigenousFalse)) {
      return false;
    }

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  contactIsRacialized(tags: Tag[]): boolean | null {
    if (tags.map((t) => t.name).includes(this.acializedTrue)) {
      return true;
    }

    if (tags.map((t) => t.name).includes(this.racializedFalse)) {
      return false;
    }

    return null;
  }
}
