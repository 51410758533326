var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "tags-form",
        dark: false,
        value: true
      },
      on: {
        "click:close": function($event) {
          return _vm.$router.push({ name: "Tags" })
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.loading
                ? _c("span", [_vm._v("Chargement...")])
                : _vm.isNew
                ? _c("span", [_vm._v("Nouvelle étiquette")])
                : _c("span", [_vm._v("Modifier l'étiquette")])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: { theme: "labase", loading: !_vm.itemReady },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              ),
              _vm.userHas("TAGS_DELETE") && !!_vm.item && !!_vm.item.instance_id
                ? _c(
                    "qs-button",
                    {
                      staticClass: "ml-3",
                      attrs: {
                        color: "error",
                        loading: !_vm.itemReady,
                        theme: "labase"
                      },
                      on: { click: _vm.archiveTag }
                    },
                    [_vm._v(" Archiver ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.itemReady
        ? _c("qs-tags-form")
        : _c(
            "div",
            {
              staticClass: "d-flex align-center justify-center",
              staticStyle: { "min-height": "10vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ],
            1
          ),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.dialog.value,
          color: _vm.dialog.color,
          icon: _vm.dialog.icon,
          loading: _vm.loading,
          message: _vm.dialog.message,
          title: _vm.dialog.title
        },
        on: {
          "click:cancel": function() {
            return (_vm.dialog.value = false)
          },
          "click:confirm": _vm.dialog.callback
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }