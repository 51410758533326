








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { PersistedPollingSector } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsRelationField,
  },
})
export default class PollingSectorAutocomplete extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) allowCreate!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) disabled!: boolean;
  @Prop({ required: false, default: null }) instanceId!: number | string | null;
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: false, default: null }) value!: PersistedPollingSector | null;

  dummyItem = {};
  newPollingSectorLoading: boolean = false;
  visible = true;

  relationDefinition = {
    key: 'data',
    slug: 'polling_sectors',
    text: 'name',
    value: 'id',
  };

  searchInput: string = '';
  persistedPollingSectorToAdd: PersistedPollingSector | null = null;
  persistedPollingSectorSearchInput: string = '';

  async createThenAddPollingSector(name: string) {
    await this.$store.dispatch('polling_sectors/create', {
      data: {
        name,
        instance_id: this.instanceId,
      },
      params: {
        prefix: 'pointage',
      },
    });

    const newPollingSector = this.$store.getters['polling_sectors/item'];
    this.$emit('created');

    if (newPollingSector) {
      this.addPollingSector(newPollingSector);

      this.$store.dispatch('polling_sectors/index', {
        accessible_from: this.instanceId,
        order: 'name',
        prefix: 'pointage',
        per_page: '*',
      });
    }
  }

  async addPollingSector(pollingSector: PersistedPollingSector | null) {
    if (pollingSector === null) {
      return;
    }

    this.$emit('input', pollingSector);

    this.visible = false;
    this.searchInput = '';
    setTimeout(() => {
      this.visible = true;
    });

    this.persistedPollingSectorToAdd = null;
  }

  @Watch('value', { deep: true })
  onValueChanged(newValue: PersistedPollingSector | null) {
    this.persistedPollingSectorToAdd = newValue;
  }
}
