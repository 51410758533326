import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { MailCampaignsViewState } from '@/types/states';

export const mailCampaignsView: Module<MailCampaignsViewState, RootState> = {
  namespaced: true,
  state: {
    contactsOptions: {
      sortBy: ['full_name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 25,
    },
    contactsParams: {
      page: 1,
      per_page: 25,
    },
    params: {
      status: 'draft,active',
      page: 1,
      per_page: 20,
    },
    selectedItems: [],
    showExtraInformation: true,
  },
  getters: {
    contactsOptions(state) {
      return state.contactsOptions;
    },
    contactsParams(state) {
      return state.contactsParams;
    },
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
  },
  mutations: {
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setContactsOptions(state, contactsOptions) {
      state.contactsOptions = contactsOptions;
    },
    setContactsParams(state, contactsParams) {
      state.contactsParams = contactsParams;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
  },
};

export default mailCampaignsView;
