













































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'axios';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsEditableTextField from 'qs_vuetify/src/components/Fields/QsEditableTextField.vue';
import QsEmailField from 'qs_vuetify/src/components/Fields/QsEmailField.vue';
import QsIconButton from 'qs_vuetify/src/components/QsIconButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import { Contact, Email, PersistedDistrict } from 'qs_vuetify/src/types/models';

import { AppNotification, Form } from 'qs_vuetify/src/types/components';

import ExchangeContactEventsSection from '@/components/Contact/ExchangeContactEventsSection.vue';
import ExchangeContactStatusSection from '@/components/Contact/ExchangeContactStatusSection.vue';
import ExchangeContactTagsSection from '@/components/Contact/ExchangeContactTagsSection.vue';

import completeMembership from '@/helpers/completeMembership';

const global: any = namespace('global');

@Component({
  components: {
    ExchangeContactEventsSection,
    ExchangeContactStatusSection,
    ExchangeContactTagsSection,
    QsButton,
    QsEditableTextField,
    QsEmailField,
    QsIconButton,
  },
})
export default class ExchangeContactSection extends mixins(AuthenticationMixin, NavigationMixin) {
  @Prop({ required: false, default: null }) contactEventForm?: Form;
  @Prop({ type: [String, Number], required: false }) id!: number | string | null;
  @Prop({ type: [String, Number], required: true }) campaignId!: number | string;
  @Prop({ type: Boolean, required: false, default: false }) loading!: boolean;

  @global.Mutation addNotification!: (arg: AppNotification) => void;

  internalLoading: boolean = false;
  item: Contact | null = null;
  tabs: 'info' | 'tabs' | 'events' = 'info';
  stickyLoading = false;

  mounted() {
    this.getItem();
  }

  get districtSelectItems(): PersistedDistrict[] {
    if (this.$store.getters['districts/data']) {
      return this.$store.getters['districts/data'].map((d: PersistedDistrict) => ({
        text: d.name,
        value: d.id,
      }));
    }

    return [];
  }

  get instanceId(): number {
    return this.$store.getters['auth/instanceId'];
  }

  get mainEmail(): Email | null {
    if (!this.item) {
      return null;
    }

    if (this.item.emails.length < 1) {
      return null;
    }

    return this.item.emails[0];
  }

  get combinedLoading(): boolean {
    return this.internalLoading || this.stickyLoading;
  }

  @Watch('loading')
  onLoadingChanged(loading: boolean) {
    if (loading) {
      this.stickyLoading = loading;
    } else {
      setTimeout(() => {
        this.stickyLoading = loading;
      }, 1000);
    }
  }

  completeMembership = completeMembership;

  async getItem(): Promise<void> {
    this.item = null;

    if (this.id) {
      try {
        this.internalLoading = true;

        const ajax = axios.get(`/contacts/${this.id}`, {
          params: {
            fields: [
              'address',
              'adopted_instances.name',
              'apartment',
              'birthdate',
              'city',
              'district.name',
              'district_id',
              'email',
              'emails.*',
              'first_name',
              'gender',
              'home_phone',
              'last_name',
              'main_language',
              'postal_code',
              'status',
              'updated_at',
              'unsubscriptions.id',
              'v1_contact_id',
            ].join(','),
            with_excluded: true,
          },
        });

        const { data } = await ajax;
        this.item = data;
      } catch (e) {
        this.$emit('error', e);
      } finally {
        this.internalLoading = false;
      }
    }
  }

  @Watch('item', { deep: true })
  async onContactItemChanged(newItem: Contact | null, oldItem: Contact | null) {
    if (!this.item) {
      return;
    }

    if (newItem && oldItem && newItem.postal_code !== oldItem.postal_code) {
      await this.$store.dispatch('districts/index', {
        postal_code: this.item.postal_code.replace(' ', ''),
        fields: 'name',
      });
    }
  }

  @Watch('id')
  onIdChanged(newId: number | null, oldId: number | null) {
    if (newId && newId != oldId) { // eslint-disable-line eqeqeq
      this.getItem();
    }
  }

  async patch(field: string, value: any) {
    if (!this.item) {
      return;
    }

    if (value === this.item[field]) {
      return;
    }

    const previousValue = this.item[field];
    try {
      this.internalLoading = true;
      this.item[field] = value;

      await axios.put(`/call_campaigns/${this.campaignId}/contacts/${this.id}`, { [field]: value });

      this.addNotification({
        color: 'success',
        message: 'Modification enregistrée avec succès.',
        timeout: 2500,
      });

      this.$emit('change');
    } catch (e) {
      this.item[field] = previousValue;

      if (['DBL', 'DEC', 'RET'].includes(this.item.status)) {
        this.addNotification({
          color: 'error',
          message: 'Impossible de modifier des contacts exclus des listes.',
          timeout: 5000,
        });
      } else {
        this.addNotification({
          color: 'error',
          message: "Erreur lors de l'enregistrement de la modification.",
          timeout: 2500,
        });
      }
    } finally {
      this.internalLoading = false;
    }
  }
}
