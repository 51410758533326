



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { District } from 'qs_vuetify/src/types/models';

interface ContactListItem {
  district: District;
  email: string;
  full_name: string;
  status: string;
  v1_contact_id: number;
}

@Component({
  filters: {
    status(status: string): string {
      switch (status) {
        case 'MER':
          return 'En règle';
        case 'MEC':
          return 'Échu -1 an';
        case 'EXM':
          return 'Échu +1 an';
        default:
          return 'Non-membre';
      }
    },
  },
})
export default class QsContactListItem extends Vue {
  @Prop({ required: true, type: Object }) item!: ContactListItem;
}
