var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.callCampaign
    ? _c(
        "v-container",
        { staticClass: "call-campaign-exchange", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass:
                "flex-shrink-1 flex-grow-0 call-campaign-exchange__header"
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "call-campaign-exchange__header__name",
                  attrs: { cols: "12", md: "3" }
                },
                [
                  _c("h1", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(_vm.callCampaign.name))
                  ])
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "call-campaign-exchange__header__stats",
                  attrs: { cols: "12", md: "9" }
                },
                [
                  _vm.stats
                    ? _c("qs-call-campaign-progress", {
                        staticClass: "d-flex flex-column",
                        attrs: { data: _vm.stats },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "chips",
                              fn: function() {
                                return [
                                  _vm.callCampaign && _vm.callCampaign.instance
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "call-campaign-exchange__header__stats__instance-chip"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.callCampaign.instance.name
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _c("div", [_vm._v(" Instance ")])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1886993512
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "flex-grow-1 d-flex mt-4",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "call-campaign-exchange__contact",
                  attrs: { cols: "12", md: "3" }
                },
                [
                  _vm.callCampaign.id
                    ? _c("exchange-contact-section", {
                        ref: "ExchangeContactSection",
                        staticClass: "py-0",
                        attrs: {
                          "campaign-id": _vm.callCampaign.id,
                          "contact-event-form": _vm.contactEventForm,
                          id: _vm.contact ? _vm.contact.id : null,
                          loading: _vm.loading
                        },
                        on: {
                          change: function($event) {
                            return _vm.$refs.QsContactHistory.fetchRevisions()
                          },
                          "change:tags": function($event) {
                            return _vm.$refs.QsContactHistory.fetchTags()
                          }
                        }
                      })
                    : _c("v-card", {
                        staticClass: "exchange-contact-section py-0 px-0",
                        attrs: { loading: "", tile: "" }
                      })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "call-campaign-exchange__exchange",
                  attrs: { cols: "12", md: "6" }
                },
                [
                  _c(
                    "v-card",
                    {
                      class:
                        "call-campaign-exchange__exchange__info " +
                        (_vm.loading ? "d-flex flex-column" : ""),
                      attrs: { tile: "", height: "100%" }
                    },
                    [
                      _vm.loading || !_vm.itemReady
                        ? _c("qs-loading-indicator")
                        : [
                            _vm.user &&
                            _vm.item.reserved_by_user &&
                            _vm.item.reserved_by_user.id !== _vm.user.id
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "text-center",
                                    attrs: { color: "warning" }
                                  },
                                  [
                                    _vm._v(
                                      " Réservé par " +
                                        _vm._s(
                                          _vm.item.reserved_by_user.contact_name
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " le " +
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            _vm.item.reserved_at,
                                            "D MMMM YYYY à HH:mm:ss"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "text-uppercase text-h2 font-weight-bold pt-0 mb-0"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("call_campaigns.script")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "call-campaign-exchange__exchange__content flex-shrink-1 flex-grow-0"
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "call-campaign-exchange__exchange__content__script qs-dark-blue white--text",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.callCampaign.script)
                                  }
                                }),
                                _c("qs-toggle-field", {
                                  attrs: {
                                    label: _vm.$t("call_campaigns.answered"),
                                    default: _vm.answered,
                                    name: "answered",
                                    "is-toggler": true
                                  },
                                  model: {
                                    value: _vm.answered,
                                    callback: function($$v) {
                                      _vm.answered = $$v
                                    },
                                    expression: "answered"
                                  }
                                }),
                                _vm.answered && _vm.item && _vm.item.answers
                                  ? _c("qs-form-builder", {
                                      attrs: {
                                        item: _vm.item.answers,
                                        form: _vm.callCampaign.form_definition,
                                        "model-name": "contact_exchange.answers"
                                      },
                                      on: { input: _vm.updateAnswers },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "enum",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              return [
                                                _c(
                                                  "qs-themed-select-field",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        description: descriptionOrNothing(
                                                          field.name
                                                        ),
                                                        items: _vm.labelsOrValues(
                                                          field.name,
                                                          field.def.rules.oneOf
                                                        ),
                                                        label:
                                                          field.def.label ||
                                                          labelOrName(
                                                            field.name
                                                          ),
                                                        name: field.name,
                                                        value: item[field.name]
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return update(
                                                            field.name,
                                                            $event
                                                              ? $event.value
                                                              : null
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "qs-themed-select-field",
                                                    field.def,
                                                    false
                                                  )
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "checkbox",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              var errorMessagesFor =
                                                ref.errorMessagesFor
                                              return [
                                                field.def.toggler
                                                  ? _c(
                                                      "qs-toggle-field",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            "is-toggler": "",
                                                            default:
                                                              item[
                                                                field.name
                                                              ] ||
                                                              field.def.default,
                                                            label:
                                                              field.def.label ||
                                                              labelOrName(
                                                                field.name
                                                              ),
                                                            description: descriptionOrNothing(
                                                              field.name
                                                            ),
                                                            value:
                                                              item[field.name],
                                                            name: field.name,
                                                            errors: errorMessagesFor(
                                                              field.name
                                                            )
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return update(
                                                                field.name,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "qs-toggle-field",
                                                        field.def,
                                                        false
                                                      )
                                                    )
                                                  : _c(
                                                      "qs-checkbox-field",
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            default:
                                                              item[
                                                                field.name
                                                              ] ||
                                                              field.def.default,
                                                            label:
                                                              field.def.label ||
                                                              labelOrName(
                                                                field.name
                                                              ),
                                                            description: descriptionOrNothing(
                                                              field.name
                                                            ),
                                                            value:
                                                              item[field.name],
                                                            name: field.name,
                                                            errors: errorMessagesFor(
                                                              field.name
                                                            )
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return update(
                                                                field.name,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "qs-checkbox-field",
                                                        field.def,
                                                        false
                                                      )
                                                    )
                                              ]
                                            }
                                          },
                                          {
                                            key: "text",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              return [
                                                _c(
                                                  "qs-themed-text-field",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label:
                                                          field.def.label ||
                                                          labelOrName(
                                                            field.name
                                                          ),
                                                        description: descriptionOrNothing(
                                                          field.name
                                                        ),
                                                        value: item[field.name],
                                                        name: field.name
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return update(
                                                            field.name,
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "qs-themed-text-field",
                                                    field.def,
                                                    false
                                                  )
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "textarea",
                                            fn: function(ref) {
                                              var field = ref.field
                                              var item = ref.item
                                              var update = ref.update
                                              var labelOrName = ref.labelOrName
                                              var descriptionOrNothing =
                                                ref.descriptionOrNothing
                                              return [
                                                _c(
                                                  "qs-themed-textarea-field",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label:
                                                          field.def.label ||
                                                          labelOrName(
                                                            field.name
                                                          ),
                                                        description: descriptionOrNothing(
                                                          field.name
                                                        ),
                                                        value: item[field.name],
                                                        name: field.name
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return update(
                                                            field.name,
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "qs-themed-textarea-field",
                                                    field.def,
                                                    false
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1864034857
                                      )
                                    })
                                  : _vm._e(),
                                !_vm.answered
                                  ? _c(
                                      "div",
                                      [
                                        _c("qs-toggle-field", {
                                          attrs: {
                                            disabled: _vm.answered,
                                            default: _vm.item.left_message,
                                            name: "left_message",
                                            label: _vm.$t(
                                              "call_campaigns.left_message"
                                            ),
                                            "is-toggler": ""
                                          },
                                          model: {
                                            value: _vm.item.left_message,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "left_message",
                                                $$v
                                              )
                                            },
                                            expression: "item.left_message"
                                          }
                                        }),
                                        _c("qs-split-date-field", {
                                          staticClass: "mb-7",
                                          attrs: {
                                            description:
                                              "\n                  Choisir un moment de rappel consigne cet appel et<br>\n                  programme un rappel après la date et heure spéciféees.\n                ",
                                            label:
                                              "Choisir un moment de rappel",
                                            name: "recall_at"
                                          },
                                          model: {
                                            value: _vm.item.recall_at,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.item,
                                                "recall_at",
                                                $$v
                                              )
                                            },
                                            expression: "item.recall_at"
                                          }
                                        }),
                                        _vm.userHas("CONTACTS_DELETE")
                                          ? _c("qs-checkbox-field", {
                                              attrs: {
                                                bordered: "",
                                                large: "",
                                                disabled: _vm.answered,
                                                default:
                                                  _vm.item.notify_deceased,
                                                name: "notify_deceased",
                                                label: _vm.$t(
                                                  "call_campaigns.notify_deceased"
                                                )
                                              },
                                              model: {
                                                value: _vm.item.notify_deceased,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "notify_deceased",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.notify_deceased"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("qs-themed-textarea-field", {
                                  attrs: {
                                    name: "comments",
                                    label: "Commentaires"
                                  },
                                  model: {
                                    value: _vm.item.comments,
                                    callback: function($$v) {
                                      _vm.$set(_vm.item, "comments", $$v)
                                    },
                                    expression: "item.comments"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                      _c(
                        "v-card-actions",
                        {
                          staticClass:
                            "d-flex call-campaign-exchange__exchange__info__actions"
                        },
                        [
                          _vm.userHas("CALL_CAMPAIGN_CONTACTS_DELETE") ||
                          _vm.userHas("CAMPAIGN_CONTACTS_DELETE")
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "white--text px-0",
                                                  attrs: {
                                                    color: "qs-light-blue",
                                                    disabled: _vm.loading,
                                                    large: "",
                                                    theme: "labase"
                                                  },
                                                  on: {
                                                    click: _vm.removeContact
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v("mdi-account-remove")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1341736429
                                  )
                                },
                                [_vm._v(" Retirer le contact ")]
                              )
                            : _vm._e(),
                          _c(
                            "qs-button",
                            {
                              staticClass: "flex-grow-1 white--text",
                              attrs: {
                                color: "qs-orange",
                                disabled: _vm.loading,
                                large: "",
                                theme: "labase"
                              },
                              on: { click: _vm.confirmOrQuit }
                            },
                            [
                              _c("v-icon", [_vm._v("$qs-phone-down")]),
                              _vm._v(" Quitter ")
                            ],
                            1
                          ),
                          _c(
                            "qs-button",
                            {
                              staticClass: "flex-grow-1 qs-dark-blue--text",
                              attrs: {
                                color: "qs-green",
                                disabled: _vm.loading,
                                large: "",
                                theme: "labase",
                                title: "Continuer"
                              },
                              on: { click: _vm.completeExchange }
                            },
                            [
                              _c("v-icon", [_vm._v("$qs-phone-next")]),
                              _vm.previousLocation
                                ? _c("span", [_vm._v(" Sauvegarder ")])
                                : _c("span", [_vm._v("Continuer")])
                            ],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "qs-button",
                                          _vm._g(
                                            {
                                              staticClass: "white--text px-0",
                                              attrs: {
                                                color: "qs-light-blue",
                                                disabled: _vm.loading,
                                                large: "",
                                                theme: "labase"
                                              },
                                              on: {
                                                click: _vm.confirmOrSkipAndNext
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "white" } },
                                              [_vm._v("mdi-skip-next")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2266594792
                              )
                            },
                            [_vm._v(" Passer cet appel ")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c("qs-contact-history", {
                    ref: "QsContactHistory",
                    staticClass: "call-campaign-exchange__history py-0",
                    attrs: {
                      id: _vm.contact ? _vm.contact.id : null,
                      "instance-id": _vm.callCampaign.instance
                        ? _vm.callCampaign.instance.id
                        : null,
                      user: _vm.user
                    },
                    on: {
                      reload: function($event) {
                        return _vm.$refs.ExchangeContactSection.getItem()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "qs-confirm-dialog",
            {
              attrs: {
                prompt: "Campagne terminée 🎉",
                cancelText: "Quitter",
                confirmText: "Continuer les appels",
                onCancel: function() {
                  _vm.endDialog = false
                  _vm.$router.replace("/")
                },
                onConfirm: function() {
                  _vm.endDialog = false
                  _vm.setRecallSameDay(true)
                }
              },
              model: {
                value: _vm.endDialog,
                callback: function($$v) {
                  _vm.endDialog = $$v
                },
                expression: "endDialog"
              }
            },
            [
              _vm._v(
                " Ou presque! Tous les destinataires de cette campagne d'appels ont reçu au moins une tentative d'appel. Les contacts qui restent ont été tentés aujourd'hui, sans réponse."
              ),
              _c("br"),
              _c("br"),
              _vm._v(" Voulez-vous poursuivre les appels quand même? ")
            ]
          ),
          _vm.stats && _vm.stats.leaderboard
            ? _c("call-campaign-leaderboard", {
                attrs: { users: _vm.stats.leaderboard },
                model: {
                  value: _vm.showLeaderboard,
                  callback: function($$v) {
                    _vm.showLeaderboard = $$v
                  },
                  expression: "showLeaderboard"
                }
              })
            : _vm._e(),
          _c(
            "qs-action-modal",
            {
              attrs: { "content-class": "seen_contacts" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Derniers échanges ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3016692721
              ),
              model: {
                value: _vm.showSeenContacts,
                callback: function($$v) {
                  _vm.showSeenContacts = $$v
                },
                expression: "showSeenContacts"
              }
            },
            [
              _c("v-alert", { staticClass: "body-2" }, [
                _c("strong", [_vm._v("Attention!")]),
                _vm._v(" Assurez-vous de sauvegarder votre échange actuel "),
                _c("em", [_vm._v("avant")]),
                _vm._v(" de consigner votre retour d'appel. ")
              ]),
              _c(
                "v-list",
                _vm._l(_vm.seenContacts, function(ref) {
                  var called_at = ref.called_at
                  var contact = ref.contact
                  var direction = ref.direction
                  var id = ref.id
                  var status = ref.status
                  return _c(
                    "v-list-item",
                    { key: "seen_contact_" + id },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                color:
                                  status === "completed" ? "success" : "error"
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "qs-grey" } }, [
                                _vm._v(
                                  " " + _vm._s("mdi-phone-" + direction) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold" },
                            [_vm._v(_vm._s(contact.full_name))]
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$filters.phone(contact.home_phone)
                                    ? _vm.$filters.phone(contact.home_phone)
                                    : contact.home_phone
                                ) +
                                " "
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(contact.age) +
                                " ans · " +
                                _vm._s(
                                  _vm.$filters.postalCode(contact.postal_code)
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          called_at
                            ? _c("v-list-item-action-text", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$dayjs(called_at).from(_vm.$dayjs())
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "qs-button",
                            {
                              attrs: {
                                icon: "",
                                loading:
                                  _vm.jumpToPreviousExchangeLoading ===
                                  contact.id,
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.jumpToPreviousExchange(contact.id)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-phone-return")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }