var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "navigation-button",
              {
                attrs: {
                  to: {
                    name: "DistrictResult",
                    params: {
                      districtElectionId: _vm.$route.params.districtElectionId
                    }
                  }
                }
              },
              [
                _c("v-icon", { staticClass: "mr-4" }, [
                  _vm._v("mdi-arrow-left")
                ]),
                _vm._v(" Résultats par circonscription "),
                _c("v-spacer")
              ],
              1
            ),
            _c(
              "navigation-button",
              {
                attrs: {
                  to: {
                    name: "SubdivisionResults",
                    params: {
                      districtElectionId: _vm.$route.params.districtElectionId
                    }
                  }
                }
              },
              [
                _c("v-icon", { staticClass: "mr-4" }, [
                  _vm._v("mdi-arrow-left")
                ]),
                _vm._v(" Résultats par bureau de vote "),
                _c("v-spacer")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m3-card m3-surface pa-2" },
              [
                _c(
                  "v-btn-toggle",
                  {
                    staticClass: "m3-segmented-button",
                    attrs: { dense: "" },
                    model: {
                      value: _vm.activeMapStyle,
                      callback: function($$v) {
                        _vm.activeMapStyle = $$v
                      },
                      expression: "activeMapStyle"
                    }
                  },
                  [
                    _c("v-btn", { attrs: { value: "votes" } }, [
                      _vm._v("Nb votes")
                    ]),
                    _c("v-btn", { attrs: { value: "votes__valid_ballots" } }, [
                      _vm._v("% votes")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.currentDistrict
              ? _c(
                  "div",
                  [
                    _c(
                      "v-list",
                      { staticStyle: { "background-color": "transparent" } },
                      _vm._l(_vm.currentDistrict.elections, function(ref) {
                        var name = ref.name
                        var id = ref.district_election_id
                        return _c(
                          "v-list-item",
                          {
                            key: id,
                            attrs: {
                              to: {
                                name: "SectorResults",
                                params: { districtElectionId: id }
                              }
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-2",
                                attrs: { color: "qs-blue", small: "" }
                              },
                              [_vm._v("mdi-subdirectory-arrow-right")]
                            ),
                            _vm._v(" " + _vm._s(name) + " ")
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "m3-card m3-surface pa-2" },
              [
                _c("v-data-table", {
                  staticStyle: { "background-color": "transparent" },
                  attrs: {
                    "custom-sort": _vm.customSort,
                    dense: "",
                    headers: _vm.headers,
                    items: _vm.items,
                    "items-per-page": 20,
                    loading: _vm.loading
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.polling_sector.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _vm.getWinnerColor(item),
                                small: ""
                              }
                            },
                            [_vm._v(" mdi-circle ")]
                          ),
                          _vm._v(" " + _vm._s(item.polling_sector.name) + " ")
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: "",
                                icon: "",
                                text: "",
                                small: "",
                                to:
                                  "/elections/" +
                                  _vm.$route.params.districtElectionId +
                                  "/subdivisions/" +
                                  item.id
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "qs-light-blue", small: "" }
                                },
                                [_vm._v("mdi-plus")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }