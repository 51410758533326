
















































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import CommentsDisplay from '@/components/CommentsDisplay.vue';
import NavigationButton from '@/components/ElectionResults/NavigationButton.vue';
import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import PollingSectorsAutocomplete from '@/components/ElectionResults/PollingSectorsAutocomplete.vue';
import SegmentResults from '@/components/ElectionResults/SegmentResults.vue';
import TurnoutData from '@/components/ElectionResults/TurnoutData.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import MapMixin from '@/mixins/MapMixin';

import { PersistedComment, PollingSubdivision } from 'qs_vuetify/src/types/models';
import { AppNotification, DataTableOptions, DialogProps } from 'qs_vuetify/src/types/components';
import { RestParams } from 'qs_vuetify/src/types/states';
import { GeographyableObject } from '@/types/components';

const comments = namespace('comments');
const global: any = namespace('global');
const pollingSubdivisions = namespace('polling_subdivisions');
const view = namespace('pollingSubdivisionsView');

@Component({
  components: {
    CommentsDisplay,
    NavigationButton,
    QsActionModal,
    QsButton,
    QsConfirmationModal,
    QsRelationField,
    PollingSectorsAutocomplete,
    SegmentResults,
    TurnoutData,
  },
})
export default class SubdivisionResultsDetails extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  MapMixin,
) {
  @comments.Getter('data') comments!: PersistedComment[];
  @comments.Getter('loading') commentsLoading!: boolean;

  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @pollingSubdivisions.Getter item!: PollingSubdivision;
  @pollingSubdivisions.Getter loading!: boolean;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;

  comment: string = '';
  dialog: DialogProps = {
    callback: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    loading: false,
    message: null,
    title: null,
    value: false,
  };

  get title() {
    if (this.item && this.item.type === 'BVO' && this.item.name) {
      return `Bureau de vote #${this.item.name}`;
    }

    if (this.item && this.item.type === 'BVA' && this.item.number) {
      return `Bureau de vote anticipé #${this.item.number}`;
    }

    if (this.item && this.item.name) {
      return this.item.name;
    }

    return 'Bureau de vote';
  }

  get viewParams() {
    return {
      polling_subdivisions: {
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  async confirmThenDestroyComment(id: string | number) {
    await new Promise((resolve) => {
      this.dialog.callback = async () => {
        this.dialog.loading = true;
        await this.destroyComment(id);
        this.dialog.value = false;
        this.dialog.loading = false;
        resolve(true);
      };

      this.dialog.color = 'error';
      this.dialog.icon = 'mdi-delete';
      this.dialog.loading = false;
      this.dialog.message = 'Voulez-vous vaiment continuer? Cette action est irréversible.';
      this.dialog.title = 'Supprimer un commentaire';
      this.dialog.value = true;
    });
  }

  async destroyComment(id: string | number) {
    await this.$store.dispatch('comments/destroy', {
      id,
      prefix: `/pointage/polling_subdivisions/${this.item.id}`,
    });
    this.reloadDataRoutesData(['comments.index']);
  }

  async postComment(text: string) {
    await this.$store.dispatch('comments/create', {
      data: { text },
      params: {
        prefix: `/pointage/polling_subdivisions/${this.item.id}`,
      },
    });
    this.reloadDataRoutesData(['comments.index']);

    this.comment = '';
  }

  mounted() {
    this.setGlobalSubtitle();
    if (this.item && this.item.id !== parseInt(this.$route.params.pollingSubdivisionId, 10)) {
      this.$store.commit('polling_subdivisions/item', null);
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
  }

  @Watch('item', { deep: true })
  onPollingSubdivisionItemChanged(newItem: PollingSubdivision | null) {
    if (newItem && this.isGeographyableObject(newItem) && this.$refs.mapRef) {
      this.$nextTick(() => {
        (this.$refs.mapRef as any).$mapPromise.then(() => {
          this.mapRemoveAllFeatures();
          this.mapAddFeatures([(newItem as GeographyableObject)]);
          this.mapFitBounds((newItem as GeographyableObject));

          (this.$refs.mapRef as any).$mapObject.data.setStyle({
            strokeColor: '#404040',
            strokeWeight: 3,
            fillColor: '#c6c2d6',
            fillOpacity: 0.5,
          });
        });
      });
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setGlobalSubtitle();
  }

  setGlobalSubtitle() {
    if (this.routeDataLoaded && this.$store.getters['district_elections/item']) {
      const districtName = this.$store.getters['district_elections/item'].district.name;
      const electionName = this.$store.getters['district_elections/item'].election.name.toLowerCase();
      this.$store.commit('global/subtitle', `${districtName}, ${electionName}`);
    }
    this.$emit('updateHead');
  }

  async syncPollingSectors(action: 'update' | 'destroy', polling_sector_id: string | number | null) {
    if (!polling_sector_id) {
      return;
    }

    try {
      await this.$store.dispatch(`polling_subdivisions/${action}`, {
        id: this.item.id,
        params: {
          fields: 'id',
          page: 1,
          per_page: '*',
        },
        prefix: `pointage/polling_sectors/${polling_sector_id}`,
      });
      const syncedText = action === 'destroy' ? 'dissociés des' : 'associés aux';
      this.addNotification({
        color: 'success',
        message: `Les bureaux de vote ont été ${syncedText} secteurs.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Une erreur est survenue lors de l'affectation des secteurs.",
      });
    }
  }
}
