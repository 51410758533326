












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsEventsListItem from 'qs_vuetify/src/components/Events/QsEventsListItem.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { AppNotification } from 'qs_vuetify/src/types/components';
import { PersistedEvent } from 'qs_vuetify/src/types/models';
import { RelationQueryDefinition, RestParams } from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');
const global: any = namespace('global');
const store: any = namespace('contacts');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsEventsListItem,
    QsRelationField,
  },
})
export default class AddToEventModal extends Vue {
  @auth.Getter instanceId!: number | null;

  @global.Mutation addNotification!: (arg: AppNotification) => void;
  @store.Getter('addToEventsContactsLoading') loading!: boolean;

  @Prop({ required: true, type: Object }) params!: RestParams;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  event: PersistedEvent | null = null;
  dummyItem = {};

  get queryDefinition(): RelationQueryDefinition {
    return {
      slug: 'events',
      text: 'title',
      value: 'id',
      params: {
        accessible_from: this.instanceId,
        fields: [
          'start_at',
          'title',
          'instance.name',
          'location_name',
        ].join(','),
        order: 'start_at,title',
        per_page: '*',
        status: 'current,upcoming',
      },
    };
  }

  async submit(): Promise<void> {
    if (this.event?.id) {
      try {
        await this.$store.dispatch('contacts/addToEventsContacts', {
          data: { events: [this.event.id] },
          params: {
            ...this.params,
            fields: 'id',
            per_page: '*',
            page: 1,
          },
        });

        this.addNotification({
          color: 'success',
          message: "Les participant·es ont été ajouté·es à l'événement.",
        });

        this.syncedValue = false;
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: "Une erreur est survenue lors de l'ajout.",
        });
      }
    }
  }

  @Watch('syncedValue')
  onSyncedValueChanged(val: boolean) {
    if (!val) {
      this.dummyItem = {};
      this.event = null;
      (this.$refs.relation as any).clear();
    } else {
      this.$nextTick(() => {
        (this.$refs.relation as any).focus();
      });
    }
  }
}
