





























































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import DoughnutChart from '@/components/DoughnutChart';
import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';

import NavigationButton from '@/components/ElectionResults/NavigationButton.vue';
import TurnoutData from '@/components/ElectionResults/TurnoutData.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { DistrictElection, DistrictResult as Result } from 'qs_vuetify/src/types/models';
import { formatNumber } from '@/helpers';

const districtElection = namespace('district_elections');

@Component({
  components: {
    DoughnutChart,
    NavigationButton,
    QsBaseLayout,
    QsCard,
    TurnoutData,
  },
  filters: {
    percent(n: number) {
      const n100 = n * 100;
      const s = new Intl.NumberFormat('fr-Ca', { maximumFractionDigits: 2 }).format(n100);

      return `${s} %`;
    },
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class DistrictResult extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @districtElection.Getter item!: DistrictElection;
  @districtElection.Getter loading!: boolean;
  @districtElection.Mutation('item') setItem!: any;

  get ChartData() {
    if (!this.item) {
      return {
        datasets: [],
        labels: [],
      };
    }

    interface ChartDataset { votes: number; party: { color: string; name: string } }

    const othersAcc: ChartDataset = {
      votes: 0,
      party: { color: '#c9c9c9', name: 'Autres partis' },
    };
    const others = this.item.district_results
      .filter((r: Result) => !!r?.votes__valid_ballots && r.votes__valid_ballots < 0.05)
      .reduce((acc: ChartDataset, r: Result) => {
        if (r?.votes) {
          acc.votes += r.votes;
        }

        return acc;
      }, othersAcc);

    const shown = [
      ...this.item.district_results.filter((r: Result) => !!r?.votes__valid_ballots && r.votes__valid_ballots >= 0.05),
    ];

    if (others.votes > 0) {
      shown.push(others);
    }

    const data = {
      datasets: [{
        backgroundColor: shown.map((r) => r.party.color),
        data: shown.map((r) => r.votes),
      }],
      labels: shown.map((r) => r.party.name),
    };

    return data;
  }

  get links(): { disabled: boolean; icon: string; routeName: string; title: string }[] {
    if (this.item) {
      return [
        {
          disabled: !this.item.has_polling_subdivisions,
          icon: 'mdi-table',
          routeName: 'SectorResults',
          title: 'Tableau des résultats par secteurs',
        },
        {
          disabled: !this.item.has_polling_subdivisions,
          icon: 'mdi-table',
          routeName: 'SubdivisionResults',
          title: 'Tableau des résultats par bureau de vote',
        },
        {
          disabled: !this.item.has_geometries,
          icon: 'mdi-map',
          routeName: 'SubdivisionResultsMap',
          title: 'Carte des résultats par bureau de vote',
        },
      ].filter((l) => !l.disabled);
    }

    return [];
  }

  beforeMount() {
    if (this.item && this.item.id !== parseInt(this.$route.params.districtElectionId, 10)) {
      this.$store.commit('district_elections/item', null);
    }
  }

  number = formatNumber;

  @Watch('$route', { deep: true })
  onRouteParamsChanged() {
    this.setItem(null);
    this.reloadDataRoutesData();
  }

  getMajority(districtResult: any) {
    if (!districtResult.rank || districtResult.rank !== 1) {
      return '';
    }

    const first = this.item.district_results.find((r: Result) => r.rank === 1);
    const second = this.item.district_results.find((r: Result) => r.rank === 2);

    return first.votes - second.votes;
  }

  setGlobalSubtitle() {
    if (this.routeDataLoaded && this.item.district) {
      this.$store.commit('global/subtitle', this.item.district.name);
    }
    this.$emit('updateHead');
  }
}
