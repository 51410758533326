var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "div",
        { staticClass: "m3-card m3-surface pa-2" },
        [
          _vm.closeable
            ? _c("div", { staticClass: "d-absolute mb-n6" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "", text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click:close")
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "qs-light-blue", small: "" } },
                          [_vm._v("mdi-close")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.withTitle
            ? _c("h3", { staticClass: "font-weight-bold" }, [
                _vm._v(" Section de vote #" + _vm._s(_vm.item.name) + " ")
              ])
            : _vm._e(),
          _vm.item.municipality
            ? _c("h4", [_vm._v(_vm._s(_vm.item.municipality.name))])
            : _vm._e(),
          _vm._l(_vm.item.results, function(ref) {
            var candidate = ref.candidate
            var id = ref.id
            var party = ref.party
            var votes = ref.votes
            var votes__valid_ballots = ref.votes__valid_ballots
            return _c("div", { key: id }, [
              candidate &&
              party &&
              typeof votes === "number" &&
              votes__valid_ballots
                ? _c("div", { staticClass: "result-container" }, [
                    _c("div", { staticClass: "result-container__candidate" }, [
                      _c(
                        "div",
                        { staticClass: "result-container__candidate__name" },
                        [_vm._v(_vm._s(candidate.full_name))]
                      ),
                      _c("div", [_vm._v("(" + _vm._s(party.code) + ")")])
                    ]),
                    _c("div", { staticClass: "result-container__votes" }, [
                      _vm._v(
                        " " +
                          _vm._s(votes) +
                          " (" +
                          _vm._s((votes__valid_ballots * 100).toFixed(2)) +
                          " %) "
                      )
                    ])
                  ])
                : _vm._e(),
              party && votes__valid_ballots
                ? _c(
                    "div",
                    { staticStyle: { "background-color": "#f4f4f4" } },
                    [
                      _c("div", {
                        style: {
                          "background-color": party.color,
                          height: "6px",
                          width: votes__valid_ballots * 100 + "%"
                        }
                      })
                    ]
                  )
                : _vm._e()
            ])
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }