var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "call-campaign-answers" }, [
    _c(
      "dl",
      [
        _vm._l(_vm.answers, function(value, key) {
          return [
            _c("div", { key: key, staticClass: "mb-1" }, [
              _c("dt", [
                _vm._v(
                  _vm._s((_vm.schema[key] && _vm.schema[key].label) || key)
                )
              ]),
              _vm.schema[key] && _vm.schema[key].type === "text"
                ? _c("dd", [_vm._v(_vm._s(value))])
                : _vm.schema[key] && _vm.schema[key].type === "checkbox"
                ? _c(
                    "dd",
                    [_c("qs-boolean-indicator", { attrs: { value: !!value } })],
                    1
                  )
                : _c("dd", [_vm._v(_vm._s(value))])
            ])
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }