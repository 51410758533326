import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { CampaignContact } from 'qs_vuetify/src/types/models';

import { RootState, State } from 'qs_vuetify/src/types/states';
import { CampaignContactsRestState } from '@/types/states';

import {
  buildJobsActions,
  buildJobsGetters,
  buildJobsMutations,
  buildJobsState,
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

const slug = 'campaign_contacts';
const jobs = [
  'empty_contacts',
];

export const state: CampaignContactsRestState = {
  ...buildRestState<CampaignContact>('campaign_contacts', {}),
  ...buildJobsState(slug, jobs),
};

export const getters: GetterTree<CampaignContactsRestState, RootState> = {
  ...buildRestGetters<CampaignContact, CampaignContactsRestState>(),
  ...buildJobsGetters<State>(slug, jobs),
};

export const actions: ActionTree<CampaignContactsRestState, RootState> = {
  ...buildRestActions<CampaignContact, CampaignContactsRestState>(),
  ...buildJobsActions<State>(slug, jobs),
};

export const mutations: MutationTree<CampaignContactsRestState> = {
  ...buildRestMutations<CampaignContact, CampaignContactsRestState>(),
  ...buildJobsMutations<State>(slug, jobs),
};

export const campaign_contacts: Module<CampaignContactsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
