var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-contributions" },
    [
      _c("single-line-info", {
        attrs: {
          label: "Don année en cours",
          value: _vm.item && _vm.item.contribution_current_year
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
            }
          }
        ])
      }),
      _c("single-line-info", {
        attrs: {
          label: "Don année précédente",
          value: _vm.item && _vm.item.contribution_last_year
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
            }
          }
        ])
      }),
      _c("single-line-info", {
        attrs: { label: "Dernier don en 2021", value: 0 },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [_vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }