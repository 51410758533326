



















































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';

import { PersistedMailCampaign } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import MailCampaignCard from '@/components/MailCampaign/MailCampaignCard.vue';

const auth: any = namespace('auth');
const campaigns: any = namespace('mail_campaigns');
const global: any = namespace('global');
const view: any = namespace('mailCampaignsView');

@Component({
  components: {
    MailCampaignCard,
    QsFilters,
    QsList,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class MailCampaigns extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  LazyListMixin,
) {
  @auth.Getter instanceId!: number | null;
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @campaigns.Getter items!: Array<PersistedMailCampaign>;
  @campaigns.Getter error!: ErrorResponse;
  @campaigns.Getter exportUrl!: string;
  @campaigns.Getter filtersDefinition!: FiltersDefinition | null;
  @campaigns.Getter loading!: boolean;
  @campaigns.Getter slug!: string;
  @campaigns.Getter total!: number;

  @view.Mutation addSelectedItem!: any;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: any;
  @view.Getter selectedItems!: Array<PersistedMailCampaign>;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    per_page: 20,
    order: '-id',
    fields: [
      'created_at',
      'created_by_user.contact_name',
      'deleted_at',
      'deleted_by_user.contact_name',
      'description',
      'id',
      'instance.name',
      'name',
      'sent_at',
      'sent_by_user',
      'stats.*',
      'status',
      'updated_at',
      'updated_by_user.contact_name',
    ].join(','),
    'instances.id': this.instanceId,
  }

  get viewParams() {
    return {
      mail_campaigns: {
        ...this.defaultParams,
        ...this.params,
      },
    };
  }

  filtersLoaded = true;
  viewStoreName = 'mailCampaignsView';

  mounted() {
    this.setAction();

    this.$store.commit('mail_campaigns/exportFields', [
      'id',
      'name',
    ]);
    this.$store.commit('mail_campaigns/item', null);

    if (this.items.length === 0) {
      this.setParams({
        ...this.defaultParams,
        ...this.params,
        page: 1,
      });
    }
  }

  get activeFilters(): FiltersDefinition {
    if (!this.filtersDefinition) {
      return {};
    }

    const baseFilters = {
      status: this.filtersDefinition.status,
      q: this.filtersDefinition.q,
    };

    if (this.userIsSuperadmin) {
      return {
        ...baseFilters,
        with_deleted: this.filtersDefinition.with_deleted,
      };
    }

    return baseFilters;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setAction();
    }
  }

  resetViewOptionsAndNavigateToMailCampaign(event: MouseEvent | null, id: number) {
    this.$store.commit('mailCampaignsView/setContactsOptions', {
      sortBy: ['full_name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 25,
    });
    if (event && !event.ctrlKey) {
      event.preventDefault();
      this.$router.push({ name: 'MailCampaign', params: { id: String(id) } });
    }
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('MAIL_CAMPAIGNS_CREATE') ? [
        {
          onClick: () => { this.$router.push('mail_campaigns/new'); },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Créer un nouvel envoi',
        },
      ] : [],
    );
  }
}
