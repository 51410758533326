




































import Component, { mixins } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import { ExportField } from 'qs_vuetify/src/types/states';

@Component({
  components: {
    QsActionModal,
  },
})
export default class ExportSettingsModal extends mixins(I18nMixin) {
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;
  @Prop({ required: true, type: String }) modelName!: string;
  @Prop({ required: true, type: String }) storeName!: string;
  @Prop({ required: true, type: Array }) fields!: ExportField[];

  selectAll(): void {
    const newExportFields = this.fields.map((f) => ({
      ...f,
      selected: true,
    }));
    this.$store.commit(`${this.storeName}/availableExportFields`, newExportFields);
    this.$store.commit(`${this.modelName}/exportFields`, newExportFields.reduce((acc, f) => {
      if (f.selected || f.required) {
        acc.push(f.key);
      }
      return acc;
    }, ([] as string[])));
  }

  toggleExportField(field: ExportField, checked: boolean) {
    const index = this.fields.indexOf(field);
    const newExportFields = [...this.fields];
    if (checked) {
      newExportFields[index] = {
        ...newExportFields[index],
        selected: true,
      };
    } else {
      newExportFields[index] = {
        ...newExportFields[index],
        selected: false,
      };
    }
    this.$store.commit(`${this.storeName}/availableExportFields`, newExportFields);
    this.$store.commit(`${this.modelName}/exportFields`, newExportFields.reduce((acc, f) => {
      if (f.selected || f.required) {
        acc.push(f.key);
      }
      return acc;
    }, ([] as string[])));
  }

  unselectAll(): void {
    const newExportFields = this.fields.map((f) => ({
      ...f,
      selected: f.required,
    }));
    this.$store.commit(`${this.storeName}/availableExportFields`, newExportFields);
    this.$store.commit(`${this.modelName}/exportFields`, newExportFields.reduce((acc, f) => {
      if (f.selected || f.required) {
        acc.push(f.key);
      }
      return acc;
    }, ([] as string[])));
  }
}
