import { render, staticRenderFns } from "./EventsCalendar.vue?vue&type=template&id=2f1929fa&scoped=true&"
import script from "./EventsCalendar.vue?vue&type=script&lang=ts&"
export * from "./EventsCalendar.vue?vue&type=script&lang=ts&"
import style0 from "./EventsCalendar.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./EventsCalendar.vue?vue&type=style&index=1&id=2f1929fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f1929fa",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VBtnToggle,VCalendar,VCard,VChip,VCol,VContainer,VDatePicker,VIcon,VMenu,VRow,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f1929fa')) {
      api.createRecord('2f1929fa', component.options)
    } else {
      api.reload('2f1929fa', component.options)
    }
    module.hot.accept("./EventsCalendar.vue?vue&type=template&id=2f1929fa&scoped=true&", function () {
      api.rerender('2f1929fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Events/EventsCalendar.vue"
export default component.exports