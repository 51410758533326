




























































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsMailCampaignsProgress from 'qs_vuetify/src/components/MailCampaigns/QsMailCampaignsProgress.vue';
import QsSentEmail from 'qs_vuetify/src/components/MailCampaigns/QsSentEmail.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import { ButtonProps, ExportMimeType, Form } from 'qs_vuetify/src/types/components';
import {
  PersistedContact,
  PersistedMailCampaign,
  User,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import axios from 'qs_vuetify/src/plugins/axios';

import AddSingleContactModal from '@/components/Dialog/AddSingleContactModal.vue';
import ItemNavigation from '@/components/ItemNavigation.vue';

const auth: any = namespace('auth');
const campaignContacts: any = namespace('campaign_contacts');
const contacts: any = namespace('contacts');
const global: any = namespace('global');
const mailCampaigns: any = namespace('mail_campaigns');
const view: any = namespace('mailCampaignsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contacts/data', []);
    this.$store.commit('contacts/loaded', false);
    this.$store.commit('contacts/lastLoadedAt', null);
    next();
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
  components: {
    AddSingleContactModal,
    ItemNavigation,
    QsBooleanIndicator,
    QsButton,
    QsCard,
    QsConfirmationModal,
    QsDataTable,
    QsFilters,
    QsFormBuilder,
    QsHtmlEditor,
    QsMailCampaignsProgress,
    QsSentEmail,
  },
})
export default class MailCampaign extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
  NavigationMixin,
) {
  @auth.Getter user!: User;
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @campaignContacts.Getter('filtersDefinition') campaignContactsFiltersDefinition!: FiltersDefinition | null;

  @contacts.Getter('data') contacts!: Array<PersistedContact>;
  @contacts.Getter exportUrl!: string;
  @contacts.Getter('filtersDefinition') contactsFiltersDefinition!: FiltersDefinition | null;
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;

  @mailCampaigns.Getter data!: Array<PersistedMailCampaign>;
  @mailCampaigns.Getter error!: ErrorResponse;
  @mailCampaigns.Getter form!: Form;
  @mailCampaigns.Getter initialItem!: string;
  @mailCampaigns.Getter item!: PersistedMailCampaign;
  @mailCampaigns.Getter loading!: boolean;
  @mailCampaigns.Getter loaded!: boolean;
  @mailCampaigns.Getter slug!: string;
  @mailCampaigns.Getter total!: number;
  @mailCampaigns.Mutation('item') syncItem!: any

  @view.Getter contactsOptions!: any;
  @view.Getter contactsParams!: RestParams;
  @view.Getter params!: RestParams;
  @view.Mutation setContactsOptions!: any;
  @view.Mutation setContactsParams!: any;
  @view.Mutation setParams!: any;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  contactsHeaders = [
    { text: 'No. de membre', value: 'v1_contact_id', sortable: false },
    { text: 'Contact', value: 'full_name', sortable: false },
    { text: 'Envoyé', value: 'trackable_sent_email.sent', sortable: false },
    { text: 'Ouvert', value: 'trackable_sent_email.opened', sortable: false },
    { text: 'Cliqué', value: 'trackable_sent_email.clicked', sortable: false },
    { text: 'Rebond', value: 'trackable_sent_email.bounced', sortable: false },
    { text: '', value: 'actions' },
  ];

  addSingleContactModalLoading = false;
  showAddSingleContactModal = false;

  get canSendMailCampaign(): boolean {
    if (this.item?.status === 'completed') {
      return false;
    }

    if (this.hasChanged) {
      return false;
    }

    if (!this.item?.subject || !this.item?.body) {
      return false;
    }

    return this.contactsTotal > 0;
  }

  // eslint-disable-next-line class-methods-use-this
  get contactsExportFields(): string[] {
    return [
      'contact.id',
      'contact.first_name',
      'contact.last_name',
      'contact.v1_contact_id',
      'contact.district.name',
      'contact.gender',
      'contact.birthdate',
      'contact.address',
      'contact.apartment',
      'contact.city',
      'contact.postal_code',
      'contact.home_phone',
      'contact.email',
      'trackable_sent_email.id',
      'trackable_sent_email.sent_at',
      'trackable_sent_email.opened',
      'trackable_sent_email.clicked',
      'trackable_sent_email.bounced',
    ];
  }

  get filtersDefinition(): FiltersDefinition {
    return {
      ...this.contactsFiltersDefinition,
      ...this.campaignContactsFiltersDefinition,
    };
  }

  get filtersOrder(): string[] {
    const filters: { [key: string]: boolean } = {
      q: true,
      email: true,
      id: true,
      'trackable_sent_email.bounced': !!this.item.sent_at,
      'trackable_sent_email.opened': !!this.item.sent_at,
      'trackable_sent_email.clicked': !!this.item.sent_at,
    };

    return Object.keys(filters).filter((f) => filters[f]);
  }

  get sendButtonTooltip(): string {
    if (this.item.status === 'completed') {
      return '';
    }

    if (this.hasChanged) {
      return "Sauvegardez la campagne avant de procéder à l'envoi.";
    }

    if (this.contactsTotal < 1) {
      return 'Ajoutez au moins un·e destinataire·e.';
    }

    if (!this.item?.subject || !this.item?.body) {
      return 'Ajoutez un sujet et un corps au message.';
    }

    return 'Envoyer';
  }

  get viewParams() {
    return {
      contacts: {
        ...ListMixin.buildListState(this.contactsOptions, this.contactsParams),
        fields: [
          'email',
          'full_name',
          'status',
          'v1_contact_id',
          'campaign_contact_id',
          'trackable_sent_email.*',
        ].join(','),
      },
      mail_campaigns: {
        fields: [
          'body',
          'created_at',
          'created_by_user.contact_name',
          'deleted_at',
          'deleted_by_user',
          'end_at',
          'filter',
          'id',
          'instance.email',
          'instance.name',
          'name',
          'sent_at',
          'sent_by_user.contact_name',
          'start_at',
          'stats',
          'status',
          'subject',
          'updated_at',
          'updated_by_user.contact_name',
        ].join(','),
      },
    };
  }

  mounted() {
    this.setActions();
    this.setGlobalSubtitle();
  }

  afterSave() {
    this.setGlobalSubtitle();
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Modifications enregistrées.',
      timeout: 1000,
    });
    this.setActions();
  }

  @Watch('hasChanged')
  onHasChangedChanged() {
    this.setActions();
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(loaded: boolean) {
    if (loaded) {
      this.setGlobalSubtitle();
      this.setActions();

      if (this.contacts.length === 0 && this.contactsOptions.page !== 1) {
        this.setContactsOptions({ page: 1 });
      }
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
    this.setActions();
    this.$store.commit('mail_campaigns/item', null);
  }

  confirmEmptyContacts() {
    this.dialog = {
      callback: async () => {
        this.dialog.loading = true;
        try {
          await this.emptyContacts();
        } catch (e) {
          this.addNotification({
            color: 'error',
            message: 'Erreur lors du retrait des contacts de la campagne.',
          });
        } finally {
          this.dialog.value = false;
          this.dialog.loading = false;
        }
      },
      color: 'warning',
      icon: 'mdi-alert-circle',
      loading: false,
      message: `Tous les contacts associés à cette campagne seront retirés.
      Cette opération ne peut pas être annulée.`,
      title: null,
      value: true,
    };
  }

  async emptyContacts() {
    await this.$store.dispatch('campaign_contacts/emptyContactsCampaignContacts', {
      data: { },
      params: {
        campaign_id: this.item.id,
        per_page: -1,
      },
    });

    this.addNotification({
      color: 'success',
      message: 'Le retrait des contacts de la campagne est commencé. Cette opération peut prendre quelques minutes.',
    });

    this.reloadDataRoutesData(['contacts.index']);
  }

  async exportContactsCsv() {
    await this.exportContacts('text/csv');
  }

  async exportContactsXls() {
    await this.exportContacts('application/vnd.ms-excel');
  }

  async loadNextPage() {
    if (typeof this.params.page === 'number') {
      this.setParams({
        ...this.params,
        page: this.params.page + 1,
      });
      this.$store.commit('global/subtitle', 'Chargement...');
      this.$emit('updateHead');
      await this.$store.dispatch('mail_campaigns/loadPage', this.params);
    }
  }

  async addSingleContactToMailCampaign(contact: PersistedContact) {
    this.addSingleContactModalLoading = true;

    try {
      await axios.put(`/mail_campaigns/${this.item.id}/contacts/${contact.id}`);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été ajouté·e aux destinataires`,
      });

      this.reloadDataRoutesData(['contacts.index', 'mail_campaigns.stats'], true);
      this.showAddSingleContactModal = false;
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: `Erreur lors de l'ajout de ${contact.full_name} aux destinataires`,
      });
    } finally {
      this.addSingleContactModalLoading = false;
    }
  }

  async deleteMailCampaignContact(contact: PersistedContact) {
    try {
      await axios.delete(`/mail_campaigns/${this.id}/contacts/${contact.id}`);

      this.reloadDataRoutesData(['contacts.index', 'mail_campaigns.stats']);

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été retiré des destinataires.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: e?.response?.data?.message || 'Erreur lors du retrait du destinataire.',
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getDataTableItemClass(item: PersistedContact): string[] {
    if (['RET', 'DEC', 'DBL'].includes(item.status)) {
      return ['mail-campaign__contacts__item--inactive'];
    }

    return [];
  }

  async mailCampaignSendTest() {
    try {
      await Promise.all([
        this.$store.dispatch('mail_campaigns/sendTest', this.item.id),
      ]);
      this.addNotification({
        color: 'success',
        message: `Le courriel a été envoyé à l'adresse ${this.user.email}.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: this.error?.message,
      });
    }
    this.dialog.value = false;
  }

  updateContactsFilters(name: string, value: any) {
    const newParams: RestParams = {
      ...this.contactsParams,
    };

    switch (name) {
      case 'trackable_sent_email.opened':
      case 'trackable_sent_email.clicked':
      case 'trackable_sent_email.bounced':
        if (value) {
          const parts = name.split('.');
          newParams[name] = value;
          newParams.trackable_sent_email_action = `${parts[1]};${value};${this.item.id}`;
        } else {
          delete newParams[name];
          delete newParams.trackable_sent_email_action;
        }
        break;
      default:
        if (value) {
          newParams[name] = value;
        } else {
          delete newParams[name];
        }
        break;
    }

    newParams.page = 1;

    this.setContactsParams(newParams);
  }

  private async exportContacts(mimeType: ExportMimeType) {
    const params = {
      ...this.contactsParams,
      prefix: `/mail_campaigns/${this.item.id}`,
      fields: this.contactsExportFields.join(','),
    };
    const generationNotification = {
      color: 'warning',
      message: 'Génération de votre fichier en cours...',
    };
    this.addNotification(generationNotification);

    await this.$store.dispatch('contacts/export', {
      params,
      mimeType,
    });

    this.removeNotification(generationNotification);
    this.addNotification({
      color: 'success',
      message: 'Génération terminée!',
      timeout: -1,
      action: () => {
        document.location.href = this.exportUrl;
      },
    });
  }

  async confirmAndSend() {
    if (this.item.status === 'completed') {
      this.addNotification({
        color: 'warning',
        message: 'La campagne est terminée. Impossible de l\'envoyer.',
      });
    } else {
      this.dialog = {
        callback: async () => {
          try {
            if (this.item.status === 'draft') {
              this.syncItem({
                ...this.item,
                status: 'active',
              });
              await this.$store.dispatch('mail_campaigns/updateItem');
            }

            await this.send();

            this.addNotification({
              color: 'success',
              message: "L'envoi a débuté et peut prendre plusieurs minutes à s'effectuer.",
            });
          } catch (e) {
            switch (e.status) {
              case 422:
                this.addNotification({
                  color: 'error',
                  message: 'La campagne est invalide. Veuillez valider les données.',
                });
                break;
              default:
                this.addNotification({
                  color: 'error',
                  message: "Erreur lors de la tentative d'envoi.",
                });
                break;
            }
          }
          this.dialog.value = false;
        },
        color: 'success',
        icon: 'mdi-send',
        loading: false,
        message: 'Vous êtes sur le point d\'envoyer ce courriel à<br>'
          + '<span class="font-weight-bold">'
          + `${this.contactsTotal} destinataire${this.contactsTotal > 1 ? 's' : ''}`
          + '</span>',
        title: null,
        value: true,
      };
    }
  }

  setActions() {
    const actions: Array<ButtonProps> = [
      {
        onClick: this.submitForm,
        color: 'primary',
        disabled: !this.hasChanged || this.loading,
        icon: '$qs-save',
        tooltip: 'Enregistrer',
      },
    ];

    if (this.item) {
      if (!this.item.deleted_at) {
        actions.push({
          onClick: async () => {
            this.dialog = {
              callback: () => this.mailCampaignSendTest(),
              color: 'info',
              icon: undefined,
              loading: false,
              message: 'Envoyer un courriel test à<br>'
                + `<span class="font-weight-bold">${this.user.email}</span>`,
              title: null,
              value: true,
            };
          },
          disabled: this.hasChanged || this.item.status === 'completed',
          color: 'warning',
          icon: 'mdi-email-search',
          tooltip: this.item.status !== 'completed' ? 'Envoyer un courriel test' : '',
        });

        actions.push({
          onClick: this.confirmAndSend,
          color: 'success',
          icon: 'mdi-send',
          disabled: !this.canSendMailCampaign,
          tooltip: this.sendButtonTooltip,
        });
      }

      if (this.userHas('MAIL_CAMPAIGNS_DELETE')) {
        if (this.item.deleted_at) {
          actions.push({
            onClick: async () => {
              await this.$store.dispatch('mail_campaigns/restore', { id: this.item.id });
              await this.reloadDataRoutesData();
              this.setActions();
            },
            color: 'success',
            disabled: this.loading,
            icon: 'mdi-archive-refresh',
            tooltip: 'Restaurer',
          });

          if (this.userIsSuperadmin) {
            actions.push({
              onClick: async () => {
                await this.confirmThenForceDeleteItem(
                  'Supprimer une campagne courriels',
                  `Êtes-vous certain·e de vouloir <strong>supprimer cette campagne courriel</strong>?
                  Cette opération ne peut pas être annulée. Toutes les données associées
                  seront perdues.`,
                  this.item.id,
                );
                await this.reloadDataRoutesData();
                this.setActions();
              },
              color: 'error',
              disabled: this.loading || this.item.status === 'completed',
              icon: 'mdi-archive-remove',
              tooltip: 'Supprimer définitivement',
            });
          }
        } else {
          actions.push({
            onClick: async () => {
              await this.confirmThenDeleteItem(
                'Archiver une campagne courriels',
                `Êtes-vous certain·e de vouloir <strong>archiver cette campagne courriels</strong>?
                Le courriel ne pourra plus être envoyé, mais les données seront conservées.`,
              );
              this.$router.push({ name: 'MailCampaigns' });
              this.addNotification({
                color: 'success',
                message: 'Campagne archivée',
                timeout: 2500,
              });
              this.setActions();
            },
            color: 'error',
            disabled: this.loading,
            icon: 'mdi-archive',
            tooltip: 'Archiver',
          });
        }
      }
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    if (this.itemReady) {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    try {
      await this.submit();
    } finally {
      this.setActions();
    }
  }

  async send() {
    this.setActions();

    try {
      await this.$store.dispatch('mail_campaigns/send', this.item.id);
    } finally {
      this.setActions();
    }

    await this.reloadDataRoutesData(['mail_campaigns.retrieve', 'sent_emails.index']);
  }
}
