var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    staticClass: "election-results-dashboard no-gutter",
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("div", { staticClass: "m3-card m3-surface mb-4" }, [
              _c(
                "div",
                { staticClass: "m3-card__text" },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      disabled: !_vm.routeDataLoaded,
                      "hide-details": "",
                      items: _vm.districts.map(function(d) {
                        return { text: d.name, value: d.id }
                      }),
                      label: "Choisir une circonscription",
                      outlined: "",
                      name: "district"
                    },
                    model: {
                      value: _vm.districtId,
                      callback: function($$v) {
                        _vm.districtId = $$v
                      },
                      expression: "districtId"
                    }
                  })
                ],
                1
              )
            ]),
            _vm.currentDistrict
              ? _c(
                  "div",
                  _vm._l(_vm.currentDistrict.elections_with_results, function(
                    ref
                  ) {
                    var name = ref.name
                    var id = ref.district_election_id
                    return _c(
                      "navigation-button",
                      {
                        key: id,
                        attrs: {
                          to: {
                            name: "DistrictResult",
                            params: { districtElectionId: id }
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "max-width": "90%",
                              "text-overflow": "ellipsis",
                              overflow: "hidden"
                            }
                          },
                          [_vm._v(_vm._s(name))]
                        ),
                        _c("v-spacer"),
                        _c("v-icon", { staticClass: "ml-4" }, [
                          _vm._v("mdi-arrow-right")
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _vm.$route.params.districtElectionId
              ? _c("router-view")
              : _c(
                  "div",
                  {
                    staticClass:
                      "m3-card m3-surface d-flex flex-column align-center"
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mt-12",
                        attrs: { color: "qs-light-blue", large: "" }
                      },
                      [_vm._v("mdi-cursor-default-click")]
                    ),
                    _c("p", { staticClass: "my-12 body-2 px-6 text-center" }, [
                      _vm._v(
                        " Sélectionnez une circonscription pour commencer "
                      )
                    ])
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }