var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        "content-class": "subdivision-results-detail",
        value: true,
        "max-width": "80vw"
      },
      on: {
        "click:close": function($event) {
          return _vm.$router.push(
            "/elections/" +
              _vm.$route.params.districtElectionId +
              "/subdivisions"
          )
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.title) + " ")]
            },
            proxy: true
          },
          _vm.item && _vm.item.type && _vm.item.type === "BVO"
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "models.polling_subdivisions.enums.type." +
                              _vm.item.type
                          )
                        ) +
                        " "
                    )
                  ]
                },
                proxy: true
              }
            : _vm.item && _vm.item.type === "BVA"
            ? {
                key: "subtitle",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.item.name) + " ")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.item && !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "subdivision-detail-container",
              staticStyle: { width: "100%" }
            },
            [
              _c(
                "div",
                [
                  _c("segment-results", {
                    staticClass: "m3-surface",
                    attrs: { item: _vm.item }
                  }),
                  _c("turnout-data", {
                    staticClass: "m3-surface mt-2",
                    attrs: { item: _vm.item }
                  })
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "m3-card m3-surface pa-2",
                    staticStyle: { width: "100%, height: 240px" }
                  },
                  [
                    _vm.mapCenter &&
                    _vm.mapZoom &&
                    _vm.item &&
                    _vm.item.geometry
                      ? _c("gmap-map", {
                          ref: "mapRef",
                          style: { width: "100%", height: "300px" },
                          attrs: {
                            center: _vm.mapCenter,
                            "map-type-id": "roadmap",
                            options: {
                              zoomControl: false,
                              mapTypeControl: false,
                              scaleControl: false,
                              streetViewControl: false,
                              rotateControl: false,
                              fullscreenControl: false,
                              disableDefaultUi: false,
                              styles: _vm.mapStyles
                            },
                            zoom: _vm.mapZoom
                          }
                        })
                      : _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-center justify-center",
                            staticStyle: {
                              "background-color": "#f4f3f7",
                              height: "100%"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "qs-light-blue" } },
                              [_vm._v("mdi-map")]
                            ),
                            _vm._v(" Aucune géométrie à afficher ")
                          ],
                          1
                        )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "m3-card m3-surface mt-2 px-2 pt-2 pb-1",
                    attrs: { outlined: "", tile: "" }
                  },
                  [
                    _c("h4", [_vm._v("Secteurs")]),
                    _c("polling-sectors-autocomplete", {
                      attrs: {
                        allowCreate: "",
                        instanceId: _vm.instanceId,
                        loading: _vm.$store.getters["polling_sectors/loading"]
                      },
                      on: {
                        input: function($event) {
                          return _vm.syncPollingSectors("update", $event.id)
                        }
                      }
                    }),
                    _vm.item.polling_sectors &&
                    _vm.item.polling_sectors.length < 1
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-center align-center caption"
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "qs-light-blue" } },
                              [_vm._v("mdi-vector-intersection")]
                            ),
                            _vm._v(
                              " Ce bureau de vote n'est associé à aucun secteur "
                            )
                          ],
                          1
                        )
                      : _vm._l(_vm.item.polling_sectors, function(ref) {
                          var id = ref.id
                          var name = ref.name
                          return _c(
                            "v-chip",
                            {
                              key: id,
                              staticClass: "mb-1 mr-1",
                              attrs: {
                                close: "",
                                color: "info",
                                label: "",
                                outlined: "",
                                small: ""
                              },
                              on: {
                                "click:close": function($event) {
                                  return _vm.syncPollingSectors("destroy", id)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(name) + " ")]
                          )
                        }),
                    _c(
                      "navigation-button",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          to: {
                            name: "SectorResults",
                            params: {
                              districtElectionId:
                                _vm.$route.params.districtElectionId
                            }
                          }
                        }
                      },
                      [
                        _vm._v(" Tableau des résultats par secteur "),
                        _c("v-spacer"),
                        _c(
                          "v-icon",
                          { staticClass: "ml-4", attrs: { small: "" } },
                          [_vm._v("mdi-arrow-right")]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]),
              _c(
                "div",
                [
                  _vm.item
                    ? _c("comments-display", {
                        attrs: {
                          comments: _vm.comments,
                          loading: _vm.commentsLoading,
                          user: _vm.user
                        },
                        on: { "click:delete": _vm.confirmThenDestroyComment }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "m3-card m3-surface mt-2" },
                    [
                      _c("v-textarea", {
                        attrs: {
                          "hide-details": "",
                          label: "Ajouter un commentaire"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c("qs-button", {
                                    attrs: {
                                      color: "info",
                                      icon: "mdi-comment-plus",
                                      label: "Enregistrer",
                                      theme: "labase"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.postComment(_vm.comment)
                                      }
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2884007248
                        ),
                        model: {
                          value: _vm.comment,
                          callback: function($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "d-flex align-center justify-center flex-column",
              staticStyle: { height: "40vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { color: "qs-light-blue", indeterminate: "" }
              }),
              _vm._v(" Chargement des données... ")
            ],
            1
          ),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.dialog.value,
          color: _vm.dialog.color,
          icon: _vm.dialog.icon,
          loading: _vm.loading || _vm.dialog.loading,
          message: _vm.dialog.message,
          title: _vm.dialog.title
        },
        on: {
          "click:cancel": function() {
            return (_vm.dialog.value = false)
          },
          "click:confirm": _vm.dialog.callback
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }