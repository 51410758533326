






























































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsChangedByIndicator from 'qs_vuetify/src/components/Indicators/QsChangedByIndicator.vue';
import QsIconButton from 'qs_vuetify/src/components/QsIconButton.vue';
import QsProgress from 'qs_vuetify/src/components/QsProgress.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import dayjs from 'qs_vuetify/src/plugins/dayjs';

import { Event, New, PersistedEvent } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { Watch } from 'vue-property-decorator';
import { ButtonProps, Form } from 'qs_vuetify/src/types/components';

import { CalendarEvent, CalendarType } from '@/types/states';

interface CalendarDateTime {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  weekday: number;
  hour: number;
  minute: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
  category?: string | {
    name?: string;
    categoryName?: string;
    [key: string]: any;
  };
}

const events: any = namespace('events');
const view: any = namespace('eventsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('events/data', []);
    this.$store.commit('events/loaded', false);
    this.$store.commit('events/total', null);
    this.$store.commit('events/lastLoadedAt', null);
    next();
  },
  components: {
    QsButton,
    QsCard,
    QsChangedByIndicator,
    QsIconButton,
    QsProgress,
  },
})
export default class EventsCalendar extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  LazyListMixin,
  NavigationMixin,
) {
  @events.Getter('data') events!: Array<Event>;
  @events.Getter error!: ErrorResponse;
  @events.Getter exportUrl!: string;
  @events.Getter filtersDefinition!: FiltersDefinition | null;
  @events.Getter form!: Form;
  @events.Getter item!: Event;
  @events.Getter loaded!: boolean;
  @events.Getter loading!: boolean;
  @events.Getter slug!: string;
  @events.Getter total!: number;
  @events.Mutation('initialItem') setInitialItem!: any;
  @events.Mutation('item') setItem!: any;

  @view.Mutation addSelectedItem!: any;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: (arg: PersistedEvent) => void;
  @view.Getter selectedItems!: Array<PersistedEvent>;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    fields: [
      'accessibility',
      'accessibility_notes',
      'contacts_count',
      'description',
      'duration',
      'id',
      'instance_id',
      'instance.name',
      'location_name',
      'organized_by_user.contact_name',
      'start_at',
      'title',
      'visibility',
    ].join(','),
    'instances.id': this.currentInstanceId,
    start_at: 'P1M:P1M',
  };

  formOrder: string[] = [
    'title',
    'description',
    'filter',
    'location_name',
    'start_at',
    'duration',
    'accessibility',
  ];

  dragEvent: any = null;
  dragStart: any = null;
  dragTime: any = null;
  createEvent: any = null;
  createStart: any = null;
  extendOriginal: any = null;

  selectedEvent: CalendarEvent | null = null;
  selectedElement: any = null;

  showMonthPicker: boolean = false;

  mounted() {
    if (!this.calendarValue) {
      this.setCalendarValueToday();
    }
    this.setGlobalSubtitle();
    this.setActions();
  }

  get activeFiltersDefinition(): FiltersDefinition {
    if (!this.filtersDefinition) {
      return {};
    }

    const baseFilters = {
      visibility: this.filtersDefinition.visibility,
      status: this.filtersDefinition.status,
      q: this.filtersDefinition.q,
    };

    if (this.userIsSuperadmin) {
      return {
        ...baseFilters,
        with_deleted: this.filtersDefinition.with_deleted,
      };
    }

    return baseFilters;
  }

  get calendarEvents(): CalendarEvent[] {
    const data: CalendarEvent[] = (this.events as any).map((e: any) => this.toCalendarEvent(e));

    return data;
  }

  get calendarType(): CalendarType {
    return this.$store.getters['eventsView/calendarType'];
  }

  set calendarType(type: CalendarType) {
    this.$store.commit('eventsView/setCalendarType', type);
  }

  get calendarValue(): string {
    return this.$store.getters['eventsView/calendarValue'];
  }

  set calendarValue(value: string) {
    this.$store.commit('eventsView/setCalendarValue', value);
  }

  get viewParams() {
    const paramsWithoutStatus = { ...this.params };

    if ('status' in paramsWithoutStatus) {
      delete paramsWithoutStatus.status;
    }

    return {
      events: {
        ...this.defaultParams,
        ...paramsWithoutStatus,
        per_page: -1,
        page: 1,
      },
    };
  }

  // <calendar methods>
  startDrag({ event, timed }: any) {
    if (event) {
      if (timed) {
        const safeEvent = {
          ...event,
          start: parseInt(this.$dayjs(event.start).format('x'), 10),
          end: parseInt(this.$dayjs(event.end).format('x'), 10),
        };

        this.dragEvent = safeEvent;
        this.dragTime = null;
        this.extendOriginal = null;
      }

      this.goTo(null, { name: 'EventSideForm', params: { id: event.id } });
    }
  }

  startTime(tms: CalendarDateTime) {
    const mouse = EventsCalendar.calendarDateTimeToTime(tms);

    if (this.dragEvent && this.dragTime === null) {
      const { start } = this.dragEvent;

      this.dragTime = mouse - start;
    } else {
      this.createStart = EventsCalendar.roundTime(EventsCalendar.calendarDateTimeToTime(tms));
      this.createEvent = {
        id: null,
        name: 'Nouvel événement drag&drop',
        color: 'qs-green',
        start: this.createStart,
        end: this.createStart,
        timed: true,
      };

      this.setUpdatedData(this.createEvent);
    }
  }

  extendBottom(event: any) {
    this.createEvent = event;
    this.createStart = event.start;
    this.extendOriginal = event.end;
  }

  mouseMove(tms: CalendarDateTime) {
    const mouse = EventsCalendar.calendarDateTimeToTime(tms);

    if (this.dragEvent && this.dragTime !== null) {
      const { end, start } = this.dragEvent;
      const duration = end - start;
      const newStartTime = mouse - this.dragTime;
      const newStart = EventsCalendar.roundTime(newStartTime);
      const newEnd = newStart + duration;

      this.dragEvent.start = newStart;
      this.dragEvent.end = newEnd;
      this.setUpdatedData(this.dragEvent);
    } else if (this.createEvent && this.createStart !== null) {
      const mouseRounded = EventsCalendar.roundTime(mouse, false);
      const min = Math.min(mouseRounded, this.createStart);
      const max = Math.max(mouseRounded, this.createStart);

      this.createEvent.start = min;
      this.createEvent.end = max;
      this.setUpdatedData(this.createEvent);
    }
  }

  endDrag() {
    this.dragTime = null;
    this.dragEvent = null;
    this.createEvent = null;
    this.createStart = null;
    this.extendOriginal = null;
  }

  cancelDrag() {
    if (this.createEvent) {
      if (this.extendOriginal) {
        this.createEvent.end = this.extendOriginal;
      } else {
        const i = this.events.indexOf(this.createEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }
    }

    this.createEvent = null;
    this.createStart = null;
    this.dragTime = null;
    this.dragEvent = null;
  }

  // </calendar methods>

  @Watch('calendarValue')
  onCalendarValueChanged(newValue: string) {
    const rangeStart: string = this.$dayjs(newValue).subtract(1, 'month').format('YYYY-MM-DD');
    const rangeEnd: string = this.$dayjs(newValue).add(1, 'month').format('YYYY-MM-DD');
    this.updateFilters('start_at', `${rangeStart}:${rangeEnd}`);
    this.$nextTick(() => { this.setGlobalSubtitle(); });
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setActions();
    this.deleteFromData({ id: null });
    this.reloadDataRoutesData();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setActions();
    }
  }

  contactsCountFor(e: CalendarEvent) {
    if (!e.id) {
      return 0;
    }

    const event = this.events.find((ev) => ev.id === e.id);

    if (event && event.contact_count) {
      return event.contacts_count;
    }

    return 0;
  }

  deleteFromData(value: { id: number | null }) {
    const index = this.events.map((e) => e.id).indexOf(value.id);
    const data = [...this.events];

    if (index > -1) {
      data.splice(index, 1);

      this.$store.commit('events/data', [...data]);
    }
  }

  setActions() {
    const actions: ButtonProps[] = [];

    if (this.userHas('EVENTS_CREATE')) {
      actions.push({
        onClick: () => {
          this.$store.commit('events/item', { ...this.$store.state.events.empty });
          this.goTo(null, { name: 'NewCalendarEvent' });
        },
        color: 'primary',
        icon: 'mdi-plus',
        tooltip: 'Créer un événement',
      });
    }

    if (this.userHas('EVENTS_RETRIEVE')) {
      actions.push({
        onClick: () => {
          this.calendarValue = '';
          this.$router.push({ name: 'Events' });
        },
        color: 'info',
        icon: 'mdi-view-list',
        tooltip: 'Basculer en vue liste',
      });
    }

    this.$store.commit(
      'global/actions',
      actions,
    );
  }

  setCalendarValueToday() {
    this.calendarValue = this.$dayjs().format('YYYY-MM-DD');
  }

  setGlobalSubtitle() {
    if (this.$refs.calendar) {
      const { title } = (this.$refs.calendar as any);
      this.$store.commit('global/subtitle', title.toUpperCase());
    }

    this.$emit('updateHead');
  }

  setUpdatedData(value: CalendarEvent) {
    if (!('id' in value)) {
      return;
    }

    const index = this.events.map((e) => e.id).indexOf(value.id);
    const data = [...this.events];
    let originalValue: PersistedEvent | New<Event> = {
      accessibility: 'inaccessible',
      contacts_count: 0,
      description: '',
      duration: this.$dayjs.duration(value.end - value.start).toISOString(),
      filter: { id: 0 },
      id: null,
      instance_id: this.instanceId,
      start_at: this.$dayjs(value.start).format('YYYY-MM-DD HH:mm:ss'),
      title: 'Nouvel événement drag&drop',
      visibility: 'draft',
    };

    if (index > -1) {
      originalValue = { ...data[index] };
      data.splice(index, 1);
    }

    const newValue = {
      ...originalValue,
      id: value.id,
      duration: this.$dayjs.duration(value.end - value.start).toISOString(),
      start_at: this.$dayjs(value.start).format('YYYY-MM-DD HH:mm:ss'),
    };

    this.$store.commit('events/data', [
      ...data,
      newValue,
    ]);

    this.setItem(newValue);

    const id = newValue.id ? newValue.id.toString() : 'new';
    if (this.$route.name === 'EventsCalendar' || this.$route.params.id === id) {
      this.goTo(null, { name: 'EventSideForm', params: { id } });
    }
  }

  toCalendarEvent(event: PersistedEvent | New<Event> | Event) {
    return {
      id: event.id,
      name: event.title,
      color: EventsCalendar.getEventColor(event),
      start: parseInt(this.$dayjs(event.start_at).format('x'), 10),
      end: parseInt(this.$dayjs(event.start_at)
        .add(this.$dayjs.duration(event.duration))
        .format('x'), 10),
      timed: true,
    };
  }

  viewDay({ date }: { date: string }) {
    this.calendarValue = date;
    this.calendarType = 'day';
  }

  static getEventColor(event: PersistedEvent | New<Event> | Event) {
    if (!event.id) {
      return 'qs-green';
    }

    switch (event.visibility) {
      case 'draft':
        return 'qs-green--darker';

      case 'private':
        return 'qs-blue';

      case 'public':
        return 'qs-orange';

      default:
        return 'qs-dark-blue';
    }
  }

  static roundTime(time: number, down: boolean = true): number {
    const roundTo = 15; // minutes
    const roundDownTime = roundTo * 60 * 1000;

    const timestamp = down
      ? time - (time % roundDownTime)
      : time + (roundDownTime - (time % roundDownTime));

    return timestamp;
  }

  static formatDateTime(timestamp: number) {
    return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }

  static calendarDateTimeToTime(tms: CalendarDateTime): number {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
  }
}
