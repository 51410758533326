

















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { PersistedContact } from 'qs_vuetify/src/types/models';

import ContactListItem from '@/components/ContactListItem.vue';

const global: any = namespace('global');

@Component({
  components: {
    ContactListItem,
    QsActionModal,
    QsButton,
    QsRelationField,
  },
})
export default class AddSingleContactModal extends Vue {
  @global.Mutation addNotification!: Function;

  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: false, type: String, default: 'contacts' }) slug!: string;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  contact: PersistedContact | null = null;

  @Watch('syncedValue')
  onSyncedValueChanged(visible: boolean) {
    if (visible) {
      this.contact = null;
    }
  }

  dummyItem = {};
  queryDefinition = {
    key: 'data',
    slug: this.slug,
    text: 'searchable_text',
    value: 'id',
    params: {
      fields: [
        'district',
        'email',
        'full_name',
        'searchable_text',
        'status',
        'v1_contact_id',
      ].join(','),
      prefix: '',
    },
  }

  // eslint-disable-next-line class-methods-use-this
  noFilter(): boolean {
    return true;
  }
}
