var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center",
      staticStyle: { height: "90vh" }
    },
    [
      _c("v-progress-circular", {
        attrs: { color: "primary", indeterminate: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }