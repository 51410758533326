import { Doughnut, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

const lineChart: Record<string, any> = {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};

export default lineChart;
