import axios from 'axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { CallCampaign } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { CallCampaignsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const callCampaignsState: CallCampaignsRestState = {
  ...buildRestState<CallCampaign>('call_campaigns', {}),
  stats: null,
  statsLoaded: false,
};

export const getters: GetterTree<CallCampaignsRestState, RootState> = {
  ...buildRestGetters<CallCampaign, CallCampaignsRestState>(),
  options(state) {
    return state.options;
  },
  stats(state) {
    return state.stats;
  },
  statsLoaded(state) {
    return state.statsLoaded;
  },
};

export const actions: ActionTree<CallCampaignsRestState, RootState> = {
  ...buildRestActions<CallCampaign, CallCampaignsRestState>(),
  async leave(store, id) {
    const ajax = axios.delete(`/call_campaigns/${id}/users/me`);

    await ajax;
  },
  async join(store, id) {
    const ajax = axios.put(`/call_campaigns/${id}/users/me`);

    await ajax;
  },
  async sendPaperFormRequest({ commit }, { callCampaignId, contactId }) {
    await axios.post(`/call_campaigns/${callCampaignId}/send_paper_form_request`, {
      contact_id: contactId,
    });
    commit('callCampaignsView/addRequestedPaperFormContactId', contactId);
  },
  async stats({ commit }, { id }) {
    commit('statsLoaded', false);

    const ajax = axios.get(`/call_campaigns/${id}/stats`);

    const {
      data: stats,
    } = await ajax;

    commit('stats', stats);
    commit('statsLoaded', true);
  },
};

export const mutations: MutationTree<CallCampaignsRestState> = {
  ...buildRestMutations<CallCampaign, CallCampaignsRestState>(),
  options(state, options) {
    state.options = options;
  },
  stats(state, stats) {
    state.stats = stats;
  },
  statsLoaded(state, statsLoaded) {
    state.statsLoaded = statsLoaded;
  },
};

export const call_campaigns: Module<CallCampaignsRestState, RootState> = {
  namespaced: true,
  state: callCampaignsState,
  getters,
  actions,
  mutations,
};
