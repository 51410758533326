var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        value: _vm.visible && !!_vm.model,
        "content-class": "contact-event-dialog"
      },
      on: {
        "click:close": function($event) {
          _vm.visible = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Éditer une participation ")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              _vm.contactEvent
                ? _c("span", [_vm._v(_vm._s(_vm.contactEvent.full_name))])
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.contactEvent,
                    loading: _vm.loading,
                    theme: "labase"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click:save", _vm.contactEvent)
                    }
                  }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.contactEvent
        ? _c("qs-form-builder", {
            attrs: {
              form: _vm.form,
              order: _vm.formOrder,
              item: _vm.contactEvent,
              "model-name": "contact_events"
            },
            on: { input: _vm.syncContactEvent },
            scopedSlots: _vm._u(
              [
                {
                  key: "event_confirmations.title",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(item.comment || "Nouvelle confirmation") +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "intention.prepend-inner",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-events-intention-indicator", {
                        staticClass: "mr-2",
                        attrs: {
                          disabled: "",
                          "contact-event-id": item.id,
                          value: item.intention
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              3525744606
            )
          })
        : _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }