/* eslint-disable comma-dangle */
import { MapSubdivision, PollingSector } from 'qs_vuetify/src/types/models';
import Vue from 'vue';
import { RouteConfig } from 'vue-router';

const DistrictResult = () => import('@/views/ElectionResults/DistrictResult.vue');
const ElectionResultsDashboard = () => import('@/views/ElectionResults/ElectionResultsDashboard.vue');
const SectorResults = () => import('@/views/ElectionResults/SectorResults.vue');
const SubdivisionResults = () => import('@/views/ElectionResults/SubdivisionResults.vue');
const SubdivisionResultsDetails = () => import('@/views/ElectionResults/SubdivisionResultsDetails.vue');
const SubdivisionResultsMap = () => import('@/views/ElectionResults/SubdivisionResultsMap.vue');

const beta = true;
const fetchDistricts = {
  districts: {
    // TODO: contextual() --> restrict districts with instances
    conditional(vm: Vue) {
      return !vm.$store.state.electionResultsView.districts.length;
    },
  },
};

const fetchDistrictElection = {
  retrieve: {
    contextual(vm: Vue) {
      return {
        id: vm.$route.params.districtElectionId,
      };
    },
    conditional(vm: Vue) {
      return !vm.$store.state.district_elections.item
        || vm.$store.state.district_elections.item.id !== vm.$route.params.districtElectionId;
    },
    params: {
      fields: [
        '*',
        'district.name',
        'election.name',
        'has_geometries',
        'has_polling_subdivisions',
        [
          '*',
          'candidate.full_name',
          'party.color',
          'party.name',
        ].map((f) => `district_results.${f}`),
      ].flat().join(','),
    },
  },
};

const fetchDistrictResults = {
  index: {
    conditional(vm: Vue) {
      return !vm.$store.state.district_results.data.length
        || vm.$store.state.district_results.data
          .filter((d: any) => d.district_election_id
            !== parseInt(vm.$route.params.districtElectionId, 10)).length;
    },
    contextual(vm: Vue) {
      return {
        'district_election.id': vm.$route.params.districtElectionId,
      };
    },
    params: {
      fields: [
        'district_election_id',
        'party.code',
        'party.color',
        'votes__valid_ballots',
      ].join(','),
      order: '-votes',
      per_page: 100,
    },
    prefix() {
      return 'election_results';
    },
  },
};

const festchPollingSectors = {
  index: {
    contextual(vm: Vue) {
      return {
        accessible_from: vm.$store.state.auth.instanceId,
      };
    },
    conditional(vm: Vue) {
      return !vm.$store.state.polling_sectors.data.length
        || vm.$store.state.polling_sectors.data
          .filter((p: PollingSector) => p.instance_id
            !== parseInt(vm.$store.state.auth.instanceId, 10)).length;
    },
    params: {
      fields: 'name,instance_id',
      order: 'name',
      per_page: '*',
    },
    prefix() {
      return 'pointage';
    },
  },
};

const electionResults: RouteConfig[] = [
  {
    path: '/elections',
    name: 'ElectionResultsDashboard',
    component: ElectionResultsDashboard,
    props: true,
    meta: {
      auth: true,
      beta,
      data: {
        electionResultsView: { ...fetchDistricts },
      },
      menus: ['primary'],
      requires: ['DISTRICT_RESULTS_RETRIEVE'],
      title: 'Résultats électoraux',
    },
    children: [{
      path: '/elections/:districtElectionId',
      name: 'DistrictResult',
      component: DistrictResult,
      props: true,
      meta: {
        auth: true,
        beta,
        data: {
          electionResultsView: { ...fetchDistricts },
          district_elections: { ...fetchDistrictElection },
        },
        menus: [],
        requires: [],
        title: 'Résultats électoraux',
      },
    }],
  },
  {
    path: '/elections/:districtElectionId/sectors',
    name: 'SectorResults',
    component: SectorResults,
    props: true,
    meta: {
      auth: true,
      beta,
      data: {
        district_elections: { ...fetchDistrictElection },
        district_results: { ...fetchDistrictResults },
        polling_sector_elections: {
          index: {
            contextual(vm: Vue) {
              return {
                district_election_id: vm.$route.params.districtElectionId,
              };
            },
            params: {
              fields: [
                'polling_sector.name',
                [
                  'party.code',
                  'party.color',
                  'party.name',
                  'votes',
                  'votes__valid_ballots',
                ].map((f) => `results.${f}`),
                'registered_electors',
                'turnout_rate',
                'valid_ballots',
              ].flat().join(','),
              order: 'polling_sector.name',
              per_page: '*',
            },
            prefix() {
              return 'election_results';
            },
          },
        },
      },
      menus: [],
      requires: [],
      title: 'Résultats par secteur',
    },
  },
  {
    path: '/elections/:districtElectionId/subdivisions',
    name: 'SubdivisionResults',
    component: SubdivisionResults,
    props: true,
    meta: {
      auth: true,
      beta,
      data: {
        district_elections: { ...fetchDistrictElection },
        district_results: {
          index: {
            contextual(vm: Vue) {
              return {
                'district_election.id': vm.$route.params.districtElectionId,
              };
            },
            params: {
              fields: [
                'party.code',
                'party.color',
                'votes__valid_ballots',
              ].join(','),
              order: '-votes',
              per_page: 100,
            },
            prefix() {
              return 'election_results';
            },
          },
        },
        polling_sectors: { ...festchPollingSectors },
        polling_subdivisions: {
          index: {
            // rechanger si les params ont changé
            /* conditional(vm: Vue) {
              return !vm.$store.state.polling_subdivisions.data.length
                || vm.$store.state.polling_subdivisions.data
                  .filter((d: any) => d.district_election_id
                    !== parseInt(vm.$route.params.districtElectionId, 10)).length;
            }, */
            contextual(vm: Vue) {
              return {
                'district_election.id': vm.$route.params.districtElectionId,
              };
            },
            params: {
              fields: [
                'district_election_id',
                'name',
                'number',
                'municipality.name',
                [
                  'party.code',
                  'party.color',
                  'party.name',
                  'votes',
                  'votes__valid_ballots',
                ].map((f) => `results.${f}`),
                'turnout_rate',
                'type',
                'valid_ballots',
              ].flat().join(','),
              order: 'number',
              type: 'BVO',
              per_page: '*',
            },
            prefix() {
              return 'pointage';
            },
          },
        },
      },
      menus: [],
      requires: [],
      title: 'Bureaux de vote',
    },
    children: [
      {
        path: ':pollingSubdivisionId',
        name: 'SubdivisionResultsDetails',
        component: SubdivisionResultsDetails,
        props: true,
        meta: {
          auth: true,
          beta,
          data: {
            comments: {
              index: {
                params: {
                  fields: [
                    'created_at',
                    'created_by_user.contact.first_name',
                    'created_by_user.contact.last_name',
                    'text',
                  ].join(','),
                  per_page: '*',
                  order: 'created_at',
                },
                prefix(vm: Vue) {
                  return `pointage/polling_subdivisions/${vm.$route.params.pollingSubdivisionId}`;
                },
              },
            },
            polling_subdivisions: {
              retrieve: {
                conditionnal(vm: Vue) {
                  return !vm.$store.state.polling_subdivisions.item
                    && vm.$store.state.polling_subdivisions.item.id !== vm.$route.params.pollingSubdivisionId;
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.pollingSubdivisionId,
                  };
                },
                params: {
                  fields: [
                    'name',
                    'number',
                    'geometry',
                    'municipality.name',
                    'polling_sectors.name',
                    'registered_electors',
                    'rejected_ballots',
                    'rejected_ballots__cast_ballots',
                    [
                      'candidate.full_name',
                      'party.code',
                      'party.color',
                      'party.name',
                      'votes',
                      'votes__valid_ballots',
                    ].map((f) => `results.${f}`),
                    'turnout_rate',
                    'type',
                    'valid_ballots',
                    'valid_ballots__cast_ballots',
                  ].flat().join(','),
                },
                prefix() {
                  return 'pointage';
                },
              }
            },
          },
          menus: [],
          required: [],
          title: 'Résultats par bureaux de vote',
        }
      }
    ],
  },
  {
    path: '/elections/:districtElectionId/geometries',
    name: 'SubdivisionResultsMap',
    component: SubdivisionResultsMap,
    props: true,
    meta: {
      auth: true,
      beta,
      data: {
        district_elections: { ...fetchDistrictElection },
        map_subdivisions: {
          index: {
            contextual(vm: Vue) {
              return {
                'district_election.id': vm.$route.params.districtElectionId,
              };
            },
            conditional(vm: Vue) {
              return !vm.$store.state.polling_subdivisions.data.length
                || vm.$store.state.polling_subdivisions.data
                  .filter((d: MapSubdivision) => d.district_election_id
                    !== parseInt(vm.$route.params.districtElectionId, 10)).length;
            },
            params: {
              fields: [
                'district_election_id',
                'name',
                [
                  'geometry',
                  'properties',
                ].map((f) => `geometry.${f}`),
                'stats',
                'municipality.name',
                'polling_subdivisions.id',
                [
                  'candidate.full_name',
                  'party.code',
                  'party.color',
                  'party.name',
                  'votes',
                  'votes__valid_ballots',
                  'votes__registered_electors',
                ].map((f) => `results.${f}`),
                'registered_electors',
                'rejected_ballots',
                'rejected_ballots__cast_ballots',
                'turnout_rate',
                'valid_ballots',
                'valid_ballots__cast_ballots',
              ].join(','),
              geometry: 1,
              type: 'BVO',
              per_page: '*',
            },
            prefix() {
              return 'election_results';
            },
          },
        },
        polling_sectors: { ...festchPollingSectors },
      },
      menus: [],
      requires: [],
      title: 'Sections de vote',
    },
  },
];

export default electionResults;
