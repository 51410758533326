




























import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { PersistedContact } from 'qs_vuetify/src/types/models';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const global: any = namespace('global');

const contactsView: any = namespace('contactsView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFilters,
    QsRelationField,
  },
})
export default class ContactCommentsModal extends mixins(DataRouteGuards) {
  @auth.Getter instanceId!: number;
  @contacts.Getter item!: PersistedContact;
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @contactsView.Getter commentsModal!: boolean;
  @contactsView.Mutation setCommentsModal: any;

  @Prop({ required: true, type: Number }) contactId!: number;
  @Prop({ required: false, type: Object, default: {} }) viewParams?: any;

  comment = '';

  queryDefinition: any = {}

  closeModal(): void {
    this.setCommentsModal(false);
  }

  async submitForm(): Promise<void> {
    if (this.comment && this.comment.length >= 3) {
      try {
        await this.$store.dispatch('contacts/addComment', {
          instance_id: this.instanceId,
          contact_id: this.contactId,
          comment: this.comment,
        });
        this.addNotification({
          color: 'success',
          message: 'Le commentaire a été ajouté.',
        });
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: 'Erreur lors de la création du·commentaire.',
        });
      }

      this.comment = '';
      this.closeModal();
      this.reloadDataRoutesData();
    }
  }
}
