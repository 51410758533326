import { GetterTree, Module } from 'vuex';

import { ContactComment } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { ContactCommentsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const contactCommentsState: ContactCommentsRestState = buildRestState<ContactComment>('contact_comments', {});

export const getters: GetterTree<ContactCommentsRestState, RootState> = {
  ...buildRestGetters<ContactComment, ContactCommentsRestState>(),
  getCommentsByContact(state) {
    return (id: number) => state.data
      .filter((comment) => comment.contact_id === id);
  },
};

export const actions = buildRestActions<ContactComment, ContactCommentsRestState>();

export const mutations = buildRestMutations<ContactComment, ContactCommentsRestState>();

export const contact_comments: Module<ContactCommentsRestState, RootState> = {
  namespaced: true,
  state: contactCommentsState,
  getters,
  actions,
  mutations,
};
