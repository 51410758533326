var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "instance-stats", attrs: { tile: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "text-h3 font-weight-bold text-uppercase d-flex instance-stats__title"
        },
        [
          _c("div", [
            _vm._v(" Statistiques "),
            _c(
              "span",
              {
                staticClass: "cursor-help ml-2",
                attrs: {
                  title: "Les données sont mises-à-jour quotidiennement."
                }
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [
                  _vm._v("mdi-help-circle-outline")
                ])
              ],
              1
            )
          ]),
          _c("v-spacer"),
          _c(
            "qs-button",
            { staticClass: "mb-2", attrs: { to: "/contributions" } },
            [_vm._v(" Tableau de bord des contributions ")]
          )
        ],
        1
      ),
      _c("v-card-text", [
        _c("div", { staticClass: "d-flex instance-stats__inner" }, [
          _c(
            "div",
            { staticClass: "flex-shrink-1" },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.selectedYearIndex,
                        callback: function($$v) {
                          _vm.selectedYearIndex = $$v
                        },
                        expression: "selectedYearIndex"
                      }
                    },
                    _vm._l(_vm.years, function(y) {
                      return _c(
                        "v-list-item",
                        { key: y },
                        [
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v(_vm._s(y))])],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.loading
            ? _c(
                "div",
                { staticClass: "flex-grow-1" },
                [
                  _c("line-chart", {
                    staticClass: "instance-stats__chart",
                    attrs: {
                      "chart-data": _vm.dataCollection,
                      options: _vm.options
                    }
                  }),
                  _c(
                    "v-row",
                    { staticClass: "mt-8" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center instance-stats-block",
                          attrs: { md: "3" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [_vm._v(_vm._s(_vm.totalNbDonations))]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Nombre total de dons "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__monthly_donations_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center instance-stats-block",
                          attrs: { md: "3" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(_vm.totalSumDonations)
                                )
                              )
                            ]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Montant total des dons "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__monthly_donations_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center instance-stats-block",
                          attrs: { md: "3" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [_vm._v(_vm._s(_vm.totalNewMembers))]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Nombre total d'adhésions "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__monthly_new_memberships_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center instance-stats-block",
                          attrs: { md: "3" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [_vm._v(_vm._s(_vm.totalRenewals))]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Nombre total de renouvellements "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__monthly_renewals_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          class: [
                            "text-center",
                            "instance-stats-block",
                            "instance-stats-block--mer"
                          ],
                          attrs: { md: "3" }
                        },
                        [
                          _vm.loading
                            ? _c("v-progress-circular", {
                                staticClass: "ml-n6 mr-3 mt-n3",
                                attrs: {
                                  color: "qs-green",
                                  size: "12",
                                  indeterminate: ""
                                }
                              })
                            : _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-checkbox-blank-circle")
                              ]),
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("number")(
                                    _vm.instanceStats.contacts.MER
                                  )
                                )
                              )
                            ]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Membres en règle "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__contacts_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "text-center",
                            "instance-stats-block",
                            "instance-stats-block--mec"
                          ],
                          attrs: { md: "3" }
                        },
                        [
                          _vm.loading
                            ? _c("v-progress-circular", {
                                staticClass: "ml-n6 mr-3 mt-n3",
                                attrs: {
                                  color: "qs-yellow",
                                  size: "12",
                                  indeterminate: ""
                                }
                              })
                            : _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-checkbox-blank-circle")
                              ]),
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("number")(
                                    _vm.instanceStats.contacts.MEC
                                  )
                                )
                              )
                            ]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Échus -1 an "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__contacts_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "text-center",
                            "instance-stats-block",
                            "instance-stats-block--exm"
                          ],
                          attrs: { md: "3" }
                        },
                        [
                          _vm.loading
                            ? _c("v-progress-circular", {
                                staticClass: "ml-n6 mr-3 mt-n3",
                                attrs: {
                                  color: "qs-red",
                                  size: "12",
                                  indeterminate: ""
                                }
                              })
                            : _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-checkbox-blank-circle")
                              ]),
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("number")(
                                    _vm.instanceStats.contacts.EXM
                                  )
                                )
                              )
                            ]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Échus +1 an "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__contacts_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "text-center",
                            "instance-stats-block",
                            "instance-stats-block--ctc"
                          ],
                          attrs: { md: "3" }
                        },
                        [
                          _vm.loading
                            ? _c("v-progress-circular", {
                                staticClass: "ml-n6 mr-3 mt-n3",
                                attrs: {
                                  color: "qs-blue",
                                  size: "12",
                                  indeterminate: ""
                                }
                              })
                            : _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-checkbox-blank-circle")
                              ]),
                          _c(
                            "span",
                            { staticClass: "instance-stats-block__stat" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("number")(
                                    _vm.instanceStats.contacts.CTC
                                  )
                                )
                              )
                            ]
                          ),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(" Non-membres "),
                            _c(
                              "span",
                              {
                                staticClass: "instance-stats-block__stat__help",
                                attrs: {
                                  title:
                                    "Généré le " +
                                    _vm.$filters.datetime(
                                      _vm.instanceStats
                                        .__cache__contacts_generated_at
                                    )
                                }
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-help-circle-outline")
                                ])
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "flex-grow-1 d-flex justify-center align-center"
                },
                [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                1
              )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }