import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { ContactsViewState } from '@/types/states';

export const contactsViewState: Module<ContactsViewState, RootState> = {
  namespaced: true,
  state: {
    availableExportFields: [
      {
        selected: true,
        key: 'id',
        cost: 0,
        required: true,
      },
      {
        selected: true,
        key: 'adopted',
        cost: 5,
      },
      {
        selected: true,
        key: 'status',
        cost: 8,
      },
      {
        selected: true,
        key: 'first_name',
        cost: 1,
      },
      {
        selected: true,
        key: 'last_name',
        cost: 1,
      },
      {
        selected: true,
        key: 'v1_contact_id',
        cost: 1,
      },
      {
        selected: true,
        key: 'district_name',
        cost: 3,
      },
      {
        selected: true,
        key: 'gender',
        cost: 1,
      },
      {
        selected: true,
        key: 'birthdate',
        cost: 1,
      },
      {
        selected: true,
        key: 'address',
        cost: 1,
      },
      {
        selected: true,
        key: 'apartment',
        cost: 1,
      },
      {
        selected: true,
        key: 'city',
        cost: 1,
      },
      {
        selected: true,
        key: 'postal_code',
        cost: 1,
      },
      {
        selected: true,
        key: 'home_phone',
        cost: 1,
      },
      {
        selected: true,
        key: 'email',
        cost: 1,
      },
      {
        selected: true,
        key: 'first_membership_period_start',
        cost: 5,
      },
      {
        selected: true,
        key: 'last_membership_period_start',
        cost: 5,
      },
      {
        selected: true,
        key: 'normalized_full_name',
        cost: 2,
      },
      {
        selected: true,
        key: 'adopted_instance_names',
        cost: 3,
      },
    ],
    draftFilter: null,
    params: {
      page: 1,
    },
    selectedItems: [],
    selectedFilterIndex: 1,
    showAlerts: true,
    showExtraInformation: true,
    commentsModal: false,
    contactExchangeModal: false,
  },
  getters: {
    availableExportFields(state) {
      return state.availableExportFields;
    },
    draftFilter(state) {
      return state.draftFilter;
    },
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    selectedFilterIndex(state) {
      return state.selectedFilterIndex;
    },
    showAlerts(state) {
      return state.showAlerts;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
    commentsModal(state) {
      return state.commentsModal;
    },
    contactExchangeModal(state) {
      return state.contactExchangeModal;
    },
  },
  mutations: {
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    availableExportFields(state, availableExportFields) {
      state.availableExportFields = availableExportFields;
    },
    clearSelectedItems(state) {
      state.selectedItems.splice(0, state.selectedItems.length);
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setDraftFilter(state, draftFilter) {
      state.draftFilter = draftFilter;
    },
    showAlerts(state, showAlerts) {
      state.showAlerts = showAlerts;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
    setSelectedFilterIndex(state, selectedFilterIndex) {
      state.selectedFilterIndex = selectedFilterIndex;
    },
    setCommentsModal(state, value) {
      state.commentsModal = value;
    },
    setContactExchangeModal(state, value) {
      state.contactExchangeModal = value;
    },
  },
};

export default contactsViewState;
