



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

interface LeaderboardUser {
  contact_name: string;
  email: string;
  v1_contact_id: number;
  contact_exchanges_count: number;
  completed_contact_exchanges_count: number;
  failed_contact_exchanges_count: number;
}

@Component({
  components: {
    QsActionModal,
  },
})
export default class CallCampaignLeaderboard extends Vue {
  @Prop({ required: false, type: Array, default: () => [] }) users!: LeaderboardUser[];
  @Prop({ required: false, type: Boolean, default: false }) value!: boolean;
}
