import axios from 'axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { Contact } from 'qs_vuetify/src/types/models';
import { RootState, State } from 'qs_vuetify/src/types/states';

import { ContactsRestState } from '@/types/states';

import {
  buildJobsActions,
  buildJobsGetters,
  buildJobsMutations,
  buildJobsState,
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

const slug: string = 'contacts';
const jobs = [
  'add_to_call_campaigns',
  'add_to_events',
  'add_to_mail_campaigns',
  'add_to_tags',
  'add_user_to_call_campaigns',
  'enable_volunteer_role',
];

export const contactsState: ContactsRestState = {
  ...buildRestState<Contact>(slug, {}),
  ...buildJobsState(slug, jobs),
  filters: [],
  filtersLoaded: false,
};

export const getters: GetterTree<ContactsRestState, RootState> = {
  ...buildRestGetters<Contact, ContactsRestState>(),
  ...buildJobsGetters<State>(slug, jobs),
  filters(state) {
    return state.filters;
  },
  filtersLoaded(state) {
    return state.filtersLoaded;
  },
};

export const actions: ActionTree<ContactsRestState, RootState> = {
  ...buildRestActions<Contact, ContactsRestState>(),
  ...buildJobsActions<State>(slug, jobs),
  async filters({ commit }, params) {
    commit('filtersLoaded', false);

    const ajax = axios.get('/filters', {
      params: {
        for: 'lists',
        per_page: '*',
        repository: 'Contact',
        ...params,
      },
    });

    const {
      data: {
        data: filters,
      },
    } = await ajax;

    commit('filters', filters);
    commit('filtersLoaded', true);
  },
  async addComment({ commit }, comment) {
    commit('auth/loading', true, { root: true });

    const ajax = axios.post(`contacts/${comment.contact_id}/contact_comments`, comment);
    await ajax;

    commit('auth/loading', false, { root: true });
  },
};

export const mutations: MutationTree<ContactsRestState> = {
  ...buildRestMutations<Contact, ContactsRestState>(),
  ...buildJobsMutations<State>(slug, jobs),
  filtersLoaded(state, filtersLoaded) {
    state.filtersLoaded = filtersLoaded;
  },
  filters(state, filters) {
    state.filters = filters;
  },
};

export const contacts: Module<ContactsRestState, RootState> = {
  namespaced: true,
  state: contactsState,
  getters,
  actions,
  mutations,
};
