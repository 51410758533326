var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        value: _vm.syncedValue,
        "content-class": "add-single-contact-modal"
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._t("title")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [_vm._t("subtitle")]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "qs-button",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.loading,
                      theme: "labase"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("add", _vm.contact)
                      }
                    }
                  },
                  [_vm._v(" Ajouter ")]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      [
        _vm._t("prepend"),
        _vm.syncedValue
          ? _c("qs-relation-field", {
              attrs: {
                description: "Sur le nom, le courriel ou le numéro de membre",
                filter: _vm.noFilter,
                item: _vm.dummyItem,
                label: "Rechercher",
                name: "add_single_contact_modal",
                query: _vm.queryDefinition,
                value: _vm.contact
              },
              on: {
                input: function($event) {
                  _vm.contact = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("contact-list-item", { attrs: { item: item } })
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("contact-list-item", { attrs: { item: item } })
                      ]
                    }
                  }
                ],
                null,
                false,
                3348803996
              )
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }