var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        value: _vm.syncedValue,
        "content-class": "export-settings-modal"
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Paramètres d'exportation ")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              _c("a", { attrs: { href: "#" }, on: { click: _vm.selectAll } }, [
                _vm._v("Tout sélectionner")
              ]),
              _vm._v(" – "),
              _c(
                "a",
                { attrs: { href: "#" }, on: { click: _vm.unselectAll } },
                [_vm._v("Ne rien sélectionner")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "ul",
        { staticClass: "export-settings-modal__list" },
        _vm._l(_vm.fields, function(field) {
          return _c(
            "li",
            { key: field.key, staticClass: "d-flex" },
            [
              field.cost < 5
                ? _c("v-icon", [_vm._v("mdi-feather")])
                : field.cost < 9
                ? _c("v-icon", [_vm._v("mdi-weight")])
                : _c("v-icon", { attrs: { color: "red" } }, [
                    _vm._v("mdi-weight")
                  ]),
              _c("v-checkbox", {
                attrs: {
                  dense: "",
                  disabled: field.required,
                  "hide-details": "auto",
                  label: _vm.labelOrName(field.key) + " (" + field.key + ")",
                  "input-value": field.selected
                },
                on: {
                  change: function($event) {
                    return _vm.toggleExportField(field, $event)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }