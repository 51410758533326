import { Module } from 'vuex';

import { FormDefinitionTemplate as Model } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { FormDefinitionTemplatesRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: FormDefinitionTemplatesRestState = buildRestState<Model>(
  'form_definition_templates',
  {},
);

export const getters = buildRestGetters<Model, FormDefinitionTemplatesRestState>();

export const actions = buildRestActions<Model, FormDefinitionTemplatesRestState>();

export const mutations = buildRestMutations<Model, FormDefinitionTemplatesRestState>();

export const form_definition_templates: Module<FormDefinitionTemplatesRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
