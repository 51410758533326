












































































































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import { InstanceStats as InstanceStatsInterface } from 'qs_vuetify/src/types/models';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import LineChart from './LineChart';

const globalView = namespace('globalView');

@Component({
  components: {
    LineChart,
    QsButton,
  },
})
export default class InstanceStats extends mixins(AuthenticationMixin) {
  @globalView.Getter instanceStats!: InstanceStatsInterface;

  loading = true;
  totalNbDonations = 0;
  totalSumDonations = '0.00';
  totalNewMembers = 0;
  totalRenewals = 0;
  arrDonations = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  arrNewMembers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  arrRenewals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  selectedYearIndex = 0;

  options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'x',
      intersect: false,
    },
  };

  async created() {
    await this.onInstanceChanged();
  }

  get dataCollection() {
    return {
      labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      datasets: [
        {
          label: 'Dons',
          backgroundColor: 'transparent',
          borderColor: '#ff5505',
          data: this.arrDonations,
        },
        {
          label: 'Adhésions',
          backgroundColor: 'transparent',
          borderColor: '#2d258b',
          data: this.arrNewMembers,
        },
        {
          label: 'Renouvellements',
          backgroundColor: 'transparent',
          borderColor: '#2d2000',
          data: this.arrRenewals,
        },
      ],
    };
  }

  get year() {
    return this.years[this.selectedYearIndex];
  }

  get years() {
    const yearCurrent = parseInt(this.$dayjs().format('YYYY'), 10);
    const arrYears = [];
    for (let i = 0, l = 7; i < l; i += 1) {
      arrYears.push(yearCurrent - i);
    }
    return arrYears;
  }

  @Watch('year')
  async onYearChanged() {
    await this.getInstanceStats();
  }

  @Watch('instanceId')
  async onInstanceChanged() {
    await this.getInstanceStats();
  }

  async getInstanceStats() {
    this.loading = true;
    await this.$store.dispatch('globalView/instanceStats', {
      accessible_from: this.instanceId,
      fields: [
        'year',
        'monthly_donations',
        'monthly_new_memberships',
        'monthly_renewals,contacts',
        '__cache__contacts_generated_at',
        '__cache__monthly_donations_generated_at',
        '__cache__monthly_new_memberships_generated_at',
        '__cache__monthly_renewals_generated_at',
      ].join(','),
      year: this.year.toString(),
      instanceId: this.instanceId,
    });
  }

  @Watch('instanceStats')
  onInstanceStatsLoaded(instanceStats: InstanceStatsInterface) {
    this.totalNbDonations = 0;
    this.totalSumDonations = '0.00';
    this.totalNewMembers = 0;
    this.totalRenewals = 0;
    this.arrDonations = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.arrNewMembers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.arrRenewals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (!instanceStats) {
      return;
    }

    const {
      monthly_donations: monthlyDonations,
      monthly_new_memberships: monthlyNewMemberships,
      monthly_renewals: monthlyRenewals,
    } = instanceStats;

    const currentYear = this.$dayjs().format('YYYY');
    const currentMonth = parseInt(this.$dayjs().format('M'), 10);

    let totalSumDonations = 0;
    for (let i = 0, l = monthlyDonations.length; i < l; i += 1) {
      const month = parseInt(monthlyDonations[i].month, 10);

      if (currentYear === this.year.toString() && currentMonth < month) {
        break;
      }

      this.arrDonations[month - 1] = parseInt(monthlyDonations[i].nb, 10);
      totalSumDonations += parseFloat(monthlyDonations[i].amount);
      this.totalNbDonations += parseInt(monthlyDonations[i].nb, 10);
    }
    this.totalSumDonations = totalSumDonations.toFixed(2);

    for (let i = 0, l = monthlyNewMemberships.length; i < l; i += 1) {
      const month = parseInt(monthlyNewMemberships[i].month, 10);

      if (currentYear === this.year.toString() && currentMonth < month) {
        break;
      }

      this.arrNewMembers[month - 1] = parseInt(monthlyNewMemberships[i].nb, 10);
      this.totalNewMembers += parseInt(monthlyNewMemberships[i].nb, 10);
    }

    for (let i = 0, l = monthlyRenewals.length; i < l; i += 1) {
      const month = parseInt(monthlyRenewals[i].month, 10);

      if (currentYear === this.year.toString() && currentMonth < month) {
        break;
      }

      this.arrRenewals[month - 1] = parseInt(monthlyRenewals[i].nb, 10);
      this.totalRenewals += parseInt(monthlyRenewals[i].nb, 10);
    }

    this.loading = false;
  }
}
