
















import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import axios from 'qs_vuetify/src/plugins/axios';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { PersistedContact } from 'qs_vuetify/src/types/models';

import { Watch } from 'vue-property-decorator';

import AddSingleContactModal from '@/components/Dialog/AddSingleContactModal.vue';

const global: any = namespace('global');

@Component({
  components: {
    AddSingleContactModal,
  },
})
export default class NewVolunteerDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @global.Mutation addNotification!: Function;

  loading: boolean = false;
  visible = true;

  mounted() {
    this.setAction();
  }

  async addVolunteer(contact: PersistedContact) {
    this.loading = true;

    try {
      const { data: volunteer } = await axios.put(`/instances/${this.instanceId}/volunteers/${contact.id}`, {}, {
        params: {
          fields: 'id,contact_id',
        },
      });

      this.addNotification({
        color: 'success',
        message: `${contact.full_name} a été affecté·e comme militant·e.`,
      });

      this.$store.commit('auth/addVolunteer', volunteer);

      this.$store.commit('volunteers/lastLoadedAt', null);
      this.reloadDataRoutesData(['volunteers.index']);

      this.visible = false;
    } catch (err) {
      this.addNotification({
        color: 'error',
        message: "Erreur à l'affection du statut de militant·e.",
      });
      throw err;
    } finally {
      this.loading = false;
    }
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('VOLUNTEERS_UPDATE') ? [
        {
          onClick: () => { this.$router.push('/volunteers/new'); },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Ajouter un·e militant·e',
        },
      ] : [],
    );
  }

  @Watch('$route', { deep: true })
  onNewVolunteerDialogPathNameChanged() {
    this.setAction();
  }

  @Watch('visible')
  onVisibleChanged(visible: boolean) {
    if (!visible) {
      this.$router.push({ name: 'Volunteers' });
    }
  }
}
