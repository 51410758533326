import { Module } from 'vuex';

import { ContactExchange } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { ContactExchangesRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: ContactExchangesRestState = buildRestState<ContactExchange>('contact_exchanges', {});

export const getters = buildRestGetters<ContactExchange, ContactExchangesRestState>();

export const actions = buildRestActions<ContactExchange, ContactExchangesRestState>();

export const mutations = buildRestMutations<ContactExchange, ContactExchangesRestState>();

export const contact_exchanges: Module<ContactExchangesRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
