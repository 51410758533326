
























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsTagsForm from 'qs_vuetify/src/components/Tags/QsTagsForm.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { Tag } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');
const tags: any = namespace('tags');
const view: any = namespace('tagsView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsConfirmationModal,
    QsTagsForm,
  },
})
export default class TagsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  I18nMixin,
) {
  @auth.Getter instanceId!: number | null;

  @tags.Action loadEmpty!: any;
  @tags.Getter error!: ErrorResponse;
  @tags.Getter form!: Form;
  @tags.Getter item!: Tag | null;
  @tags.Getter loading!: boolean;
  @tags.Getter slug!: string;
  @tags.Mutation('item') syncItem!: any
  @tags.Mutation('error') setError!: ((arg: ErrorResponse | null) => any);

  @view.Getter modal!: boolean;
  @view.Mutation setModal!: any;
  @view.Getter params!: RestParams;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  formOrder = [
    'name',
    'description',
    'instance_id',
    'parent_id',
  ];

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [slug: string]: RestParams } {
    return {
      tags: {
        fields: [
          '*',
          'instance.name',
          'parent.name',
          'created_by_user.contact_name',
          'updated_by_user.contact_name',
        ].join(','),
      },
      'tags.index': ListMixin.buildListState(this.params),
    };
  }

  mounted() {
    this.setModal(true);

    this.syncItem({
      description: '',
      instance_id: this.instanceId,
      instance: { id: this.instanceId, name: this.currentInstanceName },
      name: '',
    });
  }

  closeModal() {
    this.setError(null);
    this.setModal(false);
    this.$router.push('/tags');
  }

  async archiveTag() {
    await this.confirmThenDeleteItem(
      "Archiver l'étiquette",
      'Êtes-vous sûr de vouloir archiver cette étiquette?',
    );
    this.reloadDataRoutesData(['tags.index'], true);
    this.closeModal();
  }

  async submitForm() {
    await this.submit({
      prefix: '',
    });

    if (!this.error) {
      this.closeModal();
    }
  }
}
