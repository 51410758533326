import { render, staticRenderFns } from "./ContactListItem.vue?vue&type=template&id=ba5c3ade&"
import script from "./ContactListItem.vue?vue&type=script&lang=ts&"
export * from "./ContactListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemActionText,VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ba5c3ade')) {
      api.createRecord('ba5c3ade', component.options)
    } else {
      api.reload('ba5c3ade', component.options)
    }
    module.hot.accept("./ContactListItem.vue?vue&type=template&id=ba5c3ade&", function () {
      api.rerender('ba5c3ade', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ContactListItem.vue"
export default component.exports