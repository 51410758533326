var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-memberships" },
    [
      _c("single-line-info", {
        attrs: {
          label: "Ajout du contact",
          value: (_vm.item && _vm.item.created_at) || null
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [_vm._v(" " + _vm._s(_vm._f("date")(value)) + " ")]
            }
          }
        ])
      }),
      _c("single-line-info", {
        attrs: { label: "Source du contact", value: "Adhésions" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }