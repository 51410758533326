




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import { Prop, Watch } from 'vue-property-decorator';

import { Model, Persisted } from 'qs_vuetify/src/types/models';

const global: any = namespace('global');

@Component
export default class ItemNavigation<M extends Persisted<Model>> extends Vue {
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @Prop({ required: false, type: Function, default: null }) callback!: (id: number | string) => void | null;

  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: true, type: String }) modelName!: string;
  @Prop({ required: false, type: Object, default: undefined }) item?: M;
  @Prop({ required: true, type: Array }) items!: M[];
  @Prop({ required: false, type: Number, default: 0 }) total!: number;

  temporaryIndex: number | null = null;

  get canLoadNext(): boolean {
    return !!this.next || !!(this.callback && this.index && (this.index + 1) < this.total);
  }

  get index(): number | null {
    if (!this.item) {
      return null;
    }

    if (this.loading && this.temporaryIndex !== null) {
      return this.temporaryIndex;
    }

    const index = this.items.findIndex((i) => i.id === this.item?.id);

    if (index === -1) {
      return null;
    }

    return index;
  }

  get navigateBackLabel(): string {
    if (this.previousLocation) {
      return "Retour à l'écran précédent";
    }
    return 'Retour à la liste';
  }

  get next(): M | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index + 1 > this.items.length) {
      return null;
    }

    return this.items[this.index + 1];
  }

  get prev(): M | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index - 1 < 0) {
      return null;
    }

    return this.items[this.index - 1];
  }

  async callCallback(action: 'prev' | 'next', id: number | string) {
    switch (action) {
      case 'next':
        if (!this.next && typeof this.callback === 'function') {
          await this.callback(id);

          this.$nextTick(() => {
            if (this.next) {
              this.$router.push(`/${this.modelName}/${this.next.id}`);
            }
          });
        } else {
          this.$router.push(`/${this.modelName}/${id}`);
        }
        break;
      case 'prev':
        this.$router.push(`/${this.modelName}/${id}`);
        break;
      default:
        break;
    }
  }

  navigateBack() {
    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else {
      this.$router.push(`/${this.modelName}`);
    }
  }

  @Watch('loading')
  onLoadingChanged(loading: boolean) {
    if (!loading) {
      this.temporaryIndex = null;
    }
  }
}
