var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comments-container" },
    [
      _vm.comments.length < 1
        ? _c(
            "div",
            {
              staticClass:
                "comments-empty-state d-flex flex-column align-center justify-center py-12 font-italic mt-6",
              staticStyle: { color: "#808080" }
            },
            [
              _c("v-icon", [_vm._v("mdi-comment-off-outline")]),
              _vm._v(" Aucun commentaire ")
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.comments, function(ref, index) {
        var created_by_user = ref.created_by_user
        var created_at = ref.created_at
        var id = ref.id
        var text = ref.text
        return _c("div", { key: id, staticClass: "comment" }, [
          !_vm.isSameDayAsPrevious(index)
            ? _c(
                "div",
                { class: ["comment__timestamp", { "mt-0": index === 0 }] },
                [_vm._v(" " + _vm._s(_vm.dateFormat(created_at)) + " ")]
              )
            : _vm._e(),
          !_vm.isSameUserAsPrevious(index)
            ? _c(
                "div",
                {
                  class: [
                    "comment__user",
                    {
                      own_comment:
                        _vm.user &&
                        created_by_user &&
                        created_by_user.id === _vm.user.id
                    }
                  ]
                },
                [
                  created_by_user
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(created_by_user.contact.first_name) +
                            " " +
                            _vm._s(
                              _vm.abbreviatedName(
                                created_by_user.contact.last_name
                              )
                            ) +
                            " "
                        )
                      ]
                    : [_vm._v(" Système ")]
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              class: [
                "comment__text",
                {
                  own_comment:
                    _vm.user &&
                    created_by_user &&
                    created_by_user.id === _vm.user.id
                },
                { "mb-0": _vm.isSameUserAsNext(index) },
                { "comment__text--grouped": _vm.isSameDayAsPrevious(index) },
                { "comment__text--loading": _vm.loading }
              ]
            },
            [
              _vm.user &&
              created_by_user &&
              created_by_user.id === _vm.user.id &&
              !_vm.loading
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("click:delete", id)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("mdi-close")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "comment__text__content" }, [
                _vm._v(_vm._s(text))
              ])
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }