




























import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsDistrictForm from 'qs_vuetify/src/components/Forms/QsDistrictForm.vue';
import QsProfileForm from 'qs_vuetify/src/components/Forms/QsProfileForm.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Contact, Instance } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const global: any = namespace('global');

@Component({
  components: {
    QsDistrictForm,
    QsProfileForm,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Contacts extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @auth.Getter instanceId!: number | null;
  @auth.Getter currentInstance!: Instance | null;
  @global.Mutation addNotification!: Function;

  @contacts.Getter error!: ErrorResponse;
  @contacts.Getter item!: Contact;
  @contacts.Getter loading!: boolean;
  @contacts.Getter slug!: string;
  @contacts.Mutation('item') syncItem!: any

  formValid = true;

  mounted() {
    this.onInstanceIdChanged();

    this.setActions();
  }

  @Watch('formValid')
  onNewContactFormFormValidChanged() {
    this.setActions();
  }

  setActions() {
    this.$store.commit('global/actions', [
      {
        onClick: this.saveContact,
        color: 'primary',
        disabled: !this.formValid,
        icon: '$qs-save',
      },
    ]);
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', 'Nouveau contact');
    }
    this.$emit('updateHead');
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
    }
  }

  @Watch('instanceId')
  onInstanceIdChanged() {
    if (this.userIsConnected) {
      if (!this.userHas('CONTACTS_CREATE')) {
        this.$router.push('/contacts');
        this.addNotification({
          color: 'warning',
          message: `Vous n'avez pas la permission d'ajouter de contact
          à partir de cette instance.`,
        });
      }
    }
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.reloadDataRoutesData();
    }
  }

  async saveContact() {
    try {
      await this.$store.dispatch('contacts/createItem', {
        ...this.item,
      });
      const { item: { id, district_id } } = this.$store.state[this.slug];
      if (id && id !== 'new') {
        if (this.currentInstance && district_id !== this.currentInstance.district_id) {
          this.$store.commit('contacts/prefix', '');
        }
        this.$nextTick(() => {
          this.$store.commit('contacts/item', null);
          this.$router.push(`/contacts/${id}`);
        });
      }
      this.addNotification({
        color: 'success',
        message: 'Contact enregistré',
        timeout: 2500,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de la sauvegarde',
        timeout: 2500,
      });
    }
  }
}
