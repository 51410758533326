





























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Comment, PersistedUser } from 'qs_vuetify/src/types/models';

type CustomComment = Omit<Comment, 'created_by_user'> & {
  created_by_user: PersistedUser & {
    contact: {
      first_name: string;
      last_name: string;
    };
  };
}

@Component
export default class CommentsDisplay extends Vue {
  @Prop({ required: true, type: Array, default: () => ([]) }) comments!: CustomComment[];
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: true }) user!: PersistedUser;

  // eslint-disable-next-line class-methods-use-this
  abbreviatedName(name: string) {
    return name.split('').map((l) => (l === l.toLowerCase() ? '' : l)).join('');
  }

  // eslint-disable-next-line class-methods-use-this
  dateFormat(date: string) {
    const options = {
      day: 'numeric',
      year: 'numeric',
      month: 'short',
    };

    return new Intl.DateTimeFormat('fr-CA', (options as any)).format(new Date(date));
  }

  // eslint-disable-next-line class-methods-use-this
  isSameDayAsPrevious(currentIndex: number) {
    if (currentIndex < 1) {
      return false;
    }

    const currentDate = new Intl.DateTimeFormat('fr-CA').format(new Date(this.comments[currentIndex].created_at));
    const previousDate = new Intl.DateTimeFormat('fr-CA').format(new Date(this.comments[currentIndex - 1].created_at));
    return currentDate === previousDate;
  }

  isSameUserAsPrevious(currentIndex: number) {
    if (currentIndex < 1) {
      return false;
    }

    return this.comments[currentIndex].created_by_user.id === this.comments[currentIndex - 1].created_by_user.id;
  }

  isSameUserAsNext(currentIndex: number) {
    if (currentIndex === this.comments.length - 1) {
      return false;
    }

    return this.comments[currentIndex].created_by_user.id === this.comments[currentIndex + 1].created_by_user.id;
  }
}
