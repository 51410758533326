var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        confirm: _vm.hasChanged,
        "content-class": "new-call-campaign-dialog",
        height: "auto",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Créer une campagne d'appels ")]
            },
            proxy: true
          },
          _vm.currentInstanceName
            ? {
                key: "subtitle",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.currentInstanceName) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "qs-button",
                  {
                    attrs: {
                      color: "primary",
                      disabled:
                        !_vm.itemReady || !_vm.routeDataLoaded || _vm.loading,
                      large: "",
                      loading: _vm.loading,
                      theme: "labase"
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" Créer ")]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.itemReady && _vm.routeDataLoaded
        ? [
            _c(
              "v-alert",
              {
                staticClass: "body-2",
                attrs: {
                  border: "left",
                  color: "info",
                  dismissible: "",
                  icon: "$qs-phone-off",
                  text: "",
                  tile: ""
                }
              },
              [
                _vm._v(
                  " Les contacts qui se sont désabonnés du mode de contact « Téléphone » seront automatiquement exclus de la campagne. "
                )
              ]
            ),
            _c("qs-form-builder", {
              attrs: {
                error: _vm.error,
                form: _vm.form,
                order: _vm.formOrder,
                item: _vm.item,
                "model-name": _vm.slug
              },
              on: { input: _vm.syncItem },
              scopedSlots: _vm._u(
                [
                  {
                    key: "filter",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("filter-select", {
                          attrs: {
                            "all-items-filter": _vm.allItemsFilter,
                            "all-items-label": _vm.allItemsLabel,
                            "draft-filter": _vm.draftFilter,
                            filters: _vm.filters,
                            "selected-items": _vm.selectedItems,
                            "selected-filter-index": _vm.selectedFilterIndex
                          },
                          on: { ready: _vm.syncInitialItemOnNextTick },
                          model: {
                            value: item.filter,
                            callback: function($$v) {
                              _vm.$set(item, "filter", $$v)
                            },
                            expression: "item.filter"
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                4268594900
              )
            })
          ]
        : [
            _c(
              "div",
              {
                staticClass: "d-flex align-center justify-center",
                staticStyle: { height: "100%" }
              },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "primary" }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }