





































































import Component from 'vue-class-component';

import { PersistedMailCampaign } from 'qs_vuetify/src/types/models';

import QsBaseItemCard from 'qs_vuetify/src/components/QsBaseItemCard.vue';
import QsMailCampaignsProgress
  from 'qs_vuetify/src/components/MailCampaigns/QsMailCampaignsProgress.vue';

@Component({
  components: {
    QsMailCampaignsProgress,
  },
})
export default class MailCampaignCard extends QsBaseItemCard<PersistedMailCampaign> {}
