var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "qs-card",
                { staticClass: "events-calendar__main" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "calendar-controls d-flex justify-space-between align-center mb-4"
                    },
                    [
                      _c("qs-icon-button", {
                        attrs: {
                          disabled: _vm.loading,
                          icon: "mdi-chevron-left",
                          theme: "labase"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$refs.calendar.prev()
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticStyle: { "max-width": "300px" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: "qs-blue",
                                              ripple:
                                                !_vm.calendarType === "week",
                                              text: "",
                                              tile: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.calendarType = "week"
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [_vm._v("mdi-information")]
                                          ),
                                          _vm._v(" Astuce ")
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(
                                " Créez un nouvel événement en effectuant un cliquer-glisser sur le calendrier en vue "
                              ),
                              _c("strong", [_vm._v("semaine")]),
                              _vm._v(" ou "),
                              _c("strong", [_vm._v("journée")]),
                              _vm._v(". ")
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: { dense: "", group: "", tile: "" },
                              model: {
                                value: _vm.calendarType,
                                callback: function($$v) {
                                  _vm.calendarType = $$v
                                },
                                expression: "calendarType"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "qs-blue",
                                    text: "",
                                    tile: "",
                                    value: "month"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("mdi-calendar-month")
                                  ]),
                                  _vm._v(" Mois ")
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "qs-blue",
                                    text: "",
                                    tile: "",
                                    value: "week"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("mdi-view-week")
                                  ]),
                                  _vm._v(" Semaine ")
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "qs-blue",
                                    text: "",
                                    tile: "",
                                    value: "day"
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("mdi-view-day")
                                  ]),
                                  _vm._v(" Jour ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "qs-blue",
                                disabled:
                                  _vm.calendarValue ===
                                  this.$dayjs().format("YYYY-MM-DD"),
                                loading: _vm.loading,
                                text: "",
                                tile: ""
                              },
                              on: { click: _vm.setCalendarValueToday }
                            },
                            [_vm._v(" Aujourd'hui ")]
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                "close-on-content-click": false,
                                "offset-y": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: "qs-blue",
                                              icon: "",
                                              tile: ""
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-menu-down")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.showMonthPicker,
                                callback: function($$v) {
                                  _vm.showMonthPicker = $$v
                                },
                                expression: "showMonthPicker"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { tile: "" } },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      scrollable: "",
                                      type: "month",
                                      value: _vm.calendarValue
                                    },
                                    on: {
                                      input: function($event) {
                                        ;(_vm.calendarValue = $event + "-01"),
                                          (_vm.showMonthPicker = false)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("qs-icon-button", {
                        attrs: {
                          disabled: _vm.loading,
                          icon: "mdi-chevron-right",
                          theme: "labase"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$refs.calendar.next()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-calendar", {
                    ref: "calendar",
                    attrs: {
                      color: "qs-light-blue",
                      events: _vm.calendarEvents,
                      "first-time": "05:00",
                      type: _vm.calendarType
                    },
                    on: {
                      "click:date": _vm.viewDay,
                      "mousedown:event": _vm.startDrag,
                      "mousedown:time": _vm.startTime,
                      "mousemove:time": _vm.mouseMove,
                      "mouseup:time": _vm.endDrag
                    },
                    nativeOn: {
                      mouseleave: function($event) {
                        return _vm.cancelDrag($event)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "event",
                        fn: function(ref) {
                          var event = ref.event
                          var timed = ref.timed
                          var eventSummary = ref.eventSummary
                          return [
                            _c(
                              "div",
                              { staticClass: "v-event-draggable" },
                              [
                                _c(
                                  { render: eventSummary },
                                  { tag: "component" }
                                ),
                                event.id
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-chip", {
                                          attrs: {
                                            color:
                                              _vm.contactsCountFor(event.id) > 0
                                                ? "white"
                                                : "error",
                                            outlined:
                                              _vm.contactsCountFor(event.id) >
                                              0,
                                            "x-small": ""
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$tc(
                                                "models.events.stats.registrations",
                                                _vm.contactsCountFor(event.id)
                                              )
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            timed
                              ? _c("div", {
                                  staticClass: "v-event-drag-bottom",
                                  on: {
                                    mousedown: function($event) {
                                      $event.stopPropagation()
                                      return _vm.extendBottom(event)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.calendarValue,
                      callback: function($$v) {
                        _vm.calendarValue = $$v
                      },
                      expression: "calendarValue"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }