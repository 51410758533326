var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-event-chip mb-2" }, [
    _c(
      "div",
      { staticClass: "contact-event-chip__inner" },
      [
        _vm.item.contact_event_id
          ? _c("qs-events-intention-indicator", {
              attrs: {
                color: "#df6496",
                "contact-event-id": _vm.item.contact_event_id,
                disabled: "",
                value: _vm.item.intention,
                "with-tooltip": ""
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "contact-event-chip__content" }, [
          _c("span", { staticClass: "body-2 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.item.title))
          ]),
          _c("br"),
          _c(
            "span",
            { staticClass: "mr-1" },
            [
              _c(
                "v-icon",
                { attrs: { color: "qs-light-blue", "x-small": "" } },
                [_vm._v("mdi-map-marker")]
              )
            ],
            1
          ),
          _c("span", { staticClass: "caption" }, [
            _vm._v(_vm._s(_vm.item.location_name))
          ]),
          _c("br"),
          _c(
            "span",
            { staticClass: "mr-1" },
            [
              _c(
                "v-icon",
                { attrs: { color: "qs-light-blue", "x-small": "" } },
                [_vm._v("$qs-calendar")]
              )
            ],
            1
          ),
          _c("span", { staticClass: "caption" }, [
            _vm._v(_vm._s(_vm._f("datetime")(_vm.item.start_at)))
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }