var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exchange-contact-tags-section" },
    [
      _vm._l(_vm.contactTags, function(tag) {
        return _c("qs-tag-chip", {
          key: tag.id,
          attrs: {
            close: !_vm.deletingTagId,
            disabled: _vm.deletingTagId === tag.id,
            name: tag.name
          },
          on: {
            "click:close": function($event) {
              return _vm.deleteContactTag(tag.id)
            }
          }
        })
      }),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("qs-tags-autocomplete", {
            attrs: {
              "allow-create": "",
              "instance-id": _vm.instanceId,
              loading: _vm.loading
            },
            on: { input: _vm.putContactTag }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }