


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';

import { Form } from 'qs_vuetify/src/types/components';

@Component({
  components: {
    QsBooleanIndicator,
  },
})
export default class CallCampaignAnswers extends Vue {
  @Prop({ required: true, type: Object }) schema!: Form;
  @Prop({ required: true, type: Object }) answers!: Record<string, any>;
}
