









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { PersistedContact as Contact } from 'qs_vuetify/src/types/models';

import SingleLineInfo from '../SingleLineInfo.vue';

@Component({
  components: {
    SingleLineInfo,
  },
})
export default class ContactMemberships extends Vue {
  @Prop({ required: true, type: Object }) item!: Contact;
}
