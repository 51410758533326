






















import Component from 'vue-class-component';

import QsContactTagsSection from 'qs_vuetify/src/components/Contacts/QsContactTagsSection.vue';
import QsTagChip from 'qs_vuetify/src/components/Tags/QsTagChip.vue';

@Component({
  components: {
    QsTagChip,
  },
})
export default class ExchangeContactTagsSection extends QsContactTagsSection {
}
