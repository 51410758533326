













































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsTagsCard from 'qs_vuetify/src/components/Tags/QsTagsCard.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import TagsListActions from 'qs_vuetify/src/mixins/models/TagsListActions';

import { PersistedTag as Tag } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import {
  RestParams,
  FiltersDefinition,
  RelationQueryDefinition,
} from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');
const global: any = namespace('global');
const tags: any = namespace('tags');
const view: any = namespace('tagsView');

@Component({
  components: {
    QsActionModal,
    QsConfirmationModal,
    QsFilters,
    QsList,
    QsRelationField,
    QsTagsCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Tags extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  LazyListMixin,
  ListMixin,
  TagsListActions,
) {
  @auth.Getter instanceId!: number | null;

  @global.Mutation addNotification!: Function;

  @tags.Getter items!: Array<Tag>;
  @tags.Getter error!: ErrorResponse;
  @tags.Getter exportUrl!: string;
  @tags.Getter filtersDefinition!: FiltersDefinition | null;
  @tags.Getter loading!: boolean;
  @tags.Getter slug!: string;
  @tags.Getter total!: number;

  @view.Getter selectedFilterIndex!: number;
  @view.Mutation setSelectedFilterIndex!: (index: number) => void;

  @view.Mutation addSelectedItem!: any;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: any;
  @view.Getter selectedItems!: Array<Tag>;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    per_page: 20,
    fields: [
      '*',
      'instance.name',
      'parent.name',
      'created_by_user.contact_name',
      'updated_by_user.contact_name',
    ].join(','),
    order: 'name',
  };

  tagQueryDef: RelationQueryDefinition & { key: string } = {
    key: 'data',
    params: { fields: '*' },
    slug: 'tags',
    text: 'name',
    value: 'id',
  }

  get viewParams() {
    return {
      tags: this.defaultParams,
    };
  }

  filtersLoaded = true;
  viewStoreName = 'tagsView';

  mounted() {
    this.setAction();

    this.$store.commit('tags/exportFields', [
      '*',
    ]);

    if (this.items.length === 0) {
      this.loadFirstPage();
    }
  }

  get activeFilters(): FiltersDefinition {
    if (!this.filtersDefinition) {
      return {};
    }

    return {
      instance: {
        type: 'enum',
        values: ['true', 'false'],
      },
      'parent.id': this.filtersDefinition['parent.id'],
      q: this.filtersDefinition.q,
    };
  }

  confirmThenMerge(children: Tag[], target: Tag) {
    this.confirmList(
      '',
      'Cette action est irréversible. Voulez-vous continuer?',
      'error',
      'mdi-alert',
      async () => {
        await this.mergeTags(children, target);
      },
    );
  }

  tagCardTo(item: Tag) {
    if (this.userIsSuperadmin
      || (item.instance_id === this.instanceId && this.userHas('TAGS_UPDATE'))) {
      return { name: 'TagsForm', params: { id: item.id } };
    }

    return null;
  }

  @Watch('$route', { deep: true })
  setAction() {
    this.$store.commit('tags/item', null);
    this.$store.commit('global/actions', [
      {
        onClick: () => { this.$router.push('tags/new'); },
        color: 'primary',
        icon: 'mdi-plus',
        tooltip: 'Créer une étiquette',
      },
    ]);
  }
}
