import Vue from 'vue';
import { RouteConfig } from 'vue-router';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import { Model } from 'qs_vuetify/src/types/models';

const EventForm = () => import(/* webpackChunkName: "EventForm" */'@/views/Events/EventForm.vue');
const Events = () => import(/* webpackChunkName: "Events" */'@/views/Events/Events.vue');
const EventsCalendar = () => import(/* webpackChunkName: "EventsCalendar" */'@/views/Events/EventsCalendar.vue');
const EventSideForm = () => import('@/views/Events/EventSideForm.vue');
const NewEventDialog = () => import(/* webpackChunkName: "NewEventDialog" */'@/views/Events/NewEventDialog.vue');

const events: RouteConfig[] = [
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: {
      data: {
        events: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.events.lastLoadedAt;
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$store.state.auth.instanceId}`;
            },
          },
        },
      },
      menus: ['primary'],
      requires: ['EVENTS_RETRIEVE'],
      title: 'Événements',
    },
    children: [
      {
        path: 'new',
        name: 'NewEventDialog',
        component: NewEventDialog,
        props: (route) => ({
          id: 'new',
          type: route.params?.type,
        }),
        meta: {
          data: {
            contacts: {
              filters: {
                conditional(vm: Vue) {
                  return !vm.$store.state.contacts.filtersLoaded;
                },
                contextual(vm: Vue) {
                  return {
                    accessible_from: vm.$store.state.auth.instanceId,
                  };
                },
              },
            },
            events: {
              loadEmpty: {},
            },
          },
          menus: [],
          requires: ['EVENTS_CREATE'],
          name: 'Nouvel événement',
        },
      },
    ],
  },
  {
    path: '/events/calendar',
    name: 'EventsCalendar',
    component: EventsCalendar,
    meta: {
      data: {
        events: {
          index: {
            prefix(vm: Vue) {
              return `/instances/${vm.$store.state.auth.instanceId}`;
            },
          },
        },
      },
      menus: [],
      requires: [
        'EVENTS_RETRIEVE',
        'EVENTS_CALENDAR', // permission temporaire pour la bêta
      ],
      title: 'Calendrier',
    },
    children: [
      {
        path: 'new',
        name: 'NewCalendarEvent',
        component: EventSideForm,
        props: () => ({ id: 'new' }),
        meta: {
          data: {
            events: {
              index: {
                prefix(vm: Vue) {
                  return `/instances/${vm.$store.state.auth.instanceId}`;
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return !vm.$store.state.events.item;
                },
                transform(vm: Vue, item: Model) {
                  return {
                    ...item,
                    filter: null,
                  };
                },
              },
            },
          },
          menus: [],
          requires: ['EVENTS_CREATE'],
          name: 'Nouvel événement',
        },
      },
      {
        path: ':id',
        name: 'EventSideForm',
        component: EventSideForm,
        props: true,
        meta: {
          data: {
            events: {
              index: {
                prefix(vm: Vue) {
                  return `/instances/${vm.$store.state.auth.instanceId}`;
                },
              },
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  const {
                    with_deleted,
                    is_deleted,
                    deleted_at,
                  } = vm.$store.state.eventsView.params;

                  return {
                    id: vm.$route.params.id,
                    with_deleted: (with_deleted || is_deleted || deleted_at),
                  };
                },
              },
              stats: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
              },
            },
          },
          lockInstance: true,
          menus: [],
          title: 'Calendrier',
        },
      },
    ],
  },
  {
    path: '/events/:id',
    name: 'EventForm',
    component: EventForm,
    props: true,
    meta: {
      data: {
        events: {
          retrieve: {
            contextual(vm: Vue) {
              const {
                with_deleted,
                is_deleted,
                deleted_at,
              } = vm.$store.state.eventsView.params;

              return {
                id: vm.$route.params.id,
                with_deleted: (with_deleted || is_deleted || deleted_at),
              };
            },
          },
          stats: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
        contact_events: {
          loadEmpty: {},
        },
        contacts: {
          index: {
            conditional(vm: Vue & AuthenticationMixin) {
              return vm.userHas('CONTACT_EVENTS_RETRIEVE');
            },
            prefix(vm: Vue) {
              return `/events/${vm.$route.params.id}`;
            },
          },
        },
      },
      lockInstance: true,
      menus: [],
      title: 'Événement',
    },
  },
];

export default events;
