import { RestParams } from 'qs_vuetify/src/types/states';

const mergeInstanceIdParams = function mergeInstanceIdParams(
  params: RestParams,
  instanceId: number | null,
): RestParams {
  const {
    'instances.id': instanceIds,
    ...rest
  } = params;

  if (instanceIds && typeof instanceIds === 'string') {
    return { ...rest, 'instances.id': instanceIds };
  }

  if (instanceId) {
    return { ...rest, 'instances.id': instanceId };
  }

  return { ...rest };
};

export default mergeInstanceIdParams;
