var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "navigation-button",
              {
                attrs: {
                  to: {
                    name: "DistrictResult",
                    params: {
                      districtElectionId: _vm.$route.params.districtElectionId
                    }
                  }
                }
              },
              [
                _c("v-icon", { staticClass: "mr-4" }, [
                  _vm._v("mdi-arrow-left")
                ]),
                _vm._v(" Résultats par circonscription "),
                _c("v-spacer")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m3-card m3-surface pa-2" },
              [
                _c(
                  "v-btn-toggle",
                  {
                    staticClass: "m3-segmented-button",
                    attrs: { dense: "" },
                    model: {
                      value: _vm.activeMapStyle,
                      callback: function($$v) {
                        _vm.activeMapStyle = $$v
                      },
                      expression: "activeMapStyle"
                    }
                  },
                  [
                    _c("v-btn", { attrs: { value: "votes" } }, [
                      _vm._v("Nombre")
                    ]),
                    _c("v-btn", { attrs: { value: "votes__valid_ballots" } }, [
                      _vm._v("Pourc.")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("qs-filters", {
              staticClass: "mt-2 subdivision-results-filters",
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                "model-name": _vm.slug,
                order: _vm.filterOrder,
                theme: "labase",
                "with-search": false
              },
              on: { input: _vm.setListParams }
            }),
            _vm.selectedItems.length > 0
              ? _c("qs-card", {
                  staticClass: "mt-2",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.selectedItems.length) +
                                      " items sélectionnés "
                                  )
                                ]),
                                _c(
                                  "qs-button",
                                  _vm._b(
                                    {
                                      attrs: {
                                        icon: "",
                                        "x-small": "",
                                        theme: "labase"
                                      },
                                      on: { click: _vm.clearSelectedItems }
                                    },
                                    "qs-button",
                                    _vm.$attrs,
                                    false
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { link: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.showAddPollingSubdivisionsToPollingSectorsModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Associer à un secteur ")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "qs-light-blue",
                                              small: ""
                                            }
                                          },
                                          [_vm._v("mdi-vector-intersection")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { link: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.showRemovePollingSubdivisionsToPollingSectorsModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Dissocier d'un secteur ")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "qs-light-blue",
                                              small: ""
                                            }
                                          },
                                          [_vm._v("mdi-vector-intersection")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1388893966
                  )
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "m3-card m3-surface pa-2" },
              [
                _c("v-data-table", {
                  staticStyle: { "background-color": "transparent" },
                  attrs: {
                    "custom-sort": _vm.customSort,
                    dense: "",
                    headers: _vm.headers,
                    items: _vm.items,
                    "items-per-page": 20,
                    loading: _vm.loading,
                    "show-select": "",
                    value: _vm.selectedItems
                  },
                  on: { input: _vm.setSelectedItems },
                  scopedSlots: _vm._u([
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _vm.getWinnerColor(item),
                                small: ""
                              }
                            },
                            [_vm._v(" mdi-circle ")]
                          ),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                text: "",
                                small: "",
                                to:
                                  "/elections/" +
                                  _vm.$route.params.districtElectionId +
                                  "/subdivisions/" +
                                  item.id
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "qs-light-blue", small: "" }
                                },
                                [_vm._v("mdi-plus")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c("router-view"),
            _c("add-polling-subdivisions-to-polling-sectors-modal", {
              attrs: {
                params: {
                  id: _vm.selectedItems
                    .map(function(c) {
                      return c.id
                    })
                    .join(",")
                },
                value: _vm.showAddPollingSubdivisionsToPollingSectorsModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showAddPollingSubdivisionsToPollingSectorsModal = $event
                },
                input: function($event) {
                  _vm.showAddPollingSubdivisionsToPollingSectorsModal = $event
                }
              }
            }),
            _c("add-polling-subdivisions-to-polling-sectors-modal", {
              attrs: {
                action: "destroy",
                params: {
                  id: _vm.selectedItems
                    .map(function(c) {
                      return c.id
                    })
                    .join(",")
                },
                value: _vm.showRemovePollingSubdivisionsToPollingSectorsModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showRemovePollingSubdivisionsToPollingSectorsModal = $event
                },
                input: function($event) {
                  _vm.showRemovePollingSubdivisionsToPollingSectorsModal = $event
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }