




















































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactHistory from 'qs_vuetify/src/components/Contacts/QsContactHistory.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import {
  PersistedContact,
  Tag,
} from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import QsContactEventsSection from 'qs_vuetify/src/components/Contacts/QsContactEventsSection.vue';
import QsContactFormSection from 'qs_vuetify/src/components/Contacts/QsContactFormSection.vue';
import QsContactStatusSection from 'qs_vuetify/src/components/Contacts/QsContactStatusSection.vue';
import QsContactTagsSection from 'qs_vuetify/src/components/Contacts/QsContactTagsSection.vue';

import { Form } from 'qs_vuetify/src/types/components';

import AddSpecificContactToCallCampaignModal from '@/components/Dialog/AddSpecificContactToCallCampaignModal.vue';
import ContactCommentsModal from '@/components/Dialog/ContactCommentsModal.vue';
import ItemNavigation from '@/components/ItemNavigation.vue';
import SelfIdentification from '@/components/SelfIdentification.vue';

import completeMembership from '@/helpers/completeMembership';

import TagsMixin from '@/mixins/TagsMixin';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const contactEvents: any = namespace('contact_events');
const tags: any = namespace('tags');
const view: any = namespace('contactsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('events/data', []);
    this.$store.commit('events/loaded', false);
    next();
  },
  components: {
    AddSpecificContactToCallCampaignModal,
    ContactCommentsModal,
    QsContactFormSection,
    ItemNavigation,
    QsButton,
    QsContactEventsSection,
    QsContactStatusSection,
    QsContactTagsSection,
    QsContactHistory,
    SelfIdentification,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  TagsMixin,
) {
  @auth.Getter instanceId!: number;

  @contacts.Getter data!: Array<PersistedContact>;
  @contacts.Getter error!: ErrorResponse;
  @contacts.Getter item!: PersistedContact;
  @contacts.Getter loaded!: boolean;
  @contacts.Getter loading!: boolean;
  @contacts.Getter slug!: string;
  @contacts.Getter total!: number;
  @contacts.Mutation('item') syncItem!: any

  @contactEvents.Getter('form') contactEventForm!: Form;

  @tags.Getter('loaded') tagsLoaded!: boolean;

  @view.Getter commentsModal!: boolean;
  @view.Getter contactExchangeModal!: boolean;
  @view.Getter params!: RestParams;
  @view.Mutation setCommentsModal!: any;
  @view.Mutation setContactExchangeModal: any;
  @view.Mutation setParams!: any;

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [slug: string]: RestParams } {
    return {
      contacts: {
        fields: [
          'adopted_instances.name',
          'contact_exchanges.call_campaign_id',
          'created_at',
          'district_id',
          'district.name',
          'emails.email',
          'emails.weight',
          'tags.name',
          'updated_at',
          'v1_contact_id',
        ].join(','),
        with_excluded: true,
      },
    };
  }

  forceLoading = false;
  history = 'all';

  mounted() {
    this.setAction();
  }

  get bottomOffset(): number {
    return this.$vuetify.application.bottom;
  }

  get index(): number | null {
    const index = this.data.findIndex((i) => i.id === this.item.id);

    if (index === -1) {
      return null;
    }

    return index;
  }

  get nextContact(): PersistedContact | null {
    if (!this.item) {
      return null;
    }

    if (this.index === null) {
      return null;
    }

    if (this.index + 1 > this.data.length) {
      return null;
    }

    return this.data[this.index + 1];
  }

  get tags() {
    const root = this.item.tags.filter((tag: Tag) => !tag.parent_id);


    for (let i = 0; i < root.length; i += 1) {
      root[i].children = this.item.tags.filter((child: Tag) => child.parent_id === root[i].id);
    }

    return root;
  }

  get topOffset(): number {
    return this.$vuetify.application.top;
  }

  completeMembership = completeMembership;

  @Watch('commentsModal')
  onContactFormCommentsModalChanged(show: boolean, showBefore: boolean) {
    if (!show && !!showBefore) {
      (this.$refs.QsContactHistory as QsContactHistory).fetchComments();
    }
  }

  @Watch('itemReady')
  onItemReadyChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setAction();
    this.setGlobalSubtitle();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setAction();
    }
  }

  async loadNextPage() {
    if (typeof this.params.page === 'number') {
      this.setParams({
        ...this.params,
        page: this.params.page + 1,
      });
      this.$store.commit('global/subtitle', 'Chargement...');
      this.$emit('updateHead');
      await this.$store.dispatch('contacts/loadPage', this.params);
    }
  }

  async patch(field: string) {
    this.forceLoading = true;
    try {
      await axios.put(`/contacts/${this.id}`, { [field]: this.item[field] });
      this.showUpdate();
      (this.$refs.QsContactHistory as QsContactHistory).fetchRevisions();
    } finally {
      this.forceLoading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  paymentMethod(method: string = ''): string {
    switch (method) {
      case 'cash':
        return 'en argent comptant';
      case 'cheque':
        return 'chèque';
      case 'dgeq':
        return 'via Élections Québec';
      case 'ppm':
        return 'mensuel (DPA)';
      case 'web':
      default:
        return 'web';
    }
  }

  async removeTag(tagId: number | string): Promise<any> {
    await this.deleteContactTag(this.id, tagId);

    this.showUpdate();
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('CONTACTS_CREATE') ? [
        {
          onClick: () => { this.$router.push('/contacts/new'); },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Créer un nouveau contact',
        },
      ] : [],
    );
  }

  showUpdate(message = 'Contact mis à jour.') {
    this.reloadDataRoutesData();

    this.$store.commit('global/addNotification', {
      color: 'success',
      message,
    });
  }

  setGlobalSubtitle() {
    if (!this.routeDataLoaded) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', this.item?.full_name);
    }
    this.$emit('updateHead');
  }
}
