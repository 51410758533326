































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AddPollingSubdivisionsToPollingSectorsModal from '@/components/Dialog/AddPollingSubdivisionsToPollingSectorsModal.vue';
import NavigationButton from '@/components/ElectionResults/NavigationButton.vue';
import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import {
  DistrictResult,
  PersistedPollingSubdivision,
  PollingSubdivision,
  ResultsOwningModel,
} from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Watch } from 'vue-property-decorator';
import { MappableElectionResultsProperty } from '@/types/components';
import ElectionResultsMixin from '@/mixins/ElectionResultsMixin';
import { formatNumber } from '@/helpers';

const districtResults = namespace('district_results');
const store = namespace('polling_subdivisions');
const view = namespace('pollingSubdivisionsView');

@Component({
  components: {
    AddPollingSubdivisionsToPollingSectorsModal,
    NavigationButton,
    QsBaseLayout,
    QsButton,
    QsCard,
    QsFilters,
  },
})
export default class SubdivisionResults extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ElectionResultsMixin,
  ListMixin,
) {
  @districtResults.Getter('data') districtResults!: DistrictResult[];
  @store.Getter('data') subdivisions!: PollingSubdivision[];
  @store.Getter filtersDefinition!: FiltersDefinition | null;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: Array<PersistedPollingSubdivision>;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation clearSelectedItems!: () => void;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;


  filterOrder: string[] = [
    'child_of',
    'municipality.id',
    'name',
    'number',
    'polling_sectors.id',
    'registered_electors',
    'rejected_ballots',
    'rejected_ballots__cast_ballots',
    'turnout_rate',
    'type',
    'valid_ballots',
    'valid_ballots__cast_ballots',
  ];

  showAddPollingSubdivisionsToPollingSectorsModal = false;
  showRemovePollingSubdivisionsToPollingSectorsModal = false;

  setListParams = this.buildSetParam('polling_subdivisions', this.setParams);

  get activeMapStyle(): MappableElectionResultsProperty {
    return this.$store.getters['electionResultsView/activeMapStyle'];
  }

  set activeMapStyle(value: MappableElectionResultsProperty) {
    this.$store.commit('electionResultsView/activeMapStyle', value);
  }

  get headers() {
    const turnout = this.activeMapStyle === 'votes__valid_ballots'
      ? {
        cellClass: 'monospace',
        text: 'Taux de participation',
        value: 'turnout_rate',
        align: 'right',
      }
      : {
        cellClass: 'monospace',
        text: 'Bulletins valides',
        value: 'valid_ballots',
        align: 'right',
      };

    if (this.params.type
      && typeof this.params.type === 'string'
      && this.params.type.split(',').includes('BVO')) {
      return [
        { text: 'SV', value: 'name' },
        { text: 'Municipalité', value: 'municipality.name' },
        ...this.partiesHeaders,
        turnout,
        { text: '', value: 'actions', sortable: false },
      ];
    }

    return [
      { text: 'SV', value: 'name' },
      ...this.partiesHeaders,
      turnout,
      { text: '', value: 'actions', sortable: false },
    ];
  }

  get items() {
    return this.subdivisions
      .map(({
        name,
        number,
        results,
        turnout_rate,
        type,
        ...rest
      }: PollingSubdivision) => {
        if (results) {
          const items = results.reduce((acc: any, val: DistrictResult) => {
            const { id, code } = (val.party as { id: number; code: string });
            if (this.activeMapStyle === 'votes__valid_ballots') {
              acc[`${id}-${code}`] = formatNumber((val.votes__valid_ballots as number));

              return acc;
            }

            acc[`${id}-${code}`] = formatNumber((val.votes as number));

            return acc;
          }, {});

          return {
            ...rest,
            ...items,
            name: type === 'BVA' ? `BVA #${number}` : name,
            turnout_rate: (turnout_rate as number) > 0 ? formatNumber((turnout_rate as number)) : 'N/A',
          };
        }

        return { ...rest };
      });
  }

  get partiesHeaders() {
    return this.districtResults
      .filter((r: DistrictResult) => r.votes__valid_ballots && r.votes__valid_ballots >= 0.05)
      .map((r: DistrictResult) => {
        const { id, code } = (r.party as { id: number; code: string });
        return {
          cellClass: 'monospace',
          text: (code),
          value: `${id}-${code}`,
          align: 'right',
        };
      });
  }

  get viewParams() {
    return {
      polling_subdivisions: {
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  mounted() {
    this.setGlobalSubtitle();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
  }

  @Watch('params', { deep: true })
  onPollingSubdivisionsParamsChanged() {
    this.clearSelectedItems();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setGlobalSubtitle();
  }

  getWinnerColor(item: any) {
    const segment = this.subdivisions.find((s) => s.id === item.id);
    const winner = this.winnerFrom((segment as ResultsOwningModel));
    if (winner) {
      return winner.color;
    }

    return '#cecece';
  }

  setGlobalSubtitle() {
    if (this.routeDataLoaded && this.$store.getters['district_elections/item']) {
      const districtName = this.$store.getters['district_elections/item'].district.name;
      const electionName = this.$store.getters['district_elections/item'].election.name.toLowerCase();
      this.$store.commit('global/subtitle', `${districtName}, ${electionName}`);
    }
    this.$emit('updateHead');
  }
}
