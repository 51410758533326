import { render, staticRenderFns } from "./NewContactForm.vue?vue&type=template&id=fc739154&"
import script from "./NewContactForm.vue?vue&type=script&lang=ts&"
export * from "./NewContactForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fc739154')) {
      api.createRecord('fc739154', component.options)
    } else {
      api.reload('fc739154', component.options)
    }
    module.hot.accept("./NewContactForm.vue?vue&type=template&id=fc739154&", function () {
      api.rerender('fc739154', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Contacts/NewContactForm.vue"
export default component.exports