var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        value: _vm.syncedValue,
        "content-class": "call-campaign-user-modal"
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("span", [_vm._v("Ajouter un·e appelant·e")])]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.isLoading,
                    disabled: _vm.modalUser === null,
                    theme: "labase"
                  },
                  on: { click: _vm.putCallCampaignUser }
                },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.syncedValue
        ? _c("qs-relation-field", {
            ref: "relationField",
            attrs: {
              autofocus: "",
              description: "Sur le nom, l'id ou le numéro de membre",
              item: _vm.item,
              errors: _vm.errors,
              label: "Recherche",
              name: "call_campaign_user",
              query: _vm.queryDefinition,
              value: _vm.contact
            },
            on: {
              input: function($event) {
                _vm.contact = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("contact-list-item", { attrs: { item: item } })]
                  }
                },
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.full_name) + " ")]
                  }
                }
              ],
              null,
              false,
              3863994799
            )
          })
        : _vm._e(),
      _c("qs-toggle-field", {
        staticClass: "mb-3",
        attrs: {
          "is-toggler": "",
          label: "Rechercher parmi les militant·es de l'instance courante",
          name: "searchOnlyOnVolunteers"
        },
        model: {
          value: _vm.searchOnlyOnVolunteers,
          callback: function($$v) {
            _vm.searchOnlyOnVolunteers = $$v
          },
          expression: "searchOnlyOnVolunteers"
        }
      }),
      !_vm.searchOnlyOnVolunteers
        ? _c("qs-toggle-field", {
            staticClass: "mb-3",
            attrs: {
              "is-toggler": "",
              label: "Ajouter aux militant·es de l'instance",
              name: "addAsVolunteer"
            },
            model: {
              value: _vm.addAsVolunteer,
              callback: function($$v) {
                _vm.addAsVolunteer = $$v
              },
              expression: "addAsVolunteer"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }