import axios from 'axios';
import type { AxiosResponse } from 'axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { Contact } from 'qs_vuetify/src/types/models';
import {
  RestParams,
  RootState,
  State,
} from 'qs_vuetify/src/types/states';

import { VolunteersRestState } from '@/types/states';

import {
  buildJobsActions,
  buildJobsGetters,
  buildJobsMutations,
  buildJobsState,
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
  CancelablePromise,
} from 'qs_vuetify/src/plugins/rest';

const slug = 'volunteers';
const jobs = [
  'end_instance_role',
];

export const mailCampaignsState: VolunteersRestState = {
  ...buildRestState<Contact>('volunteers', {}),
  ...buildJobsState(slug, jobs),
  filters: [],
  filtersLoaded: false,
  stats: null,
  statsLoaded: false,
};

export const getters: GetterTree<VolunteersRestState, RootState> = {
  ...buildRestGetters<Contact, VolunteersRestState>(),
  ...buildJobsGetters<State>(slug, jobs),
  filters(state) {
    return state.filters;
  },
  filtersLoaded(state) {
    return state.filtersLoaded;
  },
  stats(state) {
    return state.stats;
  },
  statsLoaded(state) {
    return state.statsLoaded;
  },
};

export const actions: ActionTree<VolunteersRestState, RootState> = {
  ...buildRestActions<Contact, VolunteersRestState>(),
  ...buildJobsActions<State>(slug, jobs),
  async filters({ commit }, params) {
    commit('filtersLoaded', false);

    const ajax = axios.get('/filters', {
      params: {
        for: 'lists',
        per_page: '*',
        repository: 'Volunteer',
        ...params,
      },
    });

    const {
      data: {
        data: filters,
      },
    } = await ajax;

    commit('filters', filters);
    commit('filtersLoaded', true);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async search({ commit }, { q, params: { prefix, ...params } }) {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    try {
      const ajax: CancelablePromise<AxiosResponse<any>> = axios.get('/contacts/search', {
        cancelToken: source.token,
        params: {
          ...params,
          q,
        },
      });

      ajax.cancel = source.cancel;

      commit('searchAjax', ajax);

      const { data: { data } } = await ajax;

      commit('search', data);
      commit('lastSearchQuery', q);

      commit('searchAjax', null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      throw e;
    }
  },
  async stats({ state, commit }, params: RestParams) {
    commit('statsLoaded', false);

    const { prefix } = params;
    const urlPrefix = `${typeof prefix === 'string' ? prefix : (state.prefix || '')}/`;
    const ajax = axios.get(`${urlPrefix}volunteers/stats`);

    const {
      data: stats,
    } = await ajax;

    commit('stats', stats);
    commit('statsLoaded', true);
  },
};

export const mutations: MutationTree<VolunteersRestState> = {
  ...buildRestMutations<Contact, VolunteersRestState>(),
  ...buildJobsMutations<State>(slug, jobs),
  filtersLoaded(state, filtersLoaded) {
    state.filtersLoaded = filtersLoaded;
  },
  filters(state, filters) {
    state.filters = filters;
  },
  stats(state, stats) {
    state.stats = stats;
  },
  statsLoaded(state, statsLoaded) {
    state.statsLoaded = statsLoaded;
  },
};

export const volunteers: Module<VolunteersRestState, RootState> = {
  namespaced: true,
  state: mailCampaignsState,
  getters,
  actions,
  mutations,
};
