var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        "content-class": "add-polling-subdivisions-to-polling-sectors-modal",
        value: _vm.syncedValue
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.action === "destroy"
                ? [_vm._v(" Dissocier d'un secteur ")]
                : [_vm._v(" Associer à un secteur ")]
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    disabled: !_vm.polling_sector,
                    large: "",
                    loading: _vm.loading,
                    theme: "labase"
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Appliquer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.syncedValue
        ? _c("qs-relation-field", {
            ref: "relation",
            attrs: {
              item: _vm.dummyItem,
              label: "Secteurs",
              name: "polling_sectors_id",
              store: _vm.queryDefinition
            },
            model: {
              value: _vm.polling_sector,
              callback: function($$v) {
                _vm.polling_sector = $$v
              },
              expression: "polling_sector"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }