

























































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import NavigationButton from '@/components/ElectionResults/NavigationButton.vue';
import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { District, DistrictResult, PollingSector } from 'qs_vuetify/src/types/models';
import { FiltersDefinition } from 'qs_vuetify/src/types/states';
import { MappableElectionResultsProperty } from '@/types/components';
import { Watch } from 'vue-property-decorator';
import ElectionResultsMixin from '@/mixins/ElectionResultsMixin';
import { formatNumber } from '@/helpers';

const districtResults = namespace('district_results');
const store = namespace('polling_sector_elections');
const view = namespace('electionResultsView');

@Component({
  components: {
    NavigationButton,
    QsBaseLayout,
    QsButton,
  },
})
export default class SectorResults extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ElectionResultsMixin,
  ListMixin,
) {
  @districtResults.Getter('data') districtResults!: DistrictResult[];
  @store.Getter('data') sectors!: PollingSector[];
  @store.Getter filtersDefinition!: FiltersDefinition | null;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;
  @view.Getter currentDistrict!: District;

  get activeMapStyle(): MappableElectionResultsProperty {
    return this.$store.getters['electionResultsView/activeMapStyle'];
  }

  set activeMapStyle(value: MappableElectionResultsProperty) {
    this.$store.commit('electionResultsView/activeMapStyle', value);
  }

  get headers() {
    return [
      { text: 'Secteur', value: 'polling_sector.name' },
      ...this.partiesHeaders,
      this.activeMapStyle === 'votes__valid_ballots'
        ? {
          cellClass: 'monospace',
          text: 'Taux de participation',
          value: 'turnout_rate',
          align: 'right',
        }
        : {
          cellClass: 'monospace',
          text: 'Bulletins valides',
          value: 'valid_ballots',
          align: 'right',
        },
      {
        cellClass: 'monospace', text: 'Électeurs', value: 'registered_electors', align: 'right',
      },
      { text: '', value: 'actions', sortable: false },
    ];
  }

  get items() {
    return this.sectors
      .map(({
        registered_electors,
        results,
        turnout_rate,
        valid_ballots,
        ...rest
      }: PollingSector) => {
        if (results) {
          const items = results.reduce((acc: any, val: DistrictResult) => {
            const { id, code } = (val.party as { id: number; code: string });
            if (this.activeMapStyle === 'votes__valid_ballots') {
              acc[`${id}-${code}`] = formatNumber((val.votes__valid_ballots as number));

              return acc;
            }

            acc[`${id}-${code}`] = formatNumber((val.votes as number));

            return acc;
          }, {});

          return {
            ...rest,
            ...items,
            registered_electors: formatNumber(registered_electors),
            valid_ballots: formatNumber(valid_ballots),
            turnout_rate: formatNumber(turnout_rate),
          };
        }

        return { ...rest };
      });
  }

  get partiesHeaders() {
    return this.districtResults
      .filter((r: DistrictResult) => r.votes__valid_ballots && r.votes__valid_ballots >= 0.05)
      .map((r: DistrictResult) => {
        const { id, code } = (r.party as { id: number; code: string });
        return {
          cellClass: 'monospace',
          text: (code),
          value: `${id}-${code}`,
          align: 'right',
        };
      });
  }

  mounted() {
    this.setGlobalSubtitle();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setGlobalSubtitle();
  }

  getWinnerColor(item: any) {
    const segment = this.sectors.find((s) => s.id === item.id);
    const winner = this.winnerFrom((segment as any));
    if (winner) {
      return winner.color;
    }

    return '#cecece';
  }

  setGlobalSubtitle() {
    if (this.routeDataLoaded && this.$store.getters['district_elections/item']) {
      const districtName = this.$store.getters['district_elections/item'].district.name;
      const electionName = this.$store.getters['district_elections/item'].election.name.toLowerCase();
      this.$store.commit('global/subtitle', `${districtName}, ${electionName}`);
    }
    this.$emit('updateHead');
  }
}
