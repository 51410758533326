/* eslint-disable comma-dangle */
import Vue from 'vue';
import { RouteConfig } from 'vue-router';

const ContactForm = () => import(
  /* webpackChunkName: "ContactForm" */
  '@/views/Contacts/ContactForm.vue'
);
const Contacts = () => import(
  /* webpackChunkName: "Contacts" */
  '@/views/Contacts/Contacts.vue'
);
const NewContactForm = () => import(
  /* webpackChunkName: "NewContactForm" */
  '@/views/Contacts/NewContactForm.vue'
);

const contacts: RouteConfig[] = [
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      auth: true,
      data: {
        contacts: {
          filters: {
            conditional(vm: Vue) {
              return !vm.$store.state.contacts.filtersLoaded;
            },
            contextual(vm: Vue) {
              return {
                accessible_from: vm.$store.state.auth.instanceId,
              };
            },
          },
        },
      },
      menus: ['primary'],
      title: 'Contacts',
      requires: ['CONTACTS_RETRIEVE'],
    },
  },
  {
    path: '/contacts/new',
    name: 'NewContactForm',
    component: NewContactForm,
    meta: {
      data: {
        contacts: {
          loadEmpty: {},
        },
      },
      menus: [],
      title: 'Contacts',
      requires: ['CONTACTS_CREATE'],
    },
  },
  {
    path: '/contacts/:id',
    name: 'ContactForm',
    component: ContactForm,
    props: true,
    meta: {
      auth: true,
      data: {
        contacts: {
          retrieve: {
            conditional(vm: Vue) {
              return !vm.$store.state.global.previousLocation;
            },
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
            prefix() {
              return '';
            },
          },
        },
        contact_events: {
          loadEmpty: {},
        },
      },
      lockInstance: true,
      menus: [],
      title: 'Contacts',
      requires: ['CONTACTS_RETRIEVE'],
    },
  },
];

export default contacts;
