



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsEventsIntentionIndicator from 'qs_vuetify/src/components/Indicators/QsEventsIntentionIndicator.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsToggleField from 'qs_vuetify/src/components/Fields/QsToggleField.vue';

import { isGenericFormField, Form } from 'qs_vuetify/src/types/components';
import { PersistedContact, ContactEvent } from 'qs_vuetify/src/types/models';

const global: any = namespace('global');
const contactEvents: any = namespace('contact_events');

type ContactContactEvent = PersistedContact & ContactEvent & { contact_event_id: number };

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsEventsIntentionIndicator,
    QsFormBuilder,
    QsToggleField,
  },
})
export default class ContactEventModal extends Vue {
  @global.Mutation addNotification!: Function;

  @contactEvents.Getter('form') contactEventsForm!: Form;

  @Prop({ required: false, type: Boolean, default: false }) disablePresence!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @PropSync('value', { type: Boolean }) visible!: number;

  @Prop({ type: Object, required: false, default: null })
  model!: ContactContactEvent;

  contactEvent: ContactContactEvent | null = null;

  get form(): Form {
    const newForm: Form = {
      ...this.contactEventsForm,
    };

    if (isGenericFormField(newForm.event_confirmations)) {
      newForm.event_confirmations.order = ['comment'];
    }

    return newForm;
  }

  get formOrder(): string[] {
    if (this.disablePresence) {
      return ['intention', 'event_confirmations'];
    }

    return [
      'intention',
      // 'present_at', // FIXME pas mature
      // 'duration', // FIXME pas mature
      'event_confirmations',
      'presence',
    ];
  }

  syncContactEvent(model: ContactContactEvent) {
    this.contactEvent = model;
  }

  @Watch('visible')
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.contactEvent = {
        ...this.model,
      };
    } else {
      this.contactEvent = null;
    }
  }
}
