var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["turnout-data", { outlined: _vm.outlined }, { large: _vm.large }]
    },
    [
      _c("p", { staticClass: "supporting-text" }, [
        _c("span", { staticClass: "main-text" }, [
          _vm._v(_vm._s(_vm.formatNumber(_vm.item.registered_electors)))
        ]),
        _vm._v(" électrices et électeurs inscrits "),
        _c("br")
      ]),
      _c("div", { staticStyle: { "background-color": "#f4f4f4" } }, [
        _c("div", {
          style: {
            "background-color": "#201a18",
            height: "6px",
            width: _vm.item.turnout_rate * 100 + "%"
          }
        })
      ]),
      _c(
        "p",
        { staticClass: "supporting-text" },
        [
          _c("span", { staticClass: "main-text" }, [
            _vm._v(
              _vm._s(
                _vm.formatNumber(
                  _vm.item.valid_ballots + _vm.item.rejected_ballots
                )
              )
            )
          ]),
          _vm._v(
            " (" +
              _vm._s(_vm.formatNumber(_vm.item.turnout_rate)) +
              ") votes exercés "
          ),
          _c("br"),
          _c(
            "v-icon",
            {
              staticClass: "ml-2",
              attrs: { color: "qs-light-blue", "x-small": "" }
            },
            [_vm._v("mdi-subdirectory-arrow-right")]
          ),
          _c("span", { staticClass: "font-weight-bold ml-1" }, [
            _vm._v(_vm._s(_vm.formatNumber(_vm.item.valid_ballots)))
          ]),
          _vm._v(
            " (" +
              _vm._s(_vm.formatNumber(_vm.item.valid_ballots__cast_ballots)) +
              ") bulletins valides"
          ),
          _c("br"),
          _c(
            "v-icon",
            {
              staticClass: "ml-2",
              attrs: { color: "qs-light-blue", "x-small": "" }
            },
            [_vm._v("mdi-subdirectory-arrow-right")]
          ),
          _c("span", { staticClass: "font-weight-bold ml-1" }, [
            _vm._v(_vm._s(_vm.formatNumber(_vm.item.rejected_ballots)))
          ]),
          _vm._v(
            " (" +
              _vm._s(
                _vm.formatNumber(_vm.item.rejected_ballots__cast_ballots)
              ) +
              ") bulletins rejetés"
          ),
          _c("br")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }