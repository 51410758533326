import Vue from 'vue';
import { RouteConfig } from 'vue-router';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { capitalize } from 'qs_vuetify/src/plugins/filters';
import i18n from 'qs_vuetify/src/plugins/i18n';

const NewVolunteerDialog = () => import(
  /* webpackChunkName: "NewVolunteerDialog" */
  '@/views/Volunteers/NewVolunteerDialog.vue' // eslint-disable-line comma-dangle
);
const Volunteers = () => import(
  /* webpackChunkName: "Volunteers" */
  '@/views/Volunteers/Volunteers.vue' // eslint-disable-line comma-dangle
);

const volunteers: RouteConfig[] = [
  {
    path: '/volunteers',
    name: 'Volunteers',
    component: Volunteers,
    meta: {
      auth: true,
      data: {
        volunteers: {
          stats: {
            conditional(vm: Vue) {
              return !vm.$store.state.volunteers.statsLoaded;
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$store.state.auth.instanceId}`;
            },
          },
        },
        districts: {
          index: {
            conditional(vm: Vue) {
              return vm.$store.state.districts.total !== vm.$store.state.districts.data.length;
            },
            params: {
              per_page: '*',
              order: 'name',
            },
          },
        },
      },
      menus: ['primary'],
      title: capitalize(i18n.tc('models.volunteers.name', 2)),
      requires: ['VOLUNTEERS_RETRIEVE'],
    },
    children: [
      {
        path: '/volunteers/new',
        name: 'NewVolunteerDialog',
        component: NewVolunteerDialog,
        meta: {
          data: {
            volunteers: {
              loadEmpty: {
                prefix(vm: Vue) {
                  return `/instances/${vm.$store.state.auth.instanceId}`;
                },
              },
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.volunteers.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { params } = vm.$store.state.volunteersView;
                  return {
                    ...ListMixin.buildListState(params),
                  };
                },
                prefix(vm: Vue) {
                  return `/instances/${vm.$store.state.auth.instanceId}`;
                },
              },
              stats: {
                conditional(vm: Vue) {
                  return !vm.$store.state.volunteers.statsLoaded;
                },
                prefix(vm: Vue) {
                  return `/instances/${vm.$store.state.auth.instanceId}`;
                },
              },
            },
          },
          menus: [],
          title: `${capitalize(i18n.tc('models.volunteers.new', 1))} ${i18n.tc('models.volunteers.name', 1)}`,
          requires: ['VOLUNTEERS_CREATE'],
        },
      },
    ],
  },
];

export default volunteers;
