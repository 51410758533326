import Cookies from 'js-cookie';

import { Contact } from 'qs_vuetify/src/types/models';

const completeMembership = async function completeMembership(contact: Contact, email: string) {
  await Cookies.set(
    'qs_labase_adhesion',
    {
      id: contact.id,
      email: email || '',
      first_name: contact.first_name,
      last_name: contact.last_name,
      address: contact.address,
      apartment: contact.apartment,
      city: contact.city,
      postal_code: contact.postal_code,
      home_phone: contact.home_phone,
      gender: contact.gender,
      birthdate: contact.birthdate || '',
    },
    {
      expires: 1,
      secure: process.env.NODE_ENV !== 'development',
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    },
  );

  window.open(process.env.VUE_APP_ADHESION_URL, '_blank');
};

export default completeMembership;
