











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Filter, PersistedModel } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

@Component
export default class FilterSelect extends Vue {
  @Prop({ required: true, type: Object }) allItemsFilter!: RestParams;
  @Prop({ required: true, type: String }) allItemsLabel!: string;
  @Prop({ required: false, type: Object }) draftFilter!: Filter;
  @Prop({ required: true, type: Array }) filters!: Filter[];
  @Prop({ required: false, type: Array }) selectedItems!: PersistedModel[];
  @Prop({ required: false, type: Object, default: () => ({}) }) value!: Filter;
  @Prop({ required: true, type: Number }) selectedFilterIndex!: number;

  noInitialFilter = { id: 0 };

  get filtersWithDefaults(): Filter[] {
    const filtersWithDefaults: Filter[] = [
      {
        id: null,
        name: 'Aucun filtre initial',
        filter: this.noInitialFilter,
      },
    ];

    if (this.draftFilter) {
      filtersWithDefaults.push({
        ...this.draftFilter,
        id: -1,
      });
    }

    if (this.selectedItems.length > 0) {
      filtersWithDefaults.push({
        id: -2,
        filter: this.selectionFilter,
        name: `Sélection (${this.selectedItems.length})`,
      });
    }

    filtersWithDefaults.push({
      id: -3,
      filter: this.allItemsFilter,
      name: this.allItemsLabel,
    });

    filtersWithDefaults.push(...this.filters);

    return filtersWithDefaults;
  }

  get selectionFilter(): any {
    return {
      id: this.selectedItems.map((i) => i.id).join(','),
    };
  }

  mounted() {
    this.setInitialFilter();
  }

  setInitialFilter() {
    if (this.selectedItems.length > 0) {
      this.$emit('input', this.selectionFilter);
      this.$emit('ready');
      return;
    }

    if (!this.value) {
      this.$emit('input', this.noInitialFilter);
      this.$emit('ready');
      return;
    }

    switch (this.selectedFilterIndex) {
      case 0:
        if (this.draftFilter) {
          this.$emit('input', this.draftFilter.filter);
        }
        break;
      case 1:
        this.$emit('input', this.noInitialFilter);
        break;
      default:
        this.$emit('input', this.filters[this.selectedFilterIndex - 2].filter);
        break;
    }

    this.$emit('ready');
  }
}
