import Component, { mixins } from 'vue-class-component';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import { PersistedTag as Tag } from 'qs_vuetify/src/types/models';

import axios from 'qs_vuetify/src/plugins/axios';

@Component
export default class TagsMixin extends mixins(AuthenticationMixin) {
  // eslint-disable-next-line class-methods-use-this
  async deleteContactTag(contactId: number | string, tagId: number | string): Promise<void> {
    await axios.delete(`/contacts/${contactId}/tags/${tagId}`, {});
  }

  // eslint-disable-next-line class-methods-use-this
  editableTags(tags: Tag[]): Tag[] {
    if (this.userIsSuperadmin) {
      return tags;
    }
    return tags.filter((t) => !this.isReservedTag(t));
  }

  // eslint-disable-next-line class-methods-use-this
  isReservedTag(tag: Tag): boolean {
    return [
      'Est une personne racisée: Oui',
      'Est une personne racisée: Non',
      'A une ou des limitations fonctionnelles: Oui',
      'A une ou des limitations fonctionnelles: Non',
      'Partie d\'une communauté autochtone: Oui',
      'Partie d\'une communauté autochtone: Non',
      'Carte de membre par la poste: Demandée',
    ].includes(tag.name);
  }

  // eslint-disable-next-line class-methods-use-this
  async putContactTag(contactId: number | string, tagId: number | string): Promise<void> {
    await axios.put(`/contacts/${contactId}/tags/${tagId}`, {}, {
      params: {
        fields: 'description,instance.name,name',
      },
    });
  }
}
