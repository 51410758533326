var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "exchange-contact-section",
      attrs: { loading: _vm.combinedLoading, tile: "" }
    },
    [
      _vm.item
        ? _c(
            "div",
            {
              class: {
                "exchange-contact-section__inner": true,
                "exchange-contact-section__inner--loading": _vm.stickyLoading,
                "exchange-contact-section__inner--loaded": !_vm.stickyLoading
              }
            },
            [
              _c(
                "v-card-text",
                { staticClass: "exchange-contact-section__form pb-0" },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "text-uppercase d-flex flex-wrap mb-0 exchange-contact-section__form__name"
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("qs-editable-text-field", {
                            staticClass: "mr-5",
                            attrs: {
                              dense: "",
                              name: "first_name",
                              placeholder: "Prénom manquant",
                              value: _vm.item.first_name
                            },
                            on: {
                              input: function($event) {
                                return _vm.patch("first_name", $event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("qs-editable-text-field", {
                            attrs: {
                              dense: "",
                              name: "last_name",
                              placeholder: "Nom manquant",
                              value: _vm.item.last_name
                            },
                            on: {
                              input: function($event) {
                                return _vm.patch("last_name", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "h3",
                    { staticClass: "text-h3 d-flex" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("status")(_vm.item.status, "contacts"))
                        )
                      ]),
                      _c("qs-icon-button", {
                        attrs: {
                          color: "info",
                          href: _vm.$router.resolve({
                            name: "ContactForm",
                            params: { id: _vm.item.id }
                          }).href,
                          icon: "mdi-magnify",
                          label: "Naviguer à la fiche",
                          theme: "labase",
                          tile: "",
                          "x-small": ""
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.goTo($event, {
                              name: "ContactForm",
                              params: { id: _vm.item.id }
                            })
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm.item.district
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.item.district.name) + " ")
                        ])
                      : _c("span", { staticClass: "missing" }, [
                          _vm._v("Circonscription manquante")
                        ])
                  ]),
                  _vm._l(_vm.item.adopted_instances, function(instance) {
                    return _c(
                      "p",
                      {
                        key: instance.id,
                        staticClass: "font-italic my-0 py-0"
                      },
                      [_vm._v(" Adopté·e par " + _vm._s(instance.name) + " ")]
                    )
                  }),
                  _c(
                    "p",
                    { staticClass: "mb-0 mt-0 pt-0 d-flex flex-wrap" },
                    [
                      _c("qs-editable-text-field", {
                        attrs: {
                          dense: "",
                          name: "gender",
                          placeholder: "Genre manquant",
                          "select-items": [
                            { text: "Femme", value: "female" },
                            { text: "Homme", value: "male" },
                            {
                              text: "Non-binaire / aucun genre",
                              value: "other"
                            }
                          ],
                          type: "enum",
                          value: _vm.item.gender
                        },
                        on: {
                          input: function($event) {
                            return _vm.patch("gender", $event)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.item.gender
                              ? {
                                  key: "display",
                                  fn: function(ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("gender")(value)) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      }),
                      _c("span", { staticClass: "mr-3" }, [_vm._v(" , ")]),
                      _c("qs-editable-text-field", {
                        attrs: {
                          dense: "",
                          name: "birthdate",
                          placeholder: "Date de naissance manquante",
                          type: "date",
                          value: _vm.item.birthdate
                        },
                        on: {
                          input: function($event) {
                            return _vm.patch("birthdate", $event)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.item.birthdate
                              ? {
                                  key: "display",
                                  fn: function(ref) {
                                    var value = ref.value
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm._f("age")(value)) + " "
                                      ),
                                      _c("span", { staticClass: "body-2" }, [
                                        _vm._v(
                                          " (" +
                                            _vm._s(_vm._f("date")(value)) +
                                            ")"
                                        )
                                      ])
                                    ]
                                  }
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 pb-0" },
                [
                  _c("qs-editable-text-field", {
                    attrs: {
                      params: {
                        mask: "### ###-####"
                      },
                      name: "home_phone",
                      placeholder: "-",
                      value: _vm.$filters.phone(_vm.item.home_phone)
                    },
                    on: {
                      input: function($event) {
                        return _vm.patch("home_phone", $event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function() {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "qs-blue--text",
                                  attrs: { small: "" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.item.home_phone
                                          ? "$qs-phone"
                                          : "$qs-phone-off"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      4175706496
                    )
                  }),
                  _c("qs-email-field", {
                    ref: "qs-text-field-emails",
                    attrs: {
                      "contact-id": _vm.item.id,
                      dense: "",
                      name: "emails",
                      theme: "labase",
                      value: _vm.item.emails
                    },
                    on: {
                      input: function($event) {
                        return _vm.patch("emails", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "body-1 pb-0" },
                [
                  _c("qs-editable-text-field", {
                    attrs: {
                      name: "address",
                      placeholder: "Adresse manquante",
                      value: _vm.item.address
                    },
                    on: {
                      input: function($event) {
                        return _vm.patch("address", $event)
                      }
                    }
                  }),
                  _c("qs-editable-text-field", {
                    attrs: {
                      name: "apartment",
                      placeholder: "Pas d'appartement",
                      value: _vm.item.apartment
                    },
                    on: {
                      input: function($event) {
                        return _vm.patch("apartment", $event)
                      }
                    }
                  }),
                  _c("qs-editable-text-field", {
                    attrs: {
                      name: "city",
                      placeholder: "Municipalité manquante",
                      value: _vm.item.city
                    },
                    on: {
                      input: function($event) {
                        return _vm.patch("city", $event)
                      }
                    }
                  }),
                  _c("qs-editable-text-field", {
                    attrs: {
                      params: {
                        mask: "A#A #A#",
                        transform: "uppercase"
                      },
                      name: "postal_code",
                      placeholder: "Code postal manquant",
                      value: _vm.$filters.postalCode(_vm.item.postal_code)
                    },
                    on: {
                      input: function($event) {
                        return _vm.patch("postal_code", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "exchange-contact-section__tabs" },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "flex-grow-0 flex-shrink-1",
                      attrs: { flat: "" }
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          staticClass: "rounded-lg",
                          attrs: { "fixed-tabs": "" },
                          model: {
                            value: _vm.tabs,
                            callback: function($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs"
                          }
                        },
                        [
                          _c("v-tabs-slider"),
                          _c(
                            "v-tab",
                            {
                              staticClass: "qs-orange--text",
                              attrs: { href: "#info" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "qs-light-blue" } },
                                [_vm._v("$qs-membership")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            {
                              staticClass: "qs-orange--text",
                              attrs: { href: "#tags" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "qs-light-blue" } },
                                [_vm._v("mdi-tag-multiple")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            {
                              staticClass: "qs-orange--text",
                              attrs: { href: "#events" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "qs-light-blue" } },
                                [_vm._v("$qs-calendar")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tabs,
                        callback: function($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "info" } },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center mb-3" },
                            [
                              _c(
                                "qs-button",
                                {
                                  attrs: { theme: "labase" },
                                  on: {
                                    click: function($event) {
                                      return _vm.completeMembership(
                                        _vm.item,
                                        _vm.item.email || ""
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(" Compléter une adhésion ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("exchange-contact-status-section", {
                            staticClass: "exchange-contact-section__status",
                            attrs: { id: _vm.id }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          staticClass: "exchange-contact-section__tabs__tags",
                          attrs: { value: "tags" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c("exchange-contact-tags-section", {
                                attrs: {
                                  "campaign-id": _vm.campaignId,
                                  id: _vm.id,
                                  "instance-id": _vm.instanceId
                                },
                                on: {
                                  change: function($event) {
                                    _vm.$emit("change:tags")
                                    _vm.$emit("change")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-tab-item",
                        {
                          staticClass: "exchange-contact-section__tabs__events",
                          attrs: { value: "events" }
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c("exchange-contact-events-section", {
                                  attrs: {
                                    form: _vm.contactEventForm,
                                    id: _vm.id,
                                    "instance-id": _vm.instanceId
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$emit("change:events")
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "exchange-contact-section__inner" },
            [
              _c("v-card-text", {
                staticClass: "exchange-contact-section__form pb-0"
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }