import { Module } from 'vuex';

import { SentEmail } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { SentEmailsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: SentEmailsRestState = buildRestState<SentEmail>('sent_emails', {});

export const getters = buildRestGetters<SentEmail, SentEmailsRestState>();

export const actions = buildRestActions<SentEmail, SentEmailsRestState>();

export const mutations = buildRestMutations<SentEmail, SentEmailsRestState>();

export const sent_emails: Module<SentEmailsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
