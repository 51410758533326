


































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import { Prop, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { PersistedEvent } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

import FilterSelect from '@/components/FilterSelect.vue';
import LocationField from '@/components/LocationField.vue';

import FilterSelectMixin from '@/mixins/FilterSelectMixin';

const auth: any = namespace('auth');
const events: any = namespace('events');
const global: any = namespace('global');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('events/item', null);
    this.$store.commit('events/initialItem', '');
    next();
  },
  components: {
    FilterSelect,
    LocationField,
    QsActionModal,
    QsButton,
    QsFormBuilder,
    QsHtmlEditor,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewEventDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FilterSelectMixin,
  FormMixin,
) {
  @Prop({ type: [String, Number], required: true }) id!: string | number;
  @Prop({ type: String, required: false, default: 'contacts' }) type!: string;

  @auth.Getter instanceId!: number | null;

  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @events.Action loadEmpty!: any;
  @events.Getter error!: ErrorResponse;
  @events.Getter form!: Form;
  @events.Getter initialItem!: string;
  @events.Getter item!: PersistedEvent;
  @events.Getter loading!: boolean;
  @events.Getter slug!: string;
  @events.Mutation('initialItem') syncInitialItem!: any;
  @events.Mutation('item') syncItem!: any;
  @events.Mutation('error') setError!: ((arg: ErrorResponse | null) => void);

  formOrder: string[] = [
    'title',
    'description',
    'filter',
    'location',
    'start_at',
    'accessibility',
  ];

  get params(): RestParams {
    switch (this.type) {
      case 'volunteers':
        return {
          prefix: '',
        };
      case 'contacts':
      default:
        return {};
    }
  }

  afterSave() {
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: "Événement créé avec succès. L'ajout des participant-es peut prendre quelques minutes.",
      timeout: 2500,
    });
    this.resetIndexData();
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
    }
  }

  closeModal(): void {
    this.setError(null);

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
      return;
    }

    this.$router.push({ name: 'Events' });
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', 'Nouvel événement');
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    this.syncItem({
      ...this.item,
      instance_id: this.instanceId,
      type: this.type,
    });

    await this.submit();
  }

  syncInitialItemOnNextTick() {
    this.$nextTick(() => this.syncInitialItem(JSON.stringify(this.item)));
  }
}
