













































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { PersistedCallCampaign } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import CallCampaignCard from '@/components/CallCampaign/CallCampaignCard.vue';

const auth: any = namespace('auth');
const campaigns: any = namespace('call_campaigns');
const global: any = namespace('global');
const view: any = namespace('callCampaignsView');

@Component({
  components: {
    CallCampaignCard,
    QsConfirmationModal,
    QsFilters,
    QsList,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class CallCampaigns extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  LazyListMixin,
  ListMixin,
) {
  @auth.Getter instanceId!: number | null;
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @campaigns.Getter items!: Array<PersistedCallCampaign>;
  @campaigns.Getter error!: ErrorResponse;
  @campaigns.Getter exportUrl!: string;
  @campaigns.Getter filtersDefinition!: FiltersDefinition | null;
  @campaigns.Getter loading!: boolean;
  @campaigns.Getter slug!: string;
  @campaigns.Getter total!: number;

  @view.Mutation addSelectedItem!: any;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: (arg: PersistedCallCampaign) => void;
  @view.Getter selectedItems!: Array<PersistedCallCampaign>;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    per_page: 20,
    order: '-id',
    fields: [
      'id',
      'name',
      'description',
      'instance.name',
      'stats.*',
      'status',
      'created_at',
      'deleted_at',
      'updated_at',
      'created_by_user.contact_name',
      'deleted_by_user.contact_name',
      'updated_by_user.contact_name',
    ].join(','),
    'instances.id': this.instanceId,
  }

  fakeLoading = false;

  get viewParams() {
    return {
      call_campaigns: {
        ...this.defaultParams,
        ...this.params,
      },
    };
  }

  filtersLoaded = true;
  viewStoreName = 'callCampaignsView';

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get activeFilters(): FiltersDefinition {
    if (!this.filtersDefinition) {
      return {};
    }

    return {
      status: this.filtersDefinition.status,
      q: this.filtersDefinition.q,
      with_deleted: this.filtersDefinition.with_deleted,
    };
  }

  archive() {
    this.confirmList(
      "Archivage de campagnes d'appels",
      `Êtes-vous sûr-e de vouloir archiver ces campagnes d'appels?
      Vous ne pourrez plus faire d'appels, mais les données seront conservées.`,
      'warning',
      'mdi-warning',
      async () => {
        await Promise.all(this.selectedItems.filter((e) => !e.deleted_at).map(
          (e) => this.$store.dispatch('call_campaigns/destroy', { id: e.id, parallel: true }),
        ));

        this.setSelectedItems([]);
        this.loadFirstPage();
      },
    );
  }

  async restore() {
    this.fakeLoading = true;

    try {
      await Promise.all(this.selectedItems.filter((e) => !!e.deleted_at).map(
        (e) => this.$store.dispatch('call_campaigns/restore', { id: e.id, parallel: true }),
      ));
    } finally {
      this.fakeLoading = false;
    }

    this.setSelectedItems([]);
    this.loadFirstPage();
  }

  mounted() {
    this.setAction();

    this.$store.commit('call_campaigns/exportFields', [
      'id',
      'name',
    ]);

    if (this.items.length === 0) {
      this.setParams({
        ...this.defaultParams,
        ...this.params,
        page: 1,
      });
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setAction();
    }
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('CALL_CAMPAIGNS_CREATE') ? [
        {
          onClick: () => { this.$router.push('/call_campaigns/new'); },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Créer une nouvelle campagne',
        },
      ] : [],
    );
  }
}
