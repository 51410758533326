import { Module } from 'vuex';

import { User } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { UsersRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: UsersRestState = buildRestState<User>('users', {});

export const getters = buildRestGetters<User, UsersRestState>();

export const actions = buildRestActions<User, UsersRestState>();

export const mutations = buildRestMutations<User, UsersRestState>();

export const users: Module<UsersRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
