var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exchange-contact-events-section" },
    [
      _vm.hasFutureEvents
        ? _c(
            "v-list",
            _vm._l(_vm.futureEvents, function(event) {
              return _c("qs-events-list-item", {
                key: event.id,
                attrs: { item: event },
                on: {
                  click: function($event) {
                    return _vm.fetchSelectedEvent(event.contact_event_id)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    event.intention
                      ? {
                          key: "append",
                          fn: function() {
                            return [
                              _c("qs-events-intention-indicator", {
                                attrs: {
                                  "contact-event-id": event.contact_event_id,
                                  large: "",
                                  value: event.intention,
                                  "with-tooltip": ""
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        : _vm.futureEventsLoading
        ? _c(
            "div",
            { staticClass: "d-flex align-center justify-center py-3" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ],
            1
          )
        : _c("p", { staticClass: "text-center caption" }, [
            _vm._v(" Aucun événement à venir ")
          ]),
      _c(
        "qs-button",
        {
          attrs: { block: "", color: "qs-pink", theme: "labase" },
          on: {
            click: function($event) {
              _vm.newEventModal = true
            }
          }
        },
        [_vm._v(" Inscrire à un événement ")]
      ),
      _vm.form && _vm.selectedEvent
        ? _c("qs-contact-event-form", {
            attrs: {
              contactId: _vm.id,
              form: _vm.form,
              item: _vm.selectedEvent,
              loading: _vm.selectedEventLoading,
              value: _vm.selectedEventModal
            },
            on: {
              "update:item": function($event) {
                _vm.selectedEvent = $event
              },
              "update:value": function($event) {
                _vm.selectedEventModal = $event
              },
              "click:save": _vm.saveContactEvent,
              "realod:future": _vm.fetchFutureEvents,
              "reload:past": _vm.fetchPastEvents
            }
          })
        : _vm._e(),
      _c("qs-add-event-to-contact", {
        attrs: { contactId: _vm.id, items: [], value: _vm.newEventModal },
        on: {
          "click:close": function($event) {
            _vm.newEventModal = false
          },
          input: function($event) {
            return _vm.setSelectedEvent($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }