



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { AppNotification } from 'qs_vuetify/src/types/components';
import { PersistedCallCampaign } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');
const global: any = namespace('global');
const store: any = namespace('contacts');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsRelationField,
  },
})
export default class AddToCallCampaignModal extends Vue {
  @auth.Getter instanceId!: number | null;

  @global.Mutation addNotification!: (arg: AppNotification) => void;
  @store.Getter('addToCallCampaignsContactsLoading') loading!: boolean;

  @Prop({ required: true, type: Object }) params!: RestParams;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  callCampaign: PersistedCallCampaign | null = null;
  dummyItem = {};

  get queryDefinition(): any {
    return {
      key: 'data',
      slug: 'call_campaigns',
      text: 'name',
      value: 'id',
      params: {
        fields: [
          'description',
          'instance.name',
          'name',
        ].join(','),
        instance_id: this.instanceId,
        '!status': 'completed',
      },
    };
  }

  async submit(): Promise<void> {
    if (this.callCampaign?.id) {
      try {
        await this.$store.dispatch('contacts/addToCallCampaignsContacts', {
          data: { call_campaigns: [this.callCampaign.id] },
          params: {
            ...this.params,
            fields: 'id',
            per_page: '*',
            page: 1,
          },
        });

        this.addNotification({
          color: 'success',
          message: "Les destinataires ont été ajouté·es à la campagne d'appels.",
        });

        this.syncedValue = false;
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: "Une erreur est survenue lors de l'ajout.",
        });
      }
    }
  }

  @Watch('syncedValue')
  onSyncedValueChanged(val: boolean) {
    if (!val) {
      this.dummyItem = {};
      this.callCampaign = null;
    }
  }
}
