














































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import ColorScale from '@/components/ElectionResults/ColorScale.vue';
import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsThemedSelectField from 'qs_vuetify/src/components/Fields/QsThemedSelectField.vue';

import NavigationButton from '@/components/ElectionResults/NavigationButton.vue';
import SegmentResults from '@/components/ElectionResults/SegmentResults.vue';
import TurnoutData from '@/components/ElectionResults/TurnoutData.vue';
import PollingSectorsAutocomplete from '@/components/ElectionResults/PollingSectorsAutocomplete.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ElectionResultsMixin from '@/mixins/ElectionResultsMixin';
import MapMixin from '@/mixins/MapMixin';

import { formatNumber, hexToHSL } from '@/helpers';

import { GeographyableObject, MappableElectionResultsProperty } from '@/types/components';
import { Watch } from 'vue-property-decorator';
import {
  District,
  MapSubdivision,
  PersistedParty,
  PersistedPollingSector,
  PersistedPollingSubdivision,
  PollingSubdivision,
  SubdivisionResult,
} from 'qs_vuetify/src/types/models';
import { AppNotification, SelectItem } from 'qs_vuetify/src/types/components';

const global: any = namespace('global');
const map_subdivisions = namespace('map_subdivisions');
const view = namespace('mapSubdivisionsView');

@Component({
  components: {
    ColorScale,
    NavigationButton,
    PollingSectorsAutocomplete,
    QsBaseLayout,
    QsRelationField,
    QsThemedSelectField,
    SegmentResults,
    TurnoutData,
  },
})
export default class SubdivisionResultsMap extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ElectionResultsMixin,
  MapMixin,
) {
  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @map_subdivisions.Getter('data') map_subdivisions!: any[];
  @map_subdivisions.Getter loading!: boolean;

  @view.Getter selectedItems!: Array<PersistedPollingSubdivision>;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation clearSelectedItems!: () => void;
  @view.Mutation removeSelectedItem!: any;

  activePartyDisplay: number | null = 2;
  activeSubdivisionId: number | null = null;

  parties: SelectItem[] = [];

  scaleProps: { color: string; min: number; max: number; value: number | null } = {
    color: '#cecece',
    max: 1,
    min: 0,
    value: null,
  }

  settingsDialog: boolean = false;

  statsColors = [
    '#333132',
    '#ff5505',
    '#003d21',
    '#263679',
    '#67009c',
    '#ffcc00',
  ];

  get activeMapStyle(): MappableElectionResultsProperty | 'winner' | 'selection' | 'stats' {
    return this.$store.getters['electionResultsView/activeMapStyle'];
  }

  set activeMapStyle(value: MappableElectionResultsProperty | 'winner' | 'selection' | 'stats') {
    this.$store.commit('electionResultsView/activeMapStyle', value);
  }

  get activeStatsStyle(): string {
    return this.$store.getters['electionResultsView/activeStatsStyle'];
  }

  set activeStatsStyle(value: string) {
    this.$store.commit('electionResultsView/activeStatsStyle', value);
  }

  get availableStatsStyles(): SelectItem[] {
    const properties = this.map_subdivisions.map((s) => {
      if (!s.stats) {
        return [];
      }

      return Object.keys(s.stats).filter((k) => k[0] !== '_');
    }).flat();

    const unique_properties = [...new Set(properties)];

    return unique_properties.map((p) => {
      const text = this.$t(`pointage_stats.${p}`) || p;
      return { text: (text as string), value: p };
    });
  }

  get activeSubdivision(): PollingSubdivision | null {
    if (this.activeSubdivisionId) {
      const subdivision = this.map_subdivisions
        .find((s: PollingSubdivision) => s.id === this.activeSubdivisionId);

      if (subdivision) {
        return subdivision;
      }

      return null;
    }

    return null;
  }

  get district(): District | null {
    const item = this.$store.getters['district_elections/item'];

    if (item?.district) {
      return item.district;
    }

    return null;
  }

  get fillOpacity(): number {
    return this.$store.getters['electionResultsView/fillOpacity'];
  }

  set fillOpacity(value: number) {
    this.$store.commit('electionResultsView/fillOpacity', value);
  }

  get showPolygonLabels(): boolean {
    return this.$store.getters['electionResultsView/showPolygonLabels'];
  }

  set showPolygonLabels(value: boolean) {
    this.$store.commit('electionResultsView/showPolygonLabels', value);
  }

  get statsColor(): string {
    return this.$store.getters['electionResultsView/statsColor'];
  }

  set statsColor(value: string) {
    this.$store.commit('electionResultsView/statsColor', value);
  }

  get zoomOnClick(): boolean {
    return this.$store.getters['electionResultsView/zoomOnClick'];
  }

  set zoomOnClick(value: boolean) {
    this.$store.commit('electionResultsView/zoomOnClick', value);
  }

  // eslint-disable-next-line class-methods-use-this
  autoformatNumber(stats: Record<string, number>, property: string) {
    if (!(property in stats)) {
      return 'N/A';
    }

    const number = stats[property];

    return formatNumber(number);
  }

  clearAllSelected() {
    this.clearSelectedItems();
    this.onActiveMapStyleChanged(this.activeMapStyle);
  }

  @Watch('map_subdivisions')
  onGeometriesChanged(newMapSubdivisions: MapSubdivision[]) {
    if (newMapSubdivisions) {
      // Construire la liste de partis accessibles
      this.parties = this.accessiblePartiesFrom(newMapSubdivisions)
        .map((p) => ({ text: p.name || p.code, value: p.id }));

      // Construire les collections de features geojson
      const geometries = newMapSubdivisions
        .filter((g: MapSubdivision) => this.isGeographyableObject(g));

      this.$nextTick(() => {
        const mapRef = (this.$refs.mapRef as any);

        mapRef.$mapPromise.then(() => {
          this.mapRemoveAllFeatures();
          this.mapAddFeatures((geometries as GeographyableObject[]));
          this.mapAddFeaturesAsPoints((geometries as GeographyableObject[]));
          this.mapFitBounds((geometries as GeographyableObject[]));

          mapRef.$mapObject.data.addListener('click', (event: any) => {
            if (this.activeMapStyle === 'selection') {
              const featureId = event.feature.getProperty('id');
              const subdivision = this.map_subdivisions.find((s) => s.id === featureId);
              if (subdivision) {
                if (this.selectedItems.map((s) => s.id).includes(featureId)) {
                  this.removeSelectedItem(subdivision);
                } else {
                  this.addSelectedItem(subdivision);
                }
                this.onActiveMapStyleChanged(this.activeMapStyle);
              }
            } else {
              this.activeSubdivisionId = event.feature.getProperty('id');
            }
          });

          this.onActiveMapStyleChanged(this.activeMapStyle);
        });
      });
    }
  }

  @Watch('activeMapStyle')
  onActiveMapStyleChanged(newStyle: MappableElectionResultsProperty | 'winner' | 'selection' | 'stats') {
    const mapRef = (this.$refs.mapRef as any);

    const { color } = newStyle === 'stats'
      ? { color: this.statsColor }
      : this.accessiblePartiesFrom(this.map_subdivisions)
        .find((p: PersistedParty) => p.id === this.activePartyDisplay) as PersistedParty;
    const min = this.minResultValueFrom(this.map_subdivisions, (newStyle as MappableElectionResultsProperty));
    const max = this.maxResultValueFrom(this.map_subdivisions, (newStyle as MappableElectionResultsProperty));
    const selectedItemsIds = this.selectedItems.map((s) => s.id);

    mapRef.$mapObject.data.setStyle((feature: any) => {
      let fillColor = '#cecece';
      const featureId = feature.getProperty('id');
      const results = feature.getProperty('results');
      const stats = feature.getProperty('stats');
      const valid_ballots = feature.getProperty('valid_ballots');

      // STYLE => selection
      if (newStyle === 'selection' && selectedItemsIds.includes(featureId)) {
        fillColor = '#ff5505';
      }

      // STYLE => winner
      if (newStyle === 'winner' && valid_ballots > 0) {
        const winner = this.winnerFrom(({ results } as any));
        const { h, s, l } = hexToHSL(winner ? winner.color : '#cecece');
        fillColor = `hsl(${h},${s}%,${l}%)`;
      }

      // STYLE => votes || votes__valid_ballots
      if (['votes', 'votes__valid_ballots'].includes(newStyle)) {
        const subdivision_result = results
          .find((r: SubdivisionResult) => r.party && r.party.id === this.activePartyDisplay);

        if (!subdivision_result || subdivision_result[newStyle] <= 0) {
          fillColor = '#cecece';
        } else {
          const { h, s } = hexToHSL(subdivision_result.party.color);
          const value = (subdivision_result[newStyle] - min) / (max - min);

          if (this.activeSubdivisionId === feature.getProperty('id')) {
            this.scaleProps = {
              color,
              max: Number(max),
              min: Number(min),
              value,
            };
          }

          fillColor = `hsl(${h},${s}%,${(((1 - value) * 60) + 40).toFixed(2)}%)`;
        }
      }

      // STYLE => stats
      if (['stats'].includes(newStyle)) {
        const subdivision_stats = stats;
        const stats_style = this.activeStatsStyle;

        if (!subdivision_stats || subdivision_stats[stats_style] <= 0) {
          fillColor = '#cecece';
        } else {
          const { h, s } = hexToHSL(color);
          const value = (subdivision_stats[stats_style] - min) / (max - min);

          if (this.activeSubdivisionId === feature.getProperty('id')) {
            this.scaleProps = {
              color,
              max: Number(max),
              min: Number(min),
              value,
            };
          }

          fillColor = `hsl(${h},${s}%,${(((1 - value) * 60) + 40).toFixed(2)}%)`;
        }
      }

      let fillOpacity = valid_ballots > 0 ? this.fillOpacity : 0;

      if (['stats'].includes(newStyle)) {
        fillOpacity = this.fillOpacity;
      }

      return {
        icon: {
          url: '/img/marker.svg',
        },
        strokeColor: '#404040',
        fillColor,
        strokeWeight: this.activeSubdivisionId === featureId ? 3 : 0.5,
        fillOpacity,
        label: {
          color: '#404040',
          fontWeight: 'normal',
          fontSize: this.showPolygonLabels ? '0.75rem' : '0rem',
          text: feature.getProperty('name'),
        },
      };
    });

    if (!this.activeSubdivisionId) {
      this.scaleProps = {
        color,
        max: Number(max),
        min: Number(min),
        value: null,
      };
    }
  }

  @Watch('activePartyDisplay')
  onActivePartyDisplayChanged(activePartyDisplay: string) {
    this.activePartyDisplay = parseInt(activePartyDisplay, 10);
    this.onActiveMapStyleChanged(this.activeMapStyle);
  }

  @Watch('statsColor')
  @Watch('activeStatsStyle')
  onActiveStatsStyleChanged() {
    this.onActiveMapStyleChanged(this.activeMapStyle);
  }

  @Watch('activeSubdivisionId')
  onActiveSubdivisionChanged(newActiveSubdivisionId: number) {
    this.onActiveMapStyleChanged(this.activeMapStyle);
    const subdivision = this.map_subdivisions
      .find((s: PollingSubdivision) => s.id === newActiveSubdivisionId);

    if (subdivision && this.zoomOnClick) {
      const mapRef = (this.$refs.mapRef as any);

      mapRef.$mapObject.setZoom(15);

      this.$nextTick(() => {
        this.mapFitBounds(subdivision);
      });
    }
  }

  @Watch('fillOpacity')
  onFillOpacityChanged() {
    this.onActiveMapStyleChanged(this.activeMapStyle);
  }

  @Watch('showPolygonLabels')
  onShowPolygonLabelsChanged() {
    this.onActiveMapStyleChanged(this.activeMapStyle);
  }

  setSelectionModeOn() {
    this.activeMapStyle = 'selection';
    this.zoomOnClick = false;
  }

  async syncPollingSectors(polling_sector: PersistedPollingSector) {
    if (polling_sector?.id && this.selectedItems.length > 0) {
      try {
        const id = this.selectedItems.flatMap((s) => s.polling_subdivisions.map((p: any) => p.id));
        await this.$store.dispatch('polling_subdivisions/update', {
          id,
          params: {
            fields: 'id',
            page: 1,
            per_page: '*',
          },
          prefix: `pointage/polling_sectors/${polling_sector.id}`,
        });
        const message = this.selectedItems.length > 1
          ? 'Les bureaux de vote ont été associés au secteur'
          : 'Le bureau de vote a été associé au secteur';
        this.addNotification({
          color: 'success',
          message,
        });
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: "Une erreur est survenue lors de l'affectation des secteurs.",
        });
      }
    }
  }
}

