var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("add-single-contact-modal", {
    attrs: { value: _vm.visible, loading: _vm.loading, slug: "volunteers" },
    on: {
      "update:value": function($event) {
        _vm.visible = $event
      },
      add: _vm.addVolunteer
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(" Ajouter un·e militant·e ")]
        },
        proxy: true
      },
      {
        key: "subtitle",
        fn: function() {
          return [
            _vm._v(" à la liste de " + _vm._s(_vm.currentInstanceName) + " ")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }