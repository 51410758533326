var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "call-campaign-form", attrs: { fluid: "" } },
    [
      _c("item-navigation", {
        staticClass: "my-0",
        attrs: {
          callback: _vm.loadNextPage,
          item: _vm.item,
          items: _vm.data,
          loading: _vm.loading,
          "model-name": _vm.slug,
          total: _vm.total
        }
      }),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "3" } },
            [
              _c("qs-card", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v("Configuration")]
                    },
                    proxy: true
                  },
                  {
                    key: "text",
                    fn: function() {
                      return [
                        _vm.itemReady && !_vm.loading
                          ? _c("qs-form-builder", {
                              attrs: {
                                disabled: !_vm.item || !!_vm.item.deleted_at,
                                error: _vm.error,
                                form: _vm.form,
                                order: _vm.formOrder,
                                item: _vm.item,
                                "model-name": _vm.slug
                              },
                              on: { input: _vm.syncItem }
                            })
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-center align-center"
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "primary" }
                                })
                              ],
                              1
                            )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c("qs-card", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _vm._v(" Progression "),
                        _c("v-spacer"),
                        _c(
                          "qs-button",
                          {
                            attrs: {
                              disabled:
                                !_vm.routeDataLoaded ||
                                !_vm.currentUserIsCaller ||
                                !_vm.item ||
                                !!_vm.item.deleted_at,
                              theme: "labase",
                              small: "",
                              label:
                                !_vm.currentUserIsCaller &&
                                _vm.item &&
                                !_vm.item.deleted_at
                                  ? "Inscrivez-vous comme appelant·e pour commencer les appels."
                                  : null
                            },
                            on: { click: _vm.startCalls }
                          },
                          [_vm._v(" Commencer les appels ")]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "text",
                    fn: function() {
                      return [
                        _vm.itemReady && !_vm.loading && _vm.stats
                          ? _c("qs-call-campaign-progress", {
                              attrs: { data: _vm.stats }
                            })
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-center align-center"
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "primary" }
                                })
                              ],
                              1
                            )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("qs-card", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v("Script d'appel")]
                    },
                    proxy: true
                  },
                  {
                    key: "text",
                    fn: function() {
                      return [
                        _vm.itemReady && !_vm.loading
                          ? _c("qs-html-editor", {
                              attrs: {
                                disabled: !_vm.item || !!_vm.item.deleted_at,
                                loading: _vm.loadingPart === "script",
                                options: {
                                  align: false,
                                  hr: false,
                                  headings: false
                                }
                              },
                              model: {
                                value: _vm.item.script,
                                callback: function($$v) {
                                  _vm.$set(_vm.item, "script", $$v)
                                },
                                expression: "item.script"
                              }
                            })
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-center align-center"
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "primary" }
                                })
                              ],
                              1
                            )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _vm.itemReady && !_vm.loading
                ? _c(
                    "div",
                    {
                      class: {
                        "call-campaign-form__form-definition--not-warned":
                          !_vm.userWarnedAboutFormDefinition &&
                          _vm.contactExchangesTotal > 0
                      },
                      on: { click: _vm.warnAboutFormDefinition }
                    },
                    [
                      _c("qs-form-editor", {
                        attrs: {
                          disabled: !_vm.item || !!_vm.item.deleted_at,
                          templates: _vm.templates,
                          title: "Questions",
                          value: _vm.item.form_definition
                        },
                        on: { input: _vm.setFormDefinition }
                      })
                    ],
                    1
                  )
                : _c("qs-card", {
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v("Questions")]
                        },
                        proxy: true
                      },
                      {
                        key: "text",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-center align-center"
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "", color: "primary" }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
            ],
            1
          ),
          _vm.userHas("CALL_CAMPAIGN_USERS_RETRIEVE")
            ? _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Appelant·es "),
                              _c("v-spacer"),
                              _c(
                                "qs-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "info",
                                    icon: "",
                                    outlined: "",
                                    small: "",
                                    theme: "labase"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showLeaderboard = true
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-trophy")])],
                                1
                              ),
                              !_vm.users
                                .map(function(u) {
                                  return u.id
                                })
                                .includes(_vm.user.id)
                                ? _c(
                                    "qs-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        disabled:
                                          _vm.selfAssignCallCampaignUserLoading ||
                                          !_vm.itemReady ||
                                          _vm.loading ||
                                          !_vm.item ||
                                          !!_vm.item.deleted_at,
                                        loading:
                                          _vm.selfAssignCallCampaignUserLoading,
                                        outlined: "",
                                        small: "",
                                        theme: "labase"
                                      },
                                      on: {
                                        click: _vm.selfAssignCallCampaignUser
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-plus")
                                      ]),
                                      _vm._v(" moi ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.userHas("CALL_CAMPAIGN_USERS_UPDATE")
                                ? _c(
                                    "qs-button",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.itemReady ||
                                          _vm.loading ||
                                          !_vm.item ||
                                          !!_vm.item.deleted_at,
                                        small: "",
                                        theme: "labase"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showCallCampaignUsersModal = true
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        _vm.itemReady && !_vm.loading
                          ? {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm.users && _vm.users.length > 0
                                    ? _c(
                                        "v-list",
                                        _vm._l(_vm.users, function(user) {
                                          return _c(
                                            "v-list-item",
                                            { key: user.id },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          user.contact_name ||
                                                            user.email
                                                        ) +
                                                        " "
                                                    ),
                                                    user.contact
                                                      ? _c("small", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                user.contact
                                                                  .v1_contact_id
                                                              ) +
                                                              ")"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(user.email) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _vm.userHas(
                                                    "CALL_CAMPAIGN_USERS_DELETE"
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            loading:
                                                              user.loading,
                                                            text: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: [
                                                              function($event) {
                                                                return _vm.storeContactAndShowConfirmDeleteUserModal(
                                                                  user
                                                                )
                                                              },
                                                              function($event) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                              }
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    : _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "font-italic text-center"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Pas d'appelant·es affecté·es. "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              },
                              proxy: true
                            }
                          : {
                              key: "default",
                              fn: function(undefined) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-center"
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.itemReady && _vm.userHas("CONTACTS_RETRIEVE")
        ? _c(
            "v-row",
            {
              staticClass: "call-campaign-form__contacts mt-4",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c("qs-filters", {
                                attrs: {
                                  "active-filters": _vm.contactsParams,
                                  "filters-definition":
                                    _vm.contactsFiltersSubset,
                                  "keep-open": "",
                                  "model-name": "contacts",
                                  theme: "labase"
                                },
                                on: { input: _vm.updateContactsFilters }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3876626743
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Destinataires "),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._b(
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    disabled:
                                                      !!_vm.item &&
                                                      !!_vm.item.deleted_at,
                                                    small: "",
                                                    theme: "labase"
                                                  },
                                                  on: { click: on.click }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2 mr-2" },
                                                  [_vm._v("Actions")]
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-menu-down")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2070535142
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsCsv }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (CSV) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsXls }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (XLS) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.userHas("CALL_CAMPAIGN_CONTACTS_CREATE")
                                ? _c(
                                    "qs-button",
                                    {
                                      attrs: {
                                        disabled:
                                          !!_vm.item && !!_vm.item.deleted_at,
                                        small: "",
                                        theme: "labase"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showAddSingleContactModal = true
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                [
                                  _c("qs-data-table", {
                                    attrs: {
                                      headers: _vm.contactsHeaders,
                                      "item-class": _vm.getDataTableItemClass,
                                      items: _vm.contacts,
                                      loading:
                                        !_vm.routeDataLoaded ||
                                        _vm.contactsLoading,
                                      options: _vm.contactsOptions,
                                      "set-options": _vm.setContactsOptions,
                                      total: _vm.contactsTotal || 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.actions",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              _c("qs-button", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  color: "info",
                                                  icon: "mdi-magnify",
                                                  label:
                                                    "Consulter la fiche du contact",
                                                  xSmall: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.goTo($event, {
                                                      name: "ContactForm",
                                                      params: { id: contact.id }
                                                    })
                                                  }
                                                }
                                              }),
                                              _vm.userHas(
                                                "CALL_CAMPAIGN_CONTACTS_DELETE"
                                              )
                                                ? _c("qs-button", {
                                                    attrs: {
                                                      color: "error",
                                                      disabled: !_vm.canBeDeleted(
                                                        contact
                                                      ),
                                                      icon: "mdi-delete",
                                                      label: _vm.canBeDeleted(
                                                        contact
                                                      )
                                                        ? "Supprimer le·la destinataire"
                                                        : "Impossible de supprimer un·e destinataire pour qui des échanges ont été consignés",
                                                      xSmall: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.storeContactAndShowConfirmDeleteContactModal(
                                                          contact
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.contact_exchanges",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              contact &&
                                              contact.contact_exchanges &&
                                              contact.contact_exchanges.length >
                                                0
                                                ? _c(
                                                    "div",
                                                    [
                                                      _vm._l(
                                                        _vm.groupContactExchangesByStatus(
                                                          contact.contact_exchanges
                                                        ),
                                                        function(
                                                          exchanges,
                                                          status
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            { key: status },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "models.contact_exchanges.enums.status." +
                                                                            status
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(": "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    exchanges.length
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "scroll-to",
                                                              rawName:
                                                                "v-scroll-to",
                                                              value:
                                                                "#call-campaign-form__contact-exchanges__table",
                                                              expression:
                                                                "'#call-campaign-form__contact-exchanges__table'"
                                                            }
                                                          ],
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.filterExchangesByV1ContactId(
                                                                contact.v1_contact_id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" Filtrer ")]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _c("div", [
                                                    _c("small", [
                                                      _c("em", [
                                                        _vm._v("Aucun échange.")
                                                      ])
                                                    ])
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1099548582
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2512382251
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.itemReady && _vm.userHas("CONTACT_EXCHANGES_RETRIEVE")
        ? _c(
            "v-row",
            {
              staticClass: "call-campaign-form__contact-exchanges mt-4",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c("qs-filters", {
                                attrs: {
                                  "active-filters": _vm.contactExchangesParams,
                                  "filters-definition":
                                    _vm.contactExchangesFiltersDefinition,
                                  "keep-open": "",
                                  "model-name": "contact_exchanges",
                                  schemas: {
                                    answers: _vm.item.form_definition
                                  },
                                  theme: "labase"
                                },
                                on: { input: _vm.updateContactExchangesFilters }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2657161243
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Échanges "),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._b(
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    disabled:
                                                      !!_vm.item &&
                                                      !!_vm.item.deleted_at,
                                                    small: "",
                                                    theme: "labase"
                                                  },
                                                  on: { click: on.click }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2 mr-2" },
                                                  [_vm._v("Actions")]
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-menu-down")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2070535142
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: {
                                            click: _vm.exportContactExchangesCsv
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (CSV) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: {
                                            click: _vm.exportContactExchangesXls
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (XLS) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "call-campaign-form__contact-exchanges__table",
                                  attrs: {
                                    id:
                                      "call-campaign-form__contact-exchanges__table"
                                  }
                                },
                                [
                                  _c("qs-data-table", {
                                    attrs: {
                                      actions: [],
                                      headers: _vm.contactExchangesHeaders,
                                      items: _vm.contactExchanges,
                                      loading:
                                        !_vm.routeDataLoaded ||
                                        _vm.contactExchangesLoading,
                                      options: _vm.contactExchangesOptions,
                                      "set-options":
                                        _vm.setContactExchangesOptions,
                                      total: _vm.contactExchangesTotal || 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key:
                                            "item.called_by_user.contact_name",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              contact && contact.called_by_user
                                                ? _c("qs-user-chip", {
                                                    attrs: {
                                                      item:
                                                        contact.called_by_user
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.left_message",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              contact.status === "failed" ||
                                              (contact.status === "completed" &&
                                                _vm.item.leave_message)
                                                ? _c("qs-boolean-indicator", {
                                                    attrs: {
                                                      value: !!contact.left_message
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.status",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              contact.status === "active" &&
                                              contact.recall_at
                                                ? [
                                                    _vm._v(" À rappeler"),
                                                    _c("br"),
                                                    _vm
                                                      .$dayjs(contact.recall_at)
                                                      .format("YYYY-MM-DD") !==
                                                    _vm
                                                      .$dayjs()
                                                      .format("YYYY-MM-DD")
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .$dayjs(
                                                                    contact.recall_at
                                                                  )
                                                                  .fromNow()
                                                              )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " à " +
                                                        _vm._s(
                                                          _vm
                                                            .$dayjs(
                                                              contact.recall_at
                                                            )
                                                            .format("HH[h]mm")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("status")(
                                                            contact.status,
                                                            "contact_exchanges"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.answers",
                                          fn: function(ref) {
                                            var answers = ref.item.answers
                                            return [
                                              _c("call-campaign-answers", {
                                                attrs: {
                                                  answers: answers,
                                                  schema:
                                                    _vm.item.form_definition
                                                }
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key:
                                            "item.reserved_by_user.contact_name",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              contact &&
                                              contact.reserved_by_user
                                                ? _c("qs-user-chip", {
                                                    attrs: {
                                                      item:
                                                        contact.reserved_by_user
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.actions",
                                          fn: function(ref) {
                                            var contact = ref.item
                                            return [
                                              _vm.userHas(
                                                "CONTACT_EXCHANGES_UPDATE"
                                              ) && !!contact.reserved_by_user
                                                ? _c("qs-button", {
                                                    attrs: {
                                                      color: "warning",
                                                      icon: "mdi-link-off",
                                                      label:
                                                        "Retirer la réservation",
                                                      xSmall: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeExchangeReservation(
                                                          contact
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2401924158
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3246436398
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("call-campaign-users-modal", {
        attrs: {
          id: _vm.id,
          "modal-instance-id": _vm.currentInstanceId,
          value: _vm.showCallCampaignUsersModal
        },
        on: {
          "update:value": function($event) {
            _vm.showCallCampaignUsersModal = $event
          },
          input: function($event) {
            _vm.showCallCampaignUsersModal = $event
          },
          added: function($event) {
            return _vm.reloadDataRoutesData(["users.index"])
          }
        }
      }),
      _c("add-single-contact-modal", {
        attrs: {
          value: _vm.showAddSingleContactModal,
          loading: _vm.addSingleContactModalLoading
        },
        on: {
          "update:value": function($event) {
            _vm.showAddSingleContactModal = $event
          },
          add: _vm.addSingleContactToCallCampaign
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Ajouter des destinataires ")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [_vm._v(" à une campagne d'appels ")]
            },
            proxy: true
          },
          {
            key: "prepend",
            fn: function() {
              return [
                _c(
                  "v-alert",
                  {
                    staticClass: "body-2",
                    attrs: { border: "left", outlined: "", tile: "" }
                  },
                  [
                    _vm._v(
                      " Vous pouvez aussi ajouter des destinataires en masse à partir de votre "
                    ),
                    _c("router-link", { attrs: { to: "/contacts" } }, [
                      _vm._v("liste de contacts")
                    ]),
                    _vm._v(" via "),
                    _c("strong", [
                      _vm._v(
                        "Actions > Ajouter comme destinataires à une campagne d'appels"
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.stats && _vm.stats.leaderboard
        ? _c("call-campaign-leaderboard", {
            attrs: { users: _vm.stats.leaderboard },
            model: {
              value: _vm.showLeaderboard,
              callback: function($$v) {
                _vm.showLeaderboard = $$v
              },
              expression: "showLeaderboard"
            }
          })
        : _vm._e(),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.dialog.value,
          color: _vm.dialog.color,
          icon: _vm.dialog.icon,
          loading: _vm.loading,
          message: _vm.dialog.message,
          title: _vm.dialog.title
        },
        on: {
          "click:cancel": function() {
            return (_vm.dialog.value = false)
          },
          "click:confirm": _vm.dialog.callback
        }
      }),
      _c("qs-confirmation-modal", {
        attrs: {
          loading: _vm.deleteContactModal.loading,
          message: _vm.deleteContactModal.contact
            ? _vm.deleteContactModal.contact.full_name +
              " (" +
              _vm.deleteContactModal.contact.v1_contact_id +
              ")"
            : "",
          title:
            "Êtes-vous sûr de vouloir retirer ce contact de cette campagne?"
        },
        on: {
          "click:cancel": function($event) {
            _vm.deleteContactModal.visible = false
          },
          "click:confirm": _vm.deleteCallCampaignContact
        },
        model: {
          value: _vm.deleteContactModal.visible,
          callback: function($$v) {
            _vm.$set(_vm.deleteContactModal, "visible", $$v)
          },
          expression: "deleteContactModal.visible"
        }
      }),
      _c("qs-confirmation-modal", {
        attrs: {
          loading: _vm.deleteUserModal.loading,
          message: _vm.deleteUserModal.user
            ? _vm.deleteUserModal.user.contact_name +
              " (" +
              _vm.deleteUserModal.user.email +
              ")"
            : "",
          title:
            "Êtes-vous sûr de vouloir retirer ce·tte appelant·e de cette campagne?"
        },
        on: {
          "click:cancel": function($event) {
            _vm.deleteUserModal.visible = false
          },
          "click:confirm": _vm.deleteCallCampaignUser
        },
        model: {
          value: _vm.deleteUserModal.visible,
          callback: function($$v) {
            _vm.$set(_vm.deleteUserModal, "visible", $$v)
          },
          expression: "deleteUserModal.visible"
        }
      }),
      _c(
        "qs-confirmation-modal",
        {
          attrs: {
            color: "warning",
            "confirm-text": "Poursuivre quand même",
            icon: "mdi-alert-circle",
            value: _vm.showFormDefinitionWarningModal
          },
          on: {
            "click:cancel": function($event) {
              _vm.showFormDefinitionWarningModal = false
            },
            "click:confirm": function($event) {
              ;(_vm.userWarnedAboutFormDefinition = true),
                (_vm.showFormDefinitionWarningModal = false)
            }
          }
        },
        [
          _vm._v(
            " Modifier les questions d'une campagne active pourrait avoir un impact sur le calcul des statistiques."
          ),
          _c("br"),
          _vm._v(" Voulez-vous continuer? ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }