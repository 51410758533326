import axios from 'axios';
import { Module } from 'vuex';

import { RootState } from 'qs_vuetify/src/types/states';

import { ElectionResultsViewState } from '@/types/states';

export const electionResultsViewState: Module<ElectionResultsViewState, RootState> = {
  namespaced: true,
  actions: {
    async districts({ commit }, params) {
      commit('loaded', false);

      const ajax = axios.get('/districts', {
        params: {
          fields: 'name,elections_with_results.name,elections_with_results.district_election_id',
          order: 'name',
          per_page: '*',
          ...params,
        },
      });

      const {
        data: {
          data: districts,
        },
      } = await ajax;

      commit('districts', districts);
      commit('loaded', true);
    },
  },
  state: {
    activeMapStyle: 'votes__valid_ballots',
    activeStatsStyle: 'electors_count',
    currentDistrictId: null,
    districts: [],
    fillOpacity: 0.75,
    loaded: false,
    showPolygonLabels: true,
    statsColor: '#333132',
    zoomOnClick: true,
  },
  getters: {
    activeMapStyle(state) {
      return state.activeMapStyle;
    },
    activeStatsStyle(state) {
      return state.activeStatsStyle;
    },
    currentDistrictId(state) {
      return state.currentDistrictId;
    },
    currentDistrict(state) {
      if (state.districts.length > 0 && state.currentDistrictId) {
        return state.districts.find((d) => d.id === state.currentDistrictId);
      }

      return null;
    },
    districts(state) {
      return state.districts;
    },
    fillOpacity(state) {
      return state.fillOpacity;
    },
    loaded(state) {
      return state.loaded;
    },
    showPolygonLabels(state) {
      return state.showPolygonLabels;
    },
    statsColor(state) {
      return state.statsColor;
    },
    zoomOnClick(state) {
      return state.zoomOnClick;
    },
  },
  mutations: {
    activeMapStyle(state, payload) {
      state.activeMapStyle = payload;
    },
    activeStatsStyle(state, payload) {
      state.activeStatsStyle = payload;
    },
    currentDistrictId(state, payload) {
      state.currentDistrictId = payload;
    },
    districts(state, payload) {
      state.districts = payload;
    },
    fillOpacity(state, payload) {
      state.fillOpacity = payload;
    },
    loaded(state, payload) {
      state.loaded = payload;
    },
    showPolygonLabels(state, payload) {
      state.showPolygonLabels = payload;
    },
    statsColor(state, payload) {
      state.statsColor = payload;
    },
    zoomOnClick(state, payload) {
      state.zoomOnClick = payload;
    },
  },
};

export default electionResultsViewState;
