var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mail-campaign", attrs: { fluid: "" } },
    [
      _c("item-navigation", {
        staticClass: "my-0",
        attrs: {
          callback: _vm.loadNextPage,
          item: _vm.item,
          items: _vm.data,
          loading: _vm.loading,
          "model-name": _vm.slug,
          total: _vm.total
        }
      }),
      _vm.itemReady
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c(
                    "qs-card",
                    {
                      staticStyle: { "min-height": "100%" },
                      scopedSlots: _vm._u(
                        [
                          _vm.item && _vm.item.stats
                            ? {
                                key: "title",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.item.stats.sent_emails > 0
                                            ? "Statistiques"
                                            : "Configuration"
                                        ) +
                                        " "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null,
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("v-divider", {
                                  staticClass: "mb-2",
                                  staticStyle: { "border-color": "qs-grey" }
                                }),
                                _c(
                                  "v-list",
                                  { staticClass: "mt-0 pt-0" },
                                  [
                                    _vm.item && _vm.item.created_by_user
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(" Créé par "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.item.created_by_user
                                                          .contact_name
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          _vm.item.created_at
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.item &&
                                    _vm.item.updated_by_user &&
                                    _vm.item.updated_at !== _vm.item.created_at
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(" Modifié par "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.item.updated_by_user
                                                          .contact_name
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          _vm.item.updated_at
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.item && _vm.item.deleted_by_user
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(" Archivé par "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.item.deleted_by_user
                                                          .contact_name
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          _vm.item.deleted_at
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.item && _vm.item.sent_by_user
                                      ? _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(" Envoyé par "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.item.sent_by_user
                                                          .contact_name
                                                      )
                                                    )
                                                  ])
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          _vm.item.sent_at
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm.item &&
                      _vm.item.stats &&
                      _vm.item.stats.sent_emails > 0
                        ? [
                            _vm.item.stats
                              ? _c("qs-mail-campaigns-progress", {
                                  attrs: { data: _vm.item.stats }
                                })
                              : _vm._e()
                          ]
                        : _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              filled: "",
                              form: _vm.form,
                              order: ["name"],
                              item: _vm.item,
                              "model-name": _vm.slug
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _vm.item
                    ? _c("qs-card", {
                        staticStyle: { "min-height": "100%" },
                        scopedSlots: _vm._u(
                          [
                            _vm.item.status === "completed"
                              ? {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c("qs-sent-email", {
                                        attrs: {
                                          item: _vm.item,
                                          total: _vm.contactsTotal
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              : {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("qs-form-builder", {
                                        attrs: {
                                          error: _vm.error,
                                          filled: "",
                                          form: _vm.form,
                                          order: ["subject", "body"],
                                          item: _vm.item,
                                          "model-name": _vm.slug
                                        },
                                        on: { input: _vm.syncItem },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function(ref) {
                                                var errorMessagesFor =
                                                  ref.errorMessagesFor
                                                var item = ref.item
                                                return [
                                                  _c("qs-html-editor", {
                                                    attrs: {
                                                      errors: errorMessagesFor(
                                                        "body"
                                                      ),
                                                      loading: _vm.loading
                                                    },
                                                    model: {
                                                      value: item.body,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "body",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.body"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          495745702
                                        )
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { height: "40vh" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          ),
      _vm.itemReady && _vm.userHas("MAIL_CAMPAIGN_CONTACTS_RETRIEVE")
        ? _c(
            "v-row",
            {
              staticClass: "mail-campaign__contacts mt-4",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c("qs-filters", {
                                attrs: {
                                  "active-filters": _vm.contactsParams,
                                  "filters-definition": _vm.filtersDefinition,
                                  "keep-open": "",
                                  "model-name": "campaign_contacts",
                                  order: _vm.filtersOrder,
                                  theme: "labase"
                                },
                                on: { input: _vm.updateContactsFilters }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      987124723
                    )
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "9" } },
                [
                  _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Destinataires "),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "qs-button",
                                              _vm._b(
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    small: "",
                                                    theme: "labase"
                                                  },
                                                  on: { click: on.click }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml-2 mr-2" },
                                                  [_vm._v("Actions")]
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-menu-down")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1030596857
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsCsv }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (CSV) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { link: "" },
                                          on: { click: _vm.exportContactsXls }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Exporter (XLS) ")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-export")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            link: "",
                                            disabled:
                                              _vm.item.status === "completed"
                                          },
                                          on: {
                                            click: _vm.confirmEmptyContacts
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " Vider la liste des destinataires "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.userHas("MAIL_CAMPAIGN_CONTACTS_CREATE") &&
                              _vm.item.status !== "completed"
                                ? _c(
                                    "qs-button",
                                    {
                                      attrs: { small: "", theme: "labase" },
                                      on: {
                                        click: function($event) {
                                          _vm.showAddSingleContactModal = true
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "text",
                          fn: function() {
                            return [
                              _c("qs-data-table", {
                                staticClass: "mail-campaign__contacts__table",
                                attrs: {
                                  actions: ["view", "delete"],
                                  headers: _vm.contactsHeaders,
                                  "item-class": _vm.getDataTableItemClass,
                                  items: _vm.contacts,
                                  loading:
                                    !_vm.routeDataLoaded || _vm.contactsLoading,
                                  options: _vm.contactsOptions,
                                  "set-options": _vm.setContactsOptions,
                                  total: _vm.contactsTotal || 0
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          _c("qs-button", {
                                            staticClass: "mr-3",
                                            attrs: {
                                              color: "info",
                                              icon: "mdi-magnify",
                                              label:
                                                "Consulter la fiche du contact",
                                              xSmall: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goTo($event, {
                                                  name: "ContactForm",
                                                  params: { id: contact.id }
                                                })
                                              }
                                            }
                                          }),
                                          _vm.userHas(
                                            "MAIL_CAMPAIGN_CONTACTS_DELETE"
                                          )
                                            ? _c("qs-button", {
                                                attrs: {
                                                  color: "error",
                                                  disabled:
                                                    _vm.item.status ===
                                                    "completed",
                                                  icon: "mdi-delete",
                                                  label:
                                                    _vm.item.status ===
                                                    "completed"
                                                      ? "Impossible de supprimer un·e destinataire pour un courriel déjà envoyé"
                                                      : "Supprimer le·la destinataire",
                                                  xSmall: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteMailCampaignContact(
                                                      contact
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.full_name",
                                      fn: function(ref) {
                                        var contact = ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(contact.full_name) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " (" + _vm._s(contact.email) + ") "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.trackable_sent_email.sent",
                                      fn: function(ref) {
                                        var trackable_sent_email =
                                          ref.item.trackable_sent_email
                                        return [
                                          _c("qs-boolean-indicator", {
                                            attrs: {
                                              value: !!trackable_sent_email
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.trackable_sent_email.opened",
                                      fn: function(ref) {
                                        var trackable_sent_email =
                                          ref.item.trackable_sent_email
                                        return [
                                          trackable_sent_email
                                            ? _c("qs-boolean-indicator", {
                                                attrs: {
                                                  value:
                                                    trackable_sent_email.opened
                                                }
                                              })
                                            : _c("qs-boolean-indicator", {
                                                attrs: { value: null }
                                              })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.trackable_sent_email.clicked",
                                      fn: function(ref) {
                                        var trackable_sent_email =
                                          ref.item.trackable_sent_email
                                        return [
                                          trackable_sent_email
                                            ? _c("qs-boolean-indicator", {
                                                attrs: {
                                                  value:
                                                    trackable_sent_email.clicked
                                                }
                                              })
                                            : _c("qs-boolean-indicator", {
                                                attrs: { value: null }
                                              })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.trackable_sent_email.bounced",
                                      fn: function(ref) {
                                        var trackable_sent_email =
                                          ref.item.trackable_sent_email
                                        return [
                                          trackable_sent_email &&
                                          trackable_sent_email.bounced
                                            ? _c("qs-boolean-indicator", {
                                                attrs: { value: false }
                                              })
                                            : _c("qs-boolean-indicator", {
                                                attrs: { value: null }
                                              })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3528136830
                                )
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3273634230
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("add-single-contact-modal", {
        attrs: {
          value: _vm.showAddSingleContactModal,
          loading: _vm.addSingleContactModalLoading
        },
        on: {
          "update:value": function($event) {
            _vm.showAddSingleContactModal = $event
          },
          add: _vm.addSingleContactToMailCampaign
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Ajouter des destinataires ")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [_vm._v(" à une campagne courriels ")]
            },
            proxy: true
          },
          {
            key: "prepend",
            fn: function() {
              return [
                _c(
                  "v-alert",
                  {
                    staticClass: "body-2",
                    attrs: { border: "left", outlined: "", tile: "" }
                  },
                  [
                    _vm._v(
                      " Vous pouvez aussi ajouter des destinataires en masse à partir de votre "
                    ),
                    _c("router-link", { attrs: { to: "/contacts" } }, [
                      _vm._v("liste de contacts")
                    ]),
                    _vm._v(" via "),
                    _c("strong", [
                      _vm._v(
                        "Actions > Ajouter comme destinataires à une campagne courriels"
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.dialog.value,
          color: _vm.dialog.color,
          icon: _vm.dialog.icon,
          loading: _vm.loading || _vm.dialog.loading,
          message: _vm.dialog.message,
          title: _vm.dialog.title
        },
        on: {
          "click:cancel": function() {
            return (_vm.dialog.value = false)
          },
          "click:confirm": _vm.dialog.callback
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }