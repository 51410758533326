import { render, staticRenderFns } from "./SubdivisionResults.vue?vue&type=template&id=0e143c55&"
import script from "./SubdivisionResults.vue?vue&type=script&lang=ts&"
export * from "./SubdivisionResults.vue?vue&type=script&lang=ts&"
import style0 from "./SubdivisionResults.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VDataTable,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e143c55')) {
      api.createRecord('0e143c55', component.options)
    } else {
      api.reload('0e143c55', component.options)
    }
    module.hot.accept("./SubdivisionResults.vue?vue&type=template&id=0e143c55&", function () {
      api.rerender('0e143c55', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ElectionResults/SubdivisionResults.vue"
export default component.exports