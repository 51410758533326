














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SingleLineInfo extends Vue {
  @Prop({ required: false, type: String, default: '' }) label!: string;
  @Prop({ required: false, type: [Number, String], default: undefined }) value?: number | string;
}
