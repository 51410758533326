var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { value: _vm.commentsModal },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "v-icon",
                { staticClass: "mr-2 qs-orange--text", attrs: { large: "" } },
                [_vm._v("mdi-comment")]
              ),
              _c("span", [_vm._v("Ajouter un commentaire")])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.comment.length < 3,
                    theme: "labase"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-textarea", {
        attrs: { "item-value": "comment" },
        model: {
          value: _vm.comment,
          callback: function($$v) {
            _vm.comment = $$v
          },
          expression: "comment"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }