import { render, staticRenderFns } from "./SubdivisionResultsMap.vue?vue&type=template&id=aef2ba12&"
import script from "./SubdivisionResultsMap.vue?vue&type=script&lang=ts&"
export * from "./SubdivisionResultsMap.vue?vue&type=script&lang=ts&"
import style0 from "./SubdivisionResultsMap.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VBtnToggle,VChip,VDialog,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VOverlay,VProgressCircular,VSelect,VSlider,VSpacer,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aef2ba12')) {
      api.createRecord('aef2ba12', component.options)
    } else {
      api.reload('aef2ba12', component.options)
    }
    module.hot.accept("./SubdivisionResultsMap.vue?vue&type=template&id=aef2ba12&", function () {
      api.rerender('aef2ba12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ElectionResults/SubdivisionResultsMap.vue"
export default component.exports