









import Component, { mixins } from 'vue-class-component';
import axios from 'axios';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { AppNotification } from 'qs_vuetify/src/types/components';

const global: any = namespace('global');

@Component
export default class Redirect extends mixins(AuthenticationMixin, DataRouteGuards) {
  @Prop({ type: String, required: true }) field!: string;
  @Prop({ type: String, required: true }) model!: string;
  @Prop({ type: String, required: true }) value!: string;

  @global.Mutation addNotification!: (arg: AppNotification) => void;

  async mounted() {
    if (!this.userIsConnected) {
      this.$router.replace(`/?r=${encodeURIComponent(this.$route.fullPath)}`);
      return;
    }

    if (!this.field || !this.model || !this.value) {
      this.$router.push('/');
    }

    switch (this.model) {
      case 'contacts':
        switch (this.field) {
          case 'v1_contact_id':
          case 'id':
            try {
              const { data: { data } } = await axios.get(`/contacts?${this.field}=${this.value}&fields=id`);
              if (data.length > 0) {
                this.$router.replace(`/contacts/${data[0].id}`);
                return;
              }
            } catch (e) {
              // Noop
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    this.addNotification({
      color: 'warning',
      message: "Le raccourci n'est pas valide.",
    });
    this.$router.push('/');
  }
}
