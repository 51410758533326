import { Module } from 'vuex';

import { Revision } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { RevisionsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: RevisionsRestState = buildRestState<Revision>('revisions', {});

export const getters = buildRestGetters<Revision, RevisionsRestState>();

export const actions = buildRestActions<Revision, RevisionsRestState>();

export const mutations = buildRestMutations<Revision, RevisionsRestState>();

export const revisions: Module<RevisionsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
