import axios from 'qs_vuetify/src/plugins/axios';
import { ActionTree, Module } from 'vuex';

import { RootState } from 'qs_vuetify/src/types/states';

import { ContactEventsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import { ContactEvent } from 'qs_vuetify/src/types/models';

export const storeState: ContactEventsRestState = buildRestState<ContactEvent>('contact_events', {});

export const getters = buildRestGetters<ContactEvent, ContactEventsRestState>();

export const actions: ActionTree<ContactEventsRestState, RootState> = {
  ...buildRestActions<ContactEvent, ContactEventsRestState>(),
  async patch(
    { commit, state },
    { id, field, value }: { id: number; field: string; value: any },
  ) {
    try {
      await axios.put(`/${state.slug}/${id}`, {
        [field]: value,
      });
    } catch (err) {
      if (err.response && err.response.data) {
        commit('error', err.response.data);
      }
      throw err;
    }
  },
};

export const mutations = buildRestMutations<ContactEvent, ContactEventsRestState>();

export const contact_events: Module<ContactEventsRestState, RootState> = {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
