var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "polling-sectors-autocomplete" },
    [
      _vm.visible
        ? _c("qs-relation-field", {
            attrs: {
              disabled: _vm.disabled,
              item: _vm.dummyItem,
              label: "Assigner à un secteur",
              loading: _vm.loading,
              name: "polling_sectors",
              outlined: "",
              store: _vm.relationDefinition
            },
            on: {
              "update:search-input": function($event) {
                _vm.persistedPollingSectorSearchInput = $event
              },
              input: _vm.addPollingSector
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var polling_sector = ref.item
                    return [
                      _c("span", [_vm._v(_vm._s(polling_sector.name))]),
                      (polling_sector.instance &&
                        polling_sector.instance.name) ||
                      polling_sector.isntance
                        ? _c("span", { staticClass: "ml-1" }, [
                            _vm._v(
                              " (" + _vm._s(polling_sector.instance.name) + ") "
                            )
                          ])
                        : polling_sector.instance_name
                        ? _c("span", { staticClass: "ml-1" }, [
                            _vm._v(
                              " (" + _vm._s(polling_sector.instance_name) + ") "
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "no-data",
                  fn: function() {
                    return [
                      !_vm.loading
                        ? _c("p", { staticClass: "caption text-center" }, [
                            _vm._v("Aucun secteur correspondante")
                          ])
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            loading: _vm.newPollingSectorLoading || _vm.loading,
                            text: "",
                            tile: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.createThenAddPollingSector(
                                _vm.persistedPollingSectorSearchInput
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " Créer le secteur « " +
                              _vm._s(_vm.persistedPollingSectorSearchInput) +
                              " » "
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2014410406
            ),
            model: {
              value: _vm.persistedPollingSectorToAdd,
              callback: function($$v) {
                _vm.persistedPollingSectorToAdd = $$v
              },
              expression: "persistedPollingSectorToAdd"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }