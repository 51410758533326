



























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, PropSync, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsToggleField from 'qs_vuetify/src/components/Fields/QsToggleField.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import {
  PersistedCallCampaign as CallCampaign,
  PersistedContact as Contact,
  User,
} from 'qs_vuetify/src/types/models';

import axios from 'qs_vuetify/src/plugins/axios';

import ContactListItem from '@/components/ContactListItem.vue';

const global: any = namespace('global');
const store: any = namespace('call_campaigns');

@Component({
  components: {
    ContactListItem,
    QsActionModal,
    QsButton,
    QsToggleField,
    QsRelationField,
  },
})
export default class CallCampaignUsersModal extends mixins(AuthenticationMixin) {
  @global.Mutation addNotification!: Function;
  @store.Getter item!: CallCampaign;

  @Prop({ required: true, type: [Number, String] }) id!: number | string;
  @Prop({ required: true, type: [Number, String] }) modalInstanceId!: number | string;
  @PropSync('value', { type: Boolean }) syncedValue!: boolean;

  addAsVolunteer: boolean = false;
  contact: Contact | null = null;
  errorMessage: string = '';
  isLoading: boolean = false;
  searchOnlyOnVolunteers: boolean = true;
  modalUser: User | null = null;

  get queryDefinition(): any {
    const query: Record<string, any> = {
      key: 'data',
      slug: 'contacts',
      text: 'searchable_text',
      value: 'id',
      params: {
        fields: [
          'district',
          'email',
          'full_name',
          'status',
          'searchable_text',
          'user.id',
          'v1_contact_id',
        ].join(','),
        order: 'last_name,first_name,v1_contact_id',
        per_page: 10,
        prefix: '',
      },
    };

    if (this.searchOnlyOnVolunteers) {
      query.params.has_roles = `${this.modalInstanceId};;;true`;
    }

    return query;
  }

  get errors(): string[] {
    if (this.errorMessage) {
      return [this.errorMessage];
    }

    return [];
  }

  @Watch('contact')
  onContactChanged(contact: Contact): void {
    this.errorMessage = '';

    if (contact?.user?.id) {
      this.modalUser = contact.user;
    } else if (contact?.email) {
      this.createUser(contact);
    } else if (contact) {
      this.errorMessage = "Ce contact n'a pas de courriel. Ajoutez un courriel au contact pour l'ajouter"
        + " en tant qu'appelant·e sur cette campagne d'appels.";
    } else {
      this.modalUser = null;
    }
  }

  async createUser(contact: Contact) {
    try {
      const { data: user } = await axios.post('users', {
        contact_id: contact.id,
        email: contact.email,
      }, {
        params: {
          fields: '*',
        },
      });

      this.modalUser = user;
    } catch (e) {
      this.errorMessage = "Ce contact n'a pas d'utilisateur associé"
        + " et vous n'avez pas les permissions requises pour le créer. Pour utiliser"
        + " les outils de Québec solidaire, il faut s'être connecté au moins une fois sur"
        + ' Profil ou La Base, ou avoir un compte utilisateur valide.';
    }
  }

  async putCallCampaignUser(): Promise<void> {
    this.isLoading = true;

    if (this.contact && this.modalUser && this.modalUser.id) {
      try {
        await axios.put(`/call_campaigns/${this.id}/users/${this.modalUser.id}`, {}, {
          params: {
            fields: 'description,instance.name,name',
          },
        });

        this.$emit('added');

        if (this.addAsVolunteer) {
          await axios.put(`/instances/${this.modalInstanceId}/volunteers/${this.contact.id}`, {}, {
            params: {
              fields: 'id,contact_id',
            },
          });

          this.syncedValue = false;

          this.addNotification({
            color: 'success',
            message: `${this.contact.full_name} a été ajouté·e aux appelant·es et aux militant·es de l'instance.`,
          });
        } else {
          this.syncedValue = false;

          this.addNotification({
            color: 'success',
            message: `${this.contact.full_name} a été ajouté·e aux appelant·es.`,
          });
        }
      } catch (e) {
        this.addNotification({
          color: 'error',
          message: `Erreur lors de l'ajout de ${this.contact.full_name} aux appelant·es.`,
        });
      }
    }
    this.isLoading = false;
  }

  @Watch('searchOnlyOnVolunteers')
  onSearchOnlyOnVolunteersChanged() {
    this.contact = null;
  }
}
