
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { PollingSubdivision } from 'qs_vuetify/src/types/models';

import { formatNumber } from '@/helpers';

@Component({
  filters: {
    percent(n: number) {
      const n100 = n * 100;
      const s = new Intl.NumberFormat('fr-Ca', { maximumFractionDigits: 2, style: 'percent' }).format(n100);

      return `${s} %`;
    },
  },
})
export default class TurnoutData extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) outlined!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) large!: boolean;
  @Prop({ required: true, type: Object }) item!: PollingSubdivision | any;

  formatNumber = formatNumber;
}
