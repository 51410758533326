import callCampaignsViewState from './callCampaignsViewState';
import contactsViewState from './contactsViewState';
import electionResultsViewState from './electionResultsViewState';
import eventsViewState from './eventsViewState';
import globalViewState from './globalViewState';
import mailCampaignsViewState from './mailCampaignsViewState';
import tagsViewState from './tagsViewState';
import volunteersViewState from './volunteersViewState';

const states = {
  callCampaignsViewState,
  contactsViewState,
  electionResultsViewState,
  eventsViewState,
  globalViewState,
  mailCampaignsViewState,
  tagsViewState,
  volunteersViewState,
};

export default states;
