var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { class: "breakpoint-" + _vm.$vuetify.breakpoint.name },
    [
      _c("qs-layout-navigation", {
        attrs: {
          "admin-menu": _vm.adminMenuItems,
          help: _vm.routeHelp,
          instances: _vm.userInstances,
          "lock-instance": _vm.lockInstance,
          "primary-menu": _vm.primaryMenuItems,
          "version-link":
            "https://docs.google.com/document/d/1kZIBfWtRB1lb4nl_7VOD1pQkkMG7yejJJ-nkr6FR7aE/edit?usp=sharing",
          "version-number": _vm.version
        },
        on: {
          acceptedConfidentialityAgreement: _vm.redirectOrReload,
          instance: _vm.setInstanceId,
          login: _vm.loadUserAndSetInitialInstance,
          logout: _vm.redirectToHome
        },
        scopedSlots: _vm._u([
          {
            key: "loginDialogLinks",
            fn: function() {
              return [
                _c(
                  "a",
                  {
                    staticClass: "white--text",
                    attrs: { href: _vm.newPasswordLink }
                  },
                  [_vm._v(" Mot de passe oublié? ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-main",
        [
          _c("qs-layout-notifications"),
          _c("router-view"),
          _c(
            "qs-confirmation-modal",
            _vm._b(
              {
                on: {
                  "click:confirm": _vm.runConfirmationDialogCallback,
                  "click:cancel": _vm.resetConfirmationDialog
                }
              },
              "qs-confirmation-modal",
              _vm.confirmationDialog,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }