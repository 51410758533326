var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "new-contact-form" },
    [
      _c(
        "v-row",
        [
          _vm.itemReady && _vm.item
            ? _c(
                "v-col",
                [
                  _c("qs-profile-form", {
                    attrs: {
                      error: _vm.error,
                      value: _vm.item,
                      "allow-delete-email": _vm.userIsSuperadmin
                    },
                    on: {
                      input: _vm.syncItem,
                      valid: function($event) {
                        _vm.formValid = $event
                      }
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("qs-district-form", {
                            attrs: {
                              theme: "filled",
                              "postal-code": _vm.item.postal_code
                            },
                            model: {
                              value: _vm.item.district,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "district", $$v)
                              },
                              expression: "item.district"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { height: "70vh" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }