var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { "content-class": "leaderboard", value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Palmarès des appels ")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.users.length) +
                  " appelant·es ont complété des appels "
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.users, function(user, index) {
          return _c(
            "v-list-item",
            { key: "user_" + user.email },
            [
              _c(
                "v-list-item-avatar",
                [
                  index === 0
                    ? _c("v-icon", { attrs: { color: "warning" } }, [
                        _vm._v("mdi-trophy")
                      ])
                    : index === 1
                    ? _c("v-icon", { attrs: { color: "grey" } }, [
                        _vm._v("mdi-trophy")
                      ])
                    : index === 2
                    ? _c(
                        "v-icon",
                        { attrs: { color: "deep-orange darken-4" } },
                        [_vm._v("mdi-trophy")]
                      )
                    : _c("v-icon", [_vm._v("mdi-rocket-launch")])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(user.contact_name) + " ")
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(user.contact_exchanges_count) +
                        " appels complétés "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }