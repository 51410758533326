














































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import NavigationButton from '@/components/ElectionResults/NavigationButton.vue';
import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { District, DistrictElection } from 'qs_vuetify/src/types/models';

const districtElections = namespace('district_elections');
const electionResultsView = namespace('electionResultsView');

@Component({
  components: {
    NavigationButton,
    QsBaseLayout,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ElectionResultsDashboard extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @electionResultsView.Getter currentDistrict!: District;
  @electionResultsView.Getter districts!: District[];
  @electionResultsView.Getter districtsLoaded!: boolean;
  @districtElections.Getter('item') districtElection!: DistrictElection;

  get districtId(): number | null {
    return this.$store.getters['electionResultsView/currentDistrictId'];
  }

  set districtId(value: number | null) {
    this.$store.commit('electionResultsView/currentDistrictId', value);
  }

  @Watch('districtElection', { deep: true })
  onDistrictElectionChanged(newItem: any) {
    if (newItem) {
      this.districtId = newItem.district.id;
    }
  }

  @Watch('districtId')
  onDistrictIdChanged() {
    this.setGlobalSubtitle();
    this.$store.commit('district_elections/item', null);
    if (this.$route.name !== 'ElectionResultsDashboard') {
      this.$router.push('/elections');
    }
  }

  setGlobalSubtitle() {
    if (this.routeDataLoaded && this.currentDistrict) {
      this.$store.commit('global/subtitle', this.currentDistrict.name);
    }
    this.$emit('updateHead');
  }
}
