import Vue from 'vue';
import { RouteConfig } from 'vue-router';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

const TagsForm = () => import(/* webpackChunkName: "TagsForm" */'@/views/Tags/TagsForm.vue');
const Tags = () => import(/* webpackChunkName: "Tags" */'@/views/Tags/Tags.vue');

const tags: RouteConfig[] = [
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: {
      data: {
        instances: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.instances.lastLoadedAt;
            },
            params: {
              fields: 'name',
              order: 'name',
              per_page: '*',
            },
          },
        },
        tags: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.tags.lastLoadedAt;
            },
            contextual(vm: Vue) {
              const { params } = vm.$store.state.tagsView;
              return {
                ...ListMixin.buildListState(params),
              };
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$store.state.auth.instanceId}`;
            },
          },
        },
      },
      menus: ['primary'],
      requires: ['TAGS_RETRIEVE'],
      title: 'Étiquettes',
    },
    children: [
      {
        path: ':id',
        name: 'TagsForm',
        component: TagsForm,
        props: true,
        meta: {
          data: {
            instances: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instances.lastLoadedAt;
                },
                params: {
                  fields: 'name',
                  order: 'name',
                  per_page: '*',
                },
              },
            },
            tags: {
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.tags.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { params } = vm.$store.state.tagsView;
                  return {
                    ...ListMixin.buildListState(params),
                  };
                },
                prefix(vm: Vue) {
                  return `/instances/${vm.$store.state.auth.instanceId}`;
                },
              },
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
              },
            },
          },
          menus: [],
          name: 'Nouvelle étiquette',
        },
      },
    ],
  },
];

export default tags;
