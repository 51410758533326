export { default as completeMembership } from './completeMembership';
export { default as mergeInstanceIdParams } from './mergeInstanceIdParams';
export { default as hexToHSL } from './colorUtils';

export const formatNumber = function formatNumber(value: string | number) {
  const n = typeof value === 'string' ? parseFloat(value.replace(' ', '')) : value;

  if (n > 0 && n < 1) {
    const options = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    };

    return new Intl.NumberFormat('fr-Ca', options).format(n);
  }

  return new Intl.NumberFormat('fr-CA').format(n);
};
