var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mail-campaigns", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3", cols: "12" } },
            [
              _c("qs-filters", {
                staticClass: "mail-campaigns__filters sticky",
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.activeFilters,
                  "model-name": _vm.slug,
                  order: ["status", "with_deleted"],
                  theme: "labase"
                },
                on: { input: _vm.updateFilters }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "9", cols: "12" } },
            [
              _c("qs-list", {
                attrs: {
                  error: _vm.error,
                  items: _vm.items,
                  loading: _vm.loading,
                  "model-name": "mail_campaigns",
                  selection: _vm.selectedItems,
                  theme: "labase",
                  total: _vm.total
                },
                on: { load: _vm.loadNextPage, select: _vm.selectAll },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("mail-campaign-card", {
                          attrs: {
                            href: _vm.$router.resolve({
                              name: "MailCampaign",
                              params: { id: item.id }
                            }).href,
                            item: item,
                            selected: _vm.isSelected(item),
                            "show-text": _vm.showExtraInformation
                          },
                          on: {
                            check: function($event) {
                              return _vm.updateSelectedItems($event, item)
                            },
                            click: function($event) {
                              return _vm.resetViewOptionsAndNavigateToMailCampaign(
                                $event,
                                item.id
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "settings",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("v-switch", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    label:
                                      "Afficher les informations supplémentaires"
                                  },
                                  model: {
                                    value: _vm.showExtraInformation,
                                    callback: function($$v) {
                                      _vm.showExtraInformation = $$v
                                    },
                                    expression: "showExtraInformation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.exportCsv }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Exporter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(" (CSV) ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-file-export")])],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                attrs: { link: "" },
                                on: { click: _vm.exportXls }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Exporter "),
                                      _vm.selectedItems.length
                                        ? _c("span", [_vm._v(" la sélection")])
                                        : _vm._e(),
                                      _vm._v(" (XLS) ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-icon",
                                  [_c("v-icon", [_vm._v("mdi-file-excel")])],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view", { attrs: { "model-name": "mail_campaigns" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }