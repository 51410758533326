var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "navigation-button",
              {
                attrs: {
                  to: {
                    name: "DistrictResult",
                    params: {
                      districtElectionId: _vm.$route.params.districtElectionId
                    }
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "#241a00" } },
                  [_vm._v("mdi-arrow-left")]
                ),
                _vm._v(" Résultats par circonscription ")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m3-card m3-surface pa-4" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "v-btn-toggle",
                      {
                        staticClass: "m3-segmented-button",
                        attrs: { dense: "" },
                        model: {
                          value: _vm.activeMapStyle,
                          callback: function($$v) {
                            _vm.activeMapStyle = $$v
                          },
                          expression: "activeMapStyle"
                        }
                      },
                      [
                        _c("v-btn", { attrs: { value: "votes" } }, [
                          _vm._v("Nombre")
                        ]),
                        _c(
                          "v-btn",
                          { attrs: { value: "votes__valid_ballots" } },
                          [_vm._v("Pourc.")]
                        ),
                        _c("v-btn", { attrs: { value: "winner" } }, [
                          _vm._v("Gagnant")
                        ])
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn-toggle",
                      {
                        staticClass: "m3-segmented-button",
                        attrs: { dense: "" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function($event) {
                                _vm.settingsDialog = true
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-cog")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-select", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !["winner", "selection", "stats"].includes(
                          _vm.activeMapStyle
                        ) && _vm.routeDataLoaded,
                      expression:
                        "!['winner', 'selection', 'stats'].includes(activeMapStyle) && routeDataLoaded"
                    }
                  ],
                  staticClass: "mt-2",
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    outlined: "",
                    items: _vm.parties,
                    label: "Parti à afficher"
                  },
                  model: {
                    value: _vm.activePartyDisplay,
                    callback: function($$v) {
                      _vm.activePartyDisplay = $$v
                    },
                    expression: "activePartyDisplay"
                  }
                }),
                _c("v-select", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        ["stats"].includes(_vm.activeMapStyle) &&
                        _vm.routeDataLoaded,
                      expression:
                        "['stats'].includes(activeMapStyle) && routeDataLoaded"
                    }
                  ],
                  staticClass: "mt-2",
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    outlined: "",
                    items: _vm.availableStatsStyles,
                    label: "Propriété à afficher"
                  },
                  model: {
                    value: _vm.activeStatsStyle,
                    callback: function($$v) {
                      _vm.activeStatsStyle = $$v
                    },
                    expression: "activeStatsStyle"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    !["winner", "selection"].includes(_vm.activeMapStyle) &&
                    _vm.routeDataLoaded
                      ? _c(
                          "color-scale",
                          _vm._b({}, "color-scale", _vm.scaleProps, false)
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm.activeSubdivision
              ? [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeMapStyle === "stats",
                          expression: "activeMapStyle === 'stats'"
                        }
                      ]
                    },
                    [
                      _vm.activeSubdivision.stats
                        ? _c("div", { staticClass: "mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "m3-card m3-surface pa-2" },
                              [
                                _c("h3", { staticClass: "font-weight-bold" }, [
                                  _vm._v("Pointage")
                                ]),
                                _vm._l(_vm.availableStatsStyles, function(ref) {
                                  var text = ref.text
                                  var value = ref.value
                                  return _c(
                                    "div",
                                    { key: value, staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-space-between flex-grow-1",
                                          staticStyle: {
                                            "border-bottom": "1px solid grey"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "body-2" },
                                            [_vm._v(_vm._s(text))]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.autoformatNumber(
                                                  _vm.activeSubdivision.stats,
                                                  value
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeMapStyle !== "stats",
                          expression: "activeMapStyle !== 'stats'"
                        }
                      ]
                    },
                    [
                      _c("segment-results", {
                        staticClass: "mt-2",
                        attrs: {
                          closeable: "",
                          item: _vm.activeSubdivision,
                          "with-title": ""
                        },
                        on: {
                          "click:close": function($event) {
                            _vm.activeSubdivisionId = null
                          }
                        }
                      }),
                      _c("turnout-data", {
                        staticClass: "mt-2 m3-surface",
                        attrs: { item: _vm.activeSubdivision }
                      })
                    ],
                    1
                  )
                ]
              : _vm.activeMapStyle === "selection"
              ? _c(
                  "div",
                  { staticClass: "m3-card m3-surface mt-2 pa-2" },
                  [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedItems.length > 0
                              ? _vm.selectedItems.length
                              : "Aucun"
                          ) +
                          " " +
                          _vm._s(
                            _vm.selectedItems.length > 1
                              ? "bureaux de vote sélectionnés"
                              : "bureau de vote sélectionné"
                          ) +
                          " "
                      )
                    ]),
                    _vm._l(_vm.selectedItems, function(item) {
                      return _c(
                        "v-chip",
                        {
                          key: "selectedItem-" + item.id,
                          staticClass: "mb-2 mr-1",
                          attrs: { color: "#f5ded7", label: "", small: "" }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    _vm.selectedItems.length > 0
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "#ffdad6",
                              block: "",
                              depressed: "",
                              rounded: "",
                              small: ""
                            },
                            on: { click: _vm.clearAllSelected }
                          },
                          [_vm._v(" Tout désélectionner ")]
                        )
                      : _vm._e(),
                    _c("polling-sectors-autocomplete", {
                      staticClass: "mb-n4",
                      attrs: {
                        allowCreate: "",
                        disabled: _vm.selectedItems.length < 1,
                        instanceId: _vm.instanceId,
                        loading: _vm.$store.getters["polling_sectors/loading"]
                      },
                      on: { input: _vm.syncPollingSectors }
                    })
                  ],
                  2
                )
              : _c(
                  "div",
                  { staticClass: "m3-card m3-tertiary-container mt-4 pa-2" },
                  [
                    _c(
                      "p",
                      { staticClass: "body-2 pa-6 text-center" },
                      [
                        _c("v-icon", { attrs: { large: "" } }, [
                          _vm._v("mdi-cursor-default-click")
                        ]),
                        _c("br"),
                        _vm._v(
                          " Cliquez sur une section de vote pour afficher le détail des résultats "
                        )
                      ],
                      1
                    )
                  ]
                ),
            _c(
              "v-dialog",
              {
                attrs: { width: "480" },
                model: {
                  value: _vm.settingsDialog,
                  callback: function($$v) {
                    _vm.settingsDialog = $$v
                  },
                  expression: "settingsDialog"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "m3-card m3-surface--dark pa-4" },
                  [
                    _c(
                      "h4",
                      { staticClass: "headline text-center" },
                      [
                        _c("v-icon", { attrs: { color: "#201a18" } }, [
                          _vm._v("mdi-cog")
                        ]),
                        _c("br"),
                        _vm._v(" Paramètres de la carte ")
                      ],
                      1
                    ),
                    _c("v-divider", { attrs: { color: "#85736e" } }),
                    _c(
                      "v-list",
                      { staticStyle: { "background-color": "transparent" } },
                      [
                        _c(
                          "v-list-item",
                          [
                            _c("v-switch", {
                              attrs: { label: "Afficher les numéros de SV" },
                              model: {
                                value: _vm.showPolygonLabels,
                                callback: function($$v) {
                                  _vm.showPolygonLabels = $$v
                                },
                                expression: "showPolygonLabels"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          [
                            _c("v-switch", {
                              attrs: {
                                label:
                                  "Zoom automatique sur une SV après un clic"
                              },
                              model: {
                                value: _vm.zoomOnClick,
                                callback: function($$v) {
                                  _vm.zoomOnClick = $$v
                                },
                                expression: "zoomOnClick"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          [
                            _c("v-slider", {
                              attrs: {
                                label: "Opacité des polygones",
                                min: 0.2,
                                max: 0.8,
                                step: "0.05"
                              },
                              model: {
                                value: _vm.fillOpacity,
                                callback: function($$v) {
                                  _vm.fillOpacity = $$v
                                },
                                expression: "fillOpacity"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-divider", {
                          attrs: { color: "#85736e", inset: "" }
                        }),
                        _c(
                          "v-list-item",
                          { on: { click: _vm.setSelectionModeOn } },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { color: "#201a18", small: "" }
                                  },
                                  [_vm._v("mdi-cursor-default-click")]
                                )
                              ],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(" Passer en mode sélection ")
                            ])
                          ],
                          1
                        ),
                        _vm.userHas("POINTAGE_ADMIN")
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.activeMapStyle = "stats"
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "#201a18", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "mdi-chart-bell-curve-cumulative"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("v-list-item-content", [
                                  _vm._v(" Passer en mode pointage ")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.onGeometriesChanged(
                                  _vm.map_subdivisions
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { color: "#201a18", small: "" }
                                  },
                                  [_vm._v("mdi-magnify-minus-outline")]
                                )
                              ],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(" Recentrer la carte ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.activeMapStyle === "stats"
                      ? _c("v-divider", { attrs: { color: "#85736e" } })
                      : _vm._e(),
                    _vm.activeMapStyle === "stats"
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                staticClass: "m3-segmented-button",
                                attrs: { dense: "" },
                                model: {
                                  value: _vm.statsColor,
                                  callback: function($$v) {
                                    _vm.statsColor = $$v
                                  },
                                  expression: "statsColor"
                                }
                              },
                              _vm._l(_vm.statsColors, function(color) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: color,
                                    attrs: {
                                      color: color,
                                      icon: "",
                                      value: color
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "stats-color",
                                        attrs: { color: color }
                                      },
                                      [_vm._v("mdi-circle-multiple")]
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-divider", { attrs: { color: "#85736e" } }),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", rounded: "", color: "#201a18" },
                            on: {
                              click: function($event) {
                                _vm.settingsDialog = false
                              }
                            }
                          },
                          [_vm._v(" Fermer ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("div", { staticClass: "m3-card m3-surface" }, [
              _c(
                "div",
                { staticClass: "m3-card__text" },
                [
                  _vm.mapCenter && _vm.mapZoom
                    ? _c("gmap-map", {
                        ref: "mapRef",
                        style: { width: "100%", height: "85vh" },
                        attrs: {
                          center: _vm.mapCenter,
                          "map-type-id": "roadmap",
                          options: {
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false,
                            styles: _vm.mapStyles
                          },
                          zoom: _vm.mapZoom
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "append",
        fn: function() {
          return [
            _c("v-overlay", { attrs: { value: !_vm.routeDataLoaded } }, [
              _c(
                "div",
                {
                  staticClass:
                    "body-1 d-flex flex-column justify-center align-center"
                },
                [
                  _c("v-progress-circular", { attrs: { indeterminate: "" } }),
                  _vm.loading
                    ? [_vm._v(" Chargement des données... ")]
                    : [_vm._v(" Initialisation... ")]
                ],
                2
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }