














































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsField from 'qs_vuetify/src/components/Fields/QsField.vue';

import { Model } from 'qs_vuetify/src/types/models';

type CustomPlace = {
  name: string;
};

type GooglePlaceAddressComponentTypes = 'street_number' | 'route' | 'sublocality_level_1'
  | 'sublocality' | 'locality' | 'political' | 'administrative_area_level_1' | 'country' | 'postal_code';

type GooglePlaceAddressComponent = {
  long_name: string;
  short_name: string;
  types: GooglePlaceAddressComponentTypes[];
}

type GooglePlaceBusinessStatus = 'OPERATIONAL';

type GooglePlaceType = 'point_of_interest' | 'establishment';

type GooglePlaceLocation = {
  lat: () => number;
  lng: () => number;
};

type GooglePlace = {
  address_components: GooglePlaceAddressComponent[];
  adr_address: string;
  business_status: GooglePlaceBusinessStatus;
  formatted_address: string;
  formatted_phone_number: string;
  geometry: {
    location: GooglePlaceLocation;
    viewport: {
      Ab: {
        h: number;
        j: number;
      };
      Va: {
        h: number;
        j: number;
      };
    };
  };
  html_attributions: unknown[];
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  international_phone_number: string;
  name: string;
  opening_hours: any;
  photos: any[];
  place_id: string;
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  rating: number;
  reference: string;
  reviews: any[];
  types: GooglePlaceType[];
  url: string;
  user_ratings_total: number;
  utc_offset: number;
  utc_offset_minutes: number;
  vicinity: string;
  website: string;
};

type Point = {
  lat: number;
  lng: number;
};

function isGooglePlace(place: any): place is GooglePlace {
  return (place as GooglePlace).geometry !== undefined;
}

@Component
export default class LocationField extends QsField {
  @Prop({ required: true, type: Object }) item!: Model;

  searchInput: string = '';

  get center(): Point {
    if (this.item.location?.point) {
      if (Array.isArray(this.item.location?.point)) {
        const [lat, lng] = this.item.location?.point;
        return { lat, lng };
      }

      return this.item.location?.point;
    }

    return { lat: 47, lng: -72 };
  }

  get itemHasSetLocation(): boolean {
    return this.item?.location;
  }

  get zoom(): number {
    if (this.item.location?.point) {
      return 15;
    }

    return 5;
  }

  update(place: GooglePlace | CustomPlace) {
    if (isGooglePlace(place)) {
      this.$emit('input', {
        ...this.item,
        location_name: place.name,
        location: {
          google_place_name: place.name,
          google_place_address: place.formatted_address,
          google_place_id: place.place_id,
          point: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        },
      });
    }
  }
}
