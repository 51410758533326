var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "district-result" }, [
    _vm.item
      ? _c("div", { staticClass: "m3-card m3-surface" }, [
          _c("div", { staticClass: "m3-card__title" }, [
            _vm.item && _vm.item.district
              ? _c("span", [_vm._v(_vm._s(_vm.item.district.name) + " |")])
              : _vm._e(),
            _vm.item && _vm.item.election
              ? _c("span", { staticClass: "font-weight-bold ml-2" }, [
                  _vm._v(_vm._s(_vm.item.election.name))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "m3-card__text" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row-reverse justify-space-between align-start"
              },
              [
                _c(
                  "div",
                  _vm._l(_vm.links, function(ref) {
                    var icon = ref.icon
                    var name = ref.routeName
                    var title = ref.title
                    return _c(
                      "navigation-button",
                      {
                        key: title,
                        attrs: {
                          to: {
                            name: name,
                            params: {
                              districtElectionId: _vm.item.id
                            }
                          }
                        }
                      },
                      [
                        _c("v-icon", { staticClass: "mr-4" }, [
                          _vm._v(_vm._s(icon))
                        ]),
                        _vm._v(" " + _vm._s(title) + " "),
                        _c("v-spacer"),
                        _c("v-icon", { staticClass: "ml-4" }, [
                          _vm._v("mdi-arrow-right")
                        ])
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c("v-divider", {
                  staticClass: "mx-2",
                  attrs: { vertical: "" }
                }),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column flex-grow-1 justify-center"
                  },
                  [
                    _c("turnout-data", {
                      attrs: { outlined: false, large: "", item: _vm.item }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m3-card pa-4 mx-n4 mb-n4 mt-4" },
              [
                _c("h3", { staticClass: "font-weight-bold" }, [
                  _vm._v("Résultats par candidature")
                ]),
                _c("v-data-table", {
                  staticStyle: { "background-color": "transparent" },
                  attrs: {
                    headers: [
                      { text: "", value: "party.color", sortable: false },
                      {
                        text: "Personne candidate",
                        value: "candidate.full_name"
                      },
                      { text: "Appartenance politique", value: "party.name" },
                      {
                        text: "Bulletins valides",
                        value: "votes",
                        align: "right"
                      },
                      {
                        text: "Bulletins valides (%)",
                        value: "votes__valid_ballots",
                        align: "right"
                      },
                      { text: "Majorité", value: "majority", align: "right" }
                    ],
                    "hide-default-footer": "",
                    items: _vm.item.district_results,
                    "items-per-page": -1
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.party.color",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-icon",
                              { attrs: { color: item.party.color } },
                              [_vm._v("mdi-circle")]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.votes",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(_vm.number(item.votes)) + " ")
                          ]
                        }
                      },
                      {
                        key: "item.votes__valid_ballots",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.number(item.votes__valid_ballots)) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.majority",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(_vm.getMajority(item)) + " ")
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2208750452
                  )
                })
              ],
              1
            )
          ])
        ])
      : _vm.routeDataLoaded
      ? _c(
          "div",
          { staticClass: "d-flex flex-column align-center mt-12" },
          [
            _c("v-icon", { attrs: { color: "error" } }, [
              _vm._v("mdi-alert-circle")
            ]),
            _c("p", { staticClass: "mt-12" }, [
              _vm._v(" Une erreur est survenue ")
            ])
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass:
              "body-1 d-flex flex-column justify-center align-center",
            staticStyle: { height: "60vh" }
          },
          [
            _c("v-progress-circular", {
              attrs: { color: "qs-light-blue", indeterminate: "" }
            }),
            _vm._v(" Chargement des données... ")
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }