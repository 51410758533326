var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    staticClass: "volunteers",
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "sticky" },
              [
                _c("qs-filters", {
                  staticClass: "volunteers__filters",
                  attrs: {
                    "active-filters": _vm.params,
                    "filters-definition": { q: { type: "text" } },
                    "model-name": "contacts",
                    theme: "labase"
                  },
                  on: { input: _vm.updateFilters }
                }),
                _c(
                  "v-card",
                  { attrs: { tile: "", flat: "" } },
                  [
                    _c(
                      "v-card-title",
                      [
                        _vm._v(" Filtres "),
                        _c("v-spacer"),
                        !_vm.draftFilter
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showFilterModal = true
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            )
                          : _vm._e(),
                        _vm.draftFilter
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showFilterModal = true
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-pencil")])],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.filtersLoaded
                      ? _c("qs-list-filters", {
                          staticClass: "contacts__sidebar__list",
                          attrs: {
                            "draft-filter": _vm.draftFilter,
                            filters: _vm.filtersWithRights,
                            "hide-filter-ids": _vm.filterIds,
                            "model-name": _vm.slug,
                            value: _vm.selectedFilterIndex
                          },
                          on: {
                            input: _vm.setSelectedFilterIndex,
                            delete: _vm.deleteFilter,
                            duplicate: _vm.duplicateFilter,
                            edit: function($event) {
                              _vm.showFilterModal = true
                            },
                            save: function($event) {
                              _vm.showFilterSaveModal = true
                            },
                            reset: _vm.resetFilters
                          }
                        })
                      : _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  {
                                    staticClass: "d-flex justify-space-around"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ],
                  1
                ),
                _c(
                  "v-expansion-panels",
                  {
                    staticClass: "mt-0",
                    attrs: {
                      flat: "",
                      focusable: "",
                      mandatory: "",
                      tile: "",
                      value: _vm.selectedFilterIndex
                    },
                    on: {
                      change: function($event) {
                        return _vm.setSelectedFilterIndex($event)
                      }
                    }
                  },
                  [
                    _c("v-expansion-panel", { staticClass: "d-none" }),
                    _vm._l(_vm.sidebarStats, function(stat) {
                      return _c(
                        "v-expansion-panel",
                        { key: stat.key, attrs: { value: stat.value } },
                        [
                          _c("v-expansion-panel-header", {
                            staticClass:
                              "font-weight-bold pl-4 text-h4 text-truncate text-uppercase",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          stat.icon
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        outlined: "",
                                                        color: stat.color,
                                                        dark: "",
                                                        small: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: stat.color
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(stat.icon)
                                                          )
                                                        ]
                                                      ),
                                                      _vm.statsLoaded &&
                                                      stat.total !== undefined
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  stat.total
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                    size: "14",
                                                                    width: "2"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    color: stat.color,
                                                    dark: "",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm.statsLoaded &&
                                                  stat.total !== undefined
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(stat.total) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                indeterminate:
                                                                  "",
                                                                size: "14",
                                                                width: "2"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ]
                                              ),
                                          _vm._v(" " + _vm._s(stat.label) + " ")
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("v-expansion-panel-content", [
                            _c("p", {
                              staticClass: "body-2 mt-2 text-justify",
                              domProps: { innerHTML: _vm._s(stat.text) }
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("v-spacer"),
                                stat.main_action
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "flex-grow-1",
                                        attrs: {
                                          color: stat.color,
                                          depressed: "",
                                          loading: !_vm.statsLoaded,
                                          small: "",
                                          tile: ""
                                        },
                                        on: { click: stat.main_action.action }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { small: "" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(stat.main_action.icon)
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(stat.main_action.label) +
                                            " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                stat.secondary_actions &&
                                stat.secondary_actions.length > 0
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: stat.color,
                                                          icon: "",
                                                          tile: "",
                                                          small: ""
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-dots-vertical"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { dense: "" } },
                                          _vm._l(
                                            stat.secondary_actions,
                                            function(action, index) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: stat.key + "_" + index,
                                                  on: { click: action.action }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "qs-light-blue",
                                                            small: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                action.icon
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-content", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(action.label) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "v-card",
                  { staticClass: "mt-5" },
                  [
                    _c("v-card-title", [_vm._v(" Filtres de contacts ")]),
                    _c("v-card-subtitle", [
                      _vm._v(" Redirigent à la liste de contacts ")
                    ]),
                    _c(
                      "v-expansion-panels",
                      {
                        staticClass: "mt-3 volunteers__sidebar__extra",
                        attrs: { flat: "", focusable: "", tile: "" }
                      },
                      [
                        _c("v-expansion-panel", { staticClass: "d-none" }),
                        _vm._l(_vm.extraSidebarStats, function(stat) {
                          return _c(
                            "v-expansion-panel",
                            { key: stat.key, attrs: { value: stat.value } },
                            [
                              _c("v-expansion-panel-header", {
                                staticClass:
                                  "font-weight-bold pl-4 text-h4 text-truncate text-uppercase volunteers__sidebar__extra__header",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              stat.icon
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            color: stat.color,
                                                            dark: "",
                                                            small: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  stat.color
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  stat.icon
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm.statsLoaded &&
                                                          stat.total !==
                                                            undefined
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      stat.total
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c(
                                                                "span",
                                                                [
                                                                  _c(
                                                                    "v-progress-circular",
                                                                    {
                                                                      attrs: {
                                                                        indeterminate:
                                                                          "",
                                                                        size:
                                                                          "14",
                                                                        width:
                                                                          "2"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        color: stat.color,
                                                        dark: "",
                                                        small: ""
                                                      }
                                                    },
                                                    [
                                                      _vm.statsLoaded &&
                                                      stat.total !== undefined
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  stat.total
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                    size: "14",
                                                                    width: "2"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                    ]
                                                  ),
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(stat.label)
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("v-expansion-panel-content", [
                                _c("p", {
                                  staticClass: "body-2 mt-2 text-justify",
                                  domProps: { innerHTML: _vm._s(stat.text) }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c("v-spacer"),
                                    stat.main_action
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "flex-grow-1",
                                            attrs: {
                                              color: stat.color,
                                              depressed: "",
                                              loading: !_vm.statsLoaded,
                                              small: "",
                                              tile: ""
                                            },
                                            on: {
                                              click: stat.main_action.action
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-1",
                                                attrs: { small: "" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(stat.main_action.icon)
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(stat.main_action.label) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    stat.secondary_actions &&
                                    stat.secondary_actions.length > 0
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color: stat.color,
                                                              icon: "",
                                                              tile: "",
                                                              small: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-dots-vertical"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              { attrs: { dense: "" } },
                                              _vm._l(
                                                stat.secondary_actions,
                                                function(action, index) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key:
                                                        stat.key + "_" + index,
                                                      on: {
                                                        click: action.action
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-icon",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "qs-light-blue",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    action.icon
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                action.label
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-list", {
              attrs: {
                error: _vm.error,
                items: _vm.items,
                loading: _vm.loading,
                "model-name": _vm.slug,
                selection: _vm.selectedItems,
                theme: "labase",
                total: _vm.total
              },
              on: { load: _vm.loadNextPage, select: _vm.selectAll },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("volunteer-card", {
                        attrs: {
                          href: _vm.$router.resolve({
                            name: "ContactForm",
                            params: { id: item.id }
                          }).href,
                          item: item,
                          selected: _vm.isSelected(item),
                          "show-text": _vm.showExtraInformation,
                          "show-alerts": _vm.showAlerts
                        },
                        on: {
                          click: function($event) {
                            return _vm.goTo($event, {
                              name: "ContactForm",
                              params: { id: item.id }
                            })
                          },
                          check: function($event) {
                            return _vm.updateSelectedItems($event, item)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-list",
                        _vm._l(_vm.listActions, function(ref, index) {
                          var callback = ref.callback
                          var disabled = ref.disabled
                          var icon = ref.icon
                          var label = ref.label
                          return _c(
                            "v-list-item",
                            {
                              key: "qs-list__action-" + index,
                              attrs: { disabled: disabled },
                              on: { click: callback }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-2" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "qs-light-blue" } },
                                    [_vm._v(_vm._s(icon))]
                                  )
                                ],
                                1
                              ),
                              _c("v-list-item-content", [
                                _vm._v(" " + _vm._s(label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "settings",
                  fn: function() {
                    return [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                staticClass: "mt-0",
                                attrs: {
                                  "hide-details": "",
                                  label:
                                    "Afficher les informations supplémentaires"
                                },
                                model: {
                                  value: _vm.showExtraInformation,
                                  callback: function($$v) {
                                    _vm.showExtraInformation = $$v
                                  },
                                  expression: "showExtraInformation"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                staticClass: "mt-0",
                                attrs: {
                                  "hide-details": "",
                                  label: "Afficher les alertes"
                                },
                                model: {
                                  value: _vm.showAlerts,
                                  callback: function($$v) {
                                    _vm.showAlerts = $$v
                                  },
                                  expression: "showAlerts"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _c("router-view")
          ]
        },
        proxy: true
      },
      {
        key: "prepend",
        fn: function() {
          return [
            _c("qs-filter-modal", {
              attrs: {
                filter: _vm.draftFilter,
                repository: "Volunteer",
                value: _vm.showFilterModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showFilterModal = $event
                },
                apply: _vm.applyFilter,
                cancel: _vm.resetFilters,
                input: function($event) {
                  _vm.showFilterModal = $event
                }
              }
            }),
            _c("add-to-event-modal", {
              attrs: {
                params: _vm.actionParamsWithHasRoles,
                value: _vm.showAddToEventModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showAddToEventModal = $event
                },
                input: function($event) {
                  _vm.showAddToEventModal = $event
                }
              }
            }),
            _c("add-to-call-campaign-modal", {
              attrs: {
                params: _vm.actionParamsWithHasRoles,
                value: _vm.showAddToCallCampaignModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showAddToCallCampaignModal = $event
                },
                input: function($event) {
                  _vm.showAddToCallCampaignModal = $event
                }
              }
            }),
            _c("add-to-mail-campaign-modal", {
              attrs: {
                params: _vm.actionParamsWithHasRoles,
                value: _vm.showAddToMailCampaignModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showAddToMailCampaignModal = $event
                },
                input: function($event) {
                  _vm.showAddToMailCampaignModal = $event
                }
              }
            }),
            _c("add-users-to-call-campaign-modal", {
              attrs: {
                params: _vm.actionParamsWithHasRoles,
                "query-params": {
                  fields: ["description", "instance.name", "name"].join(","),
                  instance_id: _vm.instanceId,
                  order: "name",
                  "!status": "completed"
                },
                value: _vm.showAddUsersToCallCampaignModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showAddUsersToCallCampaignModal = $event
                },
                input: function($event) {
                  _vm.showAddUsersToCallCampaignModal = $event
                }
              }
            }),
            _c("add-tags-to-contacts-modal", {
              attrs: {
                params: _vm.actionParamsWithHasRoles,
                value: _vm.showAddTagsToContactsModal
              },
              on: {
                "update:value": function($event) {
                  _vm.showAddTagsToContactsModal = $event
                },
                input: function($event) {
                  _vm.showAddTagsToContactsModal = $event
                }
              }
            }),
            _c(
              "qs-confirmation-modal",
              {
                attrs: {
                  color: "warning",
                  loading: _vm.endInstanceRoleVolunteersLoading,
                  value: _vm.showEndInstanceRoleConfirmationModal
                },
                on: {
                  "click:cancel": function($event) {
                    _vm.showEndInstanceRoleConfirmationModal = false
                  },
                  "click:confirm": _vm.endInstanceRole
                }
              },
              [
                _vm._v(
                  " Êtes-vous sûr de vouloir retirer le statut de militant·e des "
                ),
                _vm.selectedItems.length === 0
                  ? _c("span", [
                      _vm._v(" contacts correspondant au filtre actuel? ")
                    ])
                  : _c("span", [_vm._v(" contacts sélectionnés? ")])
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }