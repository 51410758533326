












































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import TagsMixin from '@/mixins/TagsMixin';
import WokeMixin from '@/mixins/WokeMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

import { PersistedTag as Tag } from 'qs_vuetify/src/types/models';
import { SelectItem } from 'qs_vuetify/src/types/components';

@Component({
  components: {
    QsActionModal,
  },
})
export default class SelfIdentification extends mixins(AuthenticationMixin, TagsMixin, WokeMixin) {
  @Prop({ type: [String, Number], required: true }) id!: string | number;
  @Prop({ required: true, type: Array }) tags!: Tag[];

  isEdited = false;
  handicappedLoading = false;
  indigenousLoading = false;
  racializedLoading = false;

  handicappedValues: SelectItem[] = [
    { text: 'Vit avec une ou des limitations fonctionnelles', value: true },
    { text: 'Ne avec aucune limitation fonctionnelle', value: false },
  ];

  indigenousValues: SelectItem[] = [
    { text: 'Fait partie d\'une communauté autochtone', value: true },
    { text: 'Ne fait pas partie d\'une communauté autochtone', value: false },
  ];

  racializedValues: SelectItem[] = [
    { text: 'Est racisé·e', value: true },
    { text: 'N\'est pas racisé·e', value: false },
  ];

  get hasAnyValue(): boolean {
    return this.hasHandicappedValue
      || this.hasIndigenousValue
      || this.hasRacializedValue;
  }

  get hasHandicappedValue(): boolean {
    return this.contactIsHandicapped(this.tags) !== null;
  }

  get hasIndigenousValue(): boolean {
    return this.contactIsIndigenous(this.tags) !== null;
  }

  get hasRacializedValue(): boolean {
    return this.contactIsRacialized(this.tags) !== null;
  }

  async updateHandicapped(value: boolean | null) {
    this.handicappedLoading = true;
    if (this.handicappedFalseId) {
      await this.deleteContactTag(this.id, this.handicappedFalseId);
    }
    if (this.handicappedTrueId) {
      await this.deleteContactTag(this.id, this.handicappedTrueId);
    }

    if (value !== null && this.handicappedFalseId && this.handicappedTrueId) {
      const newValue = value ? this.handicappedTrueId : this.handicappedFalseId;
      await this.putContactTag(this.id, newValue);
    }

    this.$emit('update');
    this.isEdited = false;
    this.handicappedLoading = false;
  }

  async updateIndigenous(value: boolean | null) {
    this.indigenousLoading = true;
    if (this.indigenousFalseId) {
      await this.deleteContactTag(this.id, this.indigenousFalseId);
    }
    if (this.indigenousTrueId) {
      await this.deleteContactTag(this.id, this.indigenousTrueId);
    }

    if (value !== null && this.indigenousFalseId && this.indigenousTrueId) {
      const newValue = value ? this.indigenousTrueId : this.indigenousFalseId;
      await this.putContactTag(this.id, newValue);
    }

    this.$emit('update');
    this.isEdited = false;
    this.indigenousLoading = false;
  }

  async updateRacialized(value: boolean | null) {
    this.racializedLoading = true;
    if (this.racializedFalseId) {
      await this.deleteContactTag(this.id, this.racializedFalseId);
    }
    if (this.racializedTrueId) {
      await this.deleteContactTag(this.id, this.racializedTrueId);
    }

    if (value !== null && this.racializedFalseId && this.racializedTrueId) {
      const newValue = value ? this.racializedTrueId : this.racializedFalseId;
      await this.putContactTag(this.id, newValue);
    }

    this.$emit('update');
    this.isEdited = false;
    this.racializedLoading = false;
  }
}
