





























































































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsContactCard from 'qs_vuetify/src/components/Contacts/QsContactCard.vue';
import QsFilterModal from 'qs_vuetify/src/components/Dialog/QsFilterModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsListFilters from 'qs_vuetify/src/components/QsListFilters.vue';
import QsNewDuplicateModal
  from 'qs_vuetify/src/components/Dialog/Contacts/QsNewDuplicateModal.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import FiltersMixin from 'qs_vuetify/src/mixins/FiltersMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import { AppNotification, ListAction } from 'qs_vuetify/src/types/components';
import { Filter, PersistedContact } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { ExportField, RestParams } from 'qs_vuetify/src/types/states';

import AddToEventModal from '@/components/Event/AddToEventModal.vue';
import AddTagsToContactsModal from '@/components/Dialog/AddTagsToContactsModal.vue';
import AddToCallCampaignModal from '@/components/Dialog/AddToCallCampaignModal.vue';
import AddToMailCampaignModal from '@/components/Dialog/AddToMailCampaignModal.vue';
import AddUsersToCallCampaignModal from '@/components/Dialog/AddUsersToCallCampaignModal.vue';
import ExportSettingsModal from '@/components/Dialog/ExportSettingsModal.vue';
import FilterSaveModal from '@/components/Dialog/FilterSaveModal.vue';

import { userFields } from '@/App.vue';

import { mergeInstanceIdParams } from '@/helpers';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const global: any = namespace('global');
const view: any = namespace('contactsView');

@Component({
  components: {
    AddTagsToContactsModal,
    AddToEventModal,
    AddToCallCampaignModal,
    AddToMailCampaignModal,
    AddUsersToCallCampaignModal,
    ExportSettingsModal,
    FilterSaveModal,
    QsContactCard,
    QsFilterModal,
    QsFilters,
    QsList,
    QsListFilters,
    QsNewDuplicateModal,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Contacts extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  FiltersMixin,
  LazyListMixin,
  ListMixin,
  NavigationMixin,
) {
  @auth.Getter instanceId!: number | null;

  @contacts.Getter items!: Array<PersistedContact>;
  @contacts.Getter error!: ErrorResponse;
  @contacts.Getter exportUrl!: string;
  @contacts.Getter loading!: boolean;
  @contacts.Mutation('loading') setLoading!: (arg: boolean) => void;
  @contacts.Getter slug!: string;
  @contacts.Getter total!: number;

  @contacts.Getter filters!: Array<Filter>;
  @contacts.Getter filtersLoaded!: boolean;

  @global.Mutation addNotification!: (arg: AppNotification) => void;

  @view.Getter availableExportFields!: ExportField[];
  // 0 => Always draftFilter , 1 => Always "All contacts"
  @view.Getter selectedFilterIndex!: number;
  @view.Mutation setSelectedFilterIndex!: (index: number) => void;

  @view.Mutation addSelectedItem!: any;
  @view.Mutation clearSelectedItems!: () => void;
  @view.Getter draftFilter!: Filter;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: any;
  @view.Getter selectedItems!: Array<PersistedContact>;
  @view.Mutation setDraftFilter!: (filter: Filter | null) => void;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  showFilterModal = false;
  showFilterSaveModal = false;

  defaultParams = {
    per_page: 20,
    fields: [
      'address',
      'adopted_instances.name',
      'apartment',
      'birthdate',
      'city',
      'contribution_current_year',
      'contribution_last_year',
      'district.name',
      'dpa',
      'email',
      'first_name',
      'full_name',
      'gender',
      'home_phone',
      'last_contact_exchange.called_at',
      'last_contribution.date',
      'last_name',
      'originals.moderated_at',
      'postal_code',
      'status',
      'unsubscriptions.id',
      'v1_contact_id',
    ].join(','),
    order: 'last_name,first_name,v1_contact_id',
  }

  newDuplicateAction: {
    comment: string;
    loading: boolean;
    target: PersistedContact | null;
    value: boolean;
  } = {
    comment: '',
    loading: false,
    target: null,
    value: false,
  };

  showAddToEventModal = false;
  showAddToCallCampaignModal = false;
  showAddToMailCampaignModal = false;
  showAddUsersToCallCampaignModal = false;
  showAddTagsToContactsModal = false;
  showExportSettingsModal = false;

  viewStoreName = 'contactsView';

  get actionParamsWithInstanceId(): RestParams {
    return mergeInstanceIdParams(this.actionParams, this.instanceId);
  }

  get canCreateDuplicate(): boolean {
    return this.selectedItems.length > 1;
  }

  get canCreateVolunteers(): boolean {
    return !!this.user && this.userHas('VOLUNTEERS_CREATE');
  }

  get canExportContacts(): boolean {
    return !!this.user && this.userHas('CONTACTS_EXPORT');
  }

  get exportParams(): RestParams {
    if (this.selectedItems.length > 0) {
      return {
        id: this.selectedItems.map((c) => c.id).join(','),
      };
    }

    return {
      ...mergeInstanceIdParams(this.params, this.instanceId),
      prefix: '',
    };
  }

  get filtersWithRights(): Array<Filter> {
    return this.filters.map((f: Filter) => ({
      ...f,
      _can_delete: (this.userHas('FILTERS_DELETE') && ((f.instance_id === null && (!!f.private || this.userIsSuperadmin)) || f.instance_id !== null))
        || (f.private && f.created_by_user_id === this.user?.id),
    }));
  }

  get listActions(): ListAction[] {
    const n = this.selectedItems.length;

    const exportActions = this.canExportContacts ? [{
      callback: () => { this.exportCsv(); },
      disabled: !this.canExportContacts || this.loading,
      icon: 'mdi-file-export',
      label: `${this.$tc('models.contacts.actions.export', n)} (CSV)`,
      action: () => { this.showExportSettingsModal = true; },
    }] : [];

    const volunteerActions = this.canCreateVolunteers ? [{
      callback: () => { this.addToVolunteers(); },
      disabled: !this.canExportContacts,
      icon: 'mdi-hand-left',
      label: this.$tc('models.contacts.actions.add_contacts_as_volunteers', n),
    }] : [];

    const actions: ListAction[] = [
      {
        callback: () => { this.newDuplicateAction.value = true; },
        disabled: !this.canCreateDuplicate,
        icon: 'mdi-account-supervisor',
        label: this.$tc('models.contacts.actions.new_duplicate', n - 1),
      },
      ...exportActions,
      ...volunteerActions,
      {
        callback: () => {
          this.goTo(null, {
            name: 'NewCallCampaignDialog',
          });
        },
        disabled: false,
        icon: 'mdi-phone',
        label: this.$tc('models.contacts.actions.new_call_campaign', n),
      },
      {
        callback: () => { this.showAddToCallCampaignModal = true; },
        disabled: false,
        icon: 'mdi-phone-plus',
        label: this.$tc('models.contacts.actions.add_contacts_to_call_campaign', n),
      },
      {
        callback: () => { this.showAddUsersToCallCampaignModal = true; },
        disabled: false,
        icon: 'mdi-phone-forward',
        label: this.$tc('models.contacts.actions.add_users_to_call_campaign', n),
      },
      {
        callback: () => {
          this.goTo(null, {
            name: 'NewMailCampaignDialog',
          });
        },
        disabled: false,
        icon: 'mdi-email',
        label: this.$tc('models.contacts.actions.new_mail_campaign', n),
      },
      {
        callback: () => { this.showAddToMailCampaignModal = true; },
        disabled: false,
        icon: 'mdi-email-plus',
        label: this.$tc('models.contacts.actions.add_contacts_to_mail_campaign', n),
      },
      {
        callback: () => { this.showAddTagsToContactsModal = true; },
        disabled: false,
        icon: 'mdi-tag-plus',
        label: this.$tc('models.contacts.actions.add_tags_to_contacts', n),
      },
    ];

    if (this.userHas('EVENTS_CREATE')) {
      actions.push({
        callback: () => {
          this.goTo(null, {
            name: 'NewEventDialog',
          });
        },
        disabled: false,
        icon: 'mdi-calendar',
        label: this.$tc('models.contacts.actions.new_event', n),
      });
    }

    if (this.userHas('CONTACT_EVENTS_CREATE')) {
      actions.push({
        callback: () => { this.showAddToEventModal = true; },
        disabled: false,
        icon: 'mdi-calendar-plus',
        label: this.$tc('models.contacts.actions.add_contacts_to_event', n),
      });
    }

    return actions;
  }

  get showAlerts() {
    return this.$store.state.contactsView.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('contactsView/showAlerts', val);
  }

  mounted() {
    this.setAction();

    this.$store.commit('contacts/exportFields', [
      'id',
      'adopted',
      'status',
      'first_name',
      'last_name',
      'v1_contact_id',
      'district_name',
      'gender',
      'birthdate',
      'address',
      'apartment',
      'city',
      'postal_code',
      'home_phone',
      'email',
      'first_membership_period_start',
      'last_membership_period_start',
      'normalized_full_name',
    ]);

    if (this.items.length === 0) {
      this.loadFirstPage();
    }
  }

  async addToVolunteers() {
    try {
      if (this.selectedItems.length > 5 || this.selectedItems.length === 0) {
        this.addNotification({
          color: 'warning',
          message: 'Ajout des contacts à la liste de militant·es...',
        });
      }

      this.setLoading(true);

      await this.$store.dispatch('contacts/enableVolunteerRoleContacts', {
        data: {
          instance_id: this.instanceId,
        },
        params: {
          ...this.actionParamsWithInstanceId,
          per_page: '*',
          page: 1,
        },
      });

      this.addNotification({
        color: 'success',
        message: 'Le statut de militant·e a été ajouté avec succès.',
      });

      this.$store.commit('contacts/lastLoadedAt', null);
      this.reloadDataRoutesData();
      this.$store.dispatch('auth/loadUser', userFields);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Une erreur est survenue lors de l'ajout à la liste de militant·e.",
      });
    } finally {
      this.setLoading(false);
    }
  }

  async filterSaved(filterId: number) {
    this.setDraftFilter(null);

    this.filtersReloading = true;
    await this.$store.commit('contacts/filtersLoaded', false);
    await this.reloadDataRoutesData(['contacts.filters']);
    this.filtersReloading = false;

    const filterIndex: number = this.filters.findIndex((f) => f.id === filterId);
    this.setSelectedFilterIndex(filterIndex + 2);
  }

  async saveDuplicate(): Promise<void> {
    this.newDuplicateAction.loading = true;

    // number of duplicates for pluralization
    const n = this.selectedItems.length - 1;

    if (!this.newDuplicateAction.target || n < 1) {
      return;
    }

    const {
      newDuplicateAction: {
        comment,
        target: { id: targetId },
      },
    } = this;

    try {
      await axios.put('/jobs/contacts/create_duplicate', {
        comment,
        target_id: targetId,
      }, {
        params: {
          id: this.selectedItems.map((c) => c.id)
            .filter((c) => c !== targetId)
            .join(','),
        },
      });

      this.addNotification({
        color: 'success',
        message: this.$tc('models.contacts.messages.duplicates_save_success', n),
      });

      this.newDuplicateAction = {
        comment: '',
        loading: false,
        target: null,
        value: false,
      };

      this.$store.commit('contactsView/clearSelectedItems');
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: this.$tc('models.contacts.messages.duplicates_save_error', n),
      });

      throw e;
    } finally {
      this.newDuplicateAction.loading = false;
    }
  }

  @Watch('$route', { deep: true })
  onContactsRouteChanged() {
    this.setAction();
  }

  @Watch('userIsConnected')
  onContactsUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setAction();
    }
  }

  setAction() {
    this.$store.commit(
      'global/actions',
      this.userHas('CONTACTS_CREATE') ? [
        {
          onClick: () => { this.$router.push('contacts/new'); },
          color: 'primary',
          icon: 'mdi-plus',
          tooltip: 'Créer un nouveau contact',
        },
      ] : [],
    );
  }
}
