



























































































import Component from 'vue-class-component';

import ContactEventChip from '@/components/Volunteer/ContactEventChip.vue';
import QsBaseItemCard from 'qs_vuetify/src/components/QsBaseItemCard.vue';
import QsContactFavoriteButton from 'qs_vuetify/src/components/Contacts/QsContactFavoriteButton.vue';
import QsContactVolunteerButton from 'qs_vuetify/src/components/Contacts/QsContactVolunteerButton.vue';
import QsCopyToClipboard from 'qs_vuetify/src/components/QsCopyToClipboard.vue';

import { PersistedContact, PersistedEvent } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    ContactEventChip,
    QsContactFavoriteButton,
    QsContactVolunteerButton,
    QsCopyToClipboard,
  },
})
export default class VolunteerCard extends QsBaseItemCard<PersistedContact> {
  get futureEvents(): PersistedEvent[] {
    if (!this.item.future_events) {
      return [];
    }

    if (!this.currentInstanceId) {
      return this.item.future_events;
    }

    return this.item.future_events
      .filter((e: PersistedEvent) => e.instance_id === this.currentInstanceId);
  }

  get lastYear(): string {
    return this.$dayjs().subtract(1, 'year').format('YYYY');
  }

  get thisYear(): string {
    return this.$dayjs().format('YYYY');
  }
}
