var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "contacts", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "3", cols: "12" } }, [
            _c(
              "div",
              { staticClass: "contacts__sidebar" },
              [
                _c("qs-filters", {
                  staticClass: "contacts__sidebar__search",
                  attrs: {
                    "active-filters": _vm.params,
                    "filters-definition": { q: { type: "text" } },
                    "model-name": _vm.slug,
                    theme: "labase"
                  },
                  on: { input: _vm.updateFilters }
                }),
                _c(
                  "v-card",
                  { attrs: { tile: "", flat: "" } },
                  [
                    _c(
                      "v-card-title",
                      [
                        _vm._v(" Filtres "),
                        _c("v-spacer"),
                        !_vm.draftFilter
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showFilterModal = true
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            )
                          : _vm._e(),
                        _vm.draftFilter
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showFilterModal = true
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-pencil")])],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.filtersLoaded
                      ? _c("qs-list-filters", {
                          staticClass: "contacts__sidebar__list",
                          attrs: {
                            "allow-save-filter": "",
                            "draft-filter": _vm.draftFilter,
                            filters: _vm.filtersWithRights,
                            "model-name": _vm.slug,
                            value: _vm.selectedFilterIndex
                          },
                          on: {
                            input: _vm.setSelectedFilterIndex,
                            delete: _vm.deleteFilter,
                            duplicate: _vm.duplicateFilter,
                            edit: function($event) {
                              _vm.showFilterModal = true
                            },
                            save: function($event) {
                              _vm.showFilterSaveModal = true
                            },
                            reset: _vm.resetFilters
                          }
                        })
                      : _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-content",
                                  {
                                    staticClass: "d-flex justify-space-around"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-col",
            { attrs: { md: "9", cols: "12" } },
            [
              _c("qs-list", {
                attrs: {
                  error: _vm.error,
                  items: _vm.items,
                  loading: !_vm.filtersLoaded || _vm.loading,
                  "model-name": _vm.slug,
                  selection: _vm.selectedItems,
                  theme: "labase",
                  total: _vm.total
                },
                on: { load: _vm.loadNextPage, select: _vm.selectAll },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-contact-card", {
                          attrs: {
                            item: item,
                            selected: _vm.isSelected(item),
                            "show-text": _vm.showExtraInformation,
                            "show-alerts": _vm.showAlerts,
                            "show-update-volunteer": _vm.userHas(
                              "VOLUNTEERS_UPDATE"
                            ),
                            to: { name: "ContactForm", params: { id: item.id } }
                          },
                          on: {
                            check: function($event) {
                              return _vm.updateSelectedItems($event, item)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "settings",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("v-switch", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    label:
                                      "Afficher les informations supplémentaires"
                                  },
                                  model: {
                                    value: _vm.showExtraInformation,
                                    callback: function($$v) {
                                      _vm.showExtraInformation = $$v
                                    },
                                    expression: "showExtraInformation"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              [
                                _c("v-switch", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les alertes"
                                  },
                                  model: {
                                    value: _vm.showAlerts,
                                    callback: function($$v) {
                                      _vm.showAlerts = $$v
                                    },
                                    expression: "showAlerts"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          _vm._l(_vm.listActions, function(ref, index) {
                            var action = ref.action
                            var callback = ref.callback
                            var disabled = ref.disabled
                            var icon = ref.icon
                            var label = ref.label
                            return _c(
                              "v-list-item",
                              {
                                key: "qs-list__action-" + index,
                                attrs: { disabled: disabled },
                                on: { click: callback }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-2" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "qs-light-blue" } },
                                      [_vm._v(_vm._s(icon))]
                                    )
                                  ],
                                  1
                                ),
                                _c("v-list-item-content", [
                                  _vm._v(" " + _vm._s(label) + " ")
                                ]),
                                action
                                  ? _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return action($event)
                                              }
                                            }
                                          },
                                          [_c("v-icon", [_vm._v("mdi-cog")])],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("qs-filter-modal", {
        attrs: {
          filter: _vm.draftFilter,
          repository: "Contact",
          value: _vm.showFilterModal
        },
        on: {
          "update:value": function($event) {
            _vm.showFilterModal = $event
          },
          apply: _vm.applyFilter,
          cancel: _vm.resetFilters,
          input: function($event) {
            _vm.showFilterModal = $event
          }
        }
      }),
      _c("filter-save-modal", {
        attrs: { filter: _vm.draftFilter },
        on: { saved: _vm.filterSaved },
        model: {
          value: _vm.showFilterSaveModal,
          callback: function($$v) {
            _vm.showFilterSaveModal = $$v
          },
          expression: "showFilterSaveModal"
        }
      }),
      _c(
        "qs-new-duplicate-modal",
        _vm._b(
          {
            attrs: { items: _vm.selectedItems },
            on: {
              "click:close": function($event) {
                _vm.newDuplicateAction.value = false
              },
              "click:save": _vm.saveDuplicate,
              "input:comment": function($event) {
                _vm.newDuplicateAction.comment = $event
              },
              "input:target": function($event) {
                _vm.newDuplicateAction.target = $event
              }
            }
          },
          "qs-new-duplicate-modal",
          _vm.newDuplicateAction,
          false
        )
      ),
      _c("add-to-event-modal", {
        attrs: {
          params: _vm.actionParamsWithInstanceId,
          value: _vm.showAddToEventModal
        },
        on: {
          "update:value": function($event) {
            _vm.showAddToEventModal = $event
          },
          input: function($event) {
            _vm.showAddToEventModal = $event
          }
        }
      }),
      _c("add-to-call-campaign-modal", {
        attrs: {
          params: _vm.actionParamsWithInstanceId,
          value: _vm.showAddToCallCampaignModal
        },
        on: {
          "update:value": function($event) {
            _vm.showAddToCallCampaignModal = $event
          },
          input: function($event) {
            _vm.showAddToCallCampaignModal = $event
          }
        }
      }),
      _c("add-to-mail-campaign-modal", {
        attrs: {
          params: _vm.actionParamsWithInstanceId,
          value: _vm.showAddToMailCampaignModal
        },
        on: {
          "update:value": function($event) {
            _vm.showAddToMailCampaignModal = $event
          },
          input: function($event) {
            _vm.showAddToMailCampaignModal = $event
          }
        }
      }),
      _c("add-users-to-call-campaign-modal", {
        attrs: {
          params: _vm.actionParamsWithInstanceId,
          value: _vm.showAddUsersToCallCampaignModal
        },
        on: {
          "update:value": function($event) {
            _vm.showAddUsersToCallCampaignModal = $event
          },
          input: function($event) {
            _vm.showAddUsersToCallCampaignModal = $event
          }
        }
      }),
      _c("add-tags-to-contacts-modal", {
        attrs: {
          params: _vm.actionParamsWithInstanceId,
          value: _vm.showAddTagsToContactsModal
        },
        on: {
          "update:value": function($event) {
            _vm.showAddTagsToContactsModal = $event
          },
          input: function($event) {
            _vm.showAddTagsToContactsModal = $event
          }
        }
      }),
      _c("export-settings-modal", {
        attrs: {
          fields: _vm.availableExportFields,
          "model-name": "contacts",
          "store-name": "contactsView",
          value: _vm.showExportSettingsModal
        },
        on: {
          "update:value": function($event) {
            _vm.showExportSettingsModal = $event
          },
          input: function($event) {
            _vm.showExportSettingsModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }