var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instances-dashboard" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { cols: "3" } }, [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { gap: "24px" }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c("v-card-title", [_vm._v("Délégation")]),
                      _c("v-card-text", [
                        _c(
                          "p",
                          {
                            staticClass: "text-center font-weight-bold",
                            staticStyle: {
                              "font-size": "64px",
                              "margin-bottom": "48px"
                            }
                          },
                          [_vm._v(_vm._s(_vm.instanceDelegationSize))]
                        ),
                        _c("p", [
                          _vm._v(_vm._s(_vm.instanceName) + " peut déléguer "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.instanceDelegationSize))
                          ]),
                          _vm._v(" personnes. "),
                          _c("br"),
                          _vm._v(" Les délégation doivent être paritaires. ")
                        ])
                      ]),
                      _c(
                        "v-list",
                        { attrs: { rounded: "" } },
                        _vm._l(_vm.delegates, function(ref, i) {
                          var href = ref.href
                          var title = ref.title
                          var subtitle = ref.subtitle
                          return _c(
                            "v-list-item",
                            { key: "document-" + i, attrs: { href: href } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", { attrs: { color: "info" } }, [
                                    _vm._v("mdi-attachment")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(title))
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(subtitle))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-card-action",
                        { staticClass: "d-flex justify-end pa-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                color: "primary",
                                depressed: "",
                                disabled: !_vm.item.meta
                                  .delegation_confirmation_url,
                                href: _vm.item.meta.delegation_confirmation_url
                              }
                            },
                            [
                              _vm._v(" Confirmer la délégation de"),
                              _c("br"),
                              _vm._v(_vm._s(_vm.instanceName) + " ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v(" Dates importantes ")]),
                      _c("v-card-text", [
                        _c("p", [
                          _vm._v(
                            " 1er mai → Date limite pour soumettre des amendements"
                          ),
                          _c("br"),
                          _vm._v(" 10 mai → Envoi du cahier synthèse"),
                          _c("br"),
                          _vm._v(
                            " 24 au 26 mai → Conseil national de Saguenay"
                          ),
                          _c("br")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "v-col",
              { attrs: { cols: "6" } },
              [
                _c(
                  "v-card",
                  [
                    _c("v-card-text", [
                      _c("p", [
                        _vm._v(" Chères et chers solidaires,"),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Il nous fait plaisir de vous convier au 21e Conseil national de Québec solidaire, qui aura lieu les 24, 25 et 26 mai prochains au Cégep de Jonquière."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Le Conseil national est l’instance intermédiaire entre les Congrès, rassemblant des centaines de délégué·es de partout sur le territoire et dont les décisions orientent le travail de notre parti. Il s’ouvrira vendredi soir par une conférence d’ouverture dont les détails seront annoncés dans les prochaines semaines."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " C’est à cette occasion que se terminera officiellement la tournée des régions entamée l’automne dernier et d’en présenter le bilan. De Ville-Marie à Gaspé, et de La Malbaie à Sherbrooke, la tournée nous a permis d’avoir de franches conversations avec des acteurs de milieux variés et le fruit de ces discussions nous donne les outils pour être, plus que jamais, un parti proche du monde et de ses préoccupations."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " L’instance sera également l’occasion d’entamer des réflexions entourant le programme de notre parti ainsi que sur le processus d’adoption de nos plateformes électorales. Ces réflexions s’inscrivent dans un vaste chantier de modernisation de nos structures et de nos processus qui permettra à notre parti de mieux se préparer aux prochaines élections."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Un moment de l’instance sera également dédié à des formations organisationnelles afin de mieux vous outiller dans votre travail de mobilisation politique et de préparation électorale au sein de vos associations! C’est une opportunité pour des nouvelles personnes militantes d’aller chercher de nouvelles expertises organisationnelles."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Les documents de l’instance – ordre du jour, procédure d’inscription et cahier de propositions – seront rendus disponibles sur l’Espace solidaire au courant des prochaines semaines. Les détails des élections internes seront communiqués sous peu."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Au plaisir de vous retrouver en grand nombre dans la région du Saguenay–Lac-Saint-Jean!"
                        ),
                        _c("br"),
                        _c("br"),
                        _c("strong", [_vm._v("Nicolas Chatel-Launay")]),
                        _c("br"),
                        _vm._v(" Secrétaire général de Québec solidaire ")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-col", { attrs: { cols: "3" } }, [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { gap: "24px" }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [_vm._v("Documents de l'instance")]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-list",
                            { attrs: { rounded: "" } },
                            _vm._l(_vm.amendments, function(ref, i) {
                              var href = ref.href
                              var title = ref.title
                              var subtitle = ref.subtitle
                              return _c(
                                "v-list-item",
                                { key: "document-" + i, attrs: { href: href } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "info" } },
                                        [_vm._v("mdi-attachment")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(title))
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(subtitle))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-action",
                        { staticClass: "d-flex justify-end pa-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-center",
                              attrs: {
                                block: "",
                                color: "info",
                                outlined: "",
                                href:
                                  "https://docs.google.com/forms/d/1LyCrTWOSMK4hALBxleTHR8Y_wYqHs2zZI2G3S2cuYp0/edit"
                              }
                            },
                            [
                              _vm._v(" Soumettre un amendement"),
                              _c("br"),
                              _vm._v(
                                "au nom de " + _vm._s(_vm.instanceName) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c("v-img", {
                        attrs: {
                          src:
                            "https://mcusercontent.com/202387a19d59f62f848a60fbc/images/26e66623-9caa-6c99-2d3d-0c9d8cb588ec.png"
                        }
                      }),
                      _c("v-card-title", [
                        _vm._v(" Présentation du cahier de propositions ")
                      ]),
                      _c("v-card-text", [
                        _c("p", [
                          _vm._v(
                            "Avec Roxane Milot, présidente de Québec solidaire, et Nicolas Chatel-Launay, secrétaire général."
                          )
                        ])
                      ]),
                      _c(
                        "v-card-action",
                        { staticClass: "d-flex justify-end pa-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                color: "info",
                                depressed: "",
                                href:
                                  "https://www.youtube.com/watch?v=XIsZ8tTcmBU"
                              }
                            },
                            [_vm._v(" Visionner ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }