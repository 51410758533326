var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location-field" },
    [
      _c("gmap-autocomplete", {
        on: { place_changed: _vm.update },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var attrs = ref.attrs
              var listeners = ref.listeners
              return [
                _c("v-text-field", {
                  ref: "input",
                  attrs: { "hide-details": "", label: "Chercher un lieu" },
                  on: { listeners: listeners, attrs: attrs }
                }),
                _c("v-text-field", {
                  ref: "address-display",
                  staticClass: "mb-2 pt-0",
                  attrs: {
                    "hide-details": "",
                    readonly: "",
                    value: _vm.itemHasSetLocation
                      ? _vm.item.location.google_place_address
                      : ""
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "gmap-map",
        {
          staticStyle: { width: "100%", height: "180px" },
          attrs: {
            center: _vm.center,
            "map-type-id": "roadmap",
            options: {
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false
            },
            zoom: _vm.zoom
          }
        },
        [
          _vm.itemHasSetLocation
            ? _c("gmap-marker", { attrs: { position: _vm.center } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }