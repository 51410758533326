







































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import { Prop, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { Contact } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import FilterSelect from '@/components/FilterSelect.vue';

import FilterSelectMixin from '@/mixins/FilterSelectMixin';

const auth: any = namespace('auth');
const global: any = namespace('global');
const mailCampaigns: any = namespace('mail_campaigns');

@Component({
  components: {
    FilterSelect,
    QsActionModal,
    QsButton,
    QsFormBuilder,
    QsHtmlEditor,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewMailCampaignDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FilterSelectMixin,
  FormMixin,
) {
  @Prop({ type: [String, Number], required: true }) id!: string | number;
  @Prop({ type: String, required: false, default: 'contacts' }) type!: string;

  @auth.Getter instanceId!: number | null;

  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @mailCampaigns.Action loadEmpty!: any;
  @mailCampaigns.Getter error!: ErrorResponse;
  @mailCampaigns.Getter form!: Form;
  @mailCampaigns.Getter item!: Contact;
  @mailCampaigns.Getter loading!: boolean;
  @mailCampaigns.Getter slug!: string;
  @mailCampaigns.Mutation('initialItem') syncInitialItem!: any
  @mailCampaigns.Mutation('item') syncItem!: any
  @mailCampaigns.Mutation('error') setError!: ((arg: ErrorResponse | null) => void);

  formOrder = [
    'name',
    'filter',
  ];

  afterSave() {
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: "Campagne créée avec succès. L'ajout des contacts peut prendre quelques minutes.",
      timeout: 2000,
    });
    this.resetIndexData();
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
    }
  }

  closeModal(): void {
    this.setError(null);

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
      return;
    }

    this.$router.push({ name: 'MailCampaigns' });
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', 'Nouvelle campagne d\'appels');
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    await this.syncItem({
      ...this.item,
      instance_id: this.instanceId,
    });

    this.setPreviousLocation(null);

    await this.submit();
  }

  syncInitialItemOnNextTick() {
    this.$nextTick(() => this.syncInitialItem(JSON.stringify(this.item)));
  }
}
