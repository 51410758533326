var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex mb-3" },
    [
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4 pa-0",
              attrs: {
                color: "#FF5505",
                elevation: "0",
                fab: "",
                link: "",
                "x-small": "",
                tile: ""
              },
              on: { click: _vm.navigateBack }
            },
            [
              _c(
                "v-icon",
                { staticClass: "white--text", attrs: { "x-small": "" } },
                [_vm._v("$qs-left-arrow")]
              )
            ],
            1
          ),
          _c("span", { staticClass: "text-h6" }, [
            _vm._v(_vm._s(_vm.navigateBackLabel))
          ])
        ],
        1
      ),
      _c("v-spacer"),
      _vm.total > 0 && !_vm.previousLocation
        ? _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "#FF5505",
                    disabled: !_vm.prev || _vm.loading,
                    link: "",
                    tile: "",
                    fab: "",
                    "x-small": "",
                    elevation: "0"
                  },
                  on: {
                    click: function($event) {
                      _vm.temporaryIndex = _vm.index - 1
                      _vm.callCallback("prev", _vm.prev ? _vm.prev.id : "")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { "x-small": "" } },
                    [_vm._v("$qs-left-arrow")]
                  )
                ],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("number")((_vm.temporaryIndex || _vm.index) + 1)
                  ) +
                  " / " +
                  _vm._s(_vm._f("number")(_vm.total)) +
                  " "
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: {
                    color: "#FF5505",
                    disabled: !_vm.canLoadNext || _vm.loading,
                    elevation: "0",
                    fab: "",
                    link: "",
                    "x-small": "",
                    tile: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.temporaryIndex = _vm.index + 1
                      _vm.callCallback("next", _vm.next ? _vm.next.id : "")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { "x-small": "" } },
                    [_vm._v("$qs-right-arrow")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }