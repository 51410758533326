/* eslint-disable comma-dangle */
import Vue from 'vue';
import { Route, RouteConfig } from 'vue-router';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import { Model } from 'qs_vuetify/src/types/models';

import store from '../store';

const CallCampaignForm = () => import(
  /* webpackChunkName: "CallCampaignForm" */
  '@/views/CallCampaigns/CallCampaignForm.vue'
);
const CallCampaigns = () => import(
  /* webpackChunkName: "CallCampaigns" */
  '@/views/CallCampaigns/CallCampaigns.vue'
);
const CallCampaignExchange = () => import(
  /* webpackChunkName: "CallCampaignExchange" */
  '@/views/CallCampaigns/CallCampaignExchange.vue'
);
const NewCallCampaignDialog = () => import(
  /* webpackChunkName: "NewCallCampaignDialog" */
  '@/views/CallCampaigns/NewCallCampaignDialog.vue'
);

const callCampaigns: RouteConfig[] = [
  {
    path: '/call_campaigns',
    name: 'CallCampaigns',
    component: CallCampaigns,
    meta: {
      data: {
        call_campaigns: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.call_campaigns.lastLoadedAt;
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$store.state.auth.instanceId}`;
            },
          },
        },
      },
      menus: ['primary'],
      requires: ['CALL_CAMPAIGNS_RETRIEVE'],
      title: 'Appels',
    },
    children: [
      {
        path: 'new',
        name: 'NewCallCampaignDialog',
        component: NewCallCampaignDialog,
        props: (route) => ({
          id: 'new',
          type: route.params?.type,
        }),
        meta: {
          auth: true,
          data: {
            contacts: {
              filters: {
                conditional(vm: Vue) {
                  return !vm.$store.state.contacts.filtersLoaded;
                },
                contextual(vm: Vue) {
                  return {
                    accessible_from: vm.$store.state.auth.instanceId,
                  };
                },
              },
            },
            call_campaigns: {
              loadEmpty: {},
            },
            objectives: {
              loadEmpty: {
                conditional(vm: Vue & AuthenticationMixin) {
                  return vm.userHas('OBJECTIVES_CREATE');
                },
              },
            },
          },
          menus: [],
          name: "Nouvelle campagne d'appels",
        },
      },
    ],
  },
  {
    path: '/call_campaigns/:id',
    name: 'CallCampaignForm',
    component: CallCampaignForm,
    props: true,
    meta: {
      data: {
        call_campaigns: {
          retrieve: {
            contextual(vm: Vue) {
              const {
                with_deleted,
                is_deleted,
                deleted_at,
              } = vm.$store.state.callCampaignsView.params;

              return {
                id: vm.$route.params.id,
                with_deleted: (with_deleted || is_deleted || deleted_at),
              };
            },
            prefix() {
              return '';
            },
          },
          stats: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
        contact_exchanges: {
          index: {
            conditional(vm: Vue & AuthenticationMixin) {
              return vm.userHas('CONTACT_EXCHANGES_RETRIEVE');
            },
            prefix(vm: Vue) {
              return `/call_campaigns/${vm.$route.params.id}`;
            },
          },
        },
        contacts: {
          index: {
            prefix(vm: Vue) {
              return `/call_campaigns/${vm.$route.params.id}`;
            },
          },
        },
        form_definition_templates: {
          index: {
            conditional(vm: Vue & AuthenticationMixin) {
              return !vm.$store.state.form_definition_templates.lastLoadedAt
                && vm.userHas('FORM_DEFINITION_TEMPLATES_RETRIEVE');
            },
          },
        },
        users: {
          index: {
            conditional(vm: Vue & AuthenticationMixin) {
              return vm.userHas('CALL_CAMPAIGN_USERS_RETRIEVE');
            },
            params: {
              fields: [
                'email',
                'contact_name',
                'contact_id',
                'contact.v1_contact_id',
              ].join(','),
              order: 'contact_name',
              per_page: -1,
            },
            prefix(vm: Vue) {
              return `/call_campaigns/${vm.$route.params.id}`;
            },
          },
        },
      },
      lockInstance: true,
      menus: [],
      title: "Campagne d'appels",
    },
  },
  {
    path: '/call_campaigns/:call_campaign_id/contact_exchanges/:id',
    name: 'CallCampaign',
    component: CallCampaignExchange,
    props: (route: Route) => ({
      id: parseInt(route.params.id, 10),
      call_campaign_id: route.params.call_campaign_id,
      modelName: (store.state as any).contact_exchanges.slug,
    }),
    meta: {
      data: {
        call_campaigns: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.call_campaign_id,
              };
            },
            prefix() {
              return '';
            },
          },
          stats: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.call_campaign_id,
              };
            },
          },
        },
        contact_exchanges: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
            prefix(vm: Vue) {
              return `/call_campaigns/${vm.$route.params.call_campaign_id}`;
            },
            transform(vm: Vue, item: Model) {
              const newItem = {
                ...item,
              };
              if (!item.answers) {
                newItem.answers = {};
              }

              if (typeof item.left_message !== 'boolean') {
                newItem.left_message = false;
              }


              if (typeof item.comments !== 'string') {
                newItem.comments = '';
              }

              return newItem;
            },
          },
        },
        contact_events: {
          loadEmpty: {},
        },
        seen_contact_exchanges: {
          index: {
            contextual(vm: Vue) {
              return {
                call_campaign_id: vm.$route.params.call_campaign_id,
                called_by_user_id: vm.$store.getters['auth/user'].id,
              };
            },
            params: {
              fields: [
                'called_at',
                'contact.age',
                'contact.full_name',
                'contact.home_phone',
                'contact.postal_code',
                'direction',
                'status',
              ].join(','),
              order: '-called_at',
              per_page: -1,
            },
          },
        },
        tags: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.tags.lastLoadedAt;
            },
            contextual(vm: Vue) {
              return {
                accessible_from: vm.$store.state.auth.instanceId,
              };
            },
            params: {
              fields: 'name',
              order: 'name',
              per_page: '*',
            },
          },
        },
      },
      lockInstance: true,
      menus: [],
      title: 'Campagne d\'appel',
    },
  },
];

export default callCampaigns;
