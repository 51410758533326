import { render, staticRenderFns } from "./DistrictResult.vue?vue&type=template&id=21579a57&"
import script from "./DistrictResult.vue?vue&type=script&lang=ts&"
export * from "./DistrictResult.vue?vue&type=script&lang=ts&"
import style0 from "./DistrictResult.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDataTable,VDivider,VIcon,VProgressCircular,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21579a57')) {
      api.createRecord('21579a57', component.options)
    } else {
      api.reload('21579a57', component.options)
    }
    module.hot.accept("./DistrictResult.vue?vue&type=template&id=21579a57&", function () {
      api.rerender('21579a57', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ElectionResults/DistrictResult.vue"
export default component.exports