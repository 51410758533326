import axios from 'axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { EventsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import { Event } from 'qs_vuetify/src/types/models';

export const eventsState: EventsRestState = {
  ...buildRestState<Event>('events', {}),
  stats: null,
  statsLoaded: false,
};

export const getters: GetterTree<EventsRestState, RootState> = {
  ...buildRestGetters<Event, EventsRestState>(),
  options(state) {
    return state.options;
  },
  stats(state) {
    return state.stats;
  },
  statsLoaded(state) {
    return state.statsLoaded;
  },
};

export const actions: ActionTree<EventsRestState, RootState> = {
  ...buildRestActions<Event, EventsRestState>(),
  async start({ commit }, id) {
    commit('loaded', false);

    try {
      const ajax = axios.put(`/events/${id}/start`);

      commit('ajax', ajax);

      await ajax;
    } catch (err) {
      if (err.response && err.response.data) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('ajax', null);
    }

    commit('loaded', true);
  },
  async stats({ commit }, { id }) {
    commit('statsLoaded', false);

    const ajax = axios.get(`/events/${id}/stats`);

    const {
      data: stats,
    } = await ajax;

    commit('stats', stats);
    commit('statsLoaded', true);
  },
};

export const mutations: MutationTree<EventsRestState> = {
  ...buildRestMutations<Event, EventsRestState>(),
  options(state, options) {
    state.options = options;
  },
  stats(state, stats) {
    state.stats = stats;
  },
  statsLoaded(state, statsLoaded) {
    state.statsLoaded = statsLoaded;
  },
};

export const events: Module<EventsRestState, RootState> = {
  namespaced: true,
  state: eventsState,
  getters,
  actions,
  mutations,
};
