import { render, staticRenderFns } from "./SegmentResults.vue?vue&type=template&id=23c1afc5&"
import script from "./SegmentResults.vue?vue&type=script&lang=ts&"
export * from "./SegmentResults.vue?vue&type=script&lang=ts&"
import style0 from "./SegmentResults.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23c1afc5')) {
      api.createRecord('23c1afc5', component.options)
    } else {
      api.reload('23c1afc5', component.options)
    }
    module.hot.accept("./SegmentResults.vue?vue&type=template&id=23c1afc5&", function () {
      api.rerender('23c1afc5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ElectionResults/SegmentResults.vue"
export default component.exports