var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-self-identification body-1" },
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row",
                    on: {
                      click: function($event) {
                        _vm.isEdited = true
                      }
                    }
                  },
                  [
                    _vm.hasAnyValue
                      ? _c("div", [
                          _vm.hasHandicappedValue
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.contactIsHandicapped(_vm.tags)
                                        ? "Limitation(s) fonctionnelle(s)"
                                        : "Pas de limitation fonctionnelle"
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.hasHandicappedValue && _vm.hasIndigenousValue
                            ? _c("br")
                            : _vm._e(),
                          _vm.hasIndigenousValue
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.contactIsIndigenous(_vm.tags)
                                        ? "Autochtone"
                                        : "Allochtone"
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.hasHandicappedValue &&
                          !_vm.hasIndigenousValue &&
                          _vm.hasRacializedValue
                            ? _c("span", [_vm._v(" / ")])
                            : _vm._e(),
                          _vm.hasIndigenousValue && _vm.hasRacializedValue
                            ? _c("span", [_vm._v(" / ")])
                            : _vm._e(),
                          _vm.hasRacializedValue
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.contactIsRacialized(_vm.tags)
                                        ? "Racisé·e"
                                        : "Pas racisé·e"
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _c("div", [
                          _c("span", { staticClass: "body-1 font-italic" }, [
                            _vm._v("Auto-identification")
                          ])
                        ]),
                    _vm.userIsSuperadmin
                      ? _c(
                          "v-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: hover,
                                expression: "hover"
                              }
                            ],
                            staticClass: "ml-2",
                            attrs: { "x-small": "" }
                          },
                          [_vm._v("mdi-pencil")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "qs-action-modal",
        {
          attrs: { color: "white", dark: false, value: _vm.isEdited },
          on: {
            "click:close": function($event) {
              _vm.isEdited = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Auto-identification ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-select", {
            attrs: {
              clearable: "",
              items: _vm.handicappedValues,
              label: "Limitation(s) fonctionnelle(s)",
              loading: _vm.handicappedLoading,
              value: _vm.contactIsHandicapped(_vm.tags)
            },
            on: {
              change: function($event) {
                return _vm.updateHandicapped($event)
              }
            }
          }),
          _c("v-select", {
            attrs: {
              clearable: "",
              items: _vm.indigenousValues,
              label: "Autochtone",
              loading: _vm.indigenousLoading,
              value: _vm.contactIsIndigenous(_vm.tags)
            },
            on: {
              change: function($event) {
                return _vm.updateIndigenous($event)
              }
            }
          }),
          _c("v-select", {
            attrs: {
              clearable: "",
              items: _vm.racializedValues,
              label: "Racisé·e",
              loading: _vm.racializedLoading,
              value: _vm.contactIsRacialized(_vm.tags)
            },
            on: {
              change: function($event) {
                return _vm.updateRacialized($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }