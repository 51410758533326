var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-line-info mb-4" }, [
    _c("div", { staticClass: "single-line-info__inner" }, [
      _c("span", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.label))]),
      _vm.value
        ? _c(
            "span",
            { staticClass: "font-weight-medium text-uppercase" },
            [
              _vm._t("default", [_vm._v(_vm._s(_vm.value))], null, {
                value: _vm.value
              })
            ],
            2
          )
        : _c("span", { staticClass: "font-weight-medium text-uppercase" }, [
            _vm._v(" - ")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }