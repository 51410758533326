var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        value: _vm.syncedValue,
        "content-class": "add-to-mail-campaign-modal"
      },
      on: {
        "click:close": function($event) {
          _vm.syncedValue = false
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Ajouter des destinataires ")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [_vm._v(" À un envoi courriel ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    disabled: !_vm.mailCampaign,
                    loading: _vm.loading,
                    theme: "labase"
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Ajouter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "body-2",
          attrs: {
            border: "left",
            color: "info",
            dismissible: "",
            icon: "$qs-email-off",
            text: "",
            tile: ""
          }
        },
        [
          _vm._v(
            " Les contacts qui se sont désabonnés du mode de contact « Courriel » seront automatiquement exclus de la campagne. "
          )
        ]
      ),
      _vm.syncedValue
        ? _c("qs-relation-field", {
            ref: "relation",
            attrs: {
              item: _vm.dummyItem,
              label: "Recherche",
              name: "add_to_mail_campaign",
              query: _vm.queryDefinition,
              value: _vm.mailCampaign
            },
            on: {
              input: function($event) {
                _vm.mailCampaign = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }