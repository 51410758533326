import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { VolunteersViewState } from '@/types/states';

const volunteersViewState: Module<VolunteersViewState, RootState> = {
  namespaced: true,
  state: {
    draftFilter: null,
    params: {
      fields: [
        'adopted_instances.name',
        'contribution_current_year',
        'contribution_last_year',
        'district.name',
        'dpa',
        'email',
        'first_name',
        'full_name',
        ...[
          'contact_event_id',
          'intention',
          'instance_id',
          'location_name',
          'start_at',
          'title',
        ].map((f) => `future_events.${f}`),
        'gender',
        'home_phone',
        'last_contact_exchange.called_at',
        'last_contribution.date',
        'last_name',
        'postal_code',
        'status',
        'v1_contact_id',
        'instance_role_id',
      ].join(','),
      page: 1,
      per_page: 20,
      order: 'id',
    },
    selectedItems: [],
    selectedFilterIndex: 1,
    showAlerts: true,
    showExtraInformation: true,
  },
  getters: {
    draftFilter(state) {
      return state.draftFilter;
    },
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    selectedFilterIndex(state) {
      return state.selectedFilterIndex;
    },
    showAlerts(state) {
      return state.showAlerts;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
  },
  mutations: {
    addSelectedItem(state, selectedItem) {
      if (!state.selectedItems.find((i) => i.id === selectedItem.id)) {
        state.selectedItems.push({
          ...selectedItem,
        });
      }
    },
    clearSelectedItems(state) {
      state.selectedItems.splice(0, state.selectedItems.length);
    },
    removeSelectedItem(state, selectedItem) {
      const index = state.selectedItems.findIndex((i) => i.id === selectedItem.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setDraftFilter(state, draftFilter) {
      state.draftFilter = draftFilter;
    },
    showAlerts(state, showAlerts) {
      state.showAlerts = showAlerts;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setParams(state, params) {
      state.params = params;
    },
    setSelectedItems(state, selectedItems) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...selectedItems,
      );
    },
    setSelectedFilterIndex(state, selectedFilterIndex) {
      state.selectedFilterIndex = selectedFilterIndex;
    },
  },
};

export default volunteersViewState;
