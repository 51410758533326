




























































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsSelectField from 'qs_vuetify/src/components/Fields/QsSelectField.vue';
import QsThemedSelectField from 'qs_vuetify/src/components/Fields/QsThemedSelectField.vue';
import QsThemedTextField from 'qs_vuetify/src/components/Fields/QsThemedTextField.vue';

import { SelectItem } from 'qs_vuetify/src/types/components';

@Component({
  components: {
    QsSelectField,
    QsThemedSelectField,
    QsThemedTextField,
  },
})
export default class QsSplitDateField extends QsDateField {
  dateSelect: string | null = null;
  dateMenu: boolean = false;
  timeMenu: boolean = false;

  get nextMonth(): string {
    return this.$dayjs().add(1, 'month').format('YYYY-MM-DD');
  }

  get selectItems(): SelectItem[] {
    return [
      { text: 'Aujourd\'hui', value: this.today },
      { text: 'Demain', value: this.tomorrow },
      { text: 'Date personnalisée', value: 'custom' },
    ];
  }

  get showDateField(): boolean {
    return this.dateSelect === 'custom';
  }

  get today(): string {
    return this.$dayjs().format('YYYY-MM-DD');
  }

  get tomorrow(): string {
    return this.$dayjs().add(1, 'day').format('YYYY-MM-DD');
  }

  // eslint-disable-next-line class-methods-use-this
  allowedStep(m: number): boolean {
    return m % 15 === 0;
  }

  @Watch('time')
  onSplitDateFieldTimeChanged(value: string | null) {
    if (!value) {
      this.dateSelect = null;
    }
  }

  @Watch('dateSelect')
  onDateSelectChanged(value: string | null) {
    if (value === 'custom') {
      this.date = this.$dayjs().add(2, 'day').format('YYYY-MM-DD');
    } else if (value === null) {
      this.$emit('input', null);
    } else {
      this.date = value;
      if (!this.time) {
        this.time = this.$dayjs().add(1, 'hour').format('HH:mm');
      }
    }
  }
}
