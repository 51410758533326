














































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { Filter, Instance, InstanceStats as InstanceStatsInterface } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

const contactsView: any = namespace('contactsView');
const globalView = namespace('globalView');
const instances = namespace('instances');

interface MonthlyDonation {
  month: string;
  amount: string;
  nb: 495;
}

interface Segment {
  name: string;
  description: string;
  filter: RestParams;
}

@Component({
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContributionsDashboard extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @globalView.Getter instanceStats!: InstanceStatsInterface;

  @instances.Getter('item') instanceMeta!: Instance;
  @instances.Getter loading!: boolean;

  @contactsView.Mutation('setSelectedFilterIndex') setContactsSelectedFilterIndex!: any;
  @contactsView.Mutation('setDraftFilter') setContactsDraftFilter!: (filter: Filter | null) => void;

  segments: Segment[] = [
    {
      name: 'Contributions à renouveler',
      description: 'Personnes ayant fait une contribution en 2022, mais pas en 2023.',
      filter: { contribution_current_year: 0, contribution_last_year: '1:' },
    },
    {
      name: 'Membres anciens donateurs',
      description: 'Personnes ayant déjà fait une contribution, mais pas en 2022, ni en 2023.',
      filter: { contribution_current_year: 0, contribution_last_year: 0, contributions: 1 },
    },
    {
      name: 'Nouveaux dontaeurs à solliciter',
      description: 'Personnes n\'ayant jamais fait de contribution',
      filter: { contributions: 0, status: 'MER,MEC' },
    },
  ];

  documentation = [
    {
      class: 'third',
      image: '1-calls.png',
      name: 'Organiser une soirée d\'appels',
      description: 'Le B-A-BA d\'une soirée d\'appels réussie!',
      url: 'https://lacentrale.quebecsolidaire.net/hc/fr/articles/115001894447-Comment-organiser-une-tourn%C3%A9e-d-appels-',
    },
    {
      class: 'third',
      image: '2-fundraising.png',
      name: 'Organiser une opération de financement',
      description: 'Ne perdez plus de contributions avec des ',
      url: 'https://lacentrale.quebecsolidaire.net/hc/fr/articles/360039702113-Organiser-une-op%C3%A9ration-de-financement',
    },
    {
      class: 'third',
      image: '3-events.png',
      name: 'Faire du financement dans vos événements',
      description: 'Les événements de votre association, AG, bière ou autre, sont l\'occasion idéale de solliciter vos membres!',
      url: 'https://lacentrale.quebecsolidaire.net/hc/fr/articles/20959825775123-Faire-du-financement-dans-vos-%C3%A9v%C3%A9nements',
    },
    {
      class: 'half',
      image: null,
      name: 'Remplir une fiche de contribution',
      description: 'Assurez-vous de bien remplir les fiches de contribution avant de les renvoyer au Bureau national!',
      url: 'https://lacentrale.quebecsolidaire.net/hc/fr/articles/26416983691155-Comment-remplir-une-fiche-de-contribution',
    },
    {
      class: 'half',
      image: null,
      name: 'Gestion des certificats de sollicitation',
      description: 'Chaque militant·e qui fait de la sollicitation doit être muni·e d\'un certificat de sollicitation signée par votre représentant·e officiel·e.',
      url: 'https://lacentrale.quebecsolidaire.net/hc/fr/articles/26412270948115-Gestion-des-certificats-de-sollicitation',
    },
  ];

  get instanceStatsLoaded() {
    if (this.instanceStats && this.instanceStats.monthly_donations) {
      return true;
    }

    return false;
  }

  get contributionsSum() {
    if (!this.instanceStats || !this.instanceStats.monthly_donations) {
      return 0;
    }

    return this.instanceStats.monthly_donations
      .reduce((acc: number, item: MonthlyDonation) => acc + parseFloat(item.amount), 0);
  }

  mounted() {
    this.setGlobalSubtitle();
  }

  applyFilter(segment: Segment, to: 'contacts' | 'calls') {
    this.applyContactsDraftFilter(segment.filter, segment.name);
    switch (to) {
      case 'calls':
        this.$router.push('/call_campaigns/new');
        break;

      default:
        this.$router.push('/contacts');
        break;
    }
  }

  setGlobalSubtitle() {
    this.$store.commit('global/subtitle', this.currentInstanceName);
    this.$emit('updateHead');
  }

  private applyContactsDraftFilter(params: RestParams, filterName: string) {
    this.setContactsDraftFilter({
      id: null,
      filter: {
        ...params,
      },
      name: `Filtre temporaire (${filterName})`,
      repository: 'Contact',
    });

    this.setContactsSelectedFilterIndex(0);
  }

  /* private toNewCampaign(event: MouseEvent, campaignType: 'call' | 'mail', type: 'contacts' | 'volunteers') {
    switch (campaignType) {
      case 'call':
        this.goTo(event, {
          name: 'NewCallCampaignDialog',
          params: {
            type,
          },
        });
        break;
      case 'mail':
        this.goTo(event, {
          name: 'NewMailCampaignDialog',
          params: {
            type,
          },
        });
        break;
      default:
        break;
    }
  } */
}
