import { Module } from 'vuex';

import { Campaign } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { CampaignsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: CampaignsRestState = buildRestState<Campaign>('campaigns', {});

export const getters = buildRestGetters<Campaign, CampaignsRestState>();

export const actions = buildRestActions<Campaign, CampaignsRestState>();

export const mutations = buildRestMutations<Campaign, CampaignsRestState>();

export const campaigns: Module<CampaignsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
