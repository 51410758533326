





























































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCallCampaignProgress
  from 'qs_vuetify/src/components/CallCampaign/QsCallCampaignProgress.vue';
import QsCheckboxField from 'qs_vuetify/src/components/Fields/QsCheckboxField.vue';
import QsConfirmDialog from 'qs_vuetify/src/components/Dialog/QsConfirmDialog.vue';
import QsContactHistory from 'qs_vuetify/src/components/Contacts/QsContactHistory.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsLoadingIndicator from 'qs_vuetify/src/components/Indicators/QsLoadingIndicator.vue';
import QsSplitDateField from '@/components/CallCampaign/QsSplitDateField.vue';
import QsTextareaField from 'qs_vuetify/src/components/Fields/QsTextareaField.vue';
import QsThemedSelectField from 'qs_vuetify/src/components/Fields/QsThemedSelectField.vue';
import QsThemedTextareaField from 'qs_vuetify/src/components/Fields/QsThemedTextareaField.vue';
import QsThemedTextField from 'qs_vuetify/src/components/Fields/QsThemedTextField.vue';
import QsToggleField from 'qs_vuetify/src/components/Fields/QsToggleField.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import {
  CallCampaignStats,
  PersistedCallCampaign,
  PersistedContact,
  PersistedContactExchange,
  Tag,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import CallCampaignLeaderboard from '@/components/CallCampaign/CallCampaignLeaderboard.vue';
import ContactContributions from '@/components/Contact/ContactContributions.vue';
import ContactMemberships from '@/components/Contact/ContactMemberships.vue';
import ContactSources from '@/components/Contact/ContactSources.vue';
import ExchangeContactSection from '@/components/Contact/ExchangeContactSection.vue';
import SingleLineInfo from '@/components/SingleLineInfo.vue';

import completeMembership from '@/helpers/completeMembership';

import TagsMixin from '@/mixins/TagsMixin';
import { Form } from 'qs_vuetify/src/types/components';

const callCampaigns: any = namespace('call_campaigns');
const contactExchanges: any = namespace('contact_exchanges');
const global: any = namespace('global');
const seenContactExchanges: any = namespace('seen_contact_exchanges');
const view: any = namespace('callCampaignsView');

@Component({
  components: {
    CallCampaignLeaderboard,
    ContactContributions,
    ContactMemberships,
    ContactSources,
    ExchangeContactSection,
    QsActionModal,
    QsButton,
    QsCallCampaignProgress,
    QsCheckboxField,
    QsConfirmDialog,
    QsContactHistory,
    QsFormBuilder,
    QsLoadingIndicator,
    QsSplitDateField,
    QsTextareaField,
    QsThemedSelectField,
    QsThemedTextareaField,
    QsThemedTextField,
    QsToggleField,
    SingleLineInfo,
  },
})
export default class CallCampaignExchange extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  I18nMixin,
  NavigationMixin,
  TagsMixin,
) {
  @callCampaigns.Getter('item') callCampaign!: PersistedCallCampaign;
  @callCampaigns.Getter stats!: CallCampaignStats;
  @callCampaigns.Action sendPaperFormRequest!: any;

  @contactExchanges.Getter error!: ErrorResponse | null;
  @contactExchanges.Getter item!: PersistedContactExchange;
  @contactExchanges.Getter initialItem!: string;
  @contactExchanges.Getter loading!: boolean;
  @contactExchanges.Getter slug!: string;
  @contactExchanges.Mutation('item') syncItem!: any
  @contactExchanges.Mutation('initialItem') setInitialItem!: (val: string) => void;

  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;
  @global.Mutation showConfirmationDialog!: any;

  @seenContactExchanges.Getter('data') seenContacts!: PersistedContactExchange[];

  @view.Mutation addSeenContactId!: (id: number) => void;
  @view.Mutation removeSeenContactId!: (arg: number) => void;
  @view.Mutation setCallbackExchangeId!: (value: number | null) => void;
  @view.Mutation setRecallSameDay!: (value: boolean) => void;

  @view.Getter callbackExchangeId!: number | boolean;
  @view.Getter recallSameDay!: boolean;
  @view.Getter seenContactIds!: number[];

  @Getter('form', { namespace: 'contact_events' }) contactEventForm!: Form;
  @Prop({ type: [String, Number], required: true }) id!: string | number;

  get viewParams() {
    return {
      call_campaigns: {
        fields: [
          'script',
          'form_definition',
          'contact_exchanges.count',
          'name',
          'instance_id',
          'instance.name',
          'leave_message',
        ].join(','),
      },
      contact_exchanges: {
        fields: [
          'contact.id',
          'has_been_attempted_earlier_today',
          'reserved_at',
          'reserved_by_user.contact_name',
        ].join(','),
        '!contact_id': this.seenContactIds.join(','),
      },
    };
  }

  addTagInput = '';
  answered = true;
  endDialog = false;
  selectedTagId: number | null = null;
  showLeaderboard = false;
  showSeenContacts = false;
  jumpToPreviousExchangeLoading: number | string | null = null;
  shouldSendPaperFormRequest = false;

  get contact(): PersistedContact | null {
    return this.item?.contact || null;
  }

  get isContactUnsubEmail() {
    return this.contact?.tags.some((tag: Tag) => tag.id === 101);
  }

  set isContactUnsubEmail(val: boolean) {
    if (this.contact) {
      if (val) {
        this.putContactTag(this.contact.id, 101);
      } else {
        this.deleteContactTag(this.contact.id, 101);
      }
      this.reloadDataRoutesData();
    }
  }

  get isContactUnsubPhone() {
    return this.contact?.tags.some((tag: Tag) => tag.id === 102);
  }

  set isContactUnsubPhone(val: boolean) {
    if (this.contact) {
      if (val) {
        this.putContactTag(this.contact.id, 102);
      } else {
        this.deleteContactTag(this.contact.id, 102);
      }
      this.reloadDataRoutesData();
    }
  }

  get tags(): string[] {
    if (!this.item) {
      return [];
    }

    return this.item.tags?.map((tag: any) => tag.name) ?? [];
  }

  completeMembership = completeMembership;

  get status(): 'completed' | 'failed' {
    if (this.answered) {
      return 'completed';
    }

    if (this.callCampaign.leave_message && this.item.left_message) {
      return 'completed';
    }

    return 'failed';
  }

  async addSelectedTag() {
    if (this.contact && this.selectedTagId) {
      await this.putContactTag(this.contact.id, this.selectedTagId);
      this.selectedTagId = null;
      this.reloadDataRoutesData();
    }
  }

  async completeExchange() {
    if (this.shouldSendPaperFormRequest) {
      this.sendPaperFormRequest({
        callCampaignId: this.callCampaign.id,
        contactId: this.item.contact.id,
      });
    }

    this.syncItem({
      ...this.item,
      contact: {
        id: this.item.contact.id,
      },
      status: this.status,
      recall_at: this.answered ? null : this.item.recall_at,
      direction: this.callbackExchangeId ? 'incoming' : 'outgoing',
    });
    await this.submit();

    if (this.previousLocation && !this.callbackExchangeId) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
      return;
    }

    if (this.callbackExchangeId) {
      this.setCallbackExchangeId(null);
    }

    if (!this.item.recall_at) {
      await this.addSeenContactId(this.contact?.id || 0);
    }

    await this.goToNext();
  }

  confirmOrQuit(): void {
    if (this.hasChanged) {
      this.confirmThenQuit();
      return;
    }

    this.quit();
  }

  confirmOrSkipAndNext(): void {
    if (this.hasChanged) {
      this.confirmThenSkipAndNext();
      return;
    }

    this.skipAndNext();
  }

  confirmThenQuit() {
    this.showConfirmationDialog({
      callback: async () => {
        await this.quit();
      },
      color: 'warning',
      confirmText: 'Quitter',
      message: `Vous avez des changements non enregistrés. Si vous quittez cet appel, les changements seront perdus.
      Pour enregistrer le résultat de l'appel, choisissez plutôt Continuer, puis choisissez immédiatement Quitter
      pour arrêter de faire des appels.
      Êtes-vous sûr·e de vouloir arrêter de faire des appels?`,
      title: 'Quitter malgré les changements consignés',
    });
  }


  confirmThenSkipAndNext() {
    this.showConfirmationDialog({
      callback: async () => {
        await this.skipAndNext();
      },
      color: 'warning',
      confirmText: 'Passer',
      message: `Vous avez des changements non enregistrés. Si vous passez cet appel, les changements seront perdus.
      Pour enregistrer le résultat de l'appel, choisissez plutôt Continuer.
      Êtes-vous sûr·e de vouloir passer cet appel?`,
      title: "Passer à l'appel suivant malgré les changements consignés",
    });
  }

  async goToNext() {
    this.$router.replace(
      `/call_campaigns/${this.callCampaign.id}/contact_exchanges/next`,
    );

    try {
      await this.reloadDataRoutesData([
        'call_campaigns.stats',
        'contacts.index',
        'contact_exchanges.retrieve',
        'seen_contact_exchanges.index',
      ]);
    } catch (err) {
      // Noop: géré dans onErrorChanged
    }

    this.answered = true;
  }

  async jumpToPreviousExchange(contactId: number | string) {
    if (!contactId) {
      return;
    }

    this.jumpToPreviousExchangeLoading = contactId;

    await this.skip();
    const { data } = await axios.get(`/call_campaigns/${this.callCampaign.id}/contact_exchanges/next?contact_id=${contactId}`);

    if (data) {
      this.showSeenContacts = false;
      this.setCallbackExchangeId(data.id);
      this.removeSeenContactId(Number.parseInt(String(contactId), 10));
      this.goTo(null, {
        name: 'CallCampaign',
        params: {
          call_campaign_id: this.callCampaign.id.toString(),
          id: data.id,
        },
      });
      this.reloadDataRoutesData();
      this.setActions();
    }

    this.jumpToPreviousExchangeLoading = null;
  }

  onTagEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.addTagInput = '';
    }
  }

  async quit() {
    await this.skip();

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
      return;
    }

    if (this.userHas('CALL_CAMPAIGNS_RETRIEVE') && this.currentInstanceId === this.callCampaign.instance_id) {
      this.$router.replace(`/call_campaigns/${this.callCampaign.id}`);
    } else {
      this.$router.replace('/');
    }
  }

  async removeContact() {
    await axios.delete(
      `/call_campaigns/${this.callCampaign.id}/contacts/${this.contact?.id || 0}`,
    );

    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Contact retiré de la campagne.',
    });

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
      return;
    }

    await this.addSeenContactId(this.contact?.id || 0);

    await this.goToNext();
  }

  async skip() {
    await axios.put(
      `call_campaigns/${this.callCampaign.id}/contact_exchanges/${this.item.id}/skip`,
      { answers: {} },
    );

    await this.addSeenContactId(this.contact?.id || 0);
  }

  async skipAndNext() {
    await this.skip();

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
      return;
    }

    await this.goToNext();
  }

  setActions() {
    const actions = [
      {
        onClick: () => { this.showSeenContacts = true; },
        color: 'success',
        icon: 'mdi-phone-incoming',
        tooltip: 'Consigner un retour d\'appel',
      },
      {
        onClick: () => { this.showLeaderboard = true; },
        color: 'warning',
        icon: 'mdi-trophy',
        tooltip: 'Palmarès des appels',
      },
    ];

    this.$store.commit(
      'global/actions',
      actions,
    );
  }

  updateAnswers(input: any) {
    this.syncItem({
      ...this.item,
      answers: {
        ...input,
      },
    });
  }

  @Watch('answered')
  onCallCampaignExchangeAnsweredChanged(answered: boolean) {
    if (!answered) {
      this.updateAnswers({});
    }
  }

  @Watch('error')
  onErrorChanged(error: ErrorResponse | null): void {
    if (!error) {
      return;
    }

    const campaignCompleteNotification = {
      color: 'success',
      message: 'La campagne est complétée! Merci pour votre aide!',
    };

    switch (error.code) {
      case 'no_matching_contact_left':
        if (this.seenContactIds.length === 0) {
          this.$router.replace('/');
          this.$store.commit('global/addNotification', campaignCompleteNotification);
        } else if (!!this.stats
          && ((this.stats.failed_contact_exchanges || 0) > 0
            || (this.stats.unassigned_contacts || 0) > 0)) {
          this.endDialog = true;
        } else {
          this.$router.replace('/');
          this.$store.commit('global/addNotification', campaignCompleteNotification);
        }
        break;
      default:
        this.$router.replace('/');
        this.$store.commit('global/addNotification', {
          color: 'error',
          message: "Une erreur s'est produite.",
        });
        throw error;
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(loaded: boolean) {
    // Acceptable parce qu'on sait que l'URL contiendra parfois "skip" ou "next"
    // eslint-disable-next-line
    if (loaded) {
      if (this.id === 'next' || this.id !== this.item.id) {
        this.$router.replace(
          `/call_campaigns/${this.callCampaign.id}/contact_exchanges/${this.item.id}`,
        );
      }

      /**
       * Avertir le user si
       * l'échange a été tenté plus tôt ET
       * qu'il ne s'agit pas d'une consignation de retour d'appel ET
       * que le user n'a pas déjà rejeté l'avertissement concernant les rappels
       */
      if (
        this.item.has_been_attempted_earlier_today
        && !this.callbackExchangeId
        && !this.recallSameDay
      ) {
        this.endDialog = true;
      }

      this.setInitialItem(JSON.stringify(this.item));
    }

    this.setActions();
  }
}
