import { Module } from 'vuex';

import { Tag } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { TagsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const tagsState: TagsRestState = buildRestState<Tag>('tags', {});

export const getters = buildRestGetters<Tag, TagsRestState>();

export const actions = buildRestActions<Tag, TagsRestState>();

export const mutations = buildRestMutations<Tag, TagsRestState>();

export const tags: Module<TagsRestState, RootState> = {
  namespaced: true,
  state: tagsState,
  getters,
  actions,
  mutations,
};
