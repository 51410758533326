var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        color: "qs-grey",
        permanent: "",
        right: "",
        width: "512"
      }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: _vm.eventColor, flat: "", tile: "" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "text-uppercase", staticStyle: { color: "white" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.itemReady ? _vm.item.title : "Chargement") +
                  " "
              )
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "white", icon: "", to: "/events/calendar" } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _vm.itemReady
        ? _c(
            "div",
            {
              staticClass: "pa-2 d-flex flex-column",
              staticStyle: { height: "calc(100% - 64px - 16px)" }
            },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: {
                    focusable: "",
                    color: "transparent",
                    flat: "",
                    mandatory: "",
                    tile: ""
                  },
                  model: {
                    value: _vm.expansionPanel,
                    callback: function($$v) {
                      _vm.expansionPanel = $$v
                    },
                    expression: "expansionPanel"
                  }
                },
                _vm._l(_vm.formSections, function(ref, index) {
                  var section = ref.section
                  var fields = ref.fields
                  return _c(
                    "v-expansion-panel",
                    { key: fields.join("_") + "__" + index },
                    [
                      _c("v-expansion-panel-header", [
                        _c(
                          "div",
                          [
                            _vm.hasErrorsForFields(fields)
                              ? _c(
                                  "v-badge",
                                  {
                                    attrs: { color: "error", dot: "", left: "" }
                                  },
                                  [
                                    _c("div", {
                                      domProps: { innerHTML: _vm._s(section) }
                                    })
                                  ]
                                )
                              : _c("span", [
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(section) }
                                  })
                                ])
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.itemReady
                            ? _c("qs-form-builder", {
                                attrs: {
                                  error: _vm.error,
                                  form: _vm.form,
                                  item: _vm.item,
                                  "model-name": _vm.slug,
                                  order: fields
                                },
                                on: { input: _vm.updateItem },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "location",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("location-field", {
                                            attrs: {
                                              item: item,
                                              name: "location_field"
                                            },
                                            on: { input: _vm.updateItem }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("v-spacer"),
                  _vm.item && _vm.item.visibility === "public"
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "qs-button",
                                      _vm._g(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: "info",
                                            href:
                                              "https://mouvement.quebecsolidaire.net/carte/" +
                                              _vm.item.id,
                                            outlined: "",
                                            target: "_blank"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-map-marker-multiple")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2822943438
                          )
                        },
                        [_vm._v(" Voir sur Mouvement ")]
                      )
                    : _vm._e(),
                  _vm.id === "new"
                    ? _c(
                        "qs-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "error",
                            disabled: _vm.loading,
                            outlined: "",
                            loading: _vm.loading
                          },
                          on: { click: _vm.cancelCreate }
                        },
                        [_vm._v(" Annuler ")]
                      )
                    : _c(
                        "qs-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "info",
                            disabled: _vm.loading,
                            outlined: "",
                            loading: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              return _vm.goTo(null, {
                                name: "EventForm",
                                params: { id: _vm.id }
                              })
                            }
                          }
                        },
                        [_vm._v(" Voir les détails ")]
                      ),
                  _c(
                    "qs-button",
                    {
                      attrs: {
                        disabled: _vm.loading || !_vm.hasChanged,
                        loading: _vm.loading
                      },
                      on: { click: _vm.submit }
                    },
                    [
                      _vm.item.id
                        ? _c("span", [_vm._v(" Enregistrer ")])
                        : _c("span", [_vm._v("Créer")])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }