





















import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ElectionResultsMixin from '@/mixins/ElectionResultsMixin';

import { formatNumber, hexToHSL } from '@/helpers';

@Component
export default class ColorScale extends mixins(ElectionResultsMixin) {
  @Prop({ required: true, type: String }) color!: string;
  @Prop({ required: true, type: Number }) max!: number;
  @Prop({ required: true, type: Number }) min!: number;
  @Prop({ required: true, default: null }) value: number | null = null;

  formatNumber = formatNumber;

  get colorAsHSL() {
    return hexToHSL(this.color);
  }

  get maxColor() {
    const { h, s } = this.colorAsHSL;

    return `hsl(${h},${s}%,${(((1 - 1) * 60) + 40).toFixed(2)}%)`;
  }

  get minColor() {
    const { h, s } = this.colorAsHSL;

    return `hsl(${h},${s}%,${(((1 - 0) * 60) + 40).toFixed(2)}%)`;
  }
}
