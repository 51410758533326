import { render, staticRenderFns } from "./CallCampaignLeaderboard.vue?vue&type=template&id=3fa0d89e&"
import script from "./CallCampaignLeaderboard.vue?vue&type=script&lang=ts&"
export * from "./CallCampaignLeaderboard.vue?vue&type=script&lang=ts&"
import style0 from "./CallCampaignLeaderboard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_labase_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3fa0d89e')) {
      api.createRecord('3fa0d89e', component.options)
    } else {
      api.reload('3fa0d89e', component.options)
    }
    module.hot.accept("./CallCampaignLeaderboard.vue?vue&type=template&id=3fa0d89e&", function () {
      api.rerender('3fa0d89e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CallCampaign/CallCampaignLeaderboard.vue"
export default component.exports