import { Module } from 'vuex';

import { Duplicate } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { DuplicatesRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: DuplicatesRestState = buildRestState<Duplicate>('duplicates', {});

export const getters = buildRestGetters<Duplicate, DuplicatesRestState>();

export const actions = buildRestActions<Duplicate, DuplicatesRestState>();

export const mutations = buildRestMutations<Duplicate, DuplicatesRestState>();

export const duplicates: Module<DuplicatesRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
