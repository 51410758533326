import { Module } from 'vuex';

import { Objective } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { ObjectivesRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const state: ObjectivesRestState = buildRestState<Objective>('objectives', {});

export const getters = buildRestGetters<Objective, ObjectivesRestState>();

export const actions = buildRestActions<Objective, ObjectivesRestState>();

export const mutations = buildRestMutations<Objective, ObjectivesRestState>();

export const objectives: Module<ObjectivesRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
