var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-memberships" },
    [
      _c("single-line-info", {
        attrs: {
          label: "Première adhésion",
          value:
            _vm.item && _vm.item.first_membership
              ? _vm.item.first_membership.period_start
              : null
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("div", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm._f("date")(value)))
                ])
              ]
            }
          }
        ])
      }),
      _c("single-line-info", {
        attrs: {
          label: "Dernier renouvellement",
          value:
            _vm.item && _vm.item.last_membership
              ? _vm.item.last_membership.transaction.executed_at
              : null
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("div", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm._f("date")(value)))
                ])
              ]
            }
          }
        ])
      }),
      _c("single-line-info", {
        attrs: {
          label: "Échéance de l'adhésion",
          value:
            _vm.item && _vm.item.last_membership
              ? _vm.item.last_membership.period_end
              : null
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [
                _vm.item.dpa
                  ? _c("div", { staticClass: "text-uppercase text-right" }, [
                      _vm._v(" Abonné·e au DPA ")
                    ])
                  : _vm.item.stripe_customer &&
                    _vm.item.stripe_customer.membership_subscription_id
                  ? _c("div", { staticClass: "text-uppercase text-right" }, [
                      _vm._v(" Renouvellement automatique ")
                    ])
                  : _c("div", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm._f("date")(value)))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }