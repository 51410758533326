var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: [
        "qs-base-item-card",
        "call-campaign-card",
        { "call-campaign-card--archived": !!_vm.item.deleted_at }
      ],
      attrs: { flat: "", tile: "", to: _vm.to }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex pb-1 justify-end" },
        [
          _vm.showChecked
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected, "hide-details": "" },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex-grow text-truncate align-self-start mt-1" },
            [
              _c("h2", { staticClass: "text-h5 font-weight-bold mb-0" }, [
                _vm._v(" " + _vm._s(_vm.item.name) + " ")
              ]),
              _c("p", { staticClass: "body-1 my-0" }, [
                _vm._v(_vm._s(_vm.item.instance.name))
              ])
            ]
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "flex-shrink align-self-start mt-1 text-right" },
            [
              _c("p", { staticClass: "text-h6 mb-0" }, [
                _vm._v(
                  _vm._s(_vm._f("status")(_vm.item.status, "call_campaigns"))
                )
              ]),
              _vm.item.created_at === _vm.item.updated_at
                ? _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Créée par "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.item.created_by_user.contact_name))
                    ]),
                    _c("br"),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.created_at)) +
                        " "
                    )
                  ])
                : _vm.item.deleted_at
                ? _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Archivée "),
                    _vm.item.deleted_by_user
                      ? _c("span", [
                          _vm._v("par "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.item.deleted_by_user.contact_name)
                            )
                          ]),
                          _c("br")
                        ])
                      : _vm._e(),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.deleted_at)) +
                        " "
                    )
                  ])
                : _c("p", { staticClass: "body-2" }, [
                    _vm._v(" Dernière modification par "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.item.updated_by_user.contact_name))
                    ]),
                    _c("br"),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.updated_at)) +
                        " "
                    )
                  ])
            ]
          )
        ],
        1
      ),
      _vm.showText
        ? _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mt-3 call-campaign-card__description mb-3" },
              [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
            ),
            _c(
              "div",
              { staticClass: "call-campaign-card__stats" },
              [
                _c("qs-call-campaign-progress", {
                  attrs: { data: _vm.item.stats },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "chips",
                        fn: function() {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "mr-3",
                                attrs: {
                                  small: "",
                                  color:
                                    _vm.item.stats.users > 0 ? "info" : "error"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc(
                                        "call_campaigns.users_affected",
                                        _vm.item.stats.users
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    523353476
                  )
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }