var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "contact-form qs-blue--text pt-0",
      style:
        "min-height: calc(100vh - " +
        _vm.topOffset +
        "px - " +
        _vm.bottomOffset +
        "px)",
      attrs: { fluid: "" }
    },
    [
      _c("item-navigation", {
        staticClass: "my-3",
        attrs: {
          callback: _vm.loadNextPage,
          item: _vm.item,
          items: _vm.data,
          loading: _vm.loading,
          "model-name": _vm.slug,
          total: _vm.total
        }
      }),
      _vm.user
        ? _c(
            "v-row",
            {
              staticClass: "flex-grow-1 contact-form__main",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column contact-form__main__info",
                  staticStyle: { "row-gap": "1px" },
                  attrs: { cols: "12", lg: "9" }
                },
                [
                  _c("qs-contact-form-section", {
                    ref: "QsContactFormSection",
                    attrs: { id: _vm.id },
                    on: {
                      change: function($event) {
                        return _vm.$refs.QsContactHistory.fetchRevisions()
                      }
                    }
                  }),
                  _c("qs-contact-status-section", {
                    ref: "QsContactStatusSection",
                    attrs: { id: _vm.id },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "actions",
                          fn: function(ref) {
                            var contact = ref.item
                            var componentLoading = ref.loading
                            return [
                              _c(
                                "qs-button",
                                {
                                  attrs: {
                                    disabled: componentLoading,
                                    small: "",
                                    theme: "labase"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.completeMembership(
                                        contact,
                                        contact.email || ""
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(" Compléter une adhésion ")
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2919676040
                    )
                  }),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("qs-contact-events-section", {
                            attrs: {
                              form: _vm.contactEventForm,
                              id: _vm.id,
                              "instance-id": _vm.instanceId
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("qs-contact-tags-section", {
                            staticStyle: { "margin-left": "1px" },
                            attrs: {
                              id: _vm.id,
                              "instance-id": _vm.instanceId
                            },
                            on: {
                              change: function($event) {
                                return _vm.$refs.QsContactHistory.fetchTags()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-start contact-form__main__history",
                  staticStyle: { "min-height": "80vh" },
                  attrs: { cols: "12", lg: "3" }
                },
                [
                  _c(
                    "qs-contact-history",
                    {
                      ref: "QsContactHistory",
                      attrs: {
                        id: _vm.id,
                        "instance-id": _vm.instanceId,
                        user: _vm.user
                      },
                      on: {
                        reload: function($event) {
                          _vm.$refs.QsContactFormSection.reload()
                          _vm.$refs.QsContactStatusSection.reload()
                        }
                      }
                    },
                    [
                      _c(
                        "qs-button",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            color: "qs-orange",
                            disabled: !(_vm.routeDataLoaded && _vm.itemReady),
                            small: "",
                            theme: "labase",
                            width: "100%"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setCommentsModal(true)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-plus")]),
                          _vm._v(" Ajouter un commentaire ")
                        ],
                        1
                      ),
                      _c(
                        "qs-button",
                        {
                          attrs: {
                            color: "#C5E783",
                            disabled: !(_vm.routeDataLoaded && _vm.itemReady),
                            small: "",
                            theme: "labase",
                            width: "100%"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setContactExchangeModal(true)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-message")]),
                          _vm._v(" Échanger ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.item
        ? _c("contact-comments-modal", {
            attrs: { "contact-id": _vm.item.id, "view-params": _vm.viewParams }
          })
        : _vm._e(),
      _vm.item && _vm.item.contact_exchanges
        ? _c("add-specific-contact-to-call-campaign-modal", {
            attrs: {
              "contact-id": _vm.item.id,
              "excluded-campaign-ids": _vm.item.contact_exchanges.map(function(
                c
              ) {
                return c.call_campaign_id
              }),
              "view-params": _vm.viewParams
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }