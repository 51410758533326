























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsEventsIntentionIndicator
  from 'qs_vuetify/src/components/Indicators/QsEventsIntentionIndicator.vue';

@Component({
  components: {
    QsEventsIntentionIndicator,
  },
})
export default class ContactEventChip extends Vue {
  @Prop({ required: true, type: Object }) item!: any;
}
