


































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams, FiltersDefinition } from 'qs_vuetify/src/types/states';

import EventCard from '@/components/Event/EventCard.vue';
import { PersistedEvent } from 'qs_vuetify/src/types/models';
import { ButtonProps } from 'qs_vuetify/src/types/components';

const auth: any = namespace('auth');
const events: any = namespace('events');
const global: any = namespace('global');
const view: any = namespace('eventsView');

@Component({
  components: {
    EventCard,
    QsConfirmationModal,
    QsFilters,
    QsList,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Events extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  LazyListMixin,
  ListMixin,
) {
  @auth.Getter instanceId!: number | null;
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @events.Getter items!: Array<PersistedEvent>;
  @events.Getter error!: ErrorResponse;
  @events.Getter exportUrl!: string;
  @events.Getter filtersDefinition!: FiltersDefinition | null;
  @events.Getter loading!: boolean;
  @events.Getter slug!: string;
  @events.Getter total!: number;

  @view.Mutation addSelectedItem!: any;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: (arg: PersistedEvent) => void;
  @view.Getter selectedItems!: Array<PersistedEvent>;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  defaultParams = {
    fields: [
      'contacts_count',
      'created_at',
      'created_by_user.contact_name',
      'deleted_at',
      'deleted_by_user.contact_name',
      'description',
      'duration',
      'id',
      'instances.name',
      'location_name',
      'organized_by_user.contact_name',
      'start_at',
      'stats',
      'status',
      'title',
      'updated_at',
      'updated_by_user.contact_name',
      'visibility',
    ].join(','),
    'instances.id': this.currentInstanceId,
  }

  get viewParams() {
    return {
      events: {
        ...this.defaultParams,
        ...this.params,
        per_page: 20,
        order: '-id',
      },
    };
  }

  filtersLoaded = true;
  viewStoreName = 'eventsView';

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get showAlerts() {
    return this.$store.state.volunteersView.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('volunteersView/showAlerts', val);
  }

  mounted() {
    this.setActions();

    this.$store.commit('events/exportFields', [
      'id',
      'title',
      'start_at',
      'duration',
      'end_at',
      'location_name',
      'accessibility',
      'accessibility_notes',
      'visibility',
      'status',
    ]);

    if (this.items.length === 0) {
      this.loadFirstPage();
    }
  }

  archive() {
    this.confirmList(
      '',
      `Êtes-vous sûr-e de vouloir archiver ces événements?
      L'événement sera masqué dans la plupart des listes, mais les données seront conservées.`,
      'warning',
      'mdi-awrning',
      async () => {
        await Promise.all(this.selectedItems.filter((e) => !e.deleted_at).map(
          (e) => this.$store.dispatch('events/destroy', { id: e.id }),
        ));
        this.loadFirstPage();
      },
    );
  }

  async restore() {
    await Promise.all(this.selectedItems.filter((e) => !!e.deleted_at).map(
      (e) => this.$store.dispatch('events/restore', { id: e.id }),
    ));

    this.loadFirstPage();
  }

  setActions() {
    const actions: ButtonProps[] = [];

    if (this.userHas('EVENTS_CREATE')) {
      actions.push({
        onClick: () => { this.$router.push('events/new'); },
        color: 'primary',
        icon: 'mdi-plus',
        tooltip: 'Créer un événement',
      });
    }

    if (this.userHas('EVENTS_CREATE')) {
      actions.push({
        onClick: () => { this.$router.push({ name: 'EventsCalendar' }); },
        color: 'info',
        icon: 'mdi-calendar-month',
        tooltip: 'Basculer en vue calendrier',
      });
    }

    this.$store.commit(
      'global/actions',
      actions,
    );
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setActions();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(val: boolean) {
    if (val) {
      this.setActions();
    }
  }
}
