import Component, { mixins } from 'vue-class-component';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';

import {
  Contact,
  PersistedInstanceRole,
  PersistedInstanceUser,
} from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

interface Mandate extends Contact {
  [key: string]: any;
  roles: PersistedInstanceRole[];
  users: PersistedInstanceUser[];
}

@Component
export default class MandatesMixin extends mixins(AuthenticationMixin) {
  get mandates(): Mandate[] {
    const { getters } = this.$store;
    const contacts: { [key: number]: Mandate } = {};
    const instanceRoles = getters['instance_roles/data'];
    const instanceUsers = getters['instance_users/data'];

    const blank: Mandate = {
      id: null,
      roles: [],
      users: [],
    };

    instanceRoles.forEach((r: PersistedInstanceRole) => {
      const { contact } = r;
      if (contact && contact.id && !contacts[contact.id]) {
        contacts[contact.id] = { ...blank, ...contact };
      }
    });

    instanceUsers.forEach((u: PersistedInstanceUser) => {
      const { user: { contact } } = u;
      if (contact && contact.id && !contacts[contact.id]) {
        contacts[contact.id] = { ...blank, ...contact };
      }
    });

    return Object.values(contacts)
      .map((m: Mandate) => ({
        ...m,
        roles: instanceRoles.filter((r: PersistedInstanceRole) => r.contact_id === m.id)
          .map((r: PersistedInstanceRole) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { contact, ...rest } = r;
            return { ...rest };
          }),
        users: instanceUsers.filter((u: PersistedInstanceUser) => u.user.contact_id === m.id)
          .map((u: PersistedInstanceUser) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
            const { user: { contact }, ...rest } = u;
            return { ...rest };
          }),
      }))
      .sort((a, b) => {
        if (a && b && typeof a === 'object' && typeof b === 'object') {
          if (typeof a.last_name === 'string' && typeof b.last_name === 'string') {
            return a.last_name.localeCompare(b.last_name, 'fr');
          }
        }

        return 0;
      });
  }

  get mandatesLoading(): boolean {
    return this.$store.getters['instance_roles/loading']
      || this.$store.getters['instance_users/loading'];
  }

  getActiveMandatesForInstance(instanceId: number) {
    if (instanceId) {
      this.$store.commit('instance_roles/data', []);
      this.$store.commit('instance_users/data', []);
      this.getRolesUsersForInstance(instanceId, { is_active: true });
    }
  }

  getRolesUsersForInstance(instanceId: number | string, params: RestParams) {
    const contactFields: string[] = [
      'age',
      'birthdate',
      'district.name',
      'email',
      'first_name',
      'home_phone',
      'last_name',
      'status',
      'v1_contact_id',
    ];

    const fields: { [key: string]: string } = {
      instance_roles: [
        contactFields.map((f: string) => `contact.${f}`).join(','),
        'contact_id',
        'end_at',
        'instance_role_type.name',
        'responsibilities.name',
        'start_at',
      ].join(','),
      instance_users: [
        contactFields.map((f: string) => `user.contact.${f}`).join(','),
        'user.contact_id',
        'end_at',
        'rights_slugs',
        'start_at',
      ].join(','),
    };

    if (this.userHas('INSTANCE_ROLES_RETRIEVE')) {
      this.$store.dispatch('instance_roles/index', {
        ...params,
        fields: fields.instance_roles,
        '!instance_role_type_id': 6,
        per_page: '*',
        prefix: `/instances/${instanceId}`,
      });
    }

    if (this.userHas('INSTANCE_USERS_RETRIEVE')) {
      this.$store.dispatch('instance_users/index', {
        ...params,
        fields: fields.instance_users,
        per_page: '*',
        prefix: `/instances/${instanceId}`,
      });
    }
  }
}
